import moment from "moment";

export type AmortizationEntry = {
	date: string;
	expected_remaining_principal: number;
	remaining_principal: number;
	interest_payment: number;
	principal_payment: number;
	extra_monthly_payment: number;
};

export type AmortizationSchedule = AmortizationEntry[];

const roundToHundredths = (number: number): number => {
	return Math.round(number * 100) / 100;
};

export const generateAmortizationSchedule = ({
	start_date,
	loan_term_months,
	principal,
	interest_rate,
	extra_monthly_principal_payment = 0,
}: {
	start_date: moment.MomentInput;
	loan_term_months: number;
	principal: number;
	interest_rate: number;
	extra_monthly_principal_payment?: number;
}) => {
	const schedule: AmortizationSchedule = [];
	let remainingPrincipal = principal;
	let expectedRemainingPrincipal = principal;
	let monthlyInterestRate = interest_rate / 12 / 100;
	let monthlyPayment =
		roundToHundredths(principal * monthlyInterestRate) /
		(1 - Math.pow(1 + monthlyInterestRate, -loan_term_months));

	for (let month = 1; month <= loan_term_months; month++) {
		let interestPayment = roundToHundredths(
			remainingPrincipal * monthlyInterestRate
		);
		let principalPayment = roundToHundredths(monthlyPayment - interestPayment);

		expectedRemainingPrincipal -= principalPayment;

		// Apply the extra monthly principal payment
		principalPayment += extra_monthly_principal_payment;

		// Make sure we're not taking the remaining principal below zero
		if (remainingPrincipal - principalPayment < 0) {
			principalPayment = remainingPrincipal;
		}

		remainingPrincipal -= principalPayment;
		if (remainingPrincipal < 0) {
			remainingPrincipal = 0;
		}

		const entryDate = moment(start_date).add(month, "month");

		schedule.push({
			date: entryDate.format("YYYY-MM-DD"),
			expected_remaining_principal: roundToHundredths(
				expectedRemainingPrincipal
			),
			remaining_principal: roundToHundredths(remainingPrincipal),
			extra_monthly_payment: extra_monthly_principal_payment,
			interest_payment: interestPayment,
			principal_payment: principalPayment,
		});
	}

	return schedule;
};
