import { useAuthContext } from "context/AuthContext";
import { Label } from "elements/Input";
import { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import constants from "utils/constants";
import { useEventTracker } from "utils/useEventTracker";
// Set timeout so that user can copy again

const Reflink = ({
	referralLink,
	useLabel = false,
	label,
}: {
	referralLink: string;
	label?: string;
	useLabel?: boolean;
}) => {
	const { userData } = useAuthContext();
	const [copied, setCopied] = useState(false);
	const trackEvent = useEventTracker();

	// Timeout fucntion so we can recopy
	useEffect(() => {
		if (copied) {
			trackEvent({
				eventName: constants.EVENTS.Refer_Page_Share_Link_Button,
				data: {
					"User Created Date": userData?.created_at,
					Offer: userData?.referral_program,
				},
			});
			const handler = () => {
				setCopied(false);
			};
			const timeout = setTimeout(handler, 3000);
			return () => {
				clearTimeout(timeout);
			};
		}
	}, [copied]);

	return (
		<div id="referral-reflink-section">
			<div id="referral-reflink-text">
				<div id="referral-reflink-title">
					{useLabel ? (
						<Label>{label ? label : "Share your referral link"}</Label>
					) : (
						<h4>Share your referral link</h4>
					)}
				</div>
				<div>
					<form id="referral-reflink-form">
						<CopyToClipboard text={referralLink} onCopy={() => setCopied(true)}>
							<div className={`copy-btn${copied ? "-disabled" : ""}`}>
								<div className="referral-reflink-copy-container">
									<label id="referral-reflink-copy-text">
										{copied ? "Copied" : "Copy"}
									</label>
									<input
										disabled
										size={10}
										maxLength={10}
										className="referral-input"
										value={referralLink}
									/>
								</div>
							</div>
						</CopyToClipboard>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Reflink;
