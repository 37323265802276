const roundDownToNearest = (targetValue: number, roundBy: number): number => {
	return Math.floor(targetValue / roundBy) * roundBy;
};

const roundUpToNearest = (targetValue: number, roundBy: number): number => {
	return Math.ceil(targetValue / roundBy) * roundBy;
};

/**
 * Finds the domain range for a given data set (useful for stacked bar charts)
 * @param data - The data to find the domain range
 * @param minExpressions - The expressions to find the minimum value of each data set
 * @param maxExpressions - The expressions to find the maximum value of each data set
 */
export const findDomainRange = <T>(
	data: T[],
	minExpressions: Array<(arg: T) => number>,
	maxExpressions: Array<(arg: T) => number>
) => {
	// We have to use these methods instead of recharts inherint domain calculation feature domain={['dataMin', 'dataMax']}
	// ... because we are adding a dummy object in the case where there are only two years worth of data
	const min = data.reduce((acc, curr) => {
		const values = [
			acc,
			...minExpressions.map(expression => expression(curr)),
		].filter(v => !!v);
		return Math.min(...values);
	}, 0);
	const max = findDomainMax(data, maxExpressions);

	// Get delta between min and max
	const delta = max - min;

	// Get the base 10 of the delta (eg. Math.log10(3500) => 3.54)
	const base10 = Math.log10(delta);

	// Round down to the nearest place value of the true base  (eg. Math.floor(3.54) => 3)
	// ... (1 = 10s, 2 = 100s, 3 = 1000s, 4 = 10000s, etc.)
	const nearestPlaceValue = Math.floor(base10);

	// Get the stepAmount (eg. 10^3 => 1000)
	const stepAmount = Math.pow(10, nearestPlaceValue);

	// The domain start should be the min minus the step amount rounded down to the nearest step amount
	// ... or 0 if the result is negative
	const domainStart = Math.max(
		0,
		roundDownToNearest(min - stepAmount, stepAmount)
	);

	const domainEnd = roundUpToNearest(max, stepAmount);

	return {
		domainStart,
		domainEnd,
	};
};

/**
 * Finds the domain max for a given data set (useful for stacked bar charts)
 * @param data - The data to find the domain max
 * @param maxExpressions - The expressions to find the maximum value of each data set
 */
export const findDomainMax = <T>(
	data: T[],
	maxExpressions: Array<(arg: T) => number>
) => {
	return data.reduce((acc, curr) => {
		return Math.max(acc, ...maxExpressions.map(expression => expression(curr)));
	}, 0);
};
