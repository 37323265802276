import React, {
	createContext,
	FC,
	useContext,
	useEffect,
	useState,
} from "react";
import mixpanel from "mixpanel-browser";
import { hotjar } from "react-hotjar";
import { onOwnwellDotCom } from "utils/onOwnwellDotCom";
import { MIXPANEL_API_HOST } from "utils/useEventTracker";

export const MIXPANEL_TOKEN = "d7c9f62167a71e6b02d4a50f845342ef";
const HOTJAR_ID = 2770989;
const HOTJAR_VERSION = 6;

export interface IAppContext {
	referrer: string;
	landing: string;
	mixpanelInitialized: boolean;
	setMixpanelInitialized: React.Dispatch<React.SetStateAction<boolean>>;
	hotjarInitialized: boolean;
	setHotjarInitialized: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppContext = createContext<IAppContext>({
	referrer: "",
	landing: "",
	mixpanelInitialized: false,
	setMixpanelInitialized: () => {},
	hotjarInitialized: false,
	setHotjarInitialized: () => {},
});

export const AppContextProvider: FC = ({ children }) => {
	const [referrer, setReferrer] = useState("");
	const [landing, setLanding] = useState("");
	const [mixpanelInitialized, setMixpanelInitialized] = useState(false);
	const [hotjarInitialized, setHotjarInitialized] = useState(false);

	useEffect(() => {
		if (process.env.NODE_ENV === "production" && onOwnwellDotCom()) {
			mixpanel.init(MIXPANEL_TOKEN, {
				api_host: MIXPANEL_API_HOST,
			});
			setMixpanelInitialized(true);
			hotjar.initialize(HOTJAR_ID, HOTJAR_VERSION);
			setHotjarInitialized(true);
		}
	}, []);

	useEffect(() => {
		if (typeof window === "undefined") return;

		setReferrer(document.referrer);
		setLanding(window.location.pathname);
	}, []);

	return (
		<AppContext.Provider
			value={{
				referrer,
				landing,
				mixpanelInitialized,
				setMixpanelInitialized,
				hotjarInitialized,
				setHotjarInitialized,
			}}>
			{children}
		</AppContext.Provider>
	);
};

export const useAppContext = () => useContext(AppContext);
