import { Checkbox, TextField, Image } from "components";
import TextArea from "elements/Input/TextArea";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import client from "utils/client";
import {
	PropertySurveyPhotoUploadPrompt,
	usePropertyContext,
} from "../../context";
import QuestionCard from "components/elements/QuestionCard";
import { getMostRecentAppeal } from "utils/getMostRecentAppeal";
import { PropertySurveyEvidenceModal } from "../PropertySurveyEvidenceModal";
import NegativeImpactExample1 from "public/evidence-examples/negative-impact1.png";
import NegativeImpactExample2 from "public/evidence-examples/negative-impact2.png";
import NegativeImpactExample3 from "public/evidence-examples/negative-impact3.png";
import NegativeImpactExample4 from "public/evidence-examples/negative-impact4.png";
import formatDollar, { formatDollarStr } from "utils/formatDollar";
import { useEventTracker_DEPRECATED } from "utils/useEventTracker";
import constants from "utils/constants";
import { FileData, OneZeroOrNull } from "utils/types";
import formatDate from "utils/formatDate";
import { isValidDate } from "utils/validDate";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import ExitAreYouSure from "components/pages/Appeal/ExitAreYouSure";
import TimeForward from "public/time-forward.svg";
import {
	splitFileUploads,
	type FileUploadType,
} from "elements/Input/FileUpload";
import ButtonV2 from "elements/ButtonV2";

const allReasons = [
	"Pipeline easement",
	"Utilities easement",
	"Drainage easement",
	"Other",
];

const LIST_SEPERATOR = ",";

const Easement = () => {
	const {
		details,
		activeQuestionId,
		send,
		next,
		propertySurveyContext,
		allUploadedPropertySurveyFiles,
	} = usePropertyContext();

	const [filesToUpload, setFilesToUpload] = useState<FileUploadType[]>([]);
	const [negativeImpactList, setNegativeImpactList] = useState<string[]>(
		propertySurveyContext?.q_negative_impact_list?.split(",") || []
	);
	const [negativeImpactCost, setNegativeImpactCost] = useState(
		propertySurveyContext?.q_negative_impact_cost
			? formatDollar(propertySurveyContext?.q_negative_impact_cost)
			: ""
	);
	const [negativeImpactDesc, setNegativeImpactDesc] = useState(
		propertySurveyContext?.q_negative_impact_other || ""
	);
	const [negativeImpactDate, setNegativeImpactDate] = useState(
		propertySurveyContext?.q_negative_impact_date || ""
	);
	const [skipModalOpen, setSkipModalOpen] = useState(false);
	const [evidenceModalOpen, setEvidenceModalOpen] = useState(false);
	const [hasEvidenceModalBeenOpened, setHasEvidenceModalBeenOpened] =
		useState(false);

	const questionFiles = useMemo(() => {
		return allUploadedPropertySurveyFiles.filter(fileName =>
			fileName.startsWith("easement")
		);
	}, []); // We only want to derive this value on mount

	const filesUploaded = useMemo(() => {
		return questionFiles.length > 0 || filesToUpload.length > 0;
	}, [questionFiles, filesToUpload]);

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const handleSubmit = async () => {
		send({
			type: "update_context",
			context: {
				q_negative_impact: 1,
				q_negative_impact_list: negativeImpactList.join(LIST_SEPERATOR),
				q_negative_impact_cost: Number(
					negativeImpactCost.replace(/\D/g, "").trim()
				),
				q_negative_impact_other: negativeImpactDesc,
				q_negative_impact_date: negativeImpactDate,
			},
		});

		try {
			setSubmitting(true);
			setError(false);

			await client.submitQuestionAnswer({
				appealId: details!.appeal_id,
				questionId: activeQuestionId,
				payload: {
					value: true,
					list: negativeImpactList.join(LIST_SEPERATOR) || undefined,
					cost: negativeImpactCost.replace(/\D/g, "").trim() || undefined,
					desc: negativeImpactDesc || undefined,
					date: negativeImpactDate || undefined,
				},
			});

			if (filesToUpload.length) {
				const { errors, success } = await splitFileUploads({
					filesToUpload,
					updateFilesToUpload: setFilesToUpload,
					buildRequest: async fileToUpload => {
						const fd = new FormData();
						fd.append("easement", fileToUpload.file);
						await client.uploadFile({
							form: fd,
							appealId: details!.appeal_id.toString(),
							onProgress: pe => {
								setFilesToUpload(prev =>
									prev.map(fu => {
										if (fu.file !== fileToUpload.file) return fu;
										return {
											...fu,
											progress: pe,
										};
									})
								);
							},
						});
					},
				});

				send({
					type: "update_context",
					context: {
						files: [
							...propertySurveyContext.files,
							...success.map(fileToUpload => {
								const fileData: FileData = {
									file_name: "easement-" + fileToUpload.file.name,
									question: "easement",
									is_ownwell_document: 0,
								};
								return fileData;
							}),
						],
					},
				});

				if (errors.length > 0) {
					setError(true);
					return;
				}
			}

			if (!filesUploaded) {
				setSkipModalOpen(true);
			} else {
				next();
			}
		} catch (e) {
			console.error(e);
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	const btnDisabled = useMemo(() => {
		const dateIsValid =
			negativeImpactDate.length === 0 || negativeImpactDate.length === 10;
		return (
			submitting ||
			!negativeImpactList.length ||
			!negativeImpactDesc ||
			!negativeImpactCost ||
			!dateIsValid
		);
	}, [
		submitting,
		negativeImpactDesc,
		negativeImpactList,
		negativeImpactCost,
		negativeImpactDate,
	]);

	const handleTooltipClick = () => {
		setEvidenceModalOpen(true);
		setHasEvidenceModalBeenOpened(true);
	};

	const handleSkip = () => {
		if (!filesUploaded) {
			setSkipModalOpen(true);
		} else {
			next();
		}
	};

	const lastYear = useMemo(
		() => (details?.tax_year ?? new Date().getFullYear()) - 1,
		[details]
	);

	const costUnder5000 = useMemo(() => {
		return (
			negativeImpactCost !== "" &&
			Number(negativeImpactCost.replace(/\D/g, "")) < 5000
		);
	}, [negativeImpactCost]);

	const showDocumentsRecommendedWarning = useMemo(() => {
		if (
			(negativeImpactList.filter(i => i !== "Easement").length > 0 ||
				negativeImpactDesc !== "" ||
				negativeImpactCost !== "" ||
				negativeImpactDate !== "") &&
			!filesUploaded
		) {
			return true;
		}
		return false;
	}, [
		negativeImpactList,
		negativeImpactDesc,
		negativeImpactDate,
		negativeImpactCost,
		filesUploaded,
	]);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}>
			{evidenceModalOpen && <EvidenceModal setOpen={setEvidenceModalOpen} />}
			<QuestionCard
				id="negative-impact"
				tooltipText="We need documentation like a deed showing an easement and photos of the negative impact to earn a tax reduction. The assessor’s office rarely grants a tax reduction without documentation of an easement or recently dated photos."
				filesLabel="Deed granting easement and photos of impact"
				withRadio={false}
				alreadyUploaded={questionFiles}
				withFiles
				onTooltipClick={() => handleTooltipClick()}
				filesToUpload={filesToUpload}
				setFilesToUpload={setFilesToUpload}
				question={<p className="lg bold">Easement on the property</p>}
				subTitle={
					<p className="sm property-profile-question-card-subtitle denim-medium">
						We need documentation like a deed showing an easement and photos of
						the negative impact to earn a tax reduction.
						<br />
						<b>
							The assessor’s office rarely grants a tax reduction without
							documentation of an easement or recently dated photos.
						</b>
					</p>
				}>
				<div className="property-survey-question-section-seperator" />
				<div className="two-column-checkbox">
					{allReasons.map(name => (
						<Checkbox
							required={!negativeImpactList.length}
							key={"impact-" + name}
							name={name}
							label={name}
							labelEl="p"
							checked={negativeImpactList.includes(name)}
							value={name}
							onChange={() =>
								setNegativeImpactList(p =>
									p.includes(name) ? p.filter(e => e !== name) : p.concat(name)
								)
							}
						/>
					))}
				</div>
				<div className="property-survey-question-section-seperator" />
				<p className="bold">
					Describe the easement's negative impact on the property.*
				</p>
				<p className="sm denim-medium mb-1">
					Please describe how the easement affects the property including
					location affected.
				</p>
				<TextArea
					className="mb-2 w-100"
					value={negativeImpactDesc}
					onChange={e => setNegativeImpactDesc(e.target.value)}
					rows={4}
					required
					placeholder="Description of impact"
				/>
				<p className="bold">What is the estimated impact to property value?*</p>
				<p className="sm denim-medium mb-1">
					Values under $5,000 are unlikely to have an impact on the assessment
					value.
				</p>
				<TextField
					className={costUnder5000 ? "input-warning" : ""}
					numeric
					required
					placeholder="$"
					value={negativeImpactCost}
					onChange={e =>
						setNegativeImpactCost(formatDollarStr(e.target.value) ?? "")
					}
				/>
				{costUnder5000 && (
					<p className="sm terracotta">
						Values less than {formatDollarStr(5000)}, are rarely helpful in
						getting an assessment reduction.
					</p>
				)}
				<p className="bold mt-2">Date of photos</p>
				<p className="sm denim-medium mb-1">
					{PropertySurveyPhotoUploadPrompt}
				</p>
				<TextField
					maxLength={10}
					minLength={10}
					placeholder="MM/DD/YYYY"
					value={negativeImpactDate || ""}
					onChange={e => {
						const formatted = formatDate(e, negativeImpactDate || "");
						const valid = isValidDate(formatted);

						if (valid) {
							setNegativeImpactDate(formatted);
						}
					}}
				/>
				<div className="property-survey-question-section-seperator" />
			</QuestionCard>
			{showDocumentsRecommendedWarning && (
				<div className="property-profile-question-note-rust">
					<p className="sm">
						We recommend uploading supporting documentation before continuing.
						Evidence submitted without documentation rarely results in a
						reduction.
					</p>
				</div>
			)}
			<ButtonV2
				size="large"
				disabled={btnDisabled}
				type="submit"
				className="mx-auto">
				{submitting ? "Submitting..." : "Continue"}
			</ButtonV2>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
			<p className="profile-question-skip" onClick={() => handleSkip()}>
				Skip <RightArrow />
			</p>
			{skipModalOpen && (
				<ExitAreYouSure
					top="40%"
					label="Information Requested"
					body={
						<div style={{ marginTop: "8px" }}>
							<p>
								Evidence submitted without documentation rarely results in a
								reduction.
							</p>

							<br />
							<p>
								Please provide supporting documentation now or at a later time
								via your property portal or email.
							</p>
						</div>
					}
					exitBtnContent={
						<>
							<TimeForward />
							<p>Add Info Later</p>
						</>
					}
					onExit={() => {
						setSkipModalOpen(false);
						next(true);
					}}
					onCancel={() => {
						setSkipModalOpen(false);
					}}
				/>
			)}
		</form>
	);
};

const EvidenceModal = (props: {
	setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
	const { setOpen } = props;

	return (
		<PropertySurveyEvidenceModal onAttemptExit={() => setOpen(false)}>
			<p>
				<b>Evidence of Negative Impact</b>
			</p>
			<div className="property-survey-evidence-modal-body-text-container">
				<p className="sm">
					<b>
						Documentation showing easements and negative aspects of a property's
						location are helpful data points we can use to argue for a property
						tax reduction.
					</b>{" "}
					If circumstances surrounding your property reduce its potential resale
					value, please provide as much evidence and documentation as possible.
					<br />
					<br />
					Written descriptions are helpful. However, the assessor’s office
					rarely grants a tax reduction without documentation of an easement or
					dated photos.
				</p>
			</div>
			<p>Example Documents & Photos</p>
			<div className="property-survey-evidence-modal-image-container-grid">
				<Image
					// openInNewTab
					placeholder="blur"
					src={NegativeImpactExample4}
					alt="Negative impact example image"
					quality={100}
				/>
				<Image
					// openInNewTab
					placeholder="blur"
					src={NegativeImpactExample3}
					alt="Second negative impact example image"
					quality={100}
				/>
			</div>
			<ButtonV2
				size="mobile-extra-small"
				onClick={() => setOpen(false)}
				className="mx-auto">
				Back to Question
			</ButtonV2>
		</PropertySurveyEvidenceModal>
	);
};

export default Easement;
