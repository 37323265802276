import { Select, TextField } from "components";
import { ChangeEvent, useEffect, useState } from "react";
import { INSURANCE_CARRIER_OPTIONS } from "../utils";
import { withInsuranceSurvey } from "../withInsuranceSurvey";

const OTHER_OPTION_VALUE = "__OTHER__";

export const InsuranceCurrentProviderQuestion = withInsuranceSurvey(
	({ dispatchAnswerState, formRef, setNextBtnDisabled, answerState }) => {
		const [selectValue, setSelectValue] = useState(
			answerState && INSURANCE_CARRIER_OPTIONS.includes(answerState)
				? answerState
				: !!answerState
				? OTHER_OPTION_VALUE
				: ""
		);
		const [textFieldValue, setTextFieldValue] = useState(answerState ?? "");
		const [showTextField, setShowTextField] = useState(
			answerState && !INSURANCE_CARRIER_OPTIONS.includes(answerState)
		);

		useEffect(() => {
			setNextBtnDisabled(!selectValue);
		}, [setNextBtnDisabled, selectValue]);

		// useEffect(() => {
		// 	if (showTextField && textFieldValue && selectValue) {
		// 		setSelectValue("");
		// 	}
		// }, [showTextField, selectValue, textFieldValue]);

		useEffect(() => {
			if (selectValue !== OTHER_OPTION_VALUE) {
				setShowTextField(false);
			}
		}, [selectValue]);

		useEffect(() => {
			if (!formRef.current || !selectValue) return;

			const form = formRef.current;

			const handler = (e: SubmitEvent) => {
				e.preventDefault();
				dispatchAnswerState({
					id: "current_provider",
					payload: showTextField ? textFieldValue : selectValue,
				});
			};

			form.addEventListener("submit", handler);

			return () => {
				form.removeEventListener("submit", handler);
			};
		}, [
			formRef,
			showTextField,
			selectValue,
			textFieldValue,
			dispatchAnswerState,
		]);

		const commonProps = {
			required: true,
			onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
				const value = e.target.value;

				if (value === OTHER_OPTION_VALUE) {
					setShowTextField(true);
					setSelectValue(value);
					return;
				}

				if (e.target.nodeName === "SELECT") {
					setSelectValue(value);
				} else {
					setTextFieldValue(value);
				}
			},
		};

		return (
			<div>
				<p className="insurance-question-title">
					Current Home Insurance Provider
				</p>
				<div>
					<Select {...commonProps} value={selectValue}>
						<option hidden disabled value="">
							State Farm, Nationwide, etc.
						</option>
						{INSURANCE_CARRIER_OPTIONS.sort().map(carrier => (
							<option key={carrier} value={carrier}>
								{carrier}
							</option>
						))}
						<option value={OTHER_OPTION_VALUE}>It's not on the list</option>
						<option value="None">I don't currently have home insurance</option>
					</Select>
					{showTextField && (
						<TextField
							{...commonProps}
							containerClassName="insurance-current-provider-textfield"
							value={textFieldValue}
							placeholder="Current provider name"
						/>
					)}
				</div>
			</div>
		);
	}
);
