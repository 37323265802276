import { FC, SelectHTMLAttributes } from "react";
import Label from "./Label";
import states from "public/states.json";
import Select from "./Select";

interface SelectProps {
	label?: string;
	labelEl?: FC;
	labelBeside?: boolean;
	containerClassName?: string;
	useLongNames?: boolean;
}

const stateEntires = Object.entries(states);

const StateSelect: FC<
	SelectProps & SelectHTMLAttributes<HTMLSelectElement>
> = ({
	labelBeside = false,
	labelEl: UserLabelEl,
	useLongNames = false,
	containerClassName,
	required,
	children,
	placeholder,
	...inputProps
}) => {
	return (
		<div
			className={containerClassName}
			style={{
				display: "flex",
				flexDirection: labelBeside ? "row" : "column",
			}}>
			<Select required={required} {...inputProps}>
				{placeholder ? (
					<option value="" hidden disabled>
						{placeholder}&#8205;
					</option>
				) : null}
				{stateEntires.map(([shortName, longName], i) => (
					<option value={shortName} key={shortName + i}>
						{useLongNames ? longName : shortName}
					</option>
				))}
			</Select>
		</div>
	);
};

export default StateSelect;
