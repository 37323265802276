import { AnimatePresence, motion } from "framer-motion";

export const NoDataAvailable = () => {
	const motionProps = {
		initial: { opacity: 0 },
		animate: { opacity: 1 },
		transition: { type: "tween", delay: 0.3, duration: 0.6 },
	};

	return (
		<AnimatePresence>
			<motion.div {...motionProps} className="chart-no-data">
				<p className="lg bold">We're gathering the data...</p>
				<p className="sm">Come back tomorrow!</p>
			</motion.div>
		</AnimatePresence>
	);
};
