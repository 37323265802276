const formatDate = (event: any, state: string) => {
	if (!event.target.value) return "";

	if (state.startsWith(event.target.value)) {
		return event.target.value;
	}

	var date_raw = event.target.value.replace(/\D/g, "").substring(0, 8);

	if (state.length > date_raw) {
		return date_raw;
	}

	if (date_raw.length === 0) {
		date_raw = "";
	} else if (date_raw.length === 1) {
		if (date_raw !== "0" && date_raw !== "1") {
			date_raw = "0" + date_raw + "/";
		}
	} else if (date_raw.length === 2) {
		if (date_raw === "00") {
			date_raw = "0";
		} else if (parseInt(date_raw) > 12) {
			date_raw = "1";
		} else {
			date_raw = date_raw + "/";
		}
	} else if (date_raw.length === 3) {
		if (parseInt(date_raw.substring(2)) > 3) {
			date_raw = date_raw.substring(0, 2) + "/0" + date_raw.substring(2) + "/";
		} else {
			date_raw = date_raw.substring(0, 2) + "/" + date_raw.substring(2);
		}
	} else if (date_raw.length === 4) {
		if (date_raw[2] === "3" && parseInt(date_raw[3]) > 1) {
			date_raw = date_raw.substring(0, 2) + "/30/" + date_raw[3];
		} else {
			date_raw = date_raw.substring(0, 2) + "/" + date_raw.substring(2) + "/";
		}
	} else {
		date_raw =
			date_raw.substring(0, 2) +
			"/" +
			date_raw.substring(2, 4) +
			"/" +
			date_raw.substring(4);
	}
	return date_raw;
};

// const formatDate = (value: string) => {
//   let v = value.replace(/\D/g, "").slice(0, 10);
//   if (v.length >= 5) {
//     return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
//   } else if (v.length >= 3) {
//     return `${v.slice(0, 2)}/${v.slice(2)}`;
//   }
//   return v;
// };

export default formatDate;
