import { Label, QuickLink, TextField } from "components";
import { useAuthContext } from "context/AuthContext";
import {
	Dispatch,
	memo,
	RefObject,
	SetStateAction,
	useEffect,
	useState,
} from "react";
import client from "utils/client";
import formatDate from "utils/formatDate";
import { InsuranceAnswerReducerState } from "utils/types";
import { isValidDate } from "utils/validDate";
import { usePropertyContext } from "../context";
import { InsuranceSurveyStage } from "./InsuranceSurvey";
import EditIcon from "public/edit.svg";
import { isInsuranceStageDone } from "./utils";
import { formatDollarStr } from "utils/formatDollar";
import { useEventTracker } from "utils/useEventTracker";
import constants from "utils/constants";
import { sendWebhookToInsurance } from "utils/sendWebhook";
import ButtonV2 from "elements/ButtonV2";
import Link from "next/link";

interface ConfirmationDataCardProps {
	title: string;
	data: {
		[key: string]: string | number | null | undefined;
	};
	onRequestEdit?: () => void;
	className?: string;
}

export const ConfirmationDataCard = (props: ConfirmationDataCardProps) => {
	const { title, data, onRequestEdit } = props;

	const showEditBtn = !!onRequestEdit;

	return (
		<div
			className={[
				"insurance-survey-confirmation-card",
				props.className ? props.className : "",
			].join(" ")}>
			<div id="insurance-survey-confirmation-edit-details-title-row">
				<p className="sm bold mb-1">{title}</p>
				{showEditBtn && (
					<EditIcon
						id={"insurance-survey-confirmation-edit-icon"}
						onClick={onRequestEdit}
					/>
				)}
			</div>
			<div>
				{Object.entries(data).map(([key, value], i) => (
					<div
						key={key + value + i}
						className="insurance-survey-confirmation-card-row">
						<Label>{key}</Label>
						<p className="sm no-translate">{value || "-"}</p>
					</div>
				))}
			</div>
		</div>
	);
};

const ConfirmationPropertyDetailsCard = memo(
	function ConfirmationPropertyDetailsCard(props: {
		stage: InsuranceSurveyStage;
		editingPropertyDetails: boolean;
		setEditingPropertyDetails: Dispatch<SetStateAction<boolean>>;
	}) {
		const { setEditingPropertyDetails, editingPropertyDetails, stage } = props;
		const { details, setDetails } = usePropertyContext();

		const [loading, setLoading] = useState(false);

		const [purchaseDate, setPurchaseDate] = useState(
			(details?.last_sale_date_prop || "").toString()
		);
		const [yearBuilt, setYearBuilt] = useState(
			(details?.year_built || "").toString()
		);
		const [sqft, setSqft] = useState(
			(details?.sqft_portal_resi_imprv || "").toString()
		);

		useEffect(() => {
			if (stage !== "confirmation" && editingPropertyDetails) {
				setEditingPropertyDetails(false);
			}
		}, [stage, editingPropertyDetails, setEditingPropertyDetails]);

		const onSavePropertyDetails = async () => {
			try {
				if (!details) return;

				setLoading(true);

				const yearBultNum = Number(yearBuilt);
				const sqftNum = Number(sqft);
				const saleDateClean = purchaseDate.trim();

				if (isNaN(yearBultNum) || isNaN(sqftNum)) {
					return;
				}

				await client.updatePropertyDetails({
					id: details.id,
					appealId: details.appeal_id,
					type: "edit_details",
					payload: {
						last_sale_date_prop: saleDateClean,
						year_built: yearBultNum,
						sqft_portal_resi_imprv: sqftNum,
					},
				});

				setDetails(prev =>
					prev
						? {
								...prev,
								last_sale_date_prop: saleDateClean,
								year_built: yearBultNum,
								sqft_portal_resi_imprv: sqftNum,
						  }
						: prev
				);

				setEditingPropertyDetails(false);
			} catch (e) {
				console.error(e);
				//TODO: Error handling
			} finally {
				setLoading(false);
			}
		};

		const data = {
			"Purchase Date": purchaseDate,
			"Year Built": yearBuilt,
			"Square Feet": sqft,
		};

		return (
			<div
				className="insurance-survey-confirmation-card"
				id="insurance-survey-confirmation-edit-details-container">
				<div id="insurance-survey-confirmation-edit-details-title-row">
					<p className="insurance-survey-confirmation-card-title">
						<b>Property Info</b>
					</p>
					<EditIcon
						id={
							"insurance-survey-confirmation-edit-icon" +
							(editingPropertyDetails ? "-active" : "")
						}
						onClick={() => setEditingPropertyDetails(p => !p)}
					/>
				</div>
				<div>
					{Object.entries(data).map(([key, value], i) => (
						<div
							className="insurance-survey-confirmation-card-row"
							key={"input-" + i}>
							<TextField
								key={"input-" + i}
								label={key}
								placeholder={key}
								numeric={key !== "Purchase Date"}
								containerClassName={
									"insurance-survey-confiramtion-edit-details-input " +
									(key === "Square Feet" ? "sq-ft-input" : "")
								}
								value={value}
								onChange={e => {
									if (key === "Purchase Date") {
										const formatted = formatDate(e, purchaseDate);
										const valid = isValidDate(formatted);

										if (valid) setPurchaseDate(formatted);
									} else if (key === "Year Built") {
										const num = Number(e.target.value);

										if (!isNaN(num)) {
											setYearBuilt(num.toString());
										}
									} else if (key === "Square Feet") {
										const num = Number(e.target.value);

										if (!isNaN(num)) {
											setSqft(num.toString());
										}
									}
								}}
							/>
						</div>
					))}
				</div>
				<div id="insurance-survey-confirmation-save-details-container">
					<ButtonV2
						size="extra-small"
						disabled={loading}
						variant="primary-outline"
						onClick={onSavePropertyDetails}
						className="insurance-survey-confirmation-save-details-cta">
						Save Changes
					</ButtonV2>
					{!loading && (
						<QuickLink
							reverse
							asButton
							className="insurance-survey-confirmation-save-details-cta"
							onClick={() => setEditingPropertyDetails(false)}>
							Back
						</QuickLink>
					)}
				</div>
			</div>
		);
	}
);

interface InsuranceSurveyConfirmationProps {
	stage: InsuranceSurveyStage;
	setStage: Dispatch<SetStateAction<InsuranceSurveyStage>>;
	confirmationRef: RefObject<HTMLDivElement>;
	answerState: InsuranceAnswerReducerState;
	onResetSurvey: () => void;
	error: boolean;
	setError: Dispatch<SetStateAction<boolean>>;
}

export const InsuranceSurveyConfirmation = (
	props: InsuranceSurveyConfirmationProps
) => {
	const { error, stage, setError, setStage, answerState, confirmationRef } =
		props;

	const { userData } = useAuthContext();

	const { details } = usePropertyContext();

	const [editingPropertyDetails, setEditingPropertyDetails] = useState(false);

	const trackEvent = useEventTracker();

	const onConfirm = async () => {
		try {
			setError(false);
			// setStage("loading");
			setStage("activated");
			await client.submitInsuranceSurvey({
				meta: { customer_id: userData!.id, property_id: details!.id },
				answers: answerState,
			});
			trackEvent({
				eventName: constants.EVENTS.INS_SURVEY_COMPLETE,
				data: {
					UserId: userData!.id,
				},
			});
			sendWebhookToInsurance(
				"Insurance Survey Complete. User ID: " +
					userData!.id +
					" Address: " +
					details?.address +
					(details ? " " + details?.address2 : "") +
					" " +
					details?.city +
					" " +
					details?.state +
					" " +
					details?.zip_code +
					"/n https://www.realappeal.tax/customer/" +
					userData!.id
			);
		} catch (e) {
			setStage("confirmation");
			setError(true);
			console.error(e);
		}
	};

	return (
		<div
			ref={confirmationRef}
			id={
				"insurance-survey-confirmation" +
				(stage === "confirmation"
					? "-active"
					: isInsuranceStageDone("confirmation", stage)
					? "-done"
					: "")
			}>
			<h4>Review your details</h4>
			<p className="sm insurance-survey-confirmation-title-body">
				We just want to confirm the details you've provided. If things change in
				the near future, we can always update your information for you.
			</p>
			<div id="insurance-survey-confirmation-card-container">
				{editingPropertyDetails ? (
					<ConfirmationPropertyDetailsCard
						editingPropertyDetails={editingPropertyDetails}
						setEditingPropertyDetails={setEditingPropertyDetails}
						stage={stage}
					/>
				) : (
					<>
						<ConfirmationDataCard
							title="Current Policy"
							data={{
								Company: answerState.current_provider,
								Premium: answerState.current_premium
									? `${formatDollarStr(answerState.current_premium)}/yr`
									: "",
								Deductible: answerState.current_deductible
									? formatDollarStr(answerState.current_deductible)
									: "",
							}}
						/>
						<ConfirmationDataCard
							title="Personal Info"
							data={{
								"Date of Birth": answerState.birthday,
								// "Credit Range": answerState.credit_range,
								"Marital Status": answerState.marital_status
									? answerState.marital_status[0].toUpperCase() +
									  answerState.marital_status.slice(1).toLowerCase()
									: null,
							}}
						/>
						<ConfirmationDataCard
							title="Property Info"
							data={{
								"Purchase Date": details?.last_sale_date_prop,
								"Year Built": details?.year_built,
								"Square Feet": details?.sqft_portal_resi_imprv,
							}}
							onRequestEdit={() => setEditingPropertyDetails(true)}
						/>
					</>
				)}
			</div>
			<div id="insurance-survey-confirmation-mobile-cta">
				<ButtonV2
					size="large"
					disabled={editingPropertyDetails}
					onClick={onConfirm}>
					Submit
				</ButtonV2>
				{error && (
					<p className="sm error-text">
						Whoops, something went wrong. Please try again.
					</p>
				)}
			</div>
			<p className="body-tiny mt-2">
				By clicking "Submit," you agree that your information from your Ownwell
				account will be shared with third party partners to assist in obtaining
				insurance quotes. You also acknowledge that partners may gather data
				from reputable third parties, including your driving records, credit
				history, and insurance claims history, to provide accurate rates.
			</p>
			<div id="insurance-survey-confirmation-cta">
				<ButtonV2
					size="large"
					disabled={editingPropertyDetails}
					onClick={onConfirm}>
					Submit
				</ButtonV2>
				{error && (
					<p className="sm error-text">
						Whoops, something went wrong. Please try again.
					</p>
				)}
			</div>
		</div>
	);
};
