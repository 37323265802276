import { MIXPANEL_TOKEN, useAppContext } from "context/AppContext";
import mixpanel, { type Dict } from "mixpanel-browser";
import { useCallback } from "react";
import { onOwnwellDotCom } from "./onOwnwellDotCom";
import { sendErrorToWebhook } from "./sendWebhook";

export const MIXPANEL_API_HOST = "https://www.ownwell.com/mp";

export const useEventTracker_DEPRECATED = (eventName: string, data?: any) => {
	const { mixpanelInitialized, setMixpanelInitialized } = useAppContext();

	return useCallback(
		(eventData?: any) => {
			if (process.env.NODE_ENV === "production" && onOwnwellDotCom()) {
				if (!mixpanelInitialized) {
					mixpanel.init(MIXPANEL_TOKEN, {
						api_host: MIXPANEL_API_HOST,
					});
					setMixpanelInitialized(true);
				}
				try {
					mixpanel.track(eventName, eventData ?? data);
				} catch (e) {
					sendErrorToWebhook(
						`ERROR CAUGHT WHILE TRACKING MIXPANEL EVENT ${eventName}:\n${JSON.stringify(
							e
						)}`
					);
				}
			} else {
				console.info("WOULD FIRE MIXPANEL EVENT: ", eventName, {
					data: eventData ?? data,
				});
			}
		},
		[data, eventName, mixpanelInitialized]
	);
};

export const useEventTracker = () => {
	const { mixpanelInitialized, setMixpanelInitialized } = useAppContext();

	return useCallback(
		({ eventName, data }: { eventName: string; data?: Dict }) => {
			if (process.env.NODE_ENV === "production" && onOwnwellDotCom()) {
				if (!mixpanelInitialized) {
					mixpanel.init(MIXPANEL_TOKEN, {
						api_host: MIXPANEL_API_HOST,
					});
					setMixpanelInitialized(true);
				}
				try {
					mixpanel.track(eventName, data);
				} catch (e) {
					sendErrorToWebhook(
						`ERROR CAUGHT WHILE TRACKING MIXPANEL EVENT ${eventName}:\n${JSON.stringify(
							e
						)}`
					);
				}
			} else {
				console.info("WOULD FIRE MIXPANEL EVENT: ", eventName, {
					data: data,
				});
			}
		},
		[mixpanelInitialized]
	);
};
