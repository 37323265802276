import { Label } from "elements/Input";
import Tooltip from "elements/Tooltip";

export const EXPENSES_PROPERTY_TAX_TOOLTIP =
	"Property tax is money you pay each year to the local government based on the value of your land and home. This tax helps fund things like schools and roads in your community.";
export const EXPENSES_INSURANCE_TOOLTIP =
	"Home insurance is a financial protection policy that covers damages or losses to a homeowner's property and possessions. It helps safeguard against risks like natural disasters, theft, or accidents, providing financial support for repairs or replacements.";
export const EXPENSES_UTILITIES_TOOLTIP =
	"Utilities are essential services like electricity, water, and gas that keep our homes running. They're provided by either the government or private companies to ensure we have what we need for daily life.";
export const EXPENSES_PMI_TOOLTIP =
	"PMI, or Private Mortgage Insurance, is a type of insurance that lenders may require from homebuyers who make a down payment of less than 20% on a home. It protects the lender in case the borrower defaults on the loan, but it adds an additional cost to the borrower's monthly mortgage payments.";
export const EXPENSES_MORTGAGE_PRINCIPAL_TOOLTIP =
	"Mortgage principal is the original amount of money borrowed to buy a home, excluding interest. As the borrower makes payments, the principal decreases, and it represents the remaining balance on the loan.";
export const EXPENSES_MORTGAGE_INTEREST_TOOLTIP =
	"Mortgage interest is the cost of borrowing money to purchase a home, expressed as a percentage of the loan amount. It is a fee paid to the lender in addition to repaying the principal amount borrowed.";
export const EXPENSES_ESCROW_TOOLTIP =
	"Mortgage interest is the cost of borrowing money to purchase a home, expressed as a percentage of the loan amount. It is a fee paid to the lender in addition to repaying the principal amount borrowed.";
export const EXPENSES_INTEREST_RATE_TOOLTIP =
	"A loan interest rate is the percentage charged by a lender on the amount borrowed, representing the cost of borrowing";

export const createTooltipLabel = (props: {
	label: string;
	tooltip: string;
	disabled?: boolean;
}) => {
	return function LabelWithTooltip() {
		return (
			<div
				className={
					"flex items-center expenses-tooltip-label" +
					(props.disabled ? "-disabled" : "")
				}>
				<Label>{props.label} *</Label>
				<Tooltip noLabel text={props.tooltip} />
			</div>
		);
	};
};
