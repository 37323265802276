import { Radio } from "components";
import FileUpload, { FileUploadType } from "elements/Input/FileUpload";
import Tooltip from "elements/Tooltip";
import React, {
	Dispatch,
	FC,
	ReactElement,
	SetStateAction,
	isValidElement,
} from "react";
import { Expand } from ".";

interface IQuestionCardProps {
	id: string;
	value?: boolean | undefined;
	setValue?:
		| Dispatch<SetStateAction<boolean | undefined>>
		| ((value: boolean | undefined) => void);
	tooltipText: string;
	alreadyUploaded?: string[];
	filesToUpload?: FileUploadType[];
	setFilesToUpload?: Dispatch<SetStateAction<FileUploadType[]>>;
	filesLabel?: string;
	question?: ReactElement | string;
	subTitle?: ReactElement | string;
	withFiles?: boolean;
	inverted?: boolean;
	lastYearNote?: string | null;
	onTooltipClick?: () => void;
	renderAboveQuestion?: () => JSX.Element;
	withRadio?: boolean;
}

const QuestionCard: FC<IQuestionCardProps> = ({
	id,
	children,
	subTitle,
	value,
	setValue,
	tooltipText,
	withFiles = false,
	alreadyUploaded,
	filesLabel,
	inverted,
	filesToUpload,
	setFilesToUpload,
	question,
	onTooltipClick,
	lastYearNote,
	renderAboveQuestion,
	withRadio = true,
}) => {
	return (
		<div className="property-profile-question-card">
			<div className="property-profile-question-card-top">
				{!!renderAboveQuestion && renderAboveQuestion()}
				<div className="property-profile-question-card-title-container">
					{isValidElement(question) ? question : <p>{question}</p>}
					{withRadio && setValue && (
						<div className="property-profile-question-card-radio">
							<Radio
								name={id}
								checked={value === true}
								onChange={e => {
									//@ts-ignore
									const value = e.target.value === "Yes";
									setValue(value);
								}}
								value="Yes"
								label="Yes"
							/>
							<Radio
								name={id}
								checked={value === false}
								onChange={e => {
									//@ts-ignore
									const value = e.target.value === "Yes";
									setValue(value);
								}}
								value="No"
								label="No"
							/>
						</div>
					)}
				</div>
				{isValidElement(subTitle) ? (
					subTitle
				) : (
					<p className="sm property-profile-question-card-subtitle denim-medium">
						{subTitle}
					</p>
				)}
				{!!onTooltipClick ? (
					<Expand reverse noSwitch onClick={onTooltipClick}>
						What evidence is helpful?
					</Expand>
				) : tooltipText ? (
					<Tooltip text={tooltipText} />
				) : null}
			</div>
			{!!lastYearNote && (
				<div className="property-profile-question-card-last-year-note">
					<p className="sm">{lastYearNote}</p>
				</div>
			)}
			{(children || withFiles) && (
				<div className="property-profile-question-card-bottom">
					{children && <div>{children}</div>}
					{withFiles && filesToUpload && setFilesToUpload && (
						<FileUpload
							alreadyUploaded={alreadyUploaded}
							label={filesLabel}
							filesToUpload={filesToUpload}
							updateFilesToUpload={setFilesToUpload}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default QuestionCard;
