import { Label } from "components";
import {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useMemo,
	useState,
} from "react";
import client from "utils/client";
import { usePropertyContext } from "components/pages/Account/Property/context";
import { getMostRecentAppeal } from "utils/getMostRecentAppeal";
import ExitAreYouSure from "components/pages/Appeal/ExitAreYouSure";
import TimeForward from "public/time-forward.svg";
import DownloadIcon from "public/download.svg";
import QuestionCard from "elements/QuestionCard";
import { PropertySurveyEvidenceModal } from "../PropertySurveyEvidenceModal";
import { getLastYearAppeal } from "utils/getLastYearAppeal";
import { useEventTracker_DEPRECATED } from "utils/useEventTracker";
import constants from "utils/constants";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import { FileData } from "utils/types";
import {
	splitFileUploads,
	type FileUploadType,
} from "elements/Input/FileUpload";
import ButtonV2 from "elements/ButtonV2";

const IncomeProducing = () => {
	const {
		next,
		send,
		details,
		propertySurveyContext,
		allUploadedPropertySurveyFiles,
	} = usePropertyContext();

	const [filesToUpload, setFilesToUpload] = useState<FileUploadType[]>([]);

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);
	const [skipModalOpen, setSkipModalOpen] = useState(false);
	const [hasModalBeenOpened, setHasModalBeenOpened] = useState(false);

	const lastYear = useMemo(
		() => (details?.tax_year ?? new Date().getFullYear()) - 1,
		[details]
	);

	const [evidenceModalOpen, setEvidenceModalOpen] = useState(false);

	const questionFiles = useMemo(() => {
		return allUploadedPropertySurveyFiles.filter(fileName =>
			fileName.startsWith("incomeProducing")
		);
	}, []); // We only want to derive this value on mount

	const filesUploaded = useMemo(() => {
		return questionFiles.length > 0 || filesToUpload.length > 0;
	}, [questionFiles, filesToUpload]);

	const handleOnToolipClick = () => {
		setHasModalBeenOpened(true);
		setEvidenceModalOpen(true);
	};

	const handleSkip = () => {
		if (filesUploaded) {
			next();
		} else {
			setSkipModalOpen(true);
		}
	};

	const handleSubmit = async () => {
		try {
			setSubmitting(true);
			setError(false);
			if (filesToUpload.length) {
				const { errors, success } = await splitFileUploads({
					filesToUpload,
					updateFilesToUpload: setFilesToUpload,
					buildRequest: async fileToUpload => {
						const fd = new FormData();
						fd.append("incomeProducing", fileToUpload.file);
						await client.uploadFile({
							form: fd,
							appealId: details!.appeal_id.toString(),
							onProgress: pe => {
								setFilesToUpload(prev =>
									prev.map(fu => {
										if (fu.file !== fileToUpload.file) return fu;
										return {
											...fu,
											progress: pe,
										};
									})
								);
							},
						});
					},
				});

				send({
					type: "update_context",
					context: {
						files: [
							...propertySurveyContext.files,
							...success.map(fileToUpload => {
								const fileData: FileData = {
									file_name: "incomeProducing-" + fileToUpload.file.name,
									question: "incomeProducing",
									is_ownwell_document: 0,
								};
								return fileData;
							}),
						],
					},
				});

				if (errors.length > 0) {
					setError(true);
					return;
				}
			}

			if (!filesUploaded) {
				setSkipModalOpen(true);
			} else {
				next();
			}
		} catch (e) {
			setError(true);
			console.error("ERROR", e);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<div>
			{evidenceModalOpen && <EvidenceModal setOpen={setEvidenceModalOpen} />}
			<QuestionCard
				id="income-producing"
				tooltipText="Income producing property is assessed differently, typically requiring P&L and/or rent rolls."
				onTooltipClick={() => handleOnToolipClick()}
				question={
					<p className="lg bold">
						Monthly profit & loss statements and rent rolls by unit.
					</p>
				}
				subTitle="These documents are only required when a property has two or more units."
				alreadyUploaded={questionFiles}
				filesLabel={`${lastYear} Monthly profit & Loss Statements & ${lastYear} Monthly Rent Rolls by Unit *`}
				withFiles
				filesToUpload={filesToUpload}
				setFilesToUpload={setFilesToUpload}
			/>
			{!filesUploaded && (
				<div className="property-profile-question-note-rust">
					<p className="sm">
						We recommend uploading supporting documentation before continuing.
						Evidence submitted without documentation rarely results in a
						reduction.
					</p>
				</div>
			)}
			<ButtonV2
				size="large"
				disabled={submitting}
				onClick={handleSubmit}
				className="mx-auto">
				Continue
			</ButtonV2>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
			<p className="profile-question-skip" onClick={() => handleSkip()}>
				Skip <RightArrow />
			</p>
			{skipModalOpen && (
				<ExitAreYouSure
					top="40%"
					label="Information Requested"
					body={
						<div style={{ marginTop: "8px" }}>
							<p>
								Evidence submitted without documentation rarely results in a
								reduction.
							</p>

							<br />
							<p>
								Please provide supporting documentation now or at a later time
								via your property portal or email.
							</p>
						</div>
					}
					exitBtnContent={
						<>
							<TimeForward />
							<p>Add Info Later</p>
						</>
					}
					onExit={() => {
						setSkipModalOpen(false);
						next(true);
					}}
					onCancel={() => {
						setSkipModalOpen(false);
					}}
				/>
			)}
		</div>
	);
};

interface FileTemplate {
	title: string;
	description: string;
	href: string;
}

const FILE_TEMPLATES: FileTemplate[] = [
	{
		title: "2023 Rent Roll (.XLSX)",
		description:
			"Use this template to report 2023 rent by unit on a monthly basis",
		href: "/2023_Rent_Roll_Template.xlsx",
	},
	{
		title: "2023 Rent Roll (.PDF)",
		description:
			"Use this template to report 2023 rent by unit on a monthly basis",
		href: "/2023_Rent_Roll_Template.pdf",
	},
	{
		title: "2023 Profit Loss (.XLSX)",
		description:
			"Use this template to report 2023 rental income and operating expenses on a monthly basis.",
		href: "/2023_Profit_Loss_Template.xlsx",
	},
	{
		title: "2023 Profit Loss (.PDF)",
		description:
			"Use this template to report 2023 rental income and operating expenses on a monthly basis.",
		href: "/2023_Profit_Loss_Template.pdf",
	},
];

const FileTemplateCard: FC<FileTemplate> = ({ title, description, href }) => {
	return (
		<div className="file-template-card" onClick={e => e.stopPropagation()}>
			<Label>Template</Label>
			<p>{title}</p>
			<p className="file-template-card-description sm">{description}</p>
			<a className="file-template-card-download" href={href} download>
				<p className="sm">Download</p>
				<DownloadIcon />
			</a>
		</div>
	);
};

const EvidenceModal = (props: {
	setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
	const { setOpen } = props;

	return (
		<PropertySurveyEvidenceModal onAttemptExit={() => setOpen(false)}>
			<p>
				<b>Income Producing</b>
			</p>
			<div className="property-survey-evidence-modal-body-text-container">
				<p className="sm">
					For income-producing property, we need more information to fully
					evaluate your property for an appeal. To move forward with your
					appeal, we need:
					<ul className="mb-1">
						<li className="sm">Previous Year Rent Roll by Unit, by Month</li>
						<li className="sm">Previous Year Profit & Loss Statement</li>
					</ul>
					The information you provide will be used to create an income valuation
					model, which adds context to our own data, and strengthens our case
					for earning you a property tax reduction. Without it, we will be
					unable to earn you a reduction.
					<br />
					<br />
					Don’t have this information readily available? No problem! We’ve made
					templates that are easy to use and usually take just a few minutes to
					complete using the information you probably have on hand already.
				</p>
			</div>
			<div className="property-survey-evidence-modal-image-container-grid">
				{FILE_TEMPLATES.map((props, i) => (
					<FileTemplateCard key={props.href + i} {...props} />
				))}
			</div>
			<ButtonV2
				size="mobile-extra-small"
				onClick={() => setOpen(false)}
				className="mx-auto">
				Back to Question
			</ButtonV2>
		</PropertySurveyEvidenceModal>
	);
};

export default IncomeProducing;
