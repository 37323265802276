import { FC, HTMLAttributeAnchorTarget, MouseEventHandler } from "react";
import Link from "next/link";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import Cycle from "public/cycle.svg";

interface QuickLinkProps {
	asButton?: boolean;
	onClick?: MouseEventHandler<HTMLDivElement>;
	disabled?: boolean;
	target?: HTMLAttributeAnchorTarget;
	icon?: "cycle" | "arrow";
	iconPosition?: "left" | "right";
	size?: "extra-large" | "large" | "default" | "small" | "extra-small" | "tiny";
	href?: string;
	reverse?: boolean;
	children: React.ReactNode;
}

const QuickLink: FC<QuickLinkProps & React.HTMLAttributes<HTMLDivElement>> = ({
	id,
	className,
	children,
	asButton = false,
	icon = "arrow",
	onClick,
	disabled,
	target,
	size = "default",
	iconPosition = "right",
	reverse,
	href,
	...containerProps
}) => {
	const Icon = () => {
		const marginClass = iconPosition === "left" ? " mr-0_5" : " ml-0_5";
		const reverseClass = reverse ? "-reverse" : "";

		if (icon === "cycle") {
			return (
				<Cycle className={"quick-link-cycle" + reverseClass + marginClass} />
			);
		}

		return (
			<RightArrow className={"quick-link-arrow" + reverseClass + marginClass} />
		);
	};

	return (
		<div
			id={id}
			className={
				"quick-link" +
				(size ? `-${size}` : "") +
				(disabled ? " quick-link-disabled" : "") +
				(className ? " " + className : "")
			}
			onClick={e => {
				if (asButton) {
					if (onClick && !disabled) onClick(e);
				}
			}}
			{...containerProps}>
			{asButton ? (
				<>
					{iconPosition === "left" && <Icon />}
					<p className={"quick-link-link nowrap"}>{children}</p>
					{iconPosition === "right" && <Icon />}
				</>
			) : (
				<Link
					passHref
					target={target}
					href={href || ""}
					className={"quick-link-link"}>
					{iconPosition === "left" && <Icon />}
					{children}
					{iconPosition === "right" && <Icon />}
				</Link>
			)}
		</div>
	);
};

export default QuickLink;
