export const isValidDate = (
	val: string,
	opts: { MIN_DATE: string; MAX_DATE: string } = {
		MIN_DATE: "1900",
		MAX_DATE: new Date().getFullYear().toString(),
	}
) => {
	const { MIN_DATE, MAX_DATE } = opts;
	const split = val.split("");

	if (!val) return true;

	if (val.length >= 1) {
		if (split[0] !== "1" && split[0] !== "0") return false;
	}

	if (val.length >= 2) {
		if (split[0] === "1") {
			if (!["0", "1", "2"].includes(split[1])) return false;
		} else {
			if (split[1] === "0") return false;
		}
	}

	if (val.length >= 3) {
		if (split[2] !== "/") return false;
	}

	if (val.length >= 4) {
		if (!["0", "1", "2", "3"].includes(split[3])) {
			return false;
		}
	}

	if (val.length >= 5) {
		if (split[3] === "3") {
			if (Number(split[4]) > 1) return false;
		}
		if (split[3] === "0") {
			if (split[4] === "0") return false;
		}
	}

	if (val.length >= 6) {
		if (split[5] !== "/") return false;
	}

	if (val.length >= 7) {
		if (split[6] !== "1" && split[6] !== "2") {
			return false;
		}

		const sliced = val.slice(6);

		if (split[6] === "2") {
			if (!(sliced <= MAX_DATE.slice(0, sliced.length))) return false;
		}

		if (split[6] === "1") {
			if (sliced < MIN_DATE.slice(0, sliced.length)) return false;
		}
	}

	return true;
};
