import React from "react";
import { useRouter } from "next/router";
import { useResponsiveContext } from "context/ResponsiveContext";

export const useOnboardingStyles = (): React.CSSProperties => {
	const router = useRouter();
	const { isDesktop } = useResponsiveContext();

	const styles = React.useMemo(() => {
		const styles: React.CSSProperties = {};

		if (router.query.onboarding && isDesktop) {
			styles.pointerEvents = "none";
		}

		return styles;
	}, [router.query.onboarding, isDesktop]);

	return styles;
};
