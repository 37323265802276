import React from "react";
import { usePropertyContext } from "components/pages/Account/Property/context";
import { useAuthContext } from "context/AuthContext";

import moment from "moment";

const useDeadline = () => {
	const { userData } = useAuthContext();
	const { details } = usePropertyContext();

	const { deadline, showDeadlineBadge } = React.useMemo(() => {
		const deadline = moment(details?.owl_deadline);
		if (userData?.year_over_year_customer) deadline.subtract(1, "month");
		return {
			deadline: deadline.format("MMMM Do"),
			showDeadlineBadge: details?.state === "TX" && moment().isBefore(deadline),
		};
	}, [userData, details]);

	return { deadline, showDeadlineBadge };
};

export default useDeadline;
