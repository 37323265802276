import ErrorPage from "components/pages/Error/ErrorPage";
import { AuthContext, IAuthContext } from "context/AuthContext";
import Layout from "layout";
import { Router } from "next/router";
import {
	Component,
	Context,
	ContextType,
	Dispatch,
	ErrorInfo,
	SetStateAction,
} from "react";
import { sendErrorToWebhook } from "utils/sendWebhook";
import Button from "./Button";

interface Props {
	setHasError: Dispatch<SetStateAction<boolean>>;
}

interface State {
	hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
	static contextType = AuthContext;

	context!: ContextType<typeof AuthContext>;

	public state = {
		hasError: false,
	};

	static getDerivedStateFromError(_error: unknown) {
		return { hasError: true };
	}

	componentDidCatch(error: unknown, info: ErrorInfo) {
		this.props.setHasError(true);

		let url = window.location.href;

		const userAgent = window.navigator?.userAgent ?? "Unknown";

		let message = `CLIENT SIDE ERROR CAUGHT @ ${url}\nUSER AGENT: ${userAgent}\n`;

		const additionalData: Record<string, any> = {};

		if (this.context && this.context.isAuthenticated && this.context.userData) {
			additionalData.userID = this.context.userData.id;
		}

		if (Object.keys(additionalData).length) {
			message += JSON.stringify(additionalData, null, 2) + "\n";
		}

		if (error instanceof Error) {
			message += error.message;
		} else if (typeof error === "object") {
			message += JSON.stringify(error);
		} else if (typeof error === "string") {
			message += error;
		}

		message += "\n" + JSON.stringify(info);

		sendErrorToWebhook(message.substring(0, 990));

		const handler = () => {
			this.setState({
				hasError: false,
			});
			this.props.setHasError(false);
			Router.events.off("routeChangeComplete", handler);
		};

		Router.events.on("routeChangeComplete", handler);
	}

	render() {
		if (this.state.hasError) {
			return (
				<Layout>
					<ErrorPage
						errorType="Client-Side"
						title="Shoot! We are a little lost"
						body={
							<div className="error-text">
								<p>
									We are now aware of this error and are working hard to fix the
									problem! We will be on track shortly.
								</p>
								<br />
								<p>
									Please click the refresh button to reload this page and
									contact us if the error persists.
								</p>
							</div>
						}
					/>
				</Layout>
			);
		}
		return this.props.children;
	}
}
