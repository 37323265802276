import { Select, TextField } from "components";
import FileUpload, {
	FileUploadType,
	splitFileUploads,
} from "elements/Input/FileUpload";
import { useRouter } from "next/router";
import { FormEventHandler, useEffect, useMemo, useState } from "react";
import client from "utils/client";
import { usePropertyContext } from "../context";
import PropertyModal from "../Modal";
import Image from "next/image";
import DoubleCheckmarkAnimation from "public/account/double-checkmark.gif";
import { useEventTracker } from "utils/useEventTracker";
import constants from "utils/constants";
import { useAuthContext } from "context/AuthContext";
import formatDate from "utils/formatDate";
import { isValidDate } from "utils/validDate";
import moment from "moment";
import ButtonV2 from "elements/ButtonV2";
import { set } from "nprogress";

const DEFAULT_CONTINGENCY = 25;

export const BILL_TYPE_OPTIONS = [
	"Cell Phone",
	"Internet",
	"Electric",
	"Cable / Satellite TV",
	"Home Phone",
	"Security System",
	"Other",
];

export const INTERNET_OPTIONS = [
	"AT&T",
	"Astound Broadband",
	"CenturyLink",
	"Charter",
	"Comcast (Xfinity)",
	"Cox Communications",
	"Dish Network",
	"Frontier",
	"Optimum",
	"Spectrum",
	"Time Warner Cable",
	"T-Mobile Home Internet",
	"Verizon Fios ",
	"Other",
];

export const ELECTRIC_OPTIONS = [
	"4Change",
	"Amigo",
	"Cirro",
	"Constellation",
	"Direct",
	"Discount",
	"Flagship",
	"Frontier",
	"Gexa",
	"Green Mountain",
	"Just Energy",
	"New Power Texas",
	"Payless",
	"Pulse",
	"Reliant",
	"Rhythm",
	"TXU",
	"Tara",
	"Other",
];

export const CELL_OPTIONS = [
	"AT&T",
	"T-Mobile",
	"Verizon",
	"Spectrum",
	"Cricket",
	"Xfinity",
	"Visible",
	"Mint Mobile",
	"Consumer Cellular",
	"Boost Mobile",
	"Other",
];

export const CABLE_OR_SATELLITE_OPTIONS = [
	"AT&T",
	"Comcast",
	"Cox",
	"DirectTV",
	"Dish Network",
	"Optimum",
	"Sparklight",
	"Spectrum",
	"Verizon Fios",
	"Xfinity",
	"Other",
];

export const SECURITY_OPTIONS = [
	"ADT",
	"Abode",
	"Brinks",
	"Cover",
	"Frontpoint",
	"Ring",
	"SimpliSafe",
	"Vivint",
	"Other",
];

export const HOME_PHONE_OPTIONS = [
	"AT&T",
	"Community Phone",
	"Frontier",
	"Spectrum",
	"Verizon Fios",
	"Vonage",
	"Xfinity",
	"Other",
];

export const BillReductionModal = () => {
	const { setModalToShow, details, fetchPropertyDetails } =
		usePropertyContext();
	const { userData } = useAuthContext();

	const [billType, setBillType] = useState("");
	const [billProvider, setBillProvider] = useState("");
	const [otherBillProvider, setOtherBillProvider] = useState("");
	const [providerSecurityCode, setProviderSecurityCode] = useState("");
	const [last4DigitsSSN, setLast4DigitsSSN] = useState("");
	const [birthdate, setBirthdate] = useState("");
	const [comments, setComments] = useState("");
	const [filesToUpload, setFilesToUpload] = useState<FileUploadType[]>([]);
	const [dropdownProviders, setDropdownProviders] = useState<
		string[] | undefined
	>(undefined);

	const [submitting, setSubmitting] = useState(false);
	const [success, setSuccess] = useState(false);

	const router = useRouter();

	const trackEvent = useEventTracker();

	const mixpanelEventNamesForBillTypes: {
		[key: string]: {
			providerClicked: string;
			providerChosen: string;
			upload: string;
			submitCTA: string;
		};
	} = {
		Internet: {
			providerClicked:
				constants.EVENTS.W2S_BillReduc_Internet_Tab_Providers_Clicked,
			providerChosen:
				constants.EVENTS.W2S_BillReduc_Internet_Tab_Providers_Chosen,
			upload: constants.EVENTS.W2S_BillReduc_Internet_Tab_Uploaded_File,
			submitCTA: constants.EVENTS.W2S_BillReduc_Internet_Tab_Submit_CTA,
		},
		Electric: {
			providerClicked:
				constants.EVENTS.W2S_BillReduc_Electric_Tab_Providers_Clicked,
			providerChosen:
				constants.EVENTS.W2S_BillReduc_Electric_Tab_Providers_Chosen,
			upload: constants.EVENTS.W2S_BillReduc_Electric_Tab_Uploaded_File,
			submitCTA: constants.EVENTS.W2S_BillReduc_Electric_Tab_Submit_CTA,
		},
		"Cell Phone": {
			providerClicked:
				constants.EVENTS.W2S_BillReduc_Phone_Tab_Providers_Clicked,
			providerChosen: constants.EVENTS.W2S_BillReduc_Phone_Tab_Providers_Chosen,
			upload: constants.EVENTS.W2S_BillReduc_Phone_Tab_Uploaded_File,
			submitCTA: constants.EVENTS.W2S_BillReduc_Phone_Tab_Submit_CTA,
		},
		"Cable / Satellite TV": {
			providerClicked:
				constants.EVENTS.W2S_BillReduc_CableSatellite_Tab_Providers_Clicked,
			providerChosen:
				constants.EVENTS.W2S_BillReduc_CableSatellite_Tab_Providers_Chosen,
			upload: constants.EVENTS.W2S_BillReduc_CableSatellite_Tab_Uploaded_File,
			submitCTA: constants.EVENTS.W2S_BillReduc_CableSatellite_Tab_Submit_CTA,
		},
		"Home Phone": {
			providerClicked:
				constants.EVENTS.W2S_BillReduc_HomePhone_Tab_Providers_Clicked,
			providerChosen:
				constants.EVENTS.W2S_BillReduc_HomePhone_Tab_Providers_Chosen,
			upload: constants.EVENTS.W2S_BillReduc_HomePhone_Tab_Uploaded_File,
			submitCTA: constants.EVENTS.W2S_BillReduc_HomePhone_Tab_Submit_CTA,
		},
		"Security System": {
			providerClicked:
				constants.EVENTS.W2S_BillReduc_Security_Tab_Providers_Clicked,
			providerChosen:
				constants.EVENTS.W2S_BillReduc_Security_Tab_Providers_Chosen,
			upload: constants.EVENTS.W2S_BillReduc_Security_Tab_Uploaded_File,
			submitCTA: constants.EVENTS.W2S_BillReduc_Security_Tab_Submit_CTA,
		},
		Other: {
			providerClicked:
				constants.EVENTS.W2S_BillReduc_Other_Tab_Providers_Clicked,
			providerChosen: constants.EVENTS.W2S_BillReduc_Other_Tab_Providers_Chosen,
			upload: constants.EVENTS.W2S_BillReduc_Other_Tab_Uploaded_File,
			submitCTA: constants.EVENTS.W2S_BillReduc_Other_Tab_Submit_CTA,
		},
	};

	const mixpanelGeneralData = {
		"Customer ID": userData?.id,
		"Bill Type": billType,
		Address: `${details?.address}, ${details?.city}, ${details?.state} ${details?.zip_code}`,
	};

	useEffect(() => {
		if (billType) {
			trackEvent({
				eventName: constants.EVENTS.W2S_Bill_Reduction_Bill_Modal_Viewed,
				data: {
					"Customer ID": userData?.id,
					"Bill Type": billType,
					Address: `${details?.address}, ${details?.city}, ${details?.state} ${details?.zip_code}`,
				},
			});
		}
	}, [billType, details?.address]);

	const onProviderEnteredBlur = () => {
		if (billProvider) {
			// trackEvent({
			// 	eventName: constants.EVENTS.W2S_Bill_Reduction_Provider_Entered,
			// });
			trackEvent({
				eventName: mixpanelEventNamesForBillTypes[billType].providerChosen,
				data: {
					mixpanelData: mixpanelGeneralData,
					"Provider Name": billProvider,
				},
			});
		}
	};

	useEffect(() => {
		if (
			BILL_TYPE_OPTIONS.includes(
				(router.query["bill-reduction"] as string) ?? ""
			)
		) {
			setBillType(router.query["bill-reduction"] as string);
		}
		if (router.query["provider"] as string) {
			setBillProvider(router.query["provider"] as string);
		}
	}, [router.query]);

	const handleSubmit: FormEventHandler = async e => {
		e.preventDefault();

		if (!details) return;

		trackEvent({
			eventName: mixpanelEventNamesForBillTypes[billType].submitCTA,
			data: {
				mixpanelData: mixpanelGeneralData,
			},
		});

		try {
			setSubmitting(true);

			const form = new FormData();

			form.append(
				"provider",
				billProvider === "Other" ? otherBillProvider : billProvider
			);
			form.append("category", billType);
			form.append("security_code", providerSecurityCode);
			if (billType !== "Electric") {
				form.append("birth_date", moment(birthdate).format("YYYY-MM-DD"));
				form.append("last_4_digits_ssn", last4DigitsSSN);
			}
			form.append("comments", comments);

			for (const { file } of filesToUpload) {
				form.append("bill", file);
			}

			await client.submitBillReductionData({
				form,
				property_id: details.id,
			});

			await fetchPropertyDetails(details.id.toString(), {
				autoOpenNARModal: false,
			});

			setSuccess(true);
		} finally {
			setSubmitting(false);
		}
	};

	const onAttemptExit = () => {
		setModalToShow(undefined);

		if (!!router.query.onboarding) {
			trackEvent({
				eventName: constants.EVENTS.Onboarding_bill_reduction_exit,
				data: {
					"Customer ID": userData?.id,
					"Property ID": details?.id,
				},
			});
		} else {
			trackEvent({
				eventName: constants.EVENTS.W2S_Bill_Reduction_Bill_Modal_Closed,
				data: {
					Customer_ID: userData?.id,
					"Bill Type": billType ? billType : "N/A",
					Address: `${details?.address}, ${details?.city}, ${details?.state} ${details?.zip_code}`,
				},
			});
		}

		let query: { onboarding?: string | undefined } = {};
		if (router.query.onboarding) {
			query.onboarding = "true";
		}

		router.replace({
			pathname: window.location.pathname,
			query: query,
		});
	};

	const resetState = () => {
		setBillType("");
		setBillProvider("");
		setOtherBillProvider("");
		setProviderSecurityCode("");
		setLast4DigitsSSN("");
		setBirthdate("");
		setComments("");
		setFilesToUpload([]);
		setSuccess(false);
	};

	const buttonDisabled = useMemo(() => {
		return (
			!billType ||
			!billProvider ||
			(billProvider === "Other" && !otherBillProvider) ||
			(billType !== "Electric" && !last4DigitsSSN) ||
			(billType !== "Electric" && !providerSecurityCode) ||
			!filesToUpload.length ||
			submitting
		);
	}, [
		filesToUpload.length,
		billProvider,
		billType,
		otherBillProvider,
		providerSecurityCode,
		last4DigitsSSN,
		submitting,
	]);

	useEffect(() => {
		if (filesToUpload?.length > 0) {
			trackEvent({
				eventName: mixpanelEventNamesForBillTypes[billType].upload,
				data: {
					mixpanelData: mixpanelGeneralData,
					"Files Uploaded": filesToUpload.length,
				},
			});
		}
	}, [filesToUpload]);

	useEffect(() => {
		switch (billType) {
			case "Internet":
				setDropdownProviders(INTERNET_OPTIONS);
				break;
			case "Electric":
				setDropdownProviders(ELECTRIC_OPTIONS);
				break;
			case "Cell Phone":
				setDropdownProviders(CELL_OPTIONS);
				break;
			case "Cable / Satellite TV":
				setDropdownProviders(CABLE_OR_SATELLITE_OPTIONS);
				break;
			case "Security System":
				setDropdownProviders(SECURITY_OPTIONS);
				break;
			case "Home Phone":
				setDropdownProviders(HOME_PHONE_OPTIONS);
				break;
			default:
				setDropdownProviders(undefined);
		}
	}, [billType]);

	useEffect(() => {
		if (!success) return;

		try {
			//@ts-ignore
			if (!window.fbq) return;
			//@ts-ignore
			window.fbq("track", "bill_reduction_conversion", {
				currency: "USD",
				value: 16.0,
			});
		} catch {}
	}, [success]);

	const renderModalContent = () => {
		if (success) {
			trackEvent({
				eventName: constants.EVENTS.W2S_Bill_Reduction_Bill_Submitted,
				data: {
					Customer_ID: userData?.id,
					"Bill Type": billType ? billType : "N/A",
					Address: `${details?.address}, ${details?.city}, ${details?.state} ${details?.zip_code}`,
					"Provider Name":
						billProvider === "Other" ? otherBillProvider : billProvider,
					"Credential Type": "Upload a Bill", //NEED TO UPDATE THIS IF LOGIN CREDENTIAL FEATURE IS ADDED
				},
			});
			return (
				<div>
					<div id="bill-reduction-success-animation-container">
						<Image
							width={150}
							height={150}
							src={DoubleCheckmarkAnimation}
							alt="Checkmark animation"
						/>
					</div>
					<p className="text-center bold mt-1 mb-1">
						Your bill has been submitted!
					</p>
					<div className="bill-reduction-form-content">
						<p className="bold mb-2">What's next?</p>
						<p className="sm mb-2">
							‍🔎 We'll begin working with your provider as soon as possible to
							get your bill lowered.
						</p>
						<p className="sm mb-2">
							📱 To resolve account verification issues, we may need to contact
							you for One Time Codes or correct Pins.
						</p>
						<p className="sm mb-2">✅ We'll notify you when we get results.</p>
						<p className="sm">
							💰 When finished, enjoy your well deserved savings!
						</p>
					</div>
					<p className="body-tiny denim_5 text-center mt-2 mb-2">
						Please keep in mind it can take up to a few weeks in some cases
						depending on your provider.
					</p>
					<ButtonV2 size="large" className="mx-auto" onClick={onAttemptExit}>
						Done
					</ButtonV2>
					{/* <QuickLink
						className="mx-auto mt-2 mb-2"
						asButton
						onClick={resetState}>
						Upload Another Bill
					</QuickLink> */}
				</div>
			);
		}

		return (
			<div>
				{billType !== "Electric" && (
					<p className="bold" id="bill-reductions-heading">
						Just a few things
					</p>
				)}
				{billType === "Electric" ? (
					<p className="sm">
						Just upload your most recent bill and we'll get to work seeing if
						there's something better out there for you.
					</p>
				) : (
					<p className="sm">
						We use the same Savings-Or-Free Guarantee that you know and love! We
						only charge {details?.contingency ?? DEFAULT_CONTINGENCY}% of
						savings, and you <span className="bold">only pay if you save.</span>
					</p>
				)}
				<form id="bill-reduction-form" onSubmit={handleSubmit}>
					<div className="bill-reduction-form-content">
						<div className="bill-reduction-split-row">
							{/* <Select
								required
								value={billType}
								onChange={e => {
									setBillType(e.target.value);
									setBillProvider("");
								}}
								label="Bill Type">
								<option hidden value="">
									Select a Bill Type
								</option>
								{BILL_TYPE_OPTIONS.map((option, index) => (
									<option key={index} value={option}>
										{option}
									</option>
								))}
							</Select> */}
							<div className="mb-2">
								{dropdownProviders ? (
									<Select
										required
										value={billProvider}
										onChange={e => {
											setBillProvider(e.target.value);
											setOtherBillProvider("");
										}}
										onClick={() =>
											trackEvent({
												eventName:
													mixpanelEventNamesForBillTypes[billType]
														.providerClicked,
												data: {
													mixpanelData: mixpanelGeneralData,
												},
											})
										}
										label="Provider"
										onBlur={() => onProviderEnteredBlur()}>
										<option hidden value="">
											Select provider name
										</option>
										{dropdownProviders.map((option, index) => (
											<option key={index} value={option}>
												{option}
											</option>
										))}
									</Select>
								) : (
									<TextField
										required
										label="Provider"
										placeholder="Enter your provider name here"
										value={billProvider}
										onChange={e => setBillProvider(e.target.value)}
										onBlur={() => onProviderEnteredBlur()}
									/>
								)}
							</div>
						</div>
						{billType === "Other" && (
							<div className="bill-reduction-other-info">
								<p className="body-tiny">
									At this time we are unable to help reduce Electric, Gas or
									Trash bills. Please submit any other type of bill and we'll
									get to work for you!
								</p>
							</div>
						)}
						{billProvider === "Other" && (
							<div className="mb-4">
								<TextField
									required
									placeholder="Enter Provider Name"
									value={otherBillProvider}
									onChange={e => setOtherBillProvider(e.target.value)}
								/>
							</div>
						)}
						{billType != "Electric" && (
							<>
								<div className="bill-reduction-split-row-2 mb-2 relative">
									<div className="position-relative">
										<TextField
											required
											label="Security Pin / Passphrase"
											placeholder="0000"
											value={providerSecurityCode}
											onChange={e => setProviderSecurityCode(e.target.value)}
										/>
										<div className="bill-reduction-highly-recommended">
											<p className="body-tiny bold">Highly Recommend</p>
										</div>
									</div>
									<div className="bill-reduction-form-right-comment">
										<p className="body-tiny azure">
											These are almost always required by your provider and help
											avoid issues getting your bill reduced.
										</p>
									</div>
								</div>
								<div className="bill-reduction-split-row-2 mb-2 relative">
									<div className="position-relative">
										<TextField
											required
											label="Account Holder’s Last 4 of Social Security"
											placeholder="0000"
											value={last4DigitsSSN}
											onChange={e => {
												const value = e.target.value;
												// Allow only numbers and limit to 4 characters
												if (/^\d*$/.test(value) && value.length <= 4) {
													setLast4DigitsSSN(value);
												}
											}}
										/>
									</div>
									<div className="bill-reduction-form-right-comment">
										<p className="body-tiny azure">
											We get asked to provide this most of the time by your
											provider, so we ask for it in advance to avoid bothering
											you later.
										</p>
									</div>
								</div>
								<div className="bill-reduction-split-row-2 mb-2">
									<div>
										<TextField
											label="Date of Birth"
											placeholder="MM/DD/YYYY"
											value={birthdate}
											onChange={e => {
												const formatted = formatDate(e, birthdate ?? "");
												const valid = isValidDate(formatted);

												if (valid) {
													setBirthdate(formatted);
												}
											}}
										/>
									</div>
									<div className="bill-reduction-form-right-comment">
										<p className="body-tiny azure">
											Providing your date of birth for account verification
											reduces our need to contact you later if required.
										</p>
									</div>
								</div>
							</>
						)}
					</div>
					<div className="bill-reduction-form-content">
						<p className="bold mb-2">Upload a Bill</p>
						<FileUpload
							required
							label="Upload Your Most Recent Bill*"
							filesToUpload={filesToUpload}
							updateFilesToUpload={setFilesToUpload}
						/>
					</div>
					<p className="body-tiny denim_5 text-center mt-2">
						{billType == "Electric"
							? `You authorize Ownwell and its partners to request and access the data that Smart Meter Texas collects and holds about you. You also give us permission to set up an account with Smart Meter Texas in your name, allowing us to serve as a competitive service provider with access to your data.`
							: `By submitting, I agree to the Ownwell Terms of Service and give
						Ownwell permission to reduce this bill on my behalf.`}{" "}
					</p>
					<ButtonV2
						size="large"
						className="mx-auto mt-1"
						disabled={buttonDisabled}>
						{submitting ? "Submitting..." : "Submit"}
					</ButtonV2>
				</form>
			</div>
		);
	};

	return (
		<PropertyModal
			onAttemptExit={onAttemptExit}
			modalId="bill-reduction-modal"
			containerId="bill-reduction-modal-container">
			<p className="text-center denim_5 mb-2">{billType} Bill Reduction</p>
			{renderModalContent()}
		</PropertyModal>
	);
};
