import React, { FC } from "react";
import PropertyGrid from "components/pages/Account/Property/PropertyGrid";
import CloseX from "public/close-x.svg";
import { AnimatePresence, motion } from "framer-motion";
import useOutsideClick from "utils/useOutsideClick";

const PropertyModal: FC<{
	onAttemptExit: () => void;
	variant?: "secondary";
	modalId?: string;
	containerId?: string;
	animate?: boolean;
	clickAway?: boolean;
	removeCloseButton?: boolean;
	header?: React.ReactNode;
}> = ({
	header,
	children,
	variant,
	modalId,
	containerId,
	onAttemptExit,
	animate = false,
	clickAway = false,
	removeCloseButton = false,
}) => {
	// This was causing issues with modal form flows - was originally to prevent a scroll issue on mobile for property tax data modal
	// React.useEffect(() => {
	// 	const resetScroll = (e: Event) => {
	// 		e.preventDefault();
	// 		window.scrollTo(0, 0);
	// 	};
	//
	// 	if (window) {
	// 		window.scrollTo(0, 0);
	// 		window.addEventListener("scroll", resetScroll);
	// 	}
	//
	// 	return () => {
	// 		if (window) {
	// 			window.removeEventListener("scroll", resetScroll);
	// 		}
	// 	};
	// }, []);

	const motionProps = animate
		? {
				initial: { opacity: 0 },
				animate: { opacity: 1 },
				transition: { type: "tween", duration: 0.3 },
		  }
		: {};

	const outsideClickRef = useOutsideClick(() => {
		if (clickAway) onAttemptExit();
	});

	const showCloseButton = variant !== "secondary" && !removeCloseButton;

	return (
		<AnimatePresence>
			<motion.div
				{...motionProps}
				id={containerId}
				className={
					"property-modal" + (variant === "secondary" ? "-secondary" : "")
				}>
				<style jsx global>{`
					html,
					body {
						overflow: hidden !important;
						position: fixed !important;
						top: 0 !important;
					}
				`}</style>
				<PropertyGrid>
					<div
						ref={outsideClickRef}
						className="account-property-modal-card"
						id={modalId}>
						{!header && showCloseButton && (
							<>
								<div className="profile-modal-close" onClick={onAttemptExit}>
									<CloseX />
								</div>
							</>
						)}
						{header && (
							<header>
								{showCloseButton && <div />}
								{header}
								{showCloseButton && (
									<div className="profile-modal-close" onClick={onAttemptExit}>
										<CloseX />
									</div>
								)}
							</header>
						)}
						{children}
					</div>
				</PropertyGrid>
			</motion.div>
		</AnimatePresence>
	);
};

export default PropertyModal;
