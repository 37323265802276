import Image from "next/image";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import client from "utils/client";
import { useCallback } from "react";
import { useAuthContext } from "context/AuthContext";
import PropertyModal from "./Property/Modal";
import { sortProperties } from "utils/account";
import { useEventTracker } from "utils/useEventTracker";
import constants from "utils/constants";
import { getTotalSavings } from "utils/getTotalSavings";
import {
	PremiumPricingComparisonTable,
	usePricing,
} from "elements/Premium/PremiumPricingComparisonTable";
import { ContingencyBreakdown } from "./PremiumInfoModal";
import PremiumDiamond from "public/premium/premium-diamond.svg";
import ButtonV2 from "elements/ButtonV2";

import dynamic from "next/dynamic";
import type { ParticlesProps } from "react-particles";

const LazyParticles = dynamic<ParticlesProps>(
	() => import("react-particles").then(module => module.default),
	{
		loading: () => null,
		ssr: false,
	}
);

const SuccessCard = ({ setOpen }: { setOpen: (open: boolean) => void }) => {
	return (
		<div id="tier-change-success-card" className="text-center">
			<PremiumDiamond className="premium-diamond" />
			<h4 className="lg">Welcome to Premium!</h4>
			<p className="sm denim-medium mt-1">
				You can access your premium benefits details at any time by clicking on
				the Premium logo at the top of your screen.
			</p>
			<ButtonV2
				size="large"
				className="mt-4 mx-auto"
				onClick={() => {
					setOpen(false);
				}}>
				Continue
			</ButtonV2>
		</div>
	);
};

const UpgradeConfirmation: React.FC<{
	onConfirm: () => void;
	onCancel: () => void;
}> = ({ onConfirm, onCancel }) => {
	// TODO: Figure out how to derive this.
	const hasAppealsThatHaveBeenHeard = true;

	return (
		<div id="upgrade-confirmation">
			<PremiumDiamond className="premium-diamond" />
			<p className="lg bold">You + Premium = ❤️</p>
			<p className="sm denim-medium">
				You're on the verge of unlocking our Premium Plan! This upgrade is all
				about enhancing your experience and maximizing your tax savings for all
				of your properties.{" "}
				{hasAppealsThatHaveBeenHeard &&
					"Bonus! You’ll receive Premium benefits while paying Essential pricing on appeals that have already been heard."}
			</p>
			<ButtonV2 variant="primary" onClick={onConfirm}>
				Continue
			</ButtonV2>
			<ButtonV2 variant="primary-outline" size="extra-small" onClick={onCancel}>
				Go Back
			</ButtonV2>
		</div>
	);
};

const PricingModalContent = ({
	setOpen,
	setSuccess,
}: {
	setOpen: (open: boolean) => void;
	setSuccess: Dispatch<SetStateAction<boolean>>;
}) => {
	const { setUserData, userData, properties, setProperties } = useAuthContext();
	const { generalPricing, customerPricing, hasOwnershipInMultipleStates } =
		usePricing();

	const trackEvent = useEventTracker();

	const [showConfirmUpgrade, setShowConfirmUpgrade] = useState(false);

	useEffect(() => {
		document.getElementById("premium-pricing-modal")?.scrollIntoView({
			behavior: "auto",
			block: "start",
			inline: "nearest",
		});
	}, [showConfirmUpgrade]);

	useEffect(() => {
		trackEvent({
			eventName: constants.EVENTS.Premium_Upgrade_Modal_Viewed,
			data: {
				"Customer ID": userData?.id,
				"Sign Up Date": userData?.created_at,
				"Total Savings": getTotalSavings(properties),
				"Number of Properties": properties.length,
			},
		});
	}, []);

	const handleDismiss = async () => {
		trackEvent({
			eventName: constants.EVENTS.Premium_Upgrade_Essentials_Button,
			data: {
				"Customer ID": userData?.id,
				"Sign Up Date": userData?.created_at,
				"Total Savings": getTotalSavings(properties),
				"Number of Properties": properties.length,
			},
		});
		setOpen(false);
	};

	const handleUpgrade = async () => {
		setShowConfirmUpgrade(true);
	};

	const handleCancelUpgrade = async () => {
		setShowConfirmUpgrade(false);
	};

	const handleConfirmUpgradeToPremium = async () => {
		try {
			trackEvent({
				eventName: constants.EVENTS.Premium_Upgrade_Upgrade_Button,
				data: {
					"Customer ID": userData?.id,
					"Sign Up Date": userData?.created_at,
					"Total Savings": getTotalSavings(properties),
					"Number of Properties": properties.length,
				},
			});
			await client.upgradeAccountToPremium();

			setUserData(p => {
				if (!p) return p;
				return {
					...p,
					account_tier: "premium",
				};
			});

			setSuccess(true);
			try {
				window.navigator.vibrate(500);

				const { data } = await client.getProperties();

				setProperties(data.sort(sortProperties));
			} catch {}
		} catch (e) {
		} finally {
		}
	};

	if (!showConfirmUpgrade) {
		return (
			<>
				<p id="premium-pricing-modal-title" className="bold">
					Get the most out of Ownwell with Premium!
				</p>
				<p id="premium-pricing-modal-subtitle" className="denim-medium sm">
					We've designed our Premium membership based on the most popular
					requests from our customers, to deliver even greater savings and
					value.
				</p>
				<PremiumPricingComparisonTable
					generalPricing={generalPricing}
					onPremiumAction={handleUpgrade}
					onEssentialsAction={handleDismiss}
					hasOwnershipInMultipleStates={hasOwnershipInMultipleStates}
				/>
				<p
					id="premium-pricing-modal-disclaimer"
					className="body-tiny denim-medium">
					*Escalation funding eligibility is determined on a case by case basis.
				</p>
				<ButtonV2 variant="primary" className="mx-auto" onClick={handleUpgrade}>
					Add Premium
				</ButtonV2>
				<ButtonV2
					variant="primary-outline"
					size="extra-small"
					className="mx-auto"
					onClick={handleDismiss}>
					Don't Add Premium
				</ButtonV2>
			</>
		);
	}

	return (
		<>
			<UpgradeConfirmation
				onConfirm={handleConfirmUpgradeToPremium}
				onCancel={handleCancelUpgrade}
			/>
			<ContingencyBreakdown customerPricing={customerPricing} upgrade />
		</>
	);
};

export const PARTICLE_CONFIG = {
	fullScreen: {
		zIndex: 100,
	},
	emitters: {
		particles: {
			enable: true,
			move: {
				enable: true,
				gravity: {
					enable: true,
					acceleration: 10,
				},
				speed: {
					min: 10,
					max: 20,
				},
				decay: 0.1,
				direction: "none",
				straight: false,
				outModes: {
					default: "destroy",
					top: "none",
				},
			},
			opacity: {
				value: 1,
				animation: {
					enable: false,
					startValue: "max",
					destroy: "min",
					speed: 0.3,
					sync: true,
				},
			},
			rotate: {
				value: {
					min: 0,
					max: 360,
				},
				direction: "random",
				move: true,
				animation: {
					enable: true,
					speed: 60,
				},
			},
			tilt: {
				direction: "random",
				enable: true,
				move: true,
				value: {
					min: 0,
					max: 360,
				},
				animation: {
					enable: true,
					speed: 60,
				},
			},
			shape: {
				type: "image",
				options: {
					image: [
						{
							src: "/confetti-shapes/confetti-blue-diamond.svg",
							width: 32,
							height: 32,
							particles: {
								size: {
									value: 8,
								},
							},
						},
						{
							src: "/confetti-shapes/confetti-yellow-diamond.svg",
							width: 32,
							height: 32,
							particles: {
								size: {
									value: 8,
								},
							},
						},
						{
							src: "/confetti-shapes/confetti-dark-blue-house.svg",
							width: 32,
							height: 32,
							particles: {
								size: {
									value: 8,
								},
							},
						},
						{
							src: "/confetti-shapes/confetti-green-house.svg",
							width: 32,
							height: 32,
							particles: {
								size: {
									value: 8,
								},
							},
						},
						{
							src: "/confetti-shapes/confetti-light-blue-house.svg",
							width: 32,
							height: 32,
							particles: {
								size: {
									value: 8,
								},
							},
						},
					],
				},
			},
		},
		life: {
			duration: 3,
			count: 1,
		},
		position: {
			x: 50,
			y: 0,
		},
		size: {
			width: 80,
			height: 200,
		},
		rate: {
			quantity: 50,
		},
	},
	preset: "confetti",
};

export const PremiumPricingModal = ({
	setOpen,
}: {
	setOpen: (open: boolean) => void;
}) => {
	const { setUserData } = useAuthContext();

	const [success, setSuccess] = useState(false);

	const particlesInit = useCallback(async engine => {
		const loadSlim = (await import("tsparticles-slim")).loadSlim;
		const loadConfettiPreset = (await import("tsparticles-preset-confetti"))
			.loadConfettiPreset;
		await loadConfettiPreset(engine);
		await loadSlim(engine);
	}, []);

	useEffect(() => {
		setUserData(p => {
			if (!p) return p;

			return {
				...p,
				pricing_modal_seen: 1,
			};
		});
	}, [setUserData]);

	if (success) {
		return (
			<div id="forceful-modal">
				<style global jsx>
					{`
						html,
						body {
							overflow: hidden;
						}
					`}
				</style>
				<LazyParticles
					id="tsparticles"
					options={PARTICLE_CONFIG}
					init={particlesInit}
				/>
				<div id="forceful-modal-content" style={{ zIndex: 100000 }}>
					<SuccessCard setOpen={setOpen} />
				</div>
			</div>
		);
	}

	return (
		<PropertyModal
			modalId="premium-pricing-modal"
			onAttemptExit={() => setOpen(false)}
			clickAway>
			<PricingModalContent setOpen={setOpen} setSuccess={setSuccess} />
		</PropertyModal>
	);
};
