import axios, { AxiosProgressEvent } from "axios";
import axiosRetry from "axios-retry";
import { LegalResponse } from "context/AppealContext";
import cleanAddress from "./cleanAddress";
import concatAddressLine2 from "./concatAddressLine2";
import { isStagingEnv } from "./isStagingEnv";
import sendWebhook, { sendErrorToWebhook } from "./sendWebhook";
import {
	IAddress,
	MultiRes,
	ExactSavingsData,
	GeoLevelSavingsData,
	AutocompleteRes,
	PropertyDetails,
	ReauthorizationData,
	Invoices,
	UserData,
	ArbitrationUserData,
	InsuranceAnswerReducerState,
	NeedsAttentionReasons,
	ICategory,
	CategoryByIdRes,
	ISection,
	IArticle,
	FoundArticles,
	HelpCenterHome,
	ApnAutocompleteRes,
	CountyData,
	FileUploadProps,
	SavingsType,
	SavingsCopyType,
	AppealHistoryItem,
	ExemptionsSavingsResponse,
	PropertyTaxes,
	ExemptionCurrentlyReceivingValues,
	Dashboard,
	PropertyEquity,
	LeftoverProperty,
	ExemptionPIIRefType,
	ExpensesData,
	PropertyDetailsExemptionFields,
	SignUpType,
	CustomerPricingData,
	CrimeAlertIncident,
	AppealsLoggingData,
	LifeTimeSavingsResponse,
	GetTrendsDataResponse,
	CrimeTrendsEntry,
	NeighborhoodPermitsResponse,
	ActionsRequired,
	BillReduction,
	AllActiveProperty,
	MortgageRates,
	AllActiveAppeals,
	Property,
	Client,
} from "./types";
import { TRENDS_LAST_MODIFIED } from "./trends";
import { TaxBillManagement } from "components/pages/Account/TaxBillManagement/Context";
import { RefinanceMonitoring } from "components/pages/Account/Property/RefinanceMonitoring";

axios.defaults.withCredentials = true;

axios.interceptors.request.use(config => {
	if (isStagingEnv() && typeof window !== "undefined") {
		const token = window.localStorage.getItem("staging_auth_token");
		config.headers["Staging-Authorization"] = `Bearer ${token}`;
	}
	return config;
});

axiosRetry(axios, {
	retries: 3,
	shouldResetTimeout: false,
	retryDelay: axiosRetry.exponentialDelay,
	retryCondition: axiosRetry.isNetworkError,
});

const errorBlackList = [
	{
		fnName: "resetPassword",
		errMessage: "AxiosError: Request failed with status code 401",
	},
	{
		fnName: "login",
		errMessage: "AxiosError: Request failed with status code 401",
	},
	{
		fnName: "signup",
		errMessage: "This email is already in use. Please claim your account.",
	},
	{
		fnName: "getUserData",
		errMessage: "AxiosError: Request failed with status code 401",
	},
	{
		fnName: "fetchCms",
		errMessage: "AxiosError: Request failed with status code 404",
	},
	{
		fnName: "getProperty",
		errMessage: "AxiosError: Request failed with status code 403",
	},
	{
		fnName: "getProperty",
		errMessage: "AxiosError: Request failed with status code 401",
	},
	{
		fnName: "getReauthorizationData",
		errMessage: "AxiosError: Request failed with status code 401",
	},
	{
		fnName: "confirmOtcCode",
		errMessage: "AxiosError: Request failed with status code 401",
	},
	{
		fnName: "getArticleById",
		errMessage: "AxiosError: Request failed with status code 400",
	},
	{
		fnName: "getArticleById",
		errMessage: "AxiosError: Request failed with status code 404",
	},
	{
		fnName: "__INTERNAL_getPropertiesSitemapMetaByState",
		errMessage: "AxiosError: Request failed with status code 404",
	},
	{
		fnName: "__INTERNAL_getPropertiesSitemapByStateAndPage",
		errMessage: "AxiosError: Request failed with status code 404",
	},
	{
		fnName: "__INTERNAL_getPropertiesSitemapByStateAndPage",
		errMessage: "AxiosError: Request failed with status code 500",
	},
	{
		fnName: "loginWithGoogle",
		errMessage: "AxiosError: Request failed with status code 500",
	},
	{
		fnName: "payInvoice",
		errMessage: "AxiosError: Request failed with status code 402",
	},
	{
		fnName: "getBillReductionOtpRequestData",
		errMessage: "AxiosError: Request failed with status code 410",
	},
	{
		fnName: "getGeoMaps",
		errMessage: "AxiosError: Request failed with status code 404",
	},
	{
		fnName: "getSavingsFromGeocoding",
		errMessage: "AxiosError: Request failed with status code 429",
	},
];

function generateData(sizeInMegabytes: number) {
	const size = sizeInMegabytes * 1024 * 1024; // convert MB to bytes
	const chars =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let result = "";
	for (let i = 0; i < size; i++) {
		result += chars.charAt(Math.floor(Math.random() * chars.length));
	}
	return new Blob([result]);
}

async function testUploadBandwidth(sizeInMegabytes: number) {
	const data = generateData(sizeInMegabytes);
	const formData = new FormData();
	formData.append("file", data, "test.bin");

	const token = window.localStorage.getItem("auth_token");
	const url = createUrl("/account/files/test-upload");

	const startTime = performance.now();
	const res = await axios.postForm(url.href, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
			Authorization: `Bearer ${token}`,
		},
	});
	const endTime = performance.now();

	if (res.status === 200) {
		const durationSeconds = (endTime - startTime) / 1000;
		const bitsUploaded = sizeInMegabytes * 1024 * 1024 * 8; // Size of data in bits
		const bandwidthMbps = bitsUploaded / durationSeconds / (1024 * 1024);
		return {
			bandwidthMbps: bandwidthMbps.toFixed(2),
			status: res.status,
		};
	} else {
		return {
			status: res.status,
		};
	}
}

const wrapClientFn =
	<T, U>(func: (args: T) => U, name: string): ((args: T) => Promise<U>) =>
	async (args: T): Promise<U> => {
		try {
			return await func(args);
		} catch (err: any) {
			let channel: "errors" | "errors-network" | "errors-suppress" = "errors";

			for (const { fnName, errMessage } of errorBlackList) {
				if (fnName === name && err.toString() === errMessage) {
					throw err;
				}

				if (
					fnName === "__INTERNAL_getPropertyPageData" &&
					err.toString() === "AxiosError: Request failed with status code 404"
				) {
					channel = "errors-suppress";
				}
			}

			let status = "";
			let response = "";

			if (axios.isAxiosError(err) || axiosRetry.isNetworkError(err)) {
				if (
					err.response?.status === 421 ||
					err.response?.status === 429 ||
					err?.toString() === "AxiosError: Request aborted"
				) {
					channel = "errors-suppress";
				} else if (axiosRetry.isNetworkError(err)) {
					channel = "errors-network";
				}

				status = "**Status**: " + err.response?.status.toString() + " ";
				response = "**Body**: " + JSON.stringify(err.response?.data);

				try {
					if ("connection" in navigator) {
						// @ts-ignore
						const connection = navigator.connection as {
							type: any;
							effectiveType: any;
							downlink: any;
							downlinkMax: any;
							rtt: any;
							saveData: any;
						};
						response += `\n**NetworkInformation**: ${JSON.stringify({
							type: connection?.type,
							effectiveType: connection?.effectiveType,
							downlink: connection?.downlink,
							downlinkMax: connection?.downlinkMax,
							rtt: connection?.rtt,
							saveData: connection?.saveData,
						})}`;
					}
				} catch {}

				// temporarily capture form data info, for diagnostic purposes
				if (name === "uploadFile") {
					try {
						//@ts-ignore
						const form = args.form as FormData;
						const files = [];
						for (const [key, value] of Array.from(form.entries())) {
							if (value instanceof File) {
								files.push({
									key,
									file: {
										webkitRelativePath: value.webkitRelativePath,
										name: value.name,
										type: value.type,
										size: value.size + " bytes",
									},
								});
							}
						}
						response += "\n**FileInformation**: " + JSON.stringify(files);
					} catch {}

					try {
						const { bandwidthMbps } = await testUploadBandwidth(1);
						response += "\n**BandwidthMbps**: " + bandwidthMbps;
					} catch {}
				}
			}

			// const argsString = "```json\n" + JSON.stringify(args) + "\n```";
			sendErrorToWebhook(
				`Error caught while calling \`${name}\`\n${err.toString()}${
					status || response ? "\n" + status + response : ""
				}\n**User Agent**: ${
					typeof window === "undefined"
						? "Server"
						: window.navigator
							? window.navigator.userAgent
							: "Unknown"
				}`,
				{
					channel,
				}
			);

			throw err; // Allow caller to handle error
		}
	};

export const createUrl = (
	endpoint: string,
	// Use OVERRIDE_STAGING when calls are made in getServerSideProps or getStaticProps
	opts?: { OVERRIDE_STAGING?: boolean }
): URL => {
	let url: URL;

	let apiBaseRoute = "";

	// If we're on a preview branch or staging.ownwell.com but not in development, use the staging API
	if (isStagingEnv() && !opts?.OVERRIDE_STAGING) {
		apiBaseRoute = "/staging";
	}

	if (process.env.NODE_ENV === "development") {
		if (endpoint.startsWith("/pdf")) {
			// PDF microservice is on 3030
			url = new URL(endpoint, "http://localhost:3030");
		} else {
			url = new URL(apiBaseRoute + endpoint, "http://localhost:3001");
		}
	} else {
		url = new URL(apiBaseRoute + endpoint, "https://api.ownwell.com");
	}

	return url;
};

const client = {
	getSavingsFromGeocoding: async ({
		address,
		line2,
		city,
		zipcode,
		county,
		state,
		fips,
		apn,
	}: IAddress) => {
		const addy12 = address + (line2 ? ` ${line2}` : "");

		const params = {
			zip: zipcode,
			address: cleanAddress(addy12),
			city: city,
			county: county,
			state: state,
			fips,
			apn,
		};

		const url = createUrl("/savings/elastic");

		for (const [key, value] of Object.entries(params)) {
			if (value) url.searchParams.append(key, value);
		}

		return await axios.get<ExactSavingsData>(url.href);
	},
	getCountyWideSavings: async (args: { county: string; state: string }) => {
		const url = createUrl("/savings/county");

		return await axios.get<GeoLevelSavingsData>(url.href, {
			params: {
				...args,
			},
		});
	},
	logSavingsStep: async (args: AppealsLoggingData) => {
		const address = concatAddressLine2(args.address, args.line2);

		const url = createUrl("/appeal");

		const params = {
			...args,
			address,
			step: "savings",
		};

		try {
			await axios.post(url.href, JSON.stringify(params), {
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (err: any) {
			const full_address = `${
				args.address + (args.line2 ? `, ${args.line2}` : "")
			}, ${args.city}, ${args.state} ${args.zip}`;
			sendWebhook(`**Server Error:** ${err.message}\n${full_address}`);
		}
	},
	submitLegalData: async (args: {
		address: string;
		city: string;
		state: string;
		zip: string;
		session: string;
		savings: number;
		isMailer: boolean;
		fname: string;
		lname: string;
		phone: string;
		email: string;
		company: string;
		company_title: string;
		company_type: string;
		county: string;
		step: string;
		agreed: string;
		signature: string;
		agreement: string;
		assessment: number | undefined;
		form: string;
		addressRaw: string | undefined;
		apnRaw: string | undefined;
		referralCode: string | undefined;
		usedTypeToSign: boolean;
		campaign: string | undefined;
		campaignSetAt: string | undefined;
		apn: string | undefined;
		fips: string | undefined;
		discount: number | undefined;
		oversubscribed: boolean;
		isTakeover: boolean | undefined;
		ip: string;
		sms_consent: boolean;
		recentPurchase: boolean | undefined;
		activeFreeze: boolean | undefined;
		authorized_as: string | undefined;
		savingsType: SavingsType | undefined;
		savingsCopyType: SavingsCopyType | undefined;
		savingsDisplayed: string | undefined;
		referrer: string | undefined;
		exemption_savings_data: ExemptionsSavingsResponse | undefined;
		partner_ref: string | undefined;
		signup_type: SignUpType;
		partner_partial_attribution: boolean | null;
		showSavingsBox: boolean | null;
		ai_video_ab_test: boolean | null;
		ai_video_name: string | null;
		ai_video_clicked: boolean | null;
	}) => {
		const url = createUrl("/appeal");

		return await axios.post<LegalResponse>(url.href, args);
	},
	createAppealMagicLink: async (args: {
		firstName: string;
		lastName: string;
		email: string;
		phone: string;
		multiProps: Array<{
			apn?: string;
			address: string;
			city: string;
			state: string;
			zip: string;
			county?: string;
			company: string;
			company_type: string;
			company_title: string;
			savings_amt?: number;
			recent_purchase?: boolean;
		}>;
		creation_source: string;
	}) => {
		const url = createUrl("/appeal/magic-link");
		return await axios.post(url.href, args);
	},
	submitMultiData: async (args: {
		ip: string;
		usedTypeToSign: boolean;
		session: string;
		signature: string;
		fname: string;
		lname: string;
		phone: string;
		email: string;
		referralCode: string | undefined;
		sms_consent: boolean;
		partner_ref: string | undefined;
		campaign: string | undefined;
		campaignSetAt: string | undefined;
	}) => {
		const url = createUrl("/multi/signed");

		return await axios.post(url.href, args);
	},
	getMultiDataBySession: async (session: string) => {
		return await axios.get<MultiRes>(
			createUrl(`/multi?session=${session}`).href
		);
	},
	logContactStep: async (args: AppealsLoggingData) => {
		const address = concatAddressLine2(args.address, args.line2);

		const params = {
			...args,
			address,
			step: "contact",
		};

		const url = createUrl("/appeal");

		try {
			return await axios.post(url.href, params);
		} catch (err: any) {
			const contact_details = `${args.fname} ${args.lname} ${args.email} ${args.phone}`;
			const full_address = `${args.address}, ${args.city}, ${args.state} ${args.zip}`;
			sendWebhook(
				`**Server Error:** ${err.message}\n${full_address}\n${contact_details}`
			);
		}
	},
	fetchCms: async (endpoint: string) => {
		const url = createUrl("/cms" + endpoint, { OVERRIDE_STAGING: true });
		return await axios.get(url.href);
	},
	getAppealStatusToken: async ({ email }: { email: string }) => {
		const url = createUrl(`/status`);

		const { data } = await axios.post(url.href, {
			email,
		});

		return data;
	},
	updateAppealStatusUpdateFreq: async (args: {
		token: string;
		freq: number;
	}) => {
		const url = createUrl("/status/updateFrequency");

		const { token, freq } = args;
		const res = await axios.post(url.href, {
			token,
			freq,
		});

		return res.data;
	},
	getUserData: async (): Promise<UserData> => {
		try {
			const url = createUrl("/account/user");
			const token = window.localStorage.getItem("auth_token");
			const res = await axios.get(url.href, {
				headers: { Authorization: `Bearer ${token}` },
			});

			return res.data;
		} catch (err) {
			if (!!window.localStorage && !!window.localStorage.removeItem) {
				window.localStorage.removeItem("auth_token");
			}
			console.error("Error:", err);
			throw err;
		}
	},
	signup: async (args: {
		email: string;
		password: string;
		first_name: string;
		last_name: string;
		phone: string;
	}) => {
		try {
			const url = createUrl("/auth/signup");
			const res = await axios.post(url.href, args);
			return res.data;
		} catch (e: any) {
			if (e.response) {
				throw e.response.data.error;
			}
			throw e;
		}
	},
	login: async (args: { email: string; password: string }) => {
		const url = createUrl("/auth/login");
		const res = await axios.post(url.href, args);
		if (
			!!window.localStorage &&
			!!window.localStorage.setItem &&
			!!res.data.token
		) {
			window.localStorage.setItem("auth_token", res.data.token);
		}
		return res.data;
	},
	loginWithGoogle: async (args: { token: string }) => {
		const url = createUrl("/auth/login/google");
		const res = await axios.post(url.href, args);
		if (!!window.localStorage && !!window.localStorage.setItem) {
			window.localStorage.setItem("auth_token", res.data.token);
		}
		return res.data;
	},
	logout: async () => {
		if (!!window.localStorage && !!window.localStorage.removeItem) {
			window.localStorage.removeItem("auth_token");
		}
		const url = createUrl("/auth/logout");
		const res = await axios.post(url.href);
		return res.data;
	},
	forgotPassword: async (args: { email: string }) => {
		const url = createUrl("/auth/forgot");
		const res = await axios.post(url.href, args);
		return res.data;
	},
	claimAccount: async (args: { email: string }) => {
		const url = createUrl("/auth/forgot");
		const res = await axios.post(url.href, { ...args, claiming: true });
		return res.data;
	},
	resetPassword: async (args: {
		email: string;
		password: string;
		key: string;
	}) => {
		const url = createUrl("/auth/reset");
		const res = await axios.post(url.href, args);
		return res.data;
	},
	confirmEmail: async (args: { email: string; token: string }) => {
		const url = createUrl("/auth/confirm");
		const res = await axios.post(url.href, args);
		return res.data;
	},
	fetchSatelliteImg: async (args: { appealId: number }) => {
		const url = createUrl(`/account/satellite/${args.appealId}.png`);
		const token = window.localStorage.getItem("auth_token");
		const res = await axios.get(url.href, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: "blob",
		});
		return res.data;
	},
	sendReferralEmails: async (args: { emails: string[] }) => {
		const url = createUrl(`/account/refer`);
		const token = window.localStorage.getItem("auth_token");
		const res = await axios.post(url.href, args, {
			headers: { Authorization: `Bearer ${token}` },
		});
		return res.data;
	},
	autocomplete: async (args: {
		query: string;
		preferredState?: string;
	}): Promise<AutocompleteRes> => {
		const { query, preferredState } = args;
		const url = createUrl(`/autocomplete`);
		const res = await axios.get(url.href, {
			params: { query, preferredState },
		});
		return res.data;
	},
	autocompleteApn: async (args: {
		query: string;
		county: string;
		state: string;
	}): Promise<ApnAutocompleteRes> => {
		const { query, county, state } = args;
		const url = createUrl(`/autocomplete/apn`);
		const res = await axios.get(url.href, {
			params: { query, county, state },
		});
		return res.data;
	},
	submitAdditionalQuestions: async (args: { form: FormData }) => {
		const { form } = args;
		const url = createUrl("/appeal/questions");
		const res = await axios.post(url.href, form, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});

		return res;
	},
	getPDFContract: async (args: {
		authorization_id: number;
		type: "AOA" | "SA";
	}) => {
		const { authorization_id } = args;
		let url: URL;
		if (args.type == "AOA") {
			url = createUrl("/account/pdf/Authorization-of-Agent.pdf");
		} else if (args.type == "SA") {
			url = createUrl("/account/pdf/Service-Agreement.pdf");
		}

		const token = window.localStorage.getItem("auth_token");
		const res = await axios.get(url!.href, {
			headers: { Authorization: `Bearer ${token}` },
			params: { id: authorization_id },
			responseType: "blob",
		});
		return res;
	},
	submitContactForm: async (args: {
		fname: string;
		lname: string;
		email: string;
		phone?: string | undefined;
		address?: string | undefined;
		county?: string | undefined;
		state?: string | undefined;
		subject?: string;
		topicReason?: string;
		comments: string;
		commercial: boolean;
		unitNumber?: string;
		propertyType?: string;
		company?: string;
		customerType?: string;
		magicLinkOutput?: string;
		fullAddress?: string;
		fips?: string;
		apn?: string;
	}) => {
		const url = createUrl("/contact");
		const res = await axios.post(url.href, args);
		return res;
	},
	submitPartnerForm: async (args: {
		fname: string;
		lname: string;
		email: string;
		phone: string;
		partnerType: string;
		company: string;
		linkedinUrl: string;
		heardAbout: string;
		comments: string;
	}) => {
		const url = createUrl("/contact-partner");
		const res = await axios.post(url.href, args);
		return res;
	},
	getArticleById: async (id: string) => {
		const url = createUrl(`/help/article/${id}`, { OVERRIDE_STAGING: true });
		const res = await axios.get<IArticle>(url.href);
		return res;
	},
	getSectionById: async (id: string) => {
		const url = createUrl(`/help/section/${id}`, { OVERRIDE_STAGING: true });
		const res = await axios.get<ISection>(url.href);
		return res;
	},
	getCategoryById: async (id: string) => {
		const url = createUrl(`/help/category/${id}`, { OVERRIDE_STAGING: true });
		const res = await axios.get<CategoryByIdRes>(url.href);
		return res;
	},
	getCategories: async () => {
		const url = createUrl("/help/categories", { OVERRIDE_STAGING: true });
		const res = await axios.get<ICategory[]>(url.href);
		return res;
	},
	getSections: async () => {
		const url = createUrl("/help/sections", { OVERRIDE_STAGING: true });
		const res = await axios.get<ISection[]>(url.href);
		return res;
	},
	getHelpCenterAllArticles: async () => {
		const url = createUrl("/help/articles", { OVERRIDE_STAGING: true });
		const res = await axios.get<IArticle[]>(url.href);
		return res;
	},
	getSearchResults: async (query: string) => {
		const url = createUrl("/help/search", { OVERRIDE_STAGING: true });
		const res = await axios.get<FoundArticles>(url.href, {
			params: { query },
		});
		return res;
	},
	getHelpCenterHomeData: async () => {
		const url = createUrl("/help/home", { OVERRIDE_STAGING: true });
		const res = await axios.get<HelpCenterHome>(url.href);
		return res;
	},
	getPropertiesMultiDash: async (args: {
		search?: string;
		pageNumber?: number;
		tax_year?: number[];
		state?: string[];
		company?: string[];
		narBucket?: string;
		statusBucket?: string;
	}) => {
		const url = createUrl("/account/multi-dash");
		const token = window.localStorage.getItem("auth_token");

		const res = await axios.get<Dashboard.GetProperties.Response>(url.href, {
			headers: { Authorization: `Bearer ${token}` },
			params: { ...args },
		});

		return res;
	},
	getActiveProperties: async (args: {
		search?: string;
		pageNumber?: number;
		state?: string[];
		county?: string[];
		sorting?: string | null;
		desc?: boolean;
		resultsPerPage: number;
	}) => {
		const url = createUrl("/account/all-active-properties");
		const token = window.localStorage.getItem("auth_token");

		const res = await axios.get<AllActiveProperty.GetProperties.Response>(
			url.href,
			{
				headers: { Authorization: `Bearer ${token}` },
				params: { ...args },
			}
		);

		return res;
	},
	getActionsRequiredCounts: async () => {
		const url = createUrl("/account/actions-required/counts");
		const token = window.localStorage.getItem("auth_token");

		const res =
			await axios.get<ActionsRequired.GetActionsRequiredCounts.Response>(
				url.href,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

		return res;
	},
	getActionsRequired: async (args: {
		search?: string;
		pageNumber?: number;
		tax_years?: number[];
		states?: string[];
		task_statuses?: string[];
		task_review_statuses?: string[];
		task_types?: string[];
	}) => {
		const url = createUrl("/account/actions-required");
		const token = window.localStorage.getItem("auth_token");

		const res = await axios.get<ActionsRequired.GetActionsRequired.Response>(
			url.href,
			{
				headers: { Authorization: `Bearer ${token}` },
				params: { ...args },
			}
		);

		return res;
	},
	downloadActionsRequired: async (args: {
		search?: string;
		pageNumber?: number;
		tax_years?: number[];
		states?: string[];
		task_statuses?: string[];
		task_review_statuses?: string[];
		task_types?: string[];
	}) => {
		const url = createUrl("/account/actions-required/download");
		const token = window.localStorage.getItem("auth_token");

		const res = await axios.get(url.href, {
			headers: { Authorization: `Bearer ${token}` },
			params: { ...args },
			responseType: "arraybuffer",
		});

		return res;
	},
	downloadPropertiesMultiDash: async (args: {
		search?: string;
		tax_year?: number[];
		state?: string[];
		company?: string[];
		narBucket?: string;
		statusBucket?: string;
	}) => {
		const url = createUrl("/account/multi-dash/download");
		const token = window.localStorage.getItem("auth_token");

		const res = await axios.get(url.href, {
			headers: { Authorization: `Bearer ${token}` },
			params: { ...args },
			responseType: "arraybuffer",
		});

		return res;
	},
	downloadActiveProperties: async (args: {
		search?: string;
		pageNumber?: number;
		state?: string[];
		county?: string[];
		sorting?: string | null;
		desc?: boolean;
		resultsPerPage: number;
	}) => {
		const url = createUrl("/account/all-active-properties/download");
		const token = window.localStorage.getItem("auth_token");

		const res = await axios.get(url.href, {
			headers: { Authorization: `Bearer ${token}` },
			params: { ...args },
			responseType: "arraybuffer",
		});

		return res;
	},
	getPricing: async () => {
		const url = createUrl("/account/pricing");
		const token = window.localStorage.getItem("auth_token");

		const { data } = await axios.get<CustomerPricingData>(url.href, {
			headers: { Authorization: `Bearer ${token}` },
		});

		return data;
	},
	getProperties: async () => {
		const url = createUrl("/account/properties");
		const token = window.localStorage.getItem("auth_token");

		const res = await axios.get<PropertyDetails[]>(url.href, {
			headers: { Authorization: `Bearer ${token}` },
		});

		return res;
	},
	getProperty: async ({ id }: { id: string }) => {
		const url = createUrl("/account/property");
		const token = window.localStorage.getItem("auth_token");

		const res = await axios.get<PropertyDetails>(url.href, {
			params: { id },
			headers: { Authorization: `Bearer ${token}` },
		});
		return res;
	},
	patchProperty: async (args: Property.API.PATCH.Request) => {
		const url = createUrl("/account/property");
		const token = window.localStorage.getItem("auth_token");
		return await axios.patch<any, any, Property.API.PATCH.Request>(
			url.href,
			args,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
	},
	trackExitIntent: async ({
		session,
		address,
	}: {
		session: string;
		address: string;
	}) => {
		const url = createUrl("/appeal/exitintent");
		const res = await axios.get(url.href, {
			params: { session, address },
		});
		return res;
	},
	uploadFile: async ({
		form,
		appealId,
		onProgress,
	}: {
		form: FormData;
		appealId: string;
		onProgress?: (progressEvent: AxiosProgressEvent) => void;
	}) => {
		const token = window.localStorage.getItem("auth_token");
		const url = createUrl("/account/files");
		await axios.postForm(url.href, form, {
			params: { appeal: appealId },
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${token}`,
			},
			onUploadProgress: progressEvent => {
				if (onProgress) onProgress(progressEvent);
			},
		});
	},
	getFilesByPropertyId: async (property_id: number | string) => {
		property_id = property_id.toString();

		const url = createUrl("/account/files");
		const token = window.localStorage.getItem("auth_token");

		return await axios.get<FileUploadProps[]>(url.href, {
			params: { property_id },
			headers: { Authorization: `Bearer ${token}` },
		});
	},
	getInsuranceWaitlistPlace: async () => {
		const url = createUrl("/account/waitlist/insurance/place");
		const token = window.localStorage.getItem("auth_token");
		return await axios.get<{
			placeInLine: number | undefined;
			success: boolean;
		}>(url.href, {
			headers: { Authorization: `Bearer ${token}` },
		});
	},
	joinInsuranceWaitlist: async () => {
		const url = createUrl("/account/waitlist/insurance");
		const token = window.localStorage.getItem("auth_token");
		return await axios.get<{
			placeInLine: number | undefined;
			success: boolean;
		}>(url.href, {
			headers: { Authorization: `Bearer ${token}` },
		});
	},
	updatePassword: async (args: {
		email: string;
		password: string;
		oldPassword: string;
	}) => {
		const url = createUrl("/account/update/password");
		const token = window.localStorage.getItem("auth_token");
		return await axios.post(url.href, args, {
			headers: { Authorization: `Bearer ${token}` },
		});
	},
	updateName: async (args: { first_name: string; last_name: string }) => {
		const url = createUrl("/account/update/name");
		const token = window.localStorage.getItem("auth_token");
		return await axios.post(url.href, args, {
			headers: { Authorization: `Bearer ${token}` },
		});
	},
	updateMailingAddress: async (args: {
		address: string;
		address2: string;
		city: string;
		state: string;
		zip_code: string;
	}) => {
		const url = createUrl("/account/update/mailing");
		const token = window.localStorage.getItem("auth_token");
		return await axios.post(url.href, args, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	getReauthorizationData: async (session: string) => {
		const url = createUrl("/reauthorize");
		const res = await axios.get<ReauthorizationData[]>(url.href, {
			params: { session },
		});
		return res;
	},
	submitReauthorization: async (args: {
		ip: string;
		session: string;
		usedTypeToSign: boolean;
		company: string | undefined;
		company_title: string | undefined;
		company_type: string | undefined;
		first_name: string;
		last_name: string;
		signature: string;
	}) => {
		const url = createUrl("/reauthorize");
		const res = await axios.post<ReauthorizationData>(url.href, args);
		return res;
	},
	updatePropertyDetails: async (args: {
		id: number;
		appealId: number;
		type: "edit_details" | "profile_stage_1" | "profile_stage_2";
		payload?: {
			beds?: number;
			baths?: number;
			nickname?: string;
			year_built?: number;
			year_renovated?: number;
			last_sale_date_prop?: string;
			last_sale_price_prop?: number;
			use_info?: string;
			num_stories?: number;
			garage_type?: string;
			garage_spaces?: number;
			attic_sqft_fin?: number;
			attic_sqft_unfin?: number;
			basement_sqft_fin?: number;
			basement_sqft_unfin?: number;
			num_units?: number | undefined;
			has_pool?: boolean | undefined;
			has_patio?: boolean | undefined;
			has_deck?: boolean | undefined;
			has_porch?: boolean | undefined;
			interior_walls?: string;
			air_conditioning?: string;
			sqft_portal_land?: number;
			sqft_portal_resi_imprv?: number;
			sqft_portal_comm_gross?: number;
			sqft_portal_comm_rentable?: number;
			heat?: string;
			roof_type?: string;
			fireplaces?: string;
			external_walls?: string;
			driveway?: string;
			construction?: string;
			sewer_type?: string;
			water_type?: string;
		};
	}) => {
		const url = createUrl("/account/update/property");
		const token = window.localStorage.getItem("auth_token");
		return await axios.post(url.href, args, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	submitQuestionAnswer: async (args: {
		questionId: string;
		appealId: number;
		payload: Record<string, any>;
	}) => {
		const url = createUrl("/account/profile");
		const token = window.localStorage.getItem("auth_token");
		return await axios.post(url.href, args, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	getInvoiceData: async () => {
		const url = createUrl("/account/invoice");
		const token = window.localStorage.getItem("auth_token");
		return await axios.get<Invoices.Collection>(url.href, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	getArbitrationData: async ({
		session,
		appeal_id,
	}: {
		session: string;
		appeal_id: string;
	}) => {
		const url = createUrl("/arbitration");
		return await axios.get<ArbitrationUserData>(url.href, {
			params: { session, appeal_id },
		});
	},
	submitArbitrationData: async (args: {
		signature: string;
		session: string;
		appeal_id: string;
	}) => {
		const url = createUrl("/arbitration");

		return await axios.post(url.href, args);
	},
	submitInsuranceSurvey: async (args: {
		meta: { customer_id: number; property_id: number };
		answers: InsuranceAnswerReducerState;
	}) => {
		const url = createUrl("/account/insurance/survey");
		const token = window.localStorage.getItem("auth_token");
		return await axios.post(url.href, args, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	requestInsuranceContact: async (args: {
		quote_id: number | null;
		property_id: number;
	}) => {
		const url = createUrl("/account/insurance/contact");
		const token = window.localStorage.getItem("auth_token");
		return await axios.post(url.href, args, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	removeNeedsAttentionReason: async (args: {
		appealId: number;
		reason: NeedsAttentionReasons;
	}) => {
		const url = createUrl("/account/update/needsAttention");
		const token = window.localStorage.getItem("auth_token");

		return await axios.delete(url.href, {
			headers: { Authorization: `Bearer ${token}` },
			params: { ...args },
		});
	},
	downloadAccountFile: async (args: {
		appealId: number;
		fileName: string;
		is_ownwell_document?: 1 | 0;
	}) => {
		const url = createUrl(`/account/files/${args.fileName}`);
		const token = window.localStorage.getItem("auth_token");

		const params: Record<string, any> = { appeal_id: args.appealId };

		if (args.is_ownwell_document) {
			params.is_ownwell_document = args.is_ownwell_document;
		}

		return await axios.get(url.href, {
			headers: { Authorization: `Bearer ${token}` },
			params,
			responseType: "blob",
		});
	},
	downloadOwnwellContracts: async (args: {
		appealId: number;
		formType: "aoa" | "agreement";
	}) => {
		const url = createUrl(`/account/contracts`);
		const token = window.localStorage.getItem("auth_token");

		return await axios.get(url.href, {
			headers: { Authorization: `Bearer ${token}` },
			params: { appealId: args.appealId, form: args.formType },
			responseType: "arraybuffer",
		});
	},
	consentToSms: async () => {
		const url = createUrl(`/account/sms/consent`);
		const token = window.localStorage.getItem("auth_token");

		return await axios.post(
			url.href,
			{},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);
	},
	getCountyData: async (args: { county: string; state: string }) => {
		const url = createUrl("/county");
		return await axios.get<CountyData>(url.href, {
			params: args,
		});
	},
	confirmOtcCode: async (args: { email: string; code: string }) => {
		const url = createUrl("/auth/confirm/otc");
		return await axios.post<{ token: string }>(url.href, args);
	},
	cancelCurrentAppeal: async (args: {
		propertyId: number;
		cancelReason: string;
		cancelDescription: string | undefined;
	}) => {
		const { propertyId, cancelReason, cancelDescription } = args;
		const url = createUrl(`/account/cancel-current-appeal`);

		const token = window.localStorage.getItem("auth_token");

		return await axios.post(
			url.href,
			{
				cancelReason,
				cancelDescription,
			},
			{
				headers: { Authorization: `Bearer ${token}` },
				params: {
					propertyId,
				},
			}
		);
	},
	pauseAutoAppeal: async (args: {
		propertyId: number;
		pauseReason: string;
		pauseDescription: string | undefined;
	}) => {
		const { propertyId, pauseReason, pauseDescription } = args;
		const url = createUrl(`/account/pause-auto-appeal`);

		const token = window.localStorage.getItem("auth_token");

		return await axios.post(
			url.href,
			{
				pauseReason,
				pauseDescription,
			},
			{
				headers: { Authorization: `Bearer ${token}` },
				params: {
					propertyId,
				},
			}
		);
	},
	markPropertyAsSold: async (args: {
		propertyId: number;
		closingDate: string;
		salePrice: string;
		saleReason: string;
		purchasedNewProperty: boolean | undefined;
		representNewProperty: boolean | undefined;
		satisfiedWithSavings: boolean | undefined;
		npsRating: string;
		npsDescription: string;
	}) => {
		const { propertyId, ...payload } = args;
		const url = createUrl(`/account/mark-as-sold`);

		const token = window.localStorage.getItem("auth_token");

		return await axios.post(url.href, payload, {
			headers: { Authorization: `Bearer ${token}` },
			params: {
				propertyId,
			},
		});
	},
	reduceContingency: async (args: {
		propertyId: number;
		reason: "cancel" | "auto-appeal";
	}) => {
		const { propertyId, reason } = args;
		const url = createUrl(`/account/reduce-contingency`);

		const token = window.localStorage.getItem("auth_token");

		return await axios.post(
			url.href,
			{ reason },
			{
				headers: { Authorization: `Bearer ${token}` },
				params: {
					propertyId,
				},
			}
		);
	},
	createReauthorization: async (args: { appealId: number }) => {
		const { appealId } = args;
		const url = createUrl(`/account/create-reauthorization`);

		const token = window.localStorage.getItem("auth_token");

		return await axios.post<{ session: string }>(
			url.href,
			{ appeal_id: appealId },
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);
	},
	contactedCountyOwnership: async (args: { appealId: number }) => {
		const { appealId } = args;
		const url = createUrl(`/account/contacted-county-ownership`);

		const token = window.localStorage.getItem("auth_token");

		return await axios.post<{ session: string }>(
			url.href,
			{ appeal_id: appealId },
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);
	},
	declinePropertySurvey: async (args: { appealId: number }) => {
		const { appealId } = args;
		const url = createUrl(`/account/decline-survey`);

		const token = window.localStorage.getItem("auth_token");

		return await axios.post<{ session: string }>(
			url.href,
			{ appeal_id: appealId },
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);
	},
	getOwnwellInvoice: async (args: { invoice_ref: string }) => {
		const { invoice_ref } = args;

		const url = createUrl(`/invoice`, { OVERRIDE_STAGING: true });

		return await axios.get<Invoices.Collection>(url.href, {
			params: {
				invoice_ref,
			},
		});
	},
	payInvoice: async (args: {
		invoice_ref: string;
		amount_in_cents: number;
		card_number: string;
		card_exp_month: string;
		card_exp_year: string;
		card_cvc: string;
	}) => {
		const url = createUrl(`/invoice/pay`);

		return await axios.post(url.href, args);
	},
	getAppealHistory: async (args: { appealId: number }) => {
		const { appealId } = args;
		const url = createUrl(`/account/appeal-history`);

		const token = window.localStorage.getItem("auth_token");

		return await axios.get<AppealHistoryItem[]>(url.href, {
			params: { appeal_id: appealId },
			headers: { Authorization: `Bearer ${token}` },
		});
	},
	getLifeTimeSavings: async (args: { propertyId: number }) => {
		const { propertyId } = args;
		const url = createUrl(`/account/lifetime-savings`);

		const token = window.localStorage.getItem("auth_token");

		return await axios.get<LifeTimeSavingsResponse>(url.href, {
			params: { property_id: propertyId },
			headers: { Authorization: `Bearer ${token}` },
		});
	},
	getTaxes: async (args: { propertyId: number }) => {
		const { propertyId } = args;
		const url = createUrl(`/account/taxes`);

		const token = window.localStorage.getItem("auth_token");

		return await axios.get<PropertyTaxes.Response>(url.href, {
			params: { property_id: propertyId },
			headers: { Authorization: `Bearer ${token}` },
		});
	},
	loginToStagingEnv: async (args: { email: string; password: string }) => {
		const url = createUrl("/auth/login/staging", { OVERRIDE_STAGING: true });

		const res = await axios.post(url.href, args);
		if (
			!!window.localStorage &&
			!!window.localStorage.setItem &&
			!!res.data.token
		) {
			window.localStorage.setItem("staging_auth_token", res.data.token);
		}
		return res.data;
	},
	__INTERNAL_takeoverAccount: async (args: {
		takeoverId: number;
		token: string;
	}) => {
		const url = createUrl("/internal/takeover");

		const res = await axios.post(url.href, args);

		return res;
	},
	__INTERNAL_checkToolkitAccess: async () => {
		const url = createUrl("/internal/toolkit-access");

		const res = await axios.get(url.href);

		return res;
	},
	__INTERNAL_getTakeoverToken: async () => {
		const url = createUrl("/internal/toolkit-token");

		const res = await axios.get(url.href);

		return res;
	},
	restartExemptionApplication: async (args: { propertyId: number }) => {
		const url = createUrl("/account/exemption/restart");
		const token = window.localStorage.getItem("auth_token");

		return await axios.post(url.href, args, {
			headers: { Authorization: `Bearer ${token}` },
		});
	},
	silenceExemptionFallOffAlert: async (args: { propertyId: number }) => {
		const url = createUrl("/account/exemption/silence-falloff-alerts");
		const token = window.localStorage.getItem("auth_token");

		return await axios.post(url.href, args, {
			headers: { Authorization: `Bearer ${token}` },
		});
	},
	submitExemptionsFlowAnswers: async (args: {
		propertyId: number;
		state: string;
		county: string;
		city: string;
		current_market_value: number | null;
		is_primary_residence: boolean | undefined | null;
		current_homestead_status: string | undefined | null;
		should_transfer_status: boolean | undefined | null;
		purchase_date: string | undefined | null;
		move_in_date: string | undefined | null;
		owner1_percent_ownership: number | undefined | null;
		owner2_percent_ownership: number | undefined | null;
		birth_date: string | undefined | null;
		previous_address: string | undefined | null;
		previous_address2: string | undefined | null;
		previous_city: string | undefined | null;
		previous_state: string | undefined | null;
		previous_zip_code: string | undefined | null;
		previous_county: string | undefined | null;
		previous_move_out_date: string | undefined | null;
		owner2_first_name: string | undefined | null;
		owner2_last_name: string | undefined | null;
		owner2_email: string | undefined | null;
		owner2_phone: string | undefined | null;
		owner2_birth_date: string | undefined | null;
		other_property_counties: string | undefined | null;
		ownership_status: string | undefined | null;
		marital_status: string | undefined | null;
		solar_or_wind_has_current_exemptions: boolean | undefined | null;
		solar_or_wind_interested_in_installing: boolean | undefined | null;
		owner_driver_license_exempt: boolean | undefined | null;
		owner2_driver_license_exempt: boolean | undefined | null;
		solar_or_wind_installed: boolean | undefined | null;
		other_heir_owners_occupy: boolean | undefined | null;
		is_inherited_property: boolean | undefined | null;
		has_disability: boolean | undefined | null;
		surviving_spouse_of_65_plus_person: boolean | undefined | null;
		disabled_veteran_or_surviving_spouse: boolean | undefined | null;
		surviving_spouse_of_veteran_killed_in_line_of_duty:
			| boolean
			| undefined
			| null;
		surviving_spouse_of_first_responder_killed_in_line_of_duty:
			| boolean
			| undefined
			| null;
		donated_residence_of_partially_disabled_vet_or_surviving_spouse:
			| boolean
			| undefined
			| null;
		owned_through_cooporative_housing: boolean | undefined | null;
		owned_through_non_profit: boolean | undefined | null;
		is_veteran: boolean | undefined | null;
		collect_rent_at_this_property: boolean | undefined | null;
		rare_situations_none_of_the_above: boolean | undefined | null;
		percent_income_producing: number | undefined | null;
		retro_homestead_savings_estimate?: number | undefined | null;
		manufactured_home_make: string | undefined | null;
		manufactured_home_model: string | undefined | null;
		manufactured_home_id: string | undefined | null;
		transfer_move_out_date?: string | undefined | null;
		transfer_sale_date?: string | undefined | null;
		transfer_owner_did_not_sell_property: boolean | undefined | null;
		owner1_pii_value: string | undefined | null;
		owner1_pii_ref_type: ExemptionPIIRefType | undefined | null;
		owner2_pii_value: string | undefined | null;
		owner2_pii_ref_type: ExemptionPIIRefType | undefined | null;
		owner1_license_number_value: string | undefined | null;
		owner2_license_number_value: string | undefined | null;
		ny_gov_id_account_username: string | undefined | null;
		ny_gov_id_account_password: string | undefined | null;
		special_fields:
			| undefined
			| null
			| PropertyDetailsExemptionFields["special_fields"];
	}) => {
		const url = createUrl("/account/exemption");
		const token = window.localStorage.getItem("auth_token");

		return await axios.post(
			url.href,
			{
				...args,
				special_fields: !!args.special_fields
					? JSON.stringify(args.special_fields)
					: null,
			},
			{ headers: { Authorization: `Bearer ${token}` } }
		);
	},
	submitExemptionsDataNotQualified: async (args: { propertyId: number }) => {
		const url = createUrl("/account/exemption/not-qualified");
		const token = window.localStorage.getItem("auth_token");

		return await axios.post(
			url.href,
			{ ...args },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
	},
	submitExemptionsSignature: async (args: {
		propertyId: number;
		signature: string;
		signature2?: string;
	}) => {
		const url = createUrl("/account/exemption/signed");
		const token = window.localStorage.getItem("auth_token");

		return await axios.post<{ exemption_id: number | null }>(
			url.href,
			{ ...args },
			{ headers: { Authorization: `Bearer ${token}` } }
		);
	},
	submitExemptionsDriverLicense: async (args: {
		propertyId: number;
		formData: FormData;
	}) => {
		const url = createUrl("/account/exemption/license");
		const token = window.localStorage.getItem("auth_token");

		return await axios.post(url.href, args.formData, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${token}`,
			},
			params: {
				propertyId: args.propertyId,
			},
		});
	},
	uploadExemptionFiles: async (args: {
		propertyId: number;
		formData: FormData;
		onProgress?: (progressEvent: AxiosProgressEvent) => void;
	}) => {
		const url = createUrl("/account/exemption/files");
		const token = window.localStorage.getItem("auth_token");

		return await axios.postForm<{ file_refs: { [key: string]: string } }>(
			url.href,
			args.formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
				params: {
					propertyId: args.propertyId,
				},
				onUploadProgress: progressEvent => {
					if (args.onProgress) args.onProgress(progressEvent);
				},
			}
		);
	},
	getExemptionsSavingsData: async (args: {
		propertyId: number;
		moveInDate?: string;
		purchaseDate?: string;
	}) => {
		const url = createUrl("/account/exemption/savings");
		const token = window.localStorage.getItem("auth_token");

		return await axios.post<ExemptionsSavingsResponse>(url.href, args, {
			headers: { Authorization: `Bearer ${token}` },
		});
	},
	generateTakeoverToken: async () => {
		const url = createUrl("/account/generate-takeover-token");
		const token = window.localStorage.getItem("auth_token");

		return await axios.post(
			url.href,
			{},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);
	},
	getInvoiceBreakdownSpreadsheet: async (args: { appealId: number }) => {
		const url = createUrl("/invoice-breakdown-spreadsheet");

		return await axios.get(url.href, {
			params: { appealId: args.appealId },
			responseType: "blob",
		});
	},
	sendVerificationRequestExemptionsDriversLicense: async (args: {
		propertyId: number;
		owner1: boolean;
		owner2: boolean;
	}) => {
		const url = createUrl("/account/exemption/start-license-verification");

		const token = window.localStorage.getItem("auth_token");

		return await axios.post(
			url.href,
			{ ...args },
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);
	},
	verifyExemptionsDriversLicense: async (args: { propertyId: number }) => {
		const url = createUrl("/account/exemption/license-verification");
		const token = window.localStorage.getItem("auth_token");

		return await axios.get(url.href, {
			params: { propertyId: args.propertyId },
			headers: { Authorization: `Bearer ${token}` },
		});
	},
	__INTERNAL_getPropertiesSitemapMetaByState: async ({
		key,
		...args
	}: {
		state: string;
		key: string;
	}) => {
		const url = createUrl("/seo/properties-meta", { OVERRIDE_STAGING: true });
		return await axios.get(url.href, {
			params: { state: args.state },
			headers: {
				Authorization: key,
			},
		});
	},
	__INTERNAL_getPropertiesSitemapByStateAndPage: async ({
		key,
		...args
	}: {
		state: string;
		page: number;
		key: string;
	}) => {
		const url = createUrl("/seo/properties", { OVERRIDE_STAGING: true });
		try {
			return await axios.get(url.href, {
				params: { state: args.state, page: args.page },
				headers: {
					Authorization: key,
				},
			});
		} catch (err) {
			sendErrorToWebhook(
				`Error fetching site map for state: ${args.state} page ${args.page}`
			);
			throw err;
		}
	},
	__INTERNAL_getPropertyPageData: async ({
		key,
		...args
	}: {
		state: string;
		county: string;
		apn: string;
		key: string;
	}) => {
		const url = createUrl("/seo/property", { OVERRIDE_STAGING: true });

		return await axios.get(url.href, {
			params: args,
			headers: {
				Authorization: key,
			},
		});
	},
	getGeoMaps: async ({
		state,
		county,
	}: {
		state?: string;
		county?: string;
	}) => {
		const url = createUrl(`/geo-maps`, { OVERRIDE_STAGING: true });

		return await axios.get<{
			geoJson: object | null;
			topoJson: object | null;
		}>(url.href, {
			params: {
				state,
				county,
			},
		});
	},
	getTrendsData: async ({
		state,
		county,
	}: {
		state: string;
		county: string;
	}) => {
		const url = createUrl(`/account/trends/${state}/${county}`);
		const token = window.localStorage.getItem("auth_token");
		return await axios.get<GetTrendsDataResponse>(url.href, {
			headers: { Authorization: `Bearer ${token}` },
		});
	},
	__INTERNAL_getTrendsData: async ({
		key,
		...args
	}: {
		state?: string;
		county?: string;
		city?: string;
		key: string;
	}) => {
		const path = ["/seo/trends", args.state, args.county, args.city]
			.filter(v => !!v)
			.join("/");
		const url = createUrl(path, { OVERRIDE_STAGING: true });

		const res = await fetch(
			url.href +
				"?" +
				new URLSearchParams({
					textCacheKey: TRENDS_LAST_MODIFIED,
				}),
			{
				method: "GET",
				headers: {
					Authorization: key,
				},
				mode: "no-cors",
			}
		);
		const data: GetTrendsDataResponse = await res.json();
		return { data };
	},
	__INTERNAL_getTrendsSitemap: async ({
		key,
		page,
	}: {
		page?: string;
		key: string;
	}) => {
		const url = createUrl("/seo/trends/sitemap", { OVERRIDE_STAGING: true });

		return await axios.get(url.href, {
			params: {
				page,
			},
			headers: {
				Authorization: key,
			},
		});
	},
	upgradeAccountToPremium: async () => {
		const url = createUrl("/account/upgrade-tier");
		const token = window.localStorage.getItem("auth_token");

		return await axios.post(
			url.href,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
	},
	upgradeAccountToConcierge: async (args: { accountTier: string }) => {
		const url = createUrl("/account/upgrade-tier/concierge/");
		const token = window.localStorage.getItem("auth_token");
		return await axios.post(
			url.href,
			{
				accountTier: args.accountTier,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
	},
	payConciergeInvoice: async (args: {
		product: string;
		cadence: string;
		card_number: string;
		card_exp_month: string;
		card_exp_year: string;
		card_cvc: string;
	}) => {
		const url = createUrl("/account/subscription/start/");
		const token = window.localStorage.getItem("auth_token");
		return await axios.post(url.href, args, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	cancelConciergeSubsription: async (args: { subscription_id: number }) => {
		const url = createUrl("/account/subscription/cancel/");
		const token = window.localStorage.getItem("auth_token");
		return await axios.post(url.href, args, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	downgradeAccountToPremium: async () => {
		const url = createUrl("/account/downgrade-tier");
		const token = window.localStorage.getItem("auth_token");

		return await axios.post(
			url.href,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
	},
	getNotifications: async (args: { propertyId?: number }) => {
		const url = createUrl("/account/notifications");
		const token = window.localStorage.getItem("auth_token");

		return await axios.get(url.href, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			params: { property_id: args.propertyId },
			responseType: "json",
		});
	},
	dismissNotification: async (notificationId: number) => {
		const url = createUrl(`/account/notifications/${notificationId}/dismiss`);
		const token = window.localStorage.getItem("auth_token");

		return await axios.patch(
			url.href,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
	},
	getPropertyMarketValueHistory: async (args: { propertyId?: number }) => {
		const url = createUrl("/account/property-market-value-history");
		const token = window.localStorage.getItem("auth_token");

		return await axios.get(url.href, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			params: { property_id: args.propertyId },
			responseType: "json",
		});
	},
	trackVisit: async ({
		abortController,
		...args
	}: {
		sessionId: string;
		deviceId: string | null;
		utm_content: string | null;
		utm_term: string | null;
		utm_campaign: string | null;
		utm_medium: string | null;
		utm_source: string | null;
		customer_id: number | null;
		abortController: AbortController;
	}) => {
		const url = createUrl("/track-visit");

		return await axios
			.post(url.href, args, {
				signal: abortController.signal,
			})
			.catch(err => {
				if (axios.isAxiosError(err) && err.response?.status === 500) {
					throw err;
				}
			});
	},
	trackAction: async ({
		abortController,
		...args
	}: {
		sessionId: string;
		url: string;
		params: Record<string, string>;
		abortController: AbortController;
	}) => {
		const url = createUrl("/track-action");

		return await axios
			.post(url.href, args, {
				signal: abortController.signal,
			})
			.catch(err => {
				if (axios.isAxiosError(err) && err.response?.status === 500) {
					throw err;
				}
			});
	},
	patchEquityDetails: async (args: {
		property_id: number;
		payload?: {
			last_sale_date_prop?: string;
			last_sale_price_prop?: number;
			mortgage_down_payment?: number;
			mortgage_amount?: number;
			mortgage_interest_rate?: number;
			mortgage_term?: number;
			mortgage_paid_off?: boolean;
			mortgage_extra_monthly_payment?: number;
			mortgage_refinance_date?: string | null;
		};
	}) => {
		const url = createUrl("/account/property-equity");
		const token = window.localStorage.getItem("auth_token");
		return await axios.patch(url.href, args, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	getPropertyEquity: async ({ propertyId }: { propertyId: number }) => {
		const url = createUrl("/account/property-equity");
		const token = window.localStorage.getItem("auth_token");

		const res = await axios.get<PropertyEquity.Response>(url.href, {
			params: { property_id: propertyId },
			headers: { Authorization: `Bearer ${token}` },
		});
		return res;
	},
	getLeftovers: async () => {
		const url = createUrl("/account/leftovers");

		const token = window.localStorage.getItem("auth_token");

		const res = await axios.get(url.href, {
			headers: { Authorization: `Bearer ${token}` },
		});
		return res;
	},
	generateLeftoversMagicLink: async (args: {
		leftovers: LeftoverProperty[];
	}) => {
		const url = createUrl("/account/leftovers-magic-link");

		const token = window.localStorage.getItem("auth_token");

		const res = await axios.post(
			url.href,
			{
				leftovers: args.leftovers,
			},
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);

		return res;
	},
	getPricingByZip: async ({ zipCode }: { zipCode: string }) => {
		const url = createUrl("/pricing-by-zip");

		const { data } = await axios.get(url.href, {
			params: { zip_code: zipCode },
		});

		return data;
	},
	getExpensesDetails: async ({
		propertyId,
		patchEstimates,
	}: {
		propertyId: number;
		patchEstimates: boolean;
	}) => {
		const url = createUrl("/account/expenses");

		const token = window.localStorage.getItem("auth_token");

		const res = await axios.get<ExpensesData>(url.href, {
			params: { propertyId, patchEstimates },
			headers: { Authorization: `Bearer ${token}` },
		});

		return res;
	},
	patchExpensesDetails: async (args: {
		property_id: number;
		in_escrow: boolean | null;
		insurance: number | null;
		utilities: number | null;
		escrow: number | null;
		property_tax: number | null;
		pmi: number | null;
	}) => {
		const url = createUrl("/account/expenses");

		const token = window.localStorage.getItem("auth_token");

		const { data } = await axios.patch(url.href, args, {
			headers: { Authorization: `Bearer ${token}` },
		});

		return data;
	},
	submitBillReductionData: async (args: {
		property_id: number;
		form: FormData;
	}) => {
		const url = createUrl("/account/bill-reduction");

		const token = window.localStorage.getItem("auth_token");

		args.form.append("property_id", args.property_id.toString());

		return await axios.post<{ file_refs: { [key: string]: string } }>(
			url.href,
			args.form,
			{
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
			}
		);
	},
	getReferralProgramByCode: async (args: { referralCode: string }) => {
		const url = createUrl("/referral-code-to-program", {
			OVERRIDE_STAGING: true,
		});

		return await axios.get(url.href, {
			params: {
				referralCode: args.referralCode,
			},
		});
	},
	getNeighborhoodCrimeIncidents: async (args: { propertyId: number }) => {
		const url = createUrl("/account/crime-alerts");

		const token = window.localStorage.getItem("auth_token");

		return await axios.get<{
			subject: { lat: number; lng: number };
			incidents: CrimeAlertIncident[];
		}>(url.href, {
			params: {
				propertyId: args.propertyId,
			},
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	getNeighborhoodCrimeTrends: async (args: { propertyId: number }) => {
		const url = createUrl("/account/crime-trends");

		const token = window.localStorage.getItem("auth_token");

		return await axios.get<{ trends: CrimeTrendsEntry[] }>(url.href, {
			params: {
				propertyId: args.propertyId,
			},
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	getNeighborhoodPermits: async (args: { propertyId: number }) => {
		const url = createUrl("/account/neighborhood-permits");

		const token = window.localStorage.getItem("auth_token");

		return await axios.get<NeighborhoodPermitsResponse>(url.href, {
			params: {
				propertyId: args.propertyId,
			},
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	getTaxBillManagementOverviewData: async (args?: {
		tax_year: string | null;
		state: string | null;
		page: number;
	}) => {
		const url = createUrl("/account/tax-bill-management/overview");

		const token = window.localStorage.getItem("auth_token");

		return await axios.get<TaxBillManagement.OverviewData>(url.href, {
			params: args,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	getTaxBillManagementProperties: async (args?: {
		tax_year: string | null;
		state: string | null;
		county: string | null;
		status: string | null;
		search: string | null;
		page: number;
		sortCol: string | null;
		sortDir: "asc" | "desc" | null;
	}) => {
		const url = createUrl("/account/tax-bill-management/properties");

		const token = window.localStorage.getItem("auth_token");

		return await axios.get<TaxBillManagement.PropertiesData>(url.href, {
			params: args,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	// getStatePageCountyInfo: async (args: { state: StatesWithStatePage }) => {
	// 	const url = createUrl("/county/deadlines");

	// 	return await axios.get<StatePageCountyInfo>(url.href, {
	// 		params: {
	// 			state: args.state,
	// 		},
	// 	});
	// },
	getBillReductionOtpRequestData: async (
		args: BillReduction.OtpRequest.GetRequestParams
	) => {
		const url = createUrl("/bill-reduction-otp", { OVERRIDE_STAGING: true });

		return await axios.get<BillReduction.OtpRequest.GetRequestResponse>(
			url.href,
			{
				params: args,
			}
		);
	},
	submitBillReductionOtp: async (
		args: BillReduction.OtpRequest.PostRequestBody
	) => {
		const url = createUrl("/bill-reduction-otp");

		return await axios.post(url.href, args);
	},
	getCurrentMortgageRates: async () => {
		const url = createUrl("/account/mortgage-rates/current");

		const token = window.localStorage.getItem("auth_token");

		return await axios.get<MortgageRates.GetCurrent.Response>(url.href, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	getAllAppeals: async (args: {
		search?: string;
		pageNumber?: number;
		state?: string[];
		county?: string[];
		sorting?: string | null;
		desc?: boolean;
		taxYear?: string[];
		statusBucket?: string[];
		resultsPerPage: number;
	}) => {
		const url = createUrl("/account/all-appeals");
		const token = window.localStorage.getItem("auth_token");

		const res = await axios.get<AllActiveAppeals.GetAppeals.Response>(
			url.href,
			{
				headers: { Authorization: `Bearer ${token}` },
				params: { ...args },
			}
		);

		return res;
	},
	downloadAllAppeals: async (args: {
		search?: string;
		pageNumber?: number;
		state?: string[];
		county?: string[];
		sorting?: string | null;
		desc?: boolean;
		resultsPerPage: number;
	}) => {
		const url = createUrl("/account/all-appeals/download");
		const token = window.localStorage.getItem("auth_token");

		const res = await axios.get(url.href, {
			headers: { Authorization: `Bearer ${token}` },
			params: { ...args },
			responseType: "arraybuffer",
		});

		return res;
	},
	getRefinanceMonitoringFields: async ({
		property_id,
	}: {
		property_id: number;
	}) => {
		const url = createUrl("/account/refinance-monitoring");
		const token = window.localStorage.getItem("auth_token");

		const res = await axios.get<RefinanceMonitoring.API.GET.Response>(
			url.href,
			{
				params: { property_id },
				headers: { Authorization: `Bearer ${token}` },
			}
		);
		return res;
	},
	patchRefinanceMonitoringFields: async (
		args: RefinanceMonitoring.API.PATCH.Request
	) => {
		const url = createUrl("/account/refinance-monitoring");
		const token = window.localStorage.getItem("auth_token");
		return await axios.patch<any, any, RefinanceMonitoring.API.PATCH.Request>(
			url.href,
			args,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
	},
	getPartnershipAgreementData: async (
		args: Client.PartnershipAgreement.GET_REQUEST
	) => {
		return await axios.get<Client.PartnershipAgreement.GET_RESPONSE>(
			createUrl("/partner-agreement").href,
			{
				params: {
					session: args.session,
				},
			}
		);
	},
	getPartnershipAgreementContract: async (
		args: Client.PartnershipAgreement.GET_AGREEMENT_REQEUST
	) => {
		return await axios.get(createUrl("/partner-agreement/agreement").href, {
			responseType: "blob",
			params: {
				session: args.session,
			},
		});
	},
	submitPartnershipAgreement: async (
		args: Client.PartnershipAgreement.POST_REQUEST
	) => {
		const fd = new FormData();
		fd.append("session", args.session);
		fd.append("signature", args.signature);
		fd.append("photo", args.photo);

		return await axios.post(createUrl("/partner-agreement").href, fd);
	},
};

for (const key in client) {
	//@ts-ignore
	client[key] = wrapClientFn(client[key], key);
}

export default client;
