import { Button, TextField } from "components";
import { useEffect, useState, useMemo, useCallback } from "react";
import client from "utils/client";
import { usePropertyContext } from "components/pages/Account/Property/context";
import QuestionCard from "elements/QuestionCard";
import { FileData, OneZeroOrNull } from "utils/types";
import { TileSelect } from "components/elements/TileSelect";
import { FileUploadType, splitFileUploads } from "elements/Input/FileUpload";
import formatDate from "utils/formatDate";
import { isValidDate } from "utils/validDate";
import ErrorIcon from "public/error-icon.svg";
import ButtonV2 from "elements/ButtonV2";

const HearingNotice = () => {
	const { details, activeQuestionId, send, next, propertySurveyContext } =
		usePropertyContext();

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const handleSubmit = async (payload?: { value: OneZeroOrNull }) => {
		if (payload) {
			send({
				type: "update_context",
				context: { q_hearing_notice: payload.value },
			});
		}

		try {
			setSubmitting(true);
			setError(false);

			await client.submitQuestionAnswer({
				appealId: details!.appeal_id,
				questionId: activeQuestionId,
				payload: {
					value: !!(payload
						? payload.value
						: propertySurveyContext.q_hearing_notice),
					date: undefined,
				},
			});
			next();
		} catch (e) {
			console.error("ERROR", e);
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	const tiles = useMemo(
		() => [
			{
				unselectedTileContent: "Yes",
				selectedTileContent: "Yes",
				isSelected: propertySurveyContext.q_hearing_notice === 1,
				onSelect: () => {
					handleSubmit({ value: 1 });
				},
			},
			{
				unselectedTileContent: "No",
				selectedTileContent: "No",
				isSelected: propertySurveyContext.q_hearing_notice === 0,
				onSelect: () => {
					handleSubmit({ value: 0 });
				},
			},
		],
		[propertySurveyContext.q_hearing_notice]
	);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}>
			<QuestionCard
				id="notice-of-value"
				tooltipText="If you have received a hearing notice, please select 'Yes'. If you have not received a hearing notice, please select 'No'."
				question={
					<p className="lg bold">Have you received a hearing notice?</p>
				}>
				<TileSelect tiles={tiles} />
			</QuestionCard>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
			<ButtonV2
				size="large"
				disabled={propertySurveyContext.q_hearing_notice === null}
				className="mx-auto"
				type="submit">
				Continue
			</ButtonV2>
		</form>
	);
};

export const HearingNoticeUpload = () => {
	const {
		details,
		next,
		send,
		propertySurveyContext,
		allUploadedPropertySurveyFiles,
		activeQuestionId,
	} = usePropertyContext();

	const [hearingNoticeDate, setHearingNoticeDate] = useState(
		propertySurveyContext?.q_hearing_notice_date ?? ""
	);
	const [hearingNoticeDateError, setHearingNoticeDateError] = useState(false);
	const [filesToUpload, setFilesToUpload] = useState<FileUploadType[]>([]);
	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const questionFiles = useMemo(() => {
		return allUploadedPropertySurveyFiles.filter(fileName =>
			fileName.startsWith("hearingNotice")
		);
	}, []); // We only want to derive this value on mount

	const filesUploaded = useMemo(() => {
		return questionFiles.length > 0 || filesToUpload.length > 0;
	}, [questionFiles, filesToUpload]);

	const handleSubmit = async () => {
		try {
			setSubmitting(true);
			setError(false);

			if (
				hearingNoticeDate?.length !== 10 ||
				hearingNoticeDate === "00/00/0000"
			) {
				setHearingNoticeDateError(true);
				return;
			}

			await client.submitQuestionAnswer({
				appealId: details!.appeal_id,
				questionId: activeQuestionId,
				payload: {
					value: propertySurveyContext.q_hearing_notice,
					date: hearingNoticeDate,
				},
			});

			if (filesToUpload.length) {
				const { errors, success } = await splitFileUploads({
					filesToUpload,
					updateFilesToUpload: setFilesToUpload,
					buildRequest: async fileToUpload => {
						const fd = new FormData();
						fd.append("hearingNotice", fileToUpload.file);
						await client.uploadFile({
							form: fd,
							appealId: details!.appeal_id.toString(),
							onProgress: pe => {
								setFilesToUpload(prev =>
									prev.map(fu => {
										if (fu.file !== fileToUpload.file) return fu;
										return {
											...fu,
											progress: pe,
										};
									})
								);
							},
						});
					},
				});

				send({
					type: "update_context",
					context: {
						files: [
							...propertySurveyContext.files,
							...success.map(fileToUpload => {
								const fileData: FileData = {
									file_name: "hearingNotice-" + fileToUpload.file.name,
									question: "hearingNotice",
									is_ownwell_document: 0,
								};
								return fileData;
							}),
						],
					},
				});

				if (errors.length > 0) {
					setError(true);
					return;
				}
			}

			next();
		} catch (e) {
			console.error("ERROR", e);
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	const btnDisabled = useMemo(() => {
		const dateIsValid = hearingNoticeDate?.length === 10;
		return submitting || !dateIsValid || !hearingNoticeDate;
	}, [submitting, hearingNoticeDate]);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}>
			<QuestionCard
				id="hearing-notice-upload"
				tooltipText="Upload your hearing notice to help us better understand your case."
				question={<p className="lg bold">What is your hearing date?</p>}
				value={true}
				alreadyUploaded={questionFiles}
				filesLabel="Document Upload"
				withFiles
				filesToUpload={filesToUpload}
				setFilesToUpload={setFilesToUpload}>
				<TextField
					required
					placeholder="MM/DD/YYYY"
					minLength={10}
					maxLength={10}
					label="Hearing Date"
					value={hearingNoticeDate ?? ""}
					containerClassName="profile-hearing-date"
					onChange={e => {
						const val = formatDate(e, hearingNoticeDate ?? "");
						const valid = isValidDate(val);

						if (valid) {
							setHearingNoticeDate(val);
						}

						if (val.length === 10) {
							e.target.setCustomValidity("");
						} else {
							e.target.setCustomValidity(
								"Please enter a valid date in the format MM/DD/YYYY."
							);
						}
					}}
					onFocus={e => {
						const val = formatDate(e, hearingNoticeDate ?? "");
						if (val === "00/00/0000") {
							setHearingNoticeDate("");
						}
					}}
				/>
				{hearingNoticeDateError && (
					<p className="error-message sm">
						<ErrorIcon className="login-error-icon" />
						Error: Please enter a valid date
					</p>
				)}
				<p className="mt-4 lg bold">Upload your hearing notice (optional)</p>
			</QuestionCard>
			{!filesUploaded && (
				<div className="property-profile-question-note-rust">
					<p className="sm">
						We recommend uploading supporting documentation before continuing.
						Evidence submitted without documentation rarely results in a
						reduction.
					</p>
				</div>
			)}
			<ButtonV2
				size="large"
				disabled={btnDisabled}
				type="submit"
				className="mx-auto">
				{submitting ? "Submitting..." : "Continue"}
			</ButtonV2>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
		</form>
	);
};

export default HearingNotice;
