import { ButtonHTMLAttributes, FC, useMemo } from "react";
import Link from "next/link";
import { useEventTracker } from "utils/useEventTracker";
import constants from "utils/constants";
import { useAppContext } from "context/AppContext";
import { useAuthContext } from "context/AuthContext";

interface ButtonProps {
	size?: "small" | "extra-small";
	navy?: boolean;
	azure?: boolean;
	variant?: "primary" | "secondary";
	toAppealFlow?: {
		referrer: string;
		exemptions?: boolean;
	};
	openInNewTab?: boolean;
}

const Button: FC<ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({
	size,
	navy = false,
	azure = false,
	variant = "primary",
	toAppealFlow,
	children,
	className: userClassName,
	openInNewTab = false,
	...buttonProps
}) => {
	const { mixpanelInitialized } = useAppContext();
	const { userData } = useAuthContext();
	const trackMixpanel = useEventTracker();
	const handletoAppealFlowClick = () => {
		if (toAppealFlow?.exemptions) {
			trackMixpanel({
				eventName: constants.EVENTS.E2E_Exemptions_LP_CTAs,
				data: {
					"User Type": userData?.id
						? "Returning (signed in)"
						: "New (signed out)",
				},
			});
		} else {
			trackMixpanel({
				eventName: constants.EVENTS.FORM_FLOW_ADDRESS,
				data: {
					referrer: toAppealFlow?.referrer,
				},
			});
		}
	};

	const className = useMemo(() => {
		let val = "button";
		switch (variant) {
			case "primary":
				val += " primary";
				break;
			case "secondary":
				val += " secondary";
				break;
		}

		if (size === "small") {
			val += " small";
		} else if (size === "extra-small") {
			val += " extra-small";
		}

		if (navy) {
			val += " navy";
		}

		if (azure) {
			val += " azure";
		}

		if (userClassName) {
			val += " " + userClassName;
		}

		return val;
	}, [size, navy, azure, userClassName]);

	if (toAppealFlow) {
		const additionalProps: Record<string, string> = {};
		if (openInNewTab) {
			additionalProps["target"] = "_blank";
			additionalProps["rel"] = "noopener noreferrer";
		}
		return (
			<Link
				passHref
				href={toAppealFlow.exemptions ? "/appeal?exemptions=true" : "/appeal"}
				className={className}
				id={buttonProps.id}
				onClick={() => handletoAppealFlowClick()}
				{...additionalProps}>
				<span>{children}</span>
			</Link>
		);
	}

	return (
		<button className={className} {...buttonProps}>
			<span>{children}</span>
		</button>
	);
};

export default Button;
