import {
	Dispatch,
	RefObject,
	SetStateAction,
	useCallback,
	useMemo,
	useState,
} from "react";
import UpArrow from "assets/imgs/up-arrow.svg";
import { InsuranceSurveyComponent } from "./withInsuranceSurvey";
import FocusTrap from "focus-trap-react";
import { DispatchAnswerState, InsuranceSurveyStage } from "./InsuranceSurvey";
import { InsuranceAnswerReducerState, InsuranceQuestionID } from "utils/types";
import { insuranceQuestions } from "./questions";
import { QuickLink } from "components";
import { useEventTracker_DEPRECATED } from "utils/useEventTracker";
import constants from "utils/constants";
import ButtonV2 from "elements/ButtonV2";

interface Props {
	id: InsuranceQuestionID;
	index: number;
	formRef: RefObject<HTMLFormElement>;
	component: InsuranceSurveyComponent;
	setStage: Dispatch<SetStateAction<InsuranceSurveyStage>>;
	currentQuestionIndex: number;
	nextBtnDisabled: boolean;
	next: (skip?: boolean) => void;
	prev: () => void;
	showPrevArrow: boolean;
	prevBtnDisabled: boolean;
	dispatchAnswerState: DispatchAnswerState;
	answerState: InsuranceAnswerReducerState[InsuranceQuestionID];
}

const SKIP_QUESTION_MIXPANEL_PAYLOAD = {
	"Action Type": "Skip",
};

const createMixpanelAnswerPayload = (answer: string | number) => {
	return {
		"Action Type": "Next",
		Answer: answer,
	};
};

export const InsuranceSurveyQuestionCard = (props: Props) => {
	const [overrideBtnDisabled, setOverrideBtnDisabled] = useState(false);

	const {
		id: questionId,
		index,
		component,
		nextBtnDisabled,
		next,
		prev,
		formRef,
		showPrevArrow,
		prevBtnDisabled,
		dispatchAnswerState,
		answerState,
		currentQuestionIndex,
		setStage,
	} = props;

	const createClassName = useCallback(
		i => {
			return i < currentQuestionIndex - 1
				? "insurance-question-behind"
				: i === currentQuestionIndex - 1
				? "insurance-question-prev"
				: i === currentQuestionIndex
				? "insurance-question-current"
				: i === currentQuestionIndex + 1
				? "insurance-question-next"
				: "insurance-question-ahead";
		},
		[currentQuestionIndex]
	);

	// const showSkipButton = useMemo(() => {
	// 	return questionId !== "current_premium"; //hide skip button on this questionId
	// }, [questionId]);

	const Component = component;

	const trackMaritalSkip = useEventTracker_DEPRECATED(
		constants.EVENTS.INS_ANSWER_MARITAL_STATUS,
		SKIP_QUESTION_MIXPANEL_PAYLOAD
	);

	const trackDobSkip = useEventTracker_DEPRECATED(
		constants.EVENTS.INS_ANSWER_DOB,
		SKIP_QUESTION_MIXPANEL_PAYLOAD
	);

	const trackCreditScoreSkip = useEventTracker_DEPRECATED(
		constants.EVENTS.INS_ANSWER_CREDIT_SCORE,
		SKIP_QUESTION_MIXPANEL_PAYLOAD
	);

	const trackProviderSkip = useEventTracker_DEPRECATED(
		constants.EVENTS.INS_ANSWER_CURRENT_PROVIDER,
		SKIP_QUESTION_MIXPANEL_PAYLOAD
	);

	const trackPremiumSkip = useEventTracker_DEPRECATED(
		constants.EVENTS.INS_ANSWER_ANNUAL_PREMIUM,
		SKIP_QUESTION_MIXPANEL_PAYLOAD
	);

	const trackDeductibleSkip = useEventTracker_DEPRECATED(
		constants.EVENTS.INS_ANSWER_CURRENT_DEDUCTIBLE,
		SKIP_QUESTION_MIXPANEL_PAYLOAD
	);

	const trackSkip = () => {
		switch (questionId) {
			case "marital_status":
				return trackMaritalSkip();
			case "birthday":
				return trackDobSkip();
			// case "credit_range":
			// 	return trackCreditScoreSkip();
			case "current_provider":
				return trackProviderSkip();
			case "current_premium":
				return trackPremiumSkip();
			case "current_deductible":
				return trackDeductibleSkip();
		}
	};

	const trackMaritalAnswer = useEventTracker_DEPRECATED(
		constants.EVENTS.INS_ANSWER_MARITAL_STATUS,
		createMixpanelAnswerPayload(answerState)
	);

	const trackDobAnswer = useEventTracker_DEPRECATED(
		constants.EVENTS.INS_ANSWER_DOB,
		createMixpanelAnswerPayload(answerState)
	);

	const trackCreditScoreAnswer = useEventTracker_DEPRECATED(
		constants.EVENTS.INS_ANSWER_CREDIT_SCORE,
		createMixpanelAnswerPayload(answerState)
	);

	const trackProvierAnswer = useEventTracker_DEPRECATED(
		constants.EVENTS.INS_ANSWER_CURRENT_PROVIDER,
		createMixpanelAnswerPayload(answerState)
	);

	const trackPremiumAnswer = useEventTracker_DEPRECATED(
		constants.EVENTS.INS_ANSWER_ANNUAL_PREMIUM,
		createMixpanelAnswerPayload(
			parseInt(answerState.toString().replace(/\D/g, ""))
		)
	);

	const trackDeductibleAnswer = useEventTracker_DEPRECATED(
		constants.EVENTS.INS_ANSWER_CURRENT_DEDUCTIBLE,
		createMixpanelAnswerPayload(
			parseInt(answerState.toString().replace(/\D/g, ""))
		)
	);

	const trackAnswer = () => {
		switch (questionId) {
			case "marital_status":
				return trackMaritalAnswer();
			case "birthday":
				return trackDobAnswer();
			// case "credit_range":
			// 	return trackCreditScoreAnswer();
			case "current_provider":
				return trackProvierAnswer();
			case "current_premium":
				return trackPremiumAnswer();
			case "current_deductible":
				return trackDeductibleAnswer();
		}
	};

	const onClick = () => {
		next();
		if (currentQuestionIndex === insuranceQuestions.length - 1) {
			setStage("confirmation");
		}
	};

	return (
		<FocusTrap
			active={currentQuestionIndex === index}
			focusTrapOptions={{ initialFocus: false, allowOutsideClick: true }}>
			<form
				ref={currentQuestionIndex === index ? formRef : undefined}
				onSubmit={e => {
					e.preventDefault();
					trackAnswer();
					onClick();
				}}
				className={"insurance-question " + createClassName(index)}>
				{showPrevArrow && (
					<div
						className={
							"insurance-question-up-arrow-container" +
							(currentQuestionIndex !== index ? "-hidden" : "") +
							(prevBtnDisabled ? " up-arrow-disabled" : "")
						}
						onClick={() => !prevBtnDisabled && prev()}>
						<UpArrow />
					</div>
				)}
				<div className="insurance-survey-question">
					<Component
						formRef={formRef}
						answerState={answerState}
						dispatchAnswerState={dispatchAnswerState}
						setNextBtnDisabled={setOverrideBtnDisabled}
					/>
				</div>
				<ButtonV2
					size="large"
					disabled={nextBtnDisabled || overrideBtnDisabled}
					className={
						"next-btn" + (currentQuestionIndex !== index ? "-hidden" : "")
					}
					type={"submit"}>
					Next
				</ButtonV2>
				{/* {showSkipButton && (
					<div
						style={{
							visibility: currentQuestionIndex === index ? "visible" : "hidden",
							cursor: "pointer",
						}}>
						<QuickLink
							asButton
							onClick={() => {
								trackSkip();
								onClick();
							}}>
							Skip
						</QuickLink>
					</div>
				)} */}
			</form>
		</FocusTrap>
	);
};
