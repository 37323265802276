import { FC, useMemo } from "react";
import BBBLogo from "public/BBB-logo.svg";
import constants from "utils/constants";

const BbbReviews: FC<{ noLink?: boolean }> = ({ noLink = false }) => {
	const ratingClassName = useMemo(
		() => (noLink ? "rating-no-hover" : "rating"),
		[noLink]
	);

	if (noLink) {
		return (
			<div
				className={`bbb-rating ${ratingClassName}`}
				aria-label="BBB Accredited Business">
				<BBBLogo height="44px" width="125px" />
				<div className="bbb-letter">
					<p>A+</p>
				</div>
			</div>
		);
	}

	return (
		<a
			onClick={e => noLink && e.preventDefault()}
			href={noLink ? "#" : constants.BBB_LINK}
			className={`bbb-rating ${ratingClassName}`}>
			<BBBLogo height="44px" width="125px" />
			<div className="bbb-letter">
				<p>A+</p>
			</div>
		</a>
	);
};

export default BbbReviews;
