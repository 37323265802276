const ConciergeOptionTab = ({
	billingCycle,
	updateBillingCycle,
}: {
	billingCycle: string;
	updateBillingCycle: (cycle: string) => void;
}) => {
	return (
		<div className="concierge-options-tab">
			<p className="tab-label small">Billing Period</p>
			<div className="tab-option">
				<p
					className={
						"small tab " + (billingCycle == "annually" ? "active" : "")
					}
					onClick={() => updateBillingCycle("annually")}>
					Annually (save 15%)
				</p>
				<p
					className={"small tab " + (billingCycle == "monthly" ? "active" : "")}
					onClick={() => updateBillingCycle("monthly")}>
					Monthly
				</p>
			</div>
		</div>
	);
};

export default ConciergeOptionTab;
