import { FC, InputHTMLAttributes, useMemo, useRef, useState } from "react";

interface CheckboxProps {
	label?: string;
	smallLabel?: boolean;
	containerClassName?: string;
}

const Radio: FC<CheckboxProps & InputHTMLAttributes<HTMLInputElement>> = ({
	label,
	containerClassName,
	smallLabel,
	...rest
}) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const containerClassNames = useMemo(() => {
		let val = "radio";

		if (containerClassName) {
			val += " ";
			val += containerClassName;
		}

		return val;
	}, [containerClassName]);

	return (
		<div className={containerClassNames}>
			<input type="radio" className="radio-input" ref={inputRef} {...rest} />
			{label && (
				<p
					className={smallLabel ? "sm" : ""}
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						inputRef.current?.click();
					}}>
					{label}
				</p>
			)}
		</div>
	);
};

export default Radio;
