import { FC } from "react";

interface CardProps {
	className?: string;
	id?: string;
}

export const Card: FC<CardProps> = props => {
	const { children, className, id } = props;

	return (
		<div className={["card", className].join(" ")} id={id}>
			{children}
		</div>
	);
};
