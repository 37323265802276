import { useEffect, useMemo, useState } from "react";
import mixpanel from "mixpanel-browser";
import AB_TEST_CONFIG from "config/ab-tests.json";
import { onOwnwellDotCom } from "utils/onOwnwellDotCom";
import { useAppContext } from "context/AppContext";

type AbTestNames = keyof typeof AB_TEST_CONFIG;

interface ChildrenProps<T extends AbTestNames> {
	readonly variant: keyof typeof AB_TEST_CONFIG[T];
}

interface Props<T extends AbTestNames> {
	children: (props: ChildrenProps<T>) => JSX.Element | null;
	testName: T;
}

const LOCAL_STORAGE_TEST_NAME_PREFIX = "test_";
export const MIXPANEL_AB_TEST_PROPERTY_KEY = "ab_tests";

const getLocalStorageKey = (testName: string) => {
	return LOCAL_STORAGE_TEST_NAME_PREFIX + testName;
};

const concatTestNameAndVariant = (testName: string, variant: string) => {
	return `${testName}:${variant}`;
};

const getVariantsForTest = <T extends AbTestNames>(testName: T): string[] => {
	return Object.entries(AB_TEST_CONFIG[testName])
		.filter(([_key, value]) => value)
		.map(([key]) => key);
};

export const AbTest = <T extends AbTestNames>({
	children,
	testName,
}: Props<T>) => {
	const [shouldRender, setShouldRender] = useState(false);

	const { mixpanelInitialized } = useAppContext();

	useEffect(() => {
		setShouldRender(true);
	}, []);

	const selectedVariant: keyof typeof AB_TEST_CONFIG[T] = useMemo(() => {
		const allVariants = getVariantsForTest<T>(testName);

		try {
			const existingSelection =
				typeof window !== "undefined"
					? window.localStorage.getItem(getLocalStorageKey(testName))
					: undefined;

			if (existingSelection) {
				if (allVariants.includes(existingSelection)) {
					return existingSelection as keyof typeof AB_TEST_CONFIG[T];
				}

				window.localStorage.removeItem(getLocalStorageKey(testName));
				// If the user has a variant that is no longer valid, we should unregister it from Mixpanel
				if (mixpanelInitialized) {
					mixpanel.unregister(MIXPANEL_AB_TEST_PROPERTY_KEY);
				}
			}

			const newSelection = allVariants[
				Math.floor(Math.random() * allVariants.length)
			] as keyof typeof AB_TEST_CONFIG[T];

			if (typeof window !== "undefined") {
				window.localStorage.setItem(
					getLocalStorageKey(testName),
					newSelection as string
				);
			}

			return newSelection;
		} catch {
			return allVariants[0] as keyof typeof AB_TEST_CONFIG[T];
		}
	}, [testName]);

	useEffect(() => {
		if (!selectedVariant || !testName || !mixpanelInitialized) return;

		const IN_PRODUCTION =
			process.env.NODE_ENV === "production" && onOwnwellDotCom();

		const existingTestProperties = IN_PRODUCTION
			? mixpanel.get_property(MIXPANEL_AB_TEST_PROPERTY_KEY)
			: undefined;

		const testNameAndVariantConcatenated = concatTestNameAndVariant(
			testName,
			selectedVariant as string
		);

		if (
			Array.isArray(existingTestProperties) &&
			existingTestProperties.includes(testNameAndVariantConcatenated)
		) {
			return;
		}

		const propertyValue = [
			...(existingTestProperties ?? []),
			testNameAndVariantConcatenated,
		];

		if (IN_PRODUCTION) {
			mixpanel.register({
				[MIXPANEL_AB_TEST_PROPERTY_KEY]: propertyValue,
			});
		}
	}, [selectedVariant, testName, mixpanelInitialized]);

	if (shouldRender) {
		return children({ variant: selectedVariant });
	} else {
		return null;
	}
};
