import axios from "axios";
import { TextField } from "components";
import { useAuthContext } from "context/AuthContext";
import ButtonV2 from "elements/ButtonV2";
import Logo from "public/logo.svg";
import { FC, useState } from "react";
import client from "utils/client";
import { sendErrorToWebhook } from "utils/sendWebhook";

const StagingEnvLogin = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);

	const { setIsAuthenticatedToStaging } = useAuthContext();

	const onSubmit = async () => {
		try {
			setLoading(true);
			const { data } = await client.loginToStagingEnv({
				email,
				password,
			});

			if (data && data.error) {
				setEmail("");
				setPassword("");
			}

			const token = window.localStorage.getItem("staging_auth_token");

			if (token) {
				setIsAuthenticatedToStaging(true);
				axios.defaults.headers.common[
					"Staging-Authorization"
				] = `Bearer ${token}`;
			}
		} catch (e) {
			setEmail("");
			setPassword("");
			console.error(e);
			sendErrorToWebhook("Failed logging into staging env: " + e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div id="staging-env-login-container">
			<div id="staging-env-login-card">
				<Logo />
				<form
					id="staging-env-login-form"
					onSubmit={e => {
						e.preventDefault();
						onSubmit();
					}}>
					<TextField
						type="email"
						label="Email"
						placeholder="Enter your email"
						value={email}
						onChange={e => setEmail(e.target.value)}
					/>
					<TextField
						type="password"
						label="Password"
						placeholder="Enter your password"
						containerClassName="mt-2"
						value={password}
						onChange={e => setPassword(e.target.value)}
					/>
					<ButtonV2 className="mt-4" size="extra-small" disabled={loading}>
						Login
					</ButtonV2>
				</form>
			</div>
		</div>
	);
};

export default StagingEnvLogin;
