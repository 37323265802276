import React, { useMemo, useState } from "react";
import { usePropertyContext } from "../../context";
import QuestionCard from "elements/QuestionCard";
import { TileSelect } from "elements/TileSelect";
import client from "utils/client";
import { OneZeroOrNull } from "utils/types";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import ButtonV2 from "elements/ButtonV2";

const DamageAndDeferredMaintenanceIntro = () => {
	const { details, activeQuestionId, send, next, propertySurveyContext } =
		usePropertyContext();

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const handleSkip = () => {
		next();
	};

	const handleSubmit = async (payload?: {
		q_flooded?: OneZeroOrNull;
		q_structural_issues_list?: string | null;
		q_roof_damage?: OneZeroOrNull;
		q_major_damage_list?: string | null;
		q_negative_impact_list?: string | null;
	}) => {
		if (payload) {
			send({
				type: "update_context",
				context: {
					...(payload.q_flooded !== undefined
						? {
								q_flooded: payload.q_flooded,
						  }
						: {}),
					...(payload.q_structural_issues_list !== undefined
						? {
								q_structural_issues_list: payload.q_structural_issues_list,
						  }
						: {}),
					...(payload.q_roof_damage !== undefined
						? {
								q_roof_damage: payload.q_roof_damage,
						  }
						: {}),
					...(payload.q_major_damage_list !== undefined
						? {
								q_major_damage_list: payload.q_major_damage_list,
						  }
						: {}),
					...(payload.q_negative_impact_list !== undefined
						? {
								q_negative_impact_list: payload.q_negative_impact_list,
						  }
						: {}),
				},
			});
		}

		try {
			setSubmitting(true);
			setError(false);

			await client.submitQuestionAnswer({
				questionId: activeQuestionId,
				appealId: details!.appeal_id,
				payload: {
					q_flooded: !!(payload?.q_flooded
						? payload.q_flooded
						: propertySurveyContext.q_flooded),
					q_structural_issues_list:
						payload?.q_structural_issues_list !== undefined
							? payload.q_structural_issues_list
							: propertySurveyContext.q_structural_issues_list,
					q_roof_damage: !!(payload?.q_roof_damage
						? payload.q_roof_damage
						: propertySurveyContext.q_roof_damage),
					q_major_damage_list:
						payload?.q_major_damage_list !== undefined
							? payload.q_major_damage_list
							: propertySurveyContext.q_major_damage_list,
					q_negative_impact_list:
						payload?.q_negative_impact_list !== undefined
							? payload.q_negative_impact_list
							: propertySurveyContext.q_negative_impact_list,
				},
			});
		} catch (e) {
			console.error(e);
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	// add or remove a valuie from a comma separated list
	const toggleValueInList = (list: string, value: string) => {
		const listArray = list
			.split(",")
			.map(v => v.trim())
			.filter(item => item !== "");
		let resultArray = listArray;
		if (listArray.includes(value)) {
			resultArray = listArray.filter(item => item !== value);
		} else {
			resultArray.push(value);
		}
		const result = resultArray.join(",");
		if (result === "") {
			return null;
		}
		return result;
	};

	const tiles = useMemo(() => {
		return [
			{
				unselectedTileContent: "Flooding within past year",
				selectedTileContent: "Flooding within past year",
				isSelected: propertySurveyContext?.q_flooded === 1,
				onSelect: () => {
					handleSubmit({
						q_flooded: propertySurveyContext?.q_flooded === 1 ? 0 : 1,
					});
				},
			},
			{
				unselectedTileContent: "Major gaps or cracks in foundation",
				selectedTileContent: "Major gaps or cracks in foundation",
				isSelected: !!propertySurveyContext.q_structural_issues_list?.includes(
					"Large cracks or gaps in foundation"
				),
				onSelect: () => {
					handleSubmit({
						q_structural_issues_list: toggleValueInList(
							propertySurveyContext.q_structural_issues_list ?? "",
							"Large cracks or gaps in foundation"
						),
					});
				},
			},
			{
				unselectedTileContent: "Damage to roof",
				selectedTileContent: "Damage to roof",
				isSelected: propertySurveyContext.q_roof_damage === 1,
				onSelect: () => {
					handleSubmit({
						q_roof_damage: propertySurveyContext?.q_roof_damage === 1 ? 0 : 1,
					});
				},
			},
			{
				unselectedTileContent: "Wood rot or termite damage",
				selectedTileContent: "Wood rot or termite damage",
				isSelected: !!propertySurveyContext.q_structural_issues_list?.includes(
					"Wood rot or termites in the structure"
				),
				onSelect: () => {
					handleSubmit({
						q_structural_issues_list: toggleValueInList(
							propertySurveyContext.q_structural_issues_list ?? "",
							"Wood rot or termites in the structure"
						),
					});
				},
			},
			{
				unselectedTileContent: "Damage from recent fire",
				selectedTileContent: "Damage from recent fire",
				isSelected: !!propertySurveyContext.q_major_damage_list?.includes(
					"Damage from recent fire"
				),
				onSelect: () => {
					handleSubmit({
						q_major_damage_list: toggleValueInList(
							propertySurveyContext.q_major_damage_list ?? "",
							"Damage from recent fire"
						),
					});
				},
			},
			{
				unselectedTileContent: "Mold",
				selectedTileContent: "Mold",
				isSelected:
					!!propertySurveyContext.q_major_damage_list?.includes("Mold"),
				onSelect: () => {
					handleSubmit({
						q_major_damage_list: toggleValueInList(
							propertySurveyContext.q_major_damage_list ?? "",
							"Mold"
						),
					});
				},
			},
			{
				unselectedTileContent: "Easement on property",
				selectedTileContent: "Easement on property",
				isSelected:
					!!propertySurveyContext.q_negative_impact_list?.includes("Easement"),
				onSelect: () => {
					handleSubmit({
						q_negative_impact_list: toggleValueInList(
							propertySurveyContext.q_negative_impact_list ?? "",
							"Easement"
						),
					});
				},
			},
			{
				unselectedTileContent: "Salvage or tear down property",
				selectedTileContent: "Salvage or tear down property",
				isSelected: !!propertySurveyContext.q_major_damage_list?.includes(
					"Salvage or tear down property"
				),
				onSelect: () => {
					handleSubmit({
						q_major_damage_list: toggleValueInList(
							propertySurveyContext.q_major_damage_list ?? "",
							"Salvage or tear down property"
						),
					});
				},
			},
			{
				unselectedTileContent: "None of the above",
				selectedTileContent: "None of the above",
				isSelected:
					propertySurveyContext.q_major_damage_list === null &&
					propertySurveyContext.q_structural_issues_list === null &&
					propertySurveyContext.q_roof_damage === 0 &&
					propertySurveyContext.q_flooded === 0 &&
					propertySurveyContext.q_negative_impact_list === null,
				onSelect: () => {
					handleSubmit({
						q_major_damage_list: null,
						q_structural_issues_list: null,
						q_roof_damage: 0,
						q_flooded: 0,
						q_negative_impact_list: null,
					});
				},
			},
		];
	}, [propertySurveyContext]);

	const btnDisabled = React.useMemo(() => {
		return (
			propertySurveyContext.q_major_damage_list === null &&
			propertySurveyContext.q_structural_issues_list === null &&
			propertySurveyContext.q_roof_damage === null &&
			propertySurveyContext.q_flooded === null &&
			propertySurveyContext.q_negative_impact_list === null
		);
	}, [propertySurveyContext]);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				next();
			}}>
			<QuestionCard
				id="currently-receiving-exemption"
				tooltipText="Information related to damages over $5000 along with documented evidence can help property owners earn a reduction on their appeal."
				question={
					<p className="lg bold">
						Do any of the following apply to your property?
					</p>
				}
				subTitle="Select all that apply.">
				<TileSelect tiles={tiles} className="mobile-1 desktop-3" />
			</QuestionCard>
			<ButtonV2 size="large" type="submit" disabled={btnDisabled}>
				Continue
			</ButtonV2>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
			<p className="profile-question-skip" onClick={() => handleSkip()}>
				Skip <RightArrow />
			</p>
		</form>
	);
};

export default DamageAndDeferredMaintenanceIntro;
