export const formatTicks = ({
	tickCount,
	domainEnd,
}: {
	tickCount: number;
	domainEnd: number;
}) => {
	const step = domainEnd / (tickCount - 1);
	return Object.keys(Array.from(new Array(tickCount))).map(
		i => Number(i) * step
	);
};
