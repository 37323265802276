import React from "react";
import axios from "axios";
import EssentialsCheck from "public/premium/essentials-check.svg";
import PremiumCheck from "public/premium/premium-check.svg";
import PremiumDiamond from "public/premium/premium-diamond.svg";
import InfoCircle from "public/info-circle.svg";
import client from "utils/client";
import { PricingData, CustomerPricingData } from "utils/types";
import { useResponsiveContext } from "context/ResponsiveContext";
import { useAuthContext } from "context/AuthContext";
import { Tooltip2 } from "elements/Tooltip";
import ButtonV2 from "elements/ButtonV2";

export const useCustomerPricingData = () => {
	const [pricingDataByCustomer, setPricingDataByCustomer] =
		React.useState<CustomerPricingData | null>(null);
	const [errorCode, setErrorCode] = React.useState<number | null>(null);
	const [loading, setLoading] = React.useState(false);
	const fetchPricingByCustomer = async () => {
		try {
			setLoading(true);
			const data = await Promise.allSettled([
				client.getPricing(),
				new Promise(resolve => setTimeout(resolve, 400)),
			]).then(([result]) => {
				if (result.status === "rejected") throw result.reason;
				return result.value;
			});

			setPricingDataByCustomer(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				setErrorCode(err.response?.status ?? 500);
			}
			setErrorCode(500);
		} finally {
			setLoading(false);
		}
	};

	return {
		fetchPricingByCustomer,
		loading,
		errorCode,
		pricingDataByCustomer,
	};
};

export const useZipCodePricingData = () => {
	const [pricingDataByZipCode, setPricingDataByZipCode] =
		React.useState<PricingData | null>(null);
	const [errorCode, setErrorCode] = React.useState<number | null>(null);
	const [loading, setLoading] = React.useState(false);

	const fetchPricingByZipCode = async (zipCode: string) => {
		try {
			setLoading(true);
			const { data } = await Promise.allSettled([
				client.getPricingByZip({ zipCode }),
				new Promise(resolve => setTimeout(resolve, 400)),
			]).then(([result]) => {
				if (result.status === "rejected") throw result.reason;
				return result.value;
			});

			setPricingDataByZipCode(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				setErrorCode(err.response?.status ?? 500);
			}
			setErrorCode(500);
		} finally {
			setLoading(false);
		}
	};
	return {
		fetchPricingByZipCode,
		loading,
		errorCode,
		pricingDataByZipCode,
	};
};

// find the lowest pricing of all property pricings
const findLowestGeneralPricing = (
	pricingDataByCustomer: CustomerPricingData
) => {
	return pricingDataByCustomer.reduce(
		(acc, pd) => {
			if (
				!pd.pricing.appeal_contingency ||
				!pd.pricing.exemption_primary_contingency ||
				!pd.pricing.premium_appeal_contingency ||
				!pd.pricing.premium_exemption_primary_contingency
			) {
				return acc;
			}
			if (
				pd.pricing.appeal_contingency < acc.appeal_contingency ||
				pd.pricing.exemption_primary_contingency <
					acc.exemption_primary_contingency ||
				pd.pricing.premium_appeal_contingency <
					acc.premium_appeal_contingency ||
				pd.pricing.premium_exemption_primary_contingency <
					acc.premium_exemption_primary_contingency
			) {
				acc = {
					appeal_contingency: pd.pricing.appeal_contingency,
					exemption_primary_contingency:
						pd.pricing.exemption_primary_contingency,
					premium_appeal_contingency: pd.pricing.premium_appeal_contingency,
					premium_exemption_primary_contingency:
						pd.pricing.premium_exemption_primary_contingency,
				};
			}
			return acc;
		},
		{
			appeal_contingency: 100,
			exemption_primary_contingency: 100,
			premium_appeal_contingency: 100,
			premium_exemption_primary_contingency: 100,
		}
	);
};

export const usePricing = (zipCode?: string) => {
	const { isAuthenticated } = useAuthContext();

	const {
		loading: pricingDataByCustomerLoading,
		pricingDataByCustomer,
		fetchPricingByCustomer,
	} = useCustomerPricingData();
	const {
		loading: pricingDataByZipCodeLoading,
		pricingDataByZipCode,
		fetchPricingByZipCode,
	} = useZipCodePricingData();

	React.useEffect(() => {
		if (zipCode) {
			fetchPricingByZipCode(zipCode);
		} else if (isAuthenticated) {
			fetchPricingByCustomer();
		}
	}, []);

	const hasOwnershipInMultipleStates = React.useMemo(() => {
		return pricingDataByCustomer?.some(
			el => el.state !== pricingDataByCustomer[0].state
		);
	}, [pricingDataByCustomer]);

	const generalPricing = React.useMemo(() => {
		if (pricingDataByZipCode) {
			return {
				appeal_contingency: pricingDataByZipCode.pricing.appeal_contingency,
				exemption_primary_contingency:
					pricingDataByZipCode.pricing.exemption_primary_contingency,
				premium_appeal_contingency:
					pricingDataByZipCode.pricing.premium_appeal_contingency,
				premium_exemption_primary_contingency:
					pricingDataByZipCode.pricing.premium_exemption_primary_contingency,
			};
		}

		if (pricingDataByCustomer) {
			return findLowestGeneralPricing(pricingDataByCustomer);
		}

		return null;
	}, [pricingDataByCustomer, pricingDataByZipCode]);

	if (pricingDataByCustomerLoading || pricingDataByZipCodeLoading) {
		return {
			loading: true,
			generalPricing: null,
			customerPricing: pricingDataByCustomer,
		};
	}

	return {
		loading: false,
		generalPricing,
		customerPricing: pricingDataByCustomer,
		hasOwnershipInMultipleStates,
	};
};

export const PremiumPricingComparisonTable: React.FC<{
	onPremiumAction: () => void;
	onEssentialsAction: () => void;
	premiumActive?: boolean;
	generalPricing: ReturnType<typeof usePricing>["generalPricing"];
	hasOwnershipInMultipleStates?: boolean;
}> = ({
	onPremiumAction,
	onEssentialsAction,
	generalPricing,
	premiumActive,
	hasOwnershipInMultipleStates,
}) => {
	const { isDesktop } = useResponsiveContext();

	let appeal_contingency,
		exemption_primary_contingency,
		premium_appeal_contingency,
		premium_exemption_primary_contingency = null;
	if (generalPricing) {
		({
			appeal_contingency,
			exemption_primary_contingency,
			premium_appeal_contingency,
			premium_exemption_primary_contingency,
		} = generalPricing);
	}

	return (
		<div id="premium-pricing-comparison-table">
			<table>
				<thead>
					<tr>
						<th></th>
						<th>
							<p className="bold">
								Essentials{" "}
								{!premiumActive && (
									<span className="body-tiny active-badge">Active</span>
								)}
							</p>
							{!premiumActive && (
								<span className="body-tiny mobile-active-badge">Active</span>
							)}
						</th>
						<th>
							<p className="bold">
								<PremiumDiamond className="premium-diamond" />
								Premium
								{premiumActive && (
									<span className="mt-1 body-tiny active-badge">Active</span>
								)}
							</p>
							{premiumActive && (
								<span className="body-tiny mobile-active-badge">
									Active <PremiumDiamond className="premium-diamond" />
								</span>
							)}
							{!premiumActive && (
								<PremiumDiamond className="absolute-premium-diamond" />
							)}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<p className="bold sm">
								Savings-or-Free Guarantee
								{/* <Tooltip2
									direction="right"
									label="Savings-or-Free Guarantee"
									text=".........................">
									<InfoCircle className="info-icon" />
								</Tooltip2> */}
							</p>
						</td>
						<td>
							<EssentialsCheck className="feature-check" />
						</td>
						<td>
							<PremiumCheck className="feature-check" />
						</td>
					</tr>
					<tr>
						<td>
							<p className="bold sm">Property tax monitoring</p>
							<ul>
								<li>
									<p className="body-tiny bold">
										Annual appeal evaluation
										{/* <Tooltip2
											direction="right"
											label="Annual appeal evaluation"
											text=".........................">
											<InfoCircle className="info-icon" />
										</Tooltip2> */}
									</p>
								</li>
								<li>
									<p className="body-tiny bold">
										Online tax appeal tracking
										{/* <Tooltip2
											direction="right"
											label="Online tax appeal tracking"
											text=".........................">
											<InfoCircle className="info-icon" />
										</Tooltip2> */}
									</p>
								</li>
								<li>
									<p className="body-tiny bold">
										Local hearing representation
										{/* <Tooltip2
											direction="left"
											label="Local hearing representation"
											text=".........................">
											<InfoCircle className="info-icon" />
										</Tooltip2> */}
									</p>
								</li>
							</ul>
						</td>
						<td>
							<EssentialsCheck className="feature-check" />
						</td>
						<td>
							<PremiumCheck className="feature-check" />
						</td>
					</tr>
					<tr>
						<td>
							<p className="sm bold">
								Priority customer support
								{/* <Tooltip2
									direction="right"
									label="Priority customer support"
									text=".........................">
									<InfoCircle className="info-icon" />
								</Tooltip2> */}
							</p>
						</td>
						<td></td>
						<td>
							<PremiumCheck className="feature-check" />
						</td>
					</tr>
					<tr>
						<td>
							<p className="sm bold">
								Email access to tax specialists
								{/* <Tooltip2
									direction="right"
									label="Access to tax specialists"
									text=".........................">
									<InfoCircle className="info-icon" />
								</Tooltip2> */}
							</p>
						</td>
						<td></td>
						<td>
							<PremiumCheck className="feature-check" />
						</td>
					</tr>
					<tr>
						<td>
							<p className="sm bold">
								Tax bill projections
								{/* <Tooltip2
									direction="right"
									label="Tax bill projections"
									text=".........................">
									<InfoCircle className="info-icon" />
								</Tooltip2> */}
							</p>
						</td>
						<td></td>
						<td>
							<PremiumCheck className="feature-check" />
						</td>
					</tr>
					<tr>
						<td>
							<p className="sm bold">
								Escalation review ($200 value)
								{/* <Tooltip2
									direction="right"
									label="Escalation review ($200 value)"
									text=".........................">
									<InfoCircle className="info-icon" />
								</Tooltip2> */}
							</p>
						</td>
						<td></td>
						<td>
							<PremiumCheck className="feature-check" />
						</td>
					</tr>
					<tr>
						<td>
							<p className="sm bold">
								Escalation funding*
								{/* <Tooltip2
									direction="right"
									label="Escalation funding*"
									text=".........................">
									<InfoCircle className="info-icon" />
								</Tooltip2> */}
							</p>
						</td>
						<td></td>
						<td>
							<PremiumCheck className="feature-check" />
						</td>
					</tr>
					<tr>
						<td>
							<p className="sm bold">
								Property tax appeal
								{/* <Tooltip2
									direction="right"
									label="Property tax appeal"
									text=".........................">
									<InfoCircle className="info-icon" />
								</Tooltip2> */}
							</p>
						</td>
						<td>
							<p
								data-skeleton-placeholder="XX% of savings"
								data-skeleton={!appeal_contingency}
								className="sm">
								{hasOwnershipInMultipleStates ? (
									<>
										<p className="sm bold">Varies by State</p>
									</>
								) : (
									appeal_contingency && (
										<>
											<strong>{appeal_contingency}</strong>% of savings
										</>
									)
								)}
							</p>
						</td>
						<td>
							<p
								data-skeleton-placeholder="XX% of savings"
								data-skeleton={!premium_appeal_contingency}
								data-skeleton-denim
								className="sm">
								{hasOwnershipInMultipleStates ? (
									<>
										<p className="sm bold">Varies by State</p>
										<p className="body-tiny">Proceed to see pricing</p>
									</>
								) : (
									premium_appeal_contingency && (
										<>
											<strong>{premium_appeal_contingency}</strong>% of savings
										</>
									)
								)}
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p className="sm bold">
								Exemption
								{/* <Tooltip2
									direction="right"
									label="Exemption"
									text=".........................">
									<InfoCircle className="info-icon" />
								</Tooltip2> */}
							</p>
						</td>
						<td>
							<p
								data-skeleton-placeholder="XX% of savings"
								data-skeleton={!exemption_primary_contingency}
								className="sm">
								{hasOwnershipInMultipleStates ? (
									<>
										<p className="sm bold">Varies by State</p>
									</>
								) : (
									exemption_primary_contingency && (
										<>
											<strong>{exemption_primary_contingency}</strong>% of
											savings
										</>
									)
								)}
							</p>
						</td>
						<td>
							<p
								data-skeleton-placeholder="XX% of savings"
								data-skeleton={!premium_exemption_primary_contingency}
								data-skeleton-denim
								className="sm">
								{hasOwnershipInMultipleStates ? (
									<>
										<p className="sm bold">Varies by State</p>
										<p className="body-tiny">Proceed to see pricing</p>
									</>
								) : (
									premium_exemption_primary_contingency && (
										<>
											<strong>{premium_exemption_primary_contingency}</strong>%
											of savings
										</>
									)
								)}
							</p>
						</td>
					</tr>
					{isDesktop && (
						<tr>
							<td></td>
							<td>
								<ButtonV2
									size="extra-small"
									variant="primary-outline"
									onClick={onEssentialsAction}>
									{premiumActive ? "Move to Essentials" : "No Thanks"}
								</ButtonV2>
							</td>
							<td>
								<ButtonV2
									variant="primary"
									onClick={onPremiumAction}
									className="premium-pricing-activate-premium-button">
									{premiumActive ? "Stay with Premium" : "Add Premium"}
								</ButtonV2>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
