import { FC, HTMLAttributes } from "react";

interface LabelProps {
	required?: boolean;
}

const Label: FC<LabelProps & HTMLAttributes<HTMLLabelElement>> = ({
	children,
	required,
	...rest
}) => {
	return (
		<label
			{...rest}
			className={
				(required ? "label required" : "label") +
				(rest.className ? " " + rest.className : "")
			}>
			{children}
		</label>
	);
};

export default Label;
