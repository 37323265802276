import React from "react";
import {
	setup,
	assign,
	EventFromLogic,
	MachineSnapshot,
	fromPromise,
	fromTransition,
} from "xstate";
import merge from "lodash.merge";
import { useMachine } from "@xstate/react";
import { DeepPartial, OneZeroOrNull } from "utils/types";
import { useEventTracker } from "utils/useEventTracker";
import { AnimatePresence, motion } from "framer-motion";
import ButtonV2 from "./ButtonV2";

import CloseCrossSVG from "public/survey-builder/close-cross.svg";
import LogoSVG from "public/survey-builder/logo.svg";
import SpeedometerSVG from "public/survey-builder/speedometer.svg";
import EncryptionSVG from "public/survey-builder/encryption.svg";
import LightBulbSVG from "public/survey-builder/light-bulb.svg";
import HouseWithCoinsSVG from "public/survey-builder/house-with-coins.svg";
import formatDollar from "utils/formatDollar";

export namespace SurveyBuilder {
	export namespace Component {
		export const Close: React.FC<
			React.ButtonHTMLAttributes<HTMLButtonElement>
		> = ({ children, onClick }) => {
			return (
				<button className="survey-builder-component-close" onClick={onClick}>
					<CloseCrossSVG />
				</button>
			);
		};

		export const Logo: React.FC<React.HTMLAttributes<HTMLOrSVGElement>> = ({
			className,
		}) => <LogoSVG className={`survey-builder-component-logo ${className}`} />;

		export const Speedometer: React.FC<
			React.HTMLAttributes<HTMLOrSVGElement>
		> = ({ className }) => (
			<SpeedometerSVG
				className={`survey-builder-component-speedometer ${className}`}
			/>
		);

		export const Encryption: React.FC<
			React.HTMLAttributes<HTMLOrSVGElement>
		> = ({ className }) => (
			<EncryptionSVG
				className={`survey-builder-component-encryption ${className}`}
			/>
		);

		export const LightBulb: React.FC<
			React.HTMLAttributes<HTMLOrSVGElement>
		> = ({ className }) => (
			<LightBulbSVG
				className={`survey-builder-component-light-bulb ${className}`}
			/>
		);

		export const HouseWithCoins: React.FC<
			React.HTMLAttributes<HTMLOrSVGElement>
		> = ({ className }) => (
			<HouseWithCoinsSVG
				className={`survey-builder-component-house-with-coins ${className}`}
			/>
		);

		export const ProgressBar: React.FC<{ progress?: number }> = ({
			progress,
		}) => (
			<div className="survey-builder-component-progress-bar">
				<motion.div
					className="survey-builder-component-progress-bar-fill"
					initial={{ width: `${0}%` }}
					animate={{ width: `${progress ?? 0}%` }}
				/>
			</div>
		);

		export const Title: React.FC<
			React.HTMLAttributes<HTMLParagraphElement>
		> = ({ children, className }) => (
			<p className={`survey-builder-component-title ${className}`}>
				{children}
			</p>
		);

		export const Subtitle: React.FC<
			React.HTMLAttributes<HTMLParagraphElement>
		> = ({ children, className }) => (
			<p className={`survey-builder-component-subtitle ${className}`}>
				{children}
			</p>
		);

		export namespace Banners {
			const class_prefix = "survey-builder-component-banner";
			const classes = `${class_prefix} flex flex-gap-1 items-center`;
			interface BannerProps extends React.HTMLAttributes<HTMLDivElement> {
				Icon?: React.ReactNode;
			}

			export const Tip: React.FC<BannerProps> = ({ children }) => (
				<Blonde Icon={<LightBulb />}>{children}</Blonde>
			);

			export const Blonde: React.FC<BannerProps> = ({
				children,
				className,
				Icon,
			}) => (
				<div className={`${classes} bg-blonde-light ${className}`}>
					{Icon}
					<p className="gold">{children}</p>
				</div>
			);

			export const Sky: React.FC<BannerProps> = ({
				children,
				className,
				Icon,
			}) => (
				<div className={`${classes} bg-sky-light ${className}`}>
					{Icon}
					<p className="royal">{children}</p>
				</div>
			);

			export const Kelly: React.FC<BannerProps> = ({
				children,
				className,
				Icon,
			}) => (
				<div className={`${classes} bg-kelly-light ${className}`}>
					{Icon}
					<p className="kelly-dark">{children}</p>
				</div>
			);

			export const Slate: React.FC<BannerProps> = ({
				children,
				className,
				Icon,
			}) => (
				<div className={`${classes} bg-slate ${className}`}>
					{Icon}
					<p className="denim">{children}</p>
				</div>
			);
		}
		export namespace Badges {
			export const Kelly: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
				children,
				className,
			}) => (
				<div
					className={`survey-builder-component-badge bg-kelly-light ${className}`}>
					<p className="semibold kelly-dark">{children}</p>
				</div>
			);
		}

		export const Block: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
			children,
			className,
			onClick,
		}) => (
			<div
				className={`survey-builder-component-block ${className}`}
				onClick={onClick}>
				{children}
			</div>
		);

		export const FadedBlock: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
			children,
			className,
			onClick,
		}) => (
			<div
				className={`survey-builder-component-faded-block ${className}`}
				onClick={onClick}>
				{children}
			</div>
		);

		export namespace Fields {
			export namespace Radio {
				const class_prefix = "survey-builder-component-fields-radio";
				type SelectionState = string | boolean | OneZeroOrNull;
				type SelectionValue = Exclude<SelectionState, null | undefined>;

				interface IContext {
					selection_state?: SelectionState;
					set_selection_state?: (value: SelectionValue) => void;
					name?: string;
				}

				const Context = React.createContext<IContext>({});

				export const Group: React.FC<
					Required<Pick<IContext, "name" | "set_selection_state">> &
						Pick<IContext, "selection_state"> & {
							layout: "tiles" | "list";
						}
				> = ({
					children,
					selection_state,
					set_selection_state,
					name,
					layout,
				}) => {
					return (
						<Context.Provider
							value={{
								selection_state,
								set_selection_state,
								name,
							}}>
							<div
								className={`${class_prefix}-group ${class_prefix} layout-${layout}`}>
								{children}
							</div>
						</Context.Provider>
					);
				};

				export const Option: React.FC<{
					selection_value: SelectionValue;
					children?:
						| React.ReactNode
						| (({ selected }: { selected: boolean }) => React.ReactNode);
				}> = ({ selection_value, children }) => {
					const { selection_state, set_selection_state, name } =
						React.useContext(Context);

					const selected =
						selection_state !== undefined &&
						selection_state !== null &&
						selection_state === selection_value;

					return (
						<Block
							className={[
								`${class_prefix}-option`,
								selected ? "selected" : "",
							].join(" ")}
							onClick={() => set_selection_state?.(selection_value)}>
							{typeof children === "function"
								? children({ selected })
								: children}
							<input
								hidden
								readOnly
								type="radio"
								name={name}
								checked={selected}
								value={selection_value.toString()}
							/>
						</Block>
					);
				};

				export const Bullet: React.FC<{ selected: boolean }> = ({
					selected,
				}) => (
					<div
						className={[
							`${class_prefix}-bullet`,
							selected ? "selected" : "",
						].join(" ")}>
						<div className="inner" />
					</div>
				);

				export const BulletWithLabel: React.FC<{
					selected: boolean;
					label: string;
					Icon?: React.ReactNode;
				}> = ({ label, Icon, selected }) => (
					<div className="flex flex-wrap flex-gap-1 items-center justify-between">
						<div className="flex flex-gap-1 items-center">
							<Bullet selected={selected} />
							<p className="sm mb-0_5 normal-case">{label}</p>
						</div>
						{Icon}
					</div>
				);

				export namespace Templates {
					export const SolidBlocks: React.FC<
						React.ComponentProps<typeof Group> & {
							options: Array<
								Pick<React.ComponentProps<typeof Option>, "selection_value"> &
									Pick<
										React.ComponentProps<typeof BulletWithLabel>,
										"label" | "Icon"
									>
							>;
						}
					> = ({
						layout,
						name,
						selection_state,
						set_selection_state,
						options,
					}) => (
						<Group
							layout={layout}
							name={name}
							selection_state={selection_state}
							set_selection_state={set_selection_state}>
							{options.map(({ selection_value, label, Icon }, idx) => (
								<Option key={idx} selection_value={selection_value}>
									{({ selected }) => (
										<BulletWithLabel
											selected={selected}
											label={label}
											Icon={Icon}
										/>
									)}
								</Option>
							))}
						</Group>
					);
				}
			}

			export namespace Jumbo {
				const class_prefix = "survey-builder-component-fields-jumbo";
				export const Dollar: React.FC<{
					label: React.ReactNode;
					name: string;
					value?: null | number;
					suffix?: React.ReactNode;
					onChange: (value: number | null) => void;
				}> = ({ children, label, name, value, suffix, onChange }) => {
					const handleChange: React.ChangeEventHandler<
						HTMLInputElement
					> = e => {
						const parsedValue = parseFloat(
							e.target.value.replace(/[^0-9.]/g, "")
						);
						const updateValue = isNaN(parsedValue) ? null : parsedValue;
						onChange(updateValue);
					};

					const formattedValue =
						typeof value === "number"
							? formatDollar(value).replace(/\$/g, "")
							: "";

					return (
						<Block className={`${class_prefix}-dollar`}>
							<label htmlFor={name}>{label}</label>
							<div className={`${class_prefix}-dollar-input-section`}>
								<div className="flex">
									<span>$</span>
									<input
										type="text"
										name={name}
										value={formattedValue}
										onChange={handleChange}
									/>
								</div>
								{suffix}
							</div>
						</Block>
					);
				};
			}
		}

		export const SwipeTransition: React.FC<{
			activeKey?: string;
			className?: string;
			direction?: "forward" | "backward";
			Step?: React.FC;
		}> = ({ activeKey, className, direction, Step }) => {
			const variants = {
				enter: (direction: string) => {
					return {
						opacity: 0,
						x: direction === "forward" ? "100%" : "-100%",
					};
				},
				center: {
					opacity: 1,
					x: 0,
					transitionEnd: {
						opacity: 1,
						x: 0,
					},
				},
				exit: (direction: string) => {
					return {
						opacity: 0,
						x: direction === "forward" ? "-100%" : "100%",
					};
				},
			};

			return (
				<AnimatePresence exitBeforeEnter custom={direction}>
					{Step && (
						<motion.div
							className={className}
							key={activeKey}
							custom={direction}
							variants={variants}
							initial="enter"
							animate="center"
							exit="exit"
							transition={{
								type: "spring",
								duration: 0.5,
							}}>
							<Step />
						</motion.div>
					)}
				</AnimatePresence>
			);
		};

		export const RemoveBodyScroll = () => (
			<style jsx global>{`
				html,
				body {
					overflow: hidden !important;
					position: fixed !important;
					top: 0 !important;
				}
			`}</style>
		);

		export const NavigationRow: React.FC = ({ children }) => (
			<div className="survey-builder-navigation-row">{children}</div>
		);
	}

	export namespace Flow {
		// SurveyValues should include all db fields that can be modified by the survey. (Values used to determine flow logic should also be included here.)
		// Interfaces are optional. (e.g. Sometimes a survey will have optional behaviour based on other account data or where the survey is being used (Public/Private contexts).
		// ... This is where the implementations of these interfaces should be passed in. )
		export interface CompatibleInterface<SurveyValues = {}, Interfaces = {}> {
			loading: boolean;
			tracking_values: Record<string, any>;
			survey_values: SurveyValues;
			optional_interfaces: Interfaces;
			survey_methods: {
				get: () => Promise<void>;
				exit: () => Promise<void>;
				patch: (values: DeepPartial<SurveyValues>) => Promise<void>;
			};
		}

		export interface CompatibleReactContext
			extends React.Context<CompatibleInterface> {}

		type InferContextType<RC extends CompatibleReactContext> =
			RC extends React.Context<infer CT> ? (CT extends {} ? CT : never) : never;

		type InferSurveyValuesFrom<RC extends CompatibleReactContext> =
			RC extends React.Context<infer CT>
				? CT extends CompatibleInterface<infer SV>
					? SV
					: never
				: never;

		type MachineContext<RC extends CompatibleReactContext> =
			InferContextType<RC> & {
				direction?: "forward" | "backward";
				progress?: number;
				is_step_valid?: (machine_context: MachineContext<RC>) => boolean;
				Step?: React.FC;
				show_template_navigation_row?: boolean;
				OverrideTemplateNavigationButtons?: React.FC;
				update_backend_error_message?: string;
			} & {
				state_value?: string;
			};

		export const createFlowMachineFactory = <
			RC extends CompatibleReactContext,
		>() =>
			setup({
				types: {
					context: {} as MachineContext<RC>,
					events: {} as
						| {
								type: "update_machine_context";
								machine_context: DeepPartial<MachineContext<RC>>;
						  }
						| {
								type: "put_machine_context";
								machine_context: Partial<MachineContext<RC>>;
						  }
						| { type: "init" }
						| { type: "resume" }
						| { type: "restart" }
						| { type: "close" }
						| { type: "next" }
						| { type: "back" }
						| { type: "skip_intro" }
						| {
								type: "update_backend";
						  },
				},
				actions: {
					update_machine_context: assign(({ context, event }) => {
						if ("machine_context" in event) {
							return merge(context, event.machine_context);
						}
						return context;
					}),
					put_machine_context: assign(({ context, event }) => {
						if ("machine_context" in event) {
							return {
								...context,
								...event.machine_context,
							};
						}
						return context;
					}),
					set_update_backend_error_message: assign(({ context, event }) => {
						return {
							...context,
							update_backend_error_message:
								"An error occurred. Please try again.",
						};
					}),
					clear_update_backend_error_message: assign(({ context, event }) => {
						return {
							...context,
							update_backend_error_message: undefined,
						};
					}),
					set_direction: assign(({ context, event }) => {
						if (["back", "restart"].includes(event.type)) {
							return {
								...context,
								direction: "backward",
							};
						}
						if (["next", "skip_intro"].includes(event.type)) {
							return {
								...context,
								direction: "forward",
							};
						}
						return context;
					}),
					track: ({ context, event }) => {},
					exit: ({ context }) => {
						context.survey_methods?.exit();
					},
				},
				guards: {},
				delays: {},
				actors: {
					update_backend: fromPromise<void, MachineContext<RC>>(
						async ({ input }) => {
							await Promise.all([
								input.survey_methods?.patch(input?.survey_values),
								new Promise(resolve => setTimeout(resolve, 200)),
							]);
						}
					),
				},
			});

		type FlowMachineFactory<RC extends CompatibleReactContext> = ReturnType<
			typeof createFlowMachineFactory<RC>
		>;

		export type FlowMachineConfig<RC extends CompatibleReactContext> =
			Parameters<FlowMachineFactory<RC>["createMachine"]>[0] & { id: string };

		type FlowMachine<RC extends CompatibleReactContext> = ReturnType<
			FlowMachineFactory<RC>["createMachine"]
		>;
		export interface IContext<RC extends CompatibleReactContext> {
			machine_context?: MachineContext<RC>;
			flow_id?: string;
			// derived
			show_back?: boolean;
			show_next?: boolean;
			next_disabled?: boolean;
			// nav
			skip_intro?: () => void;
			next?: () => void;
			back?: () => void;
			close?: () => void;
			restart?: () => void;
			update_machine_context?: (mc: DeepPartial<MachineContext<RC>>) => void;
			update_backend?: () => void;
			RestartButton: React.FC<React.ComponentProps<typeof ButtonV2>>;
			NextButton: React.FC<React.ComponentProps<typeof ButtonV2>>;
			BackButton: React.FC<React.ComponentProps<typeof ButtonV2>>;
			CloseButton: React.FC<React.ComponentProps<typeof ButtonV2>>;
		}

		export const build = <
			RC extends CompatibleReactContext,
			FM extends FlowMachine<RC>,
			MC extends MachineContext<RC>,
		>({
			reactContext,
			flowMachine,
		}: {
			reactContext: RC;
			flowMachine: FM;
		}) => {
			const FlowContext = React.createContext<IContext<RC>>({
				RestartButton: () => null,
				NextButton: () => null,
				BackButton: () => null,
				CloseButton: () => null,
			});

			const useFlow = () => {
				const context = React.useContext(FlowContext);
				if (!context) {
					throw new Error("useFlow must be used within a FlowProvider");
				}
				return context;
			};

			const FlowProvider: React.FC = ({ children }) => {
				const trackEvent = useEventTracker();
				const {
					loading,
					survey_values,
					optional_interfaces,
					survey_methods,
					tracking_values,
				} = React.useContext(reactContext);
				const [state, send] = useMachine(
					flowMachine.provide({
						actions: {
							track: ({ context, event }) => {
								trackEvent({
									eventName: `${flowMachine.id.toUpperCase()}_FLOW_${context?.state_value}_${event.type.toUpperCase()}`,
									data: {
										tracking_values: context.tracking_values,
										survey_values: context.survey_values,
									},
								});
							},
						},
					})
				);

				const flow_id = state.machine.id;

				const show_back = React.useMemo(
					() => state.can({ type: "back" }),
					[state]
				);

				const show_next = React.useMemo(
					() => state.can({ type: "next" }),
					[state]
				);

				const show_restart = React.useMemo(
					() => state.can({ type: "restart" }),
					[state]
				);

				const show_close = React.useMemo(
					() => state.can({ type: "close" }),
					[state]
				);

				const next_disabled = React.useMemo(
					() => !state.context?.is_step_valid?.(state.context),
					[state]
				);

				const skip_intro = () => {
					send({ type: "skip_intro" });
				};

				const back = () => {
					send({ type: "back" });
				};

				const next = () => {
					send({ type: "next" });
				};

				const resume = () => {
					send({ type: "resume" });
				};

				const close = () => {
					send({ type: "close" });
				};

				const restart = () => {
					send({ type: "restart" });
				};

				const init = () => {
					send({ type: "init" });
				};

				const update_machine_context = (mc: DeepPartial<MC>) => {
					send({
						type: "update_machine_context",
						machine_context: {
							...mc,
						},
					});
				};

				const put_machine_context = (mc: Partial<MC>) => {
					send({
						type: "put_machine_context",
						machine_context: {
							...mc,
						},
					});
				};

				const update_backend = () => {
					send({
						type: "update_backend",
					});
				};

				const state_value = React.useMemo(() => {
					if (typeof state.value === "string") return state.value;
					return Object.keys(state.value)[0];
				}, [state.value]);

				React.useEffect(() => {
					put_machine_context({
						state_value,
						optional_interfaces,
						survey_methods,
						tracking_values,
						survey_values,
					} as Partial<MC>);
					loading ? init() : resume();
				}, [
					state_value,
					optional_interfaces,
					survey_methods,
					tracking_values,
					survey_values,
					survey_values,
					loading,
				]);

				const BackButton: React.FC<React.ComponentProps<typeof ButtonV2>> = ({
					children,
					className,
					variant = "primary-outline",
					size,
				}) =>
					show_back ? (
						<ButtonV2
							onClick={back}
							className={className}
							variant={variant}
							size={size}>
							{children}
						</ButtonV2>
					) : null;

				const RestartButton: React.FC<
					React.ComponentProps<typeof ButtonV2>
				> = ({ children, className, variant = "primary-outline", size }) =>
					show_restart ? (
						<ButtonV2
							onClick={restart}
							className={className}
							variant={variant}
							size={size}>
							{children}
						</ButtonV2>
					) : null;

				const NextButton: React.FC<React.ComponentProps<typeof ButtonV2>> = ({
					children,
					className,
					variant,
					size,
				}) =>
					show_next ? (
						<ButtonV2
							onClick={next}
							disabled={next_disabled}
							className={className}
							variant={variant}
							size={size}>
							{children}
						</ButtonV2>
					) : null;

				const CloseButton: React.FC<React.ComponentProps<typeof ButtonV2>> = ({
					children,
					className,
					variant,
					size,
				}) =>
					show_close ? (
						<ButtonV2
							onClick={close}
							className={className}
							variant={variant}
							size={size}>
							{children}
						</ButtonV2>
					) : null;

				return (
					<FlowContext.Provider
						value={{
							flow_id,
							machine_context: state.context,
							show_back,
							show_next,
							next_disabled,
							close,
							restart,
							next,
							back,
							skip_intro,
							update_machine_context,
							update_backend,
							RestartButton,
							NextButton,
							BackButton,
							CloseButton,
						}}>
						{children}
					</FlowContext.Provider>
				);
			};

			return {
				FlowProvider,
				useFlow,
			};
		};

		export namespace Templates {
			export const FullScreen = <RC extends Flow.CompatibleReactContext>(
				props: Flow.IContext<RC>
			) => {
				const { flow_id, close, machine_context, NextButton, BackButton } =
					props;

				const {
					state_value,
					direction,
					progress,
					Step,
					OverrideTemplateNavigationButtons,
					show_template_navigation_row,
					update_backend_error_message,
				} = machine_context ?? {};

				return (
					<div
						id={`${flow_id?.toString().toLowerCase().replace(/\_/g, "-")}-survey`}>
						<Component.RemoveBodyScroll />
						<div className="survey-builder-template-fullscreen">
							<div className="survey-builder-template-fullscreen-header">
								<div className="survey-builder-template-fullscreen-header-content">
									<div className="spacer" />
									<Component.Logo />
									<Component.Close
										onClick={() => {
											close?.();
										}}
									/>
								</div>
								<Component.ProgressBar progress={progress} />
							</div>

							<Component.SwipeTransition
								className="survey-builder-template-fullscreen-body"
								key={state_value}
								direction={direction}
								Step={Step}
							/>

							<div className="survey-builder-template-fullscreen-sticky-bottom">
								{update_backend_error_message && (
									<p className="mx-auto rust text-center py-2 bg-white">
										{update_backend_error_message}
									</p>
								)}

								{show_template_navigation_row && (
									<Component.NavigationRow>
										{OverrideTemplateNavigationButtons ? (
											<OverrideTemplateNavigationButtons />
										) : (
											<>
												<BackButton className="mr-auto" size="small">
													Back
												</BackButton>
												<NextButton className="ml-auto" size="small">
													Next
												</NextButton>
											</>
										)}
									</Component.NavigationRow>
								)}

								<div className="survey-builder-template-fullscreen-footer">
									<div className="flex justify-center flex-gap-2 flex-wrap">
										<div className="flex flex-gap-1 items-center">
											<Component.Speedometer />
											<p className="extra-small denim_5 nowrap">
												No impact on credit score
											</p>
										</div>
										<div className="flex flex-gap-1 items-center">
											<Component.Encryption />
											<p className="extra-small denim_5 nowrap">
												Your data security is important to us
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			};
		}
	}
}
