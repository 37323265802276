import { ButtonHTMLAttributes, FC, useMemo } from "react";
import Link from "next/link";
import { useEventTracker } from "utils/useEventTracker";
import constants from "utils/constants";
import { useAppContext } from "context/AppContext";
import { useAuthContext } from "context/AuthContext";
import Image from "next/image";
import LoadingImg from "public/loading-button-icon.webp";
import { usePathname } from "next/navigation";

interface ButtonProps {
	size?: "mobile-extra-small" | "extra-small" | "small" | "default" | "large";
	variant?:
		| "primary"
		| "secondary"
		| "destructive"
		| "primary-outline"
		| "primary-outline-filled"
		| "secondary-outline"
		| "destructive-outline"
		| "primary-ghost"
		| "secondary-ghost"
		| "destructive-ghost";
	loading?: boolean;
	toAppealFlow?: {
		referrer: string;
		exemptions?: boolean;
		separateTracker?: { eventName?: string; data?: Record<string, any> };
	};
	openInNewTab?: boolean;
}

//Button styles:
//Primary - Azure
//Secondary - White
//Destructive - Rust

//
//Ghost

const ButtonV2: FC<ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({
	size = "default",
	variant = "primary",
	loading = false,
	toAppealFlow,
	children,
	className: userClassName,
	openInNewTab = false,
	...buttonProps
}) => {
	const pathname = usePathname();
	const { userData } = useAuthContext();
	const trackMixpanel = useEventTracker();
	const handletoAppealFlowClick = () => {
		if (toAppealFlow?.separateTracker?.eventName) {
			trackMixpanel({
				eventName: toAppealFlow.separateTracker.eventName,
				data: {
					...toAppealFlow.separateTracker.data,
				},
			});
		}
		if (toAppealFlow?.exemptions) {
			trackMixpanel({
				eventName: constants.EVENTS.E2E_Exemptions_LP_CTAs,
				data: {
					"User Type": userData?.id
						? "Returning (signed in)"
						: "New (signed out)",
				},
			});
		} else {
			trackMixpanel({
				eventName: constants.EVENTS.FORM_FLOW_ADDRESS,
				data: {
					referrer: toAppealFlow?.referrer,
				},
			});
		}
	};

	const className = useMemo(() => {
		let val = "button-v2";
		switch (variant) {
			case "primary":
				val += " primary";
				break;
			case "secondary":
				val += " secondary";
				break;
			case "destructive":
				val += " destructive";
				break;
			case "primary-outline":
				val += " primary-outline";
				break;
			case "primary-outline-filled":
				val += " primary-outline-filled";
				break;
			case "secondary-outline":
				val += " secondary-outline";
				break;
			case "destructive-outline":
				val += " destructive-outline";
				break;
			case "primary-ghost":
				val += " primary-ghost";
				break;
			case "secondary-ghost":
				val += " secondary-ghost";
				break;
			case "destructive-ghost":
				val += " destructive-ghost";
				break;
		}

		if (loading) {
			val += " loading";
		}

		if (size === "mobile-extra-small") {
			val += " mobile-extra-small";
		} else if (size === "extra-small") {
			val += " extra-small";
		} else if (size === "small") {
			val += " small";
		} else if (size === "large") {
			val += " large";
		}

		if (userClassName) {
			val += " " + userClassName;
		}

		return val;
	}, [size, variant, userClassName, loading]);

	if (toAppealFlow) {
		const additionalProps: Record<string, string> = {};
		if (openInNewTab) {
			additionalProps["target"] = "_blank";
			additionalProps["rel"] = "noopener noreferrer";
		}
		const searchParams = new URLSearchParams();
		if (pathname) {
			searchParams.append("appeal_flow_redirect", pathname);
		}
		if (toAppealFlow.exemptions) {
			searchParams.append("exemptions", "true");
		}
		return (
			<Link
				passHref
				href={`/appeal${searchParams.toString() ? `?${searchParams.toString()}` : ""}`}
				className={className}
				id={buttonProps.id}
				onClick={() => handletoAppealFlowClick()}
				{...additionalProps}>
				<span>{children}</span>
			</Link>
		);
	}

	return (
		<button className={className} {...buttonProps}>
			{loading ? (
				<>
					<span>Loading</span>
					<Image src={LoadingImg} alt="" />
				</>
			) : (
				<span>{children}</span>
			)}
		</button>
	);
};

export default ButtonV2;
