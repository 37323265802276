import React, { useState } from "react";
import { motion } from "framer-motion";

type ToggleOptions = { label: string; value: string }[];

type ToggleProps = {
	options: ToggleOptions;
	selectedOption: string;
	onOptionChange: (option: string) => void;
};

export const Toggle = ({
	options,
	selectedOption,
	onOptionChange,
}: ToggleProps) => {
	return (
		<div className="toggle-wrapper">
			<div className="toggle-options">
				{options.map(({ label, value }) => (
					<p
						key={value}
						className={`xs toggle-option ${selectedOption === value ? "selected" : ""}`}
						onClick={() => onOptionChange(value)}>
						{label}
					</p>
				))}
			</div>
		</div>
	);
};
