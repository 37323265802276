import { ALL_INSURANCE_STAGES, InsuranceSurveyStage } from "./InsuranceSurvey";

export const INSURANCE_TOOLTIPS = {
	DEDUCTIBLE:
		"The amount you will pay out of pocket for a covered claim. This does not include disaster deductibles like wind/hail and flood.",
	DWELLING:
		"Dwelling will cover your home’s structure and any installed fixtures and appliances – not any contents or land.",
	LOSS_OF_USE:
		"If your home is under construction from a recent claim, the cost of a hotel or similar rental home will be covered.",
	MEDICAL_PAYMENTS:
		"Medical Payments will cover medical expenses if a guest or neighbor is injured on your property. It does not matter who was at fault.",
	OTHER_STRUCTURE:
		"Other Structure will cover repairs or replace structures other than your home’s structure like a fence or shed.",
	PERSONAL_LIABILITY:
		"Personal Liability will cover claims of bodily harm or property damage sustained by others when a household resident is deemed responsible.",
	PERSONAL_PROPERTY:
		"Personal Property will cover the cost of the goods in your home if they are damaged or lost.",
	WIND_HAIL:
		"Wind/Hail is a deductible - the amount you will pay out of pocket, for any damage claims caused by wind or hail.",
	ANNUAL_PREMIUM: "The amount you will pay per year for your insurance policy.",
} as const;

export const INSURANCE_FAQS = [
	{
		title: "Does Ownwell charge anything?",
		body: "You won’t pay anything until you decide to purchase a policy! Shop quotes for home and auto insurance at no cost to you.",
	},
	{
		title: "Who is Matic?",
		body: "Matic is a digital insurance agency that Ownwell has partnered with to help our customers make sure their homes are adequately covered and they’re not overpaying on their home insurance. When you submit your information, Matic checks with 40+ A-rated carriers to match you with a great policy in seconds and connects you with Matic’s expert advisors to help you understand your insurance options and purchase a new policy if necessary. ",
	},
	{
		title: "Do these quotes include disaster coverage?",
		body: "Coverage for some disasters—like Flood and Earthquake—may require additional policies. Your licensed Matic agent can help you with the additional coverage you may need.",
	},
	{
		title: "How do I switch carriers? Is it hard?",
		body: "It's easy! Your licensed Matic agent will help you find the right policy for your needs and will take care of getting the new policy information to your Lender. All you have to do is cancel your old policy—and Matic's customer service team is able to assist at any point along the way!",
	},

	{
		title: "Can I bundle my car insurance?",
		body: "Yes! When you speak to your Matic agent about a new home insurance policy, they can discuss your bundling options. Bundling is one of the easiest ways to reduce the costs of insurance even more.",
	},
];

export const isInsuranceStageDone = (
	stage: InsuranceSurveyStage,
	currentStage: InsuranceSurveyStage
) => {
	return (
		ALL_INSURANCE_STAGES.indexOf(stage) <
		ALL_INSURANCE_STAGES.indexOf(currentStage)
	);
};

export const INSURANCE_CARRIER_OPTIONS = [
	"Lemonade",
	"Allstate",
	"Travelers",
	"USAA",
	"State Farm",
	"Nationwide",
	"Amica",
	"Liberty Mutual",
	"Chubb",
	"Progressive",
	"Farmers Insurance",
	"American Family",
	"Hippo",
	"Erie",
	"Hanover",
];
