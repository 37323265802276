import FacebookLogo from "public/facebookResourcesIcon.svg";
import LinkedinLogo from "public/linkedinResourcesIcon.svg";
import SmsLogo from "public/smsIcon.svg";
import TwitterLogo from "public/twitterResourcesIcon.svg";
import MailLogo from "public/mailResourcesIcon.svg";
import constants from "utils/constants";
import { useEventTracker } from "utils/useEventTracker";
import { useAuthContext } from "context/AuthContext";

const Socials = ({ referralLink }: { referralLink: string }) => {
	const { userData } = useAuthContext();
	const trackEvent = useEventTracker();
	const socialMedias = [
		{
			name: "Facebook",
			href: `https://www.facebook.com/sharer/sharer.php?u=${referralLink}`,
			Icon: FacebookLogo,
			eventTrackerName: constants.EVENTS.Refer_Page_Share_Facebook_Button,
		},
		{
			name: "LinkedIn",
			href: `https://www.linkedin.com/shareArticle?mini=true&url=${referralLink}`,
			Icon: LinkedinLogo,
			eventTrackerName: constants.EVENTS.Refer_Page_Share_LinkedIn_Button,
		},
		{
			name: "Twitter",
			href: `https://twitter.com/intent/tweet?url=${referralLink}&text=I%20won%E2%80%99t%20be%20overpaying%20my%20property%20taxes%20this%20year!%20%0ASaving%20feels%20good%0AI%E2%80%99m%20using%20Ownwell%20to%20appeal%20my%20property%20tax%20because%20it%E2%80%99s%20effective,%20quick%20%26%20inexpensive.%20Get%20a%20%24${constants.REFERRAL_CREDIT}%20credit%20for%20signing%20up%20with%20this%20link.`,
			Icon: TwitterLogo,
			eventTrackerName: constants.EVENTS.Refer_Page_Share_Twitter_Button,
		},
		{
			name: "Mail",
			href: `mailto:?body=Hey!%20I%E2%80%99m%20using%20Ownwell%20to%20appeal%20my%20property%20taxes.%20You%20should%20see%20how%20much%20you%20can%20save%20on%20their%20website.%20It%20took%20me%203%20minutes%20to%20sign-up%2C%20and%20they%20only%20charge%20if%20they%20save%20you%20money.%20Plus%20you%20can%20get%20a%20%24${constants.REFERRAL_CREDIT}%20credit%20for%20signing%20up.%0A%0A${referralLink}`,
			Icon: MailLogo,
			eventTrackerName: constants.EVENTS.Refer_Page_Share_Email_Button,
		},
		{
			name: "SMS",
			href: `sms:?&body=Hey!%20I%E2%80%99m%20using%20ownwell%20to%20appeal%20my%20property%20taxes.%20You%20should%20see%20how%20much%20you%20can%20save%20on%20their%20website.%20It%20took%20me%203%20minutes%20to%20sign-up%2C%20and%20they%20only%20charge%20if%20they%20save%20you%20money.%20Plus%20you%20can%20get%20a%20%24${constants.REFERRAL_CREDIT}%20credit%20for%20signing%20up.%0A%0A${referralLink}`,
			Icon: SmsLogo,
			eventTrackerName: constants.EVENTS.Refer_Page_Share_SMS_Button,
		},
	];

	const handleClick = ({ eventName }: any) => {
		trackEvent({
			eventName: eventName,
			data: {
				"User Created Date": userData?.created_at,
				Offer: userData?.referral_program,
			},
		});
	};

	return (
		<div id="referral-socials-section">
			<div id="referral-socials-container">
				{socialMedias.map(({ name, href, Icon, eventTrackerName }, index) => (
					<a
						className="share-btn"
						rel="noreferrer"
						target="_blank"
						href={href}
						key={name + index}
						onClick={() => handleClick({ eventName: eventTrackerName })}>
						<Icon />
					</a>
				))}
			</div>
		</div>
	);
};

export default Socials;
