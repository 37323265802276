import {
	DetailedHTMLProps,
	FC,
	HTMLAttributes,
	useEffect,
	useState,
} from "react";
import Add from "assets/imgs/add.svg";
import Cross from "assets/imgs/cross.svg";

interface ExpandButtonProps {
	defaultActive?: boolean;
	noSwitch?: boolean;
	reverse?: boolean;
	terracotta?: boolean;
}
const ExpandButton: FC<
	DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
		ExpandButtonProps
> = ({
	children,
	defaultActive = false,
	noSwitch,
	reverse,
	terracotta = false,
	...divProps
}) => {
	const [active, setActive] = useState(defaultActive);

	useEffect(() => {
		if (!noSwitch) {
			setActive(defaultActive);
		}
	}, [defaultActive]);

	return (
		<div
			{...divProps}
			className={
				"expand-button" +
				(reverse ? " expand-button-reverse " : "") +
				(divProps.className ? " " + divProps.className : "") +
				(terracotta ? " expand-button-terracotta" : "")
			}
			onClick={e => {
				if (!noSwitch) setActive(p => !p);
				if (divProps.onClick) divProps.onClick(e);
			}}>
			{reverse && (active ? <Cross /> : <Add />)}
			<p className="expand-label nowrap">{children}</p>
			{!reverse && (active ? <Cross /> : <Add />)}
		</div>
	);
};

export default ExpandButton;
