import { useEffect, useState, useMemo, useCallback } from "react";
import client from "utils/client";
import { usePropertyContext } from "../../context";
import QuestionCard from "components/elements/QuestionCard";
import { OneZeroOrNull } from "utils/types";
import { TileSelect } from "elements/TileSelect";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import ButtonV2 from "elements/ButtonV2";

const CurrentHomesteadStatus = () => {
	const { details, activeQuestionId, send, next, propertySurveyContext } =
		usePropertyContext();

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const handleSkip = () => {
		next(true);
	};

	const handleSubmit = async (payload?: { value: string | null }) => {
		if (payload) {
			send({
				type: "update_context",
				context: { q_current_homestead_status: payload.value },
			});
		}

		try {
			setSubmitting(true);
			setError(false);

			await client.submitQuestionAnswer({
				questionId: activeQuestionId,
				appealId: details!.appeal_id,
				payload: {
					homesteadStatus: payload?.value
						? payload.value
						: propertySurveyContext.q_current_homestead_status,
				},
			});

			next();
		} catch (e) {
			console.error(e);
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	const tiles = useMemo(
		() => [
			{
				unselectedTileContent: "Yes, here",
				selectedTileContent: "Yes, here",
				isSelected: propertySurveyContext.q_current_homestead_status === "yes",
				onSelect: () => {
					handleSubmit({ value: "yes" });
				},
			},
			{
				unselectedTileContent: "Yes, another property",
				selectedTileContent: "Yes, another property",
				isSelected:
					propertySurveyContext.q_current_homestead_status ===
					"yesAnotherProperty",
				onSelect: () => {
					handleSubmit({ value: "yesAnotherProperty" });
				},
			},
			{
				unselectedTileContent: "No",
				selectedTileContent: "No",
				isSelected: propertySurveyContext.q_current_homestead_status === "no",
				onSelect: () => {
					handleSubmit({ value: "no" });
				},
			},
			{
				unselectedTileContent: "Not Sure",
				selectedTileContent: "Not Sure",
				isSelected:
					propertySurveyContext.q_current_homestead_status === "notSure",
				onSelect: () => {
					handleSubmit({ value: "notSure" });
				},
			},
		],
		[propertySurveyContext.q_current_homestead_status]
	);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}>
			<QuestionCard
				id="currently-receiving-exemption"
				tooltipText="Property owners can receive discounts on their property taxes on their primary residence. Your property tax appeal will have a more favorable outcome in some jurisdictions if a property is owner-occupied."
				question={
					<p className="lg bold">
						Do you currently receive a homestead exemption?
					</p>
				}>
				<TileSelect tiles={tiles} />
			</QuestionCard>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
			<ButtonV2
				size="large"
				disabled={propertySurveyContext.q_current_homestead_status === null}
				className="mx-auto"
				type="submit">
				Continue
			</ButtonV2>
			<p className="profile-question-skip" onClick={() => handleSkip()}>
				Skip <RightArrow />
			</p>
		</form>
	);
};

export default CurrentHomesteadStatus;
