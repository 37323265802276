import Grid from "elements/Grid";
import { FC } from "react";

interface PropertySurveyEvidenceModalProps {
	onAttemptExit: () => void;
}

export const PropertySurveyEvidenceModal: FC<
	PropertySurveyEvidenceModalProps
> = ({ children, onAttemptExit }) => {
	return (
		<div
			className="property-survey-evidence-modal-container"
			onClick={onAttemptExit}>
			<div className="property-survey-evidence-modal-container-inner">
				<Grid>
					<div
						className="property-survey-evidence-modal"
						onClick={e => {
							e.stopPropagation();
							e.preventDefault();
						}}>
						{children}
					</div>
				</Grid>
			</div>
		</div>
	);
};
