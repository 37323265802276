import Grid from "elements/Grid";

import Error404Img from "public/error-404-img.png";
import Image from "next/image";
import React, { useEffect } from "react";
import { sendErrorToWebhook } from "utils/sendWebhook";

export default function StorageRequired() {
	//  Uncomment the code below if we want to get slack notifications when users have cookies disabled
	//
	// useEffect(() => {
	// 	sendErrorToWebhook(
	// 		`Encountered user with cookies disabled. User Agent: ${
	// 			typeof window === "undefined"
	// 				? "Server"
	// 				: window.navigator
	// 				? window.navigator.userAgent
	// 				: "Unknown"
	// 		}`
	// 	);
	// }, []);

	return (
		<Grid id="error">
			<div className="error-img">
				<Image
					src={Error404Img}
					alt="Lost"
					style={{
						maxWidth: "100%",
						height: "auto",
					}}
				/>
			</div>
			<div className="error-body">
				<h4 className="error-description"></h4>
				<h2 className="error-title">
					Oops! Looks like your browser has cookies disabled.
				</h2>
				<p className="error-text">
					Our site requires cookies to be enabled to provide you the best
					experience. Please enable them in your browser's security settings to
					continue.
				</p>
			</div>
		</Grid>
	);
}
