import React from "react";

import { createMachine, assign, setup } from "xstate";

import OnboardingIntro from "./questions/OnboardingIntro";
import Intro from "./questions/Intro";
import NoticeOfValue, { NoticeOfValueUpload } from "./questions/NoticeOfValue";
import HearingNotice, { HearingNoticeUpload } from "./questions/HearingNotice";
import PrimaryResidence from "./questions/PrimaryResidence";
import CurrentHomesteadStatus from "./questions/CurrentHomesteadStatus";
import Takeover from "./questions/Takeover";
import EvidenceIntro from "./questions/EvidenceIntro";
import PurchaseDate, {
	ArmsLengthTransaction,
	ArmsLengthTransactionDescription,
} from "./questions/PurchaseDate";
import IncomeProducing from "./questions/IncomeProducing";
import DamageAndDeferredMaintenanceIntro from "./questions/DamageAndDeferredMaintenanceIntro";
import Easement from "./questions/Easement";
import Flooding from "./questions/Flooding";
import FoundationIssues from "./questions/FoundationIssues";
import RoofDamage from "./questions/RoofDamage";
import MajorDamage from "./questions/MajorDamage";
import CatchAll from "./questions/CatchAll";
import ConciergeOptions from "./questions/ConciergeOptions";
import ConciergeConfirmation from "./questions/ConciergeConfirmation";
import HowDidYouHearAboutUs from "./questions/HowDidYouHearAboutUs";
import Wildcard from "./questions/Wildcard";
import Confirmation from "./questions/Confirmation";
import ConciergePayment from "./questions/ConciergePayment";

import {
	ActiveSubscription,
	PropertyDetails,
	PropertyDetailsAppeal,
	UserData,
} from "utils/types";
import { InternetReduction } from "./questions/InternetReduction";
import { InsuranceMonitoring } from "./questions/InsuranceMonitoring";
import { MortgageAndEquity } from "./questions/MortgageAndEquity";

const YARD_SIGN_COUNTIES = ["williamson", "hays", "travis"];

export const PROPERTY_SURVEY_QUESTION_STATES = [
	"ONBOARDING_INTRO",
	"INTRO",
	"NOTICE_OF_VALUE",
	"NOTICE_OF_VALUE_UPLOAD",
	"PRIMARY_RESIDENCE",
	"CURRENT_HOMESTEAD_STATUS",
	"TAKEOVER",
	"HEARING_NOTICE",
	"HEARING_NOTICE_UPLOAD",
	"EVIDENCE_INTRO",
	"PURCHASED_RECENTLY",
	"ARMS_LENGTH_TRANSACTION",
	"ARMS_LENGTH_TRANSACTION_DESCRIPTION",
	"INCOME_PRODUCING",
	"DAMAGE_AND_DEFERRED_MAINTENANCE_INTRO",
	"EASEMENT",
	"FLOODING",
	"FOUNDATION_ISSUES",
	"ROOF_DAMAGE",
	"MAJOR_DAMAGE",
	"CATCH_ALL",
	"CONCIERGE_OPTIONS",
	"CONCIERGE_CONFIRMATION",
	"INTERNET_REDUCTION",
	"INSURANCE_MONITORING",
	"MORTGAGE_AND_EQUITY",
	//"HOW_DID_YOU_HEAR_ABOUT_US",
	"WILDCARD",
	"CONFIRMATION",
	"CONCIERGE_PAYMENT",
] as const;

export type PropertySurveyQuestionState =
	(typeof PROPERTY_SURVEY_QUESTION_STATES)[number];

export const valueIsPropertySurveyQuestionState = (
	v: any
): v is PropertySurveyQuestionState => {
	try {
		return PROPERTY_SURVEY_QUESTION_STATES.includes(
			v as PropertySurveyQuestionState
		);
	} catch {
		return false;
	}
};

export const getQuestionId = (state: PropertySurveyQuestionState) => {
	const PROPERTY_SURVEY_QUESTION_ID_MAP: {
		[key in PropertySurveyQuestionState]: string;
	} = {
		ONBOARDING_INTRO: "onboardingIntro",
		INTRO: "intro",
		NOTICE_OF_VALUE: "noticeOfValue",
		NOTICE_OF_VALUE_UPLOAD: "noticeOfValue",
		PRIMARY_RESIDENCE: "primaryResidence",
		CURRENT_HOMESTEAD_STATUS: "currentHomesteadStatus",
		TAKEOVER: "takeover",
		HEARING_NOTICE: "hearingNotice",
		HEARING_NOTICE_UPLOAD: "hearingNotice",
		EVIDENCE_INTRO: "evidenceIntro",
		PURCHASED_RECENTLY: "purchasedRecently",
		ARMS_LENGTH_TRANSACTION: "armsLengthTransaction",
		ARMS_LENGTH_TRANSACTION_DESCRIPTION: "armsLengthTransactionDescription",
		INCOME_PRODUCING: "incomeProducing",
		DAMAGE_AND_DEFERRED_MAINTENANCE_INTRO: "damageAndDeferredMaintenanceIntro",
		EASEMENT: "easement",
		FLOODING: "flooding",
		FOUNDATION_ISSUES: "foundationIssues",
		ROOF_DAMAGE: "roofDamage",
		MAJOR_DAMAGE: "majorDamage",
		CATCH_ALL: "catchAll",
		CONCIERGE_OPTIONS: "conciergeOptions",
		CONCIERGE_CONFIRMATION: "conciergeConfirmation",
		INTERNET_REDUCTION: "internetReduction",
		INSURANCE_MONITORING: "insuranceMonitoring",
		MORTGAGE_AND_EQUITY: "mortgageAndEquity",
		//HOW_DID_YOU_HEAR_ABOUT_US: "heardAbout",
		WILDCARD: "wildcard",
		CONFIRMATION: "confirmation",
		CONCIERGE_PAYMENT: "conciergePayment",
	};
	return PROPERTY_SURVEY_QUESTION_ID_MAP[state];
};

export const getQuestionComponent = (state: PropertySurveyQuestionState) => {
	const PROPERTY_SURVEY_QUESTION_COMPONENT_MAP: {
		[key in PropertySurveyQuestionState]: React.FC;
	} = {
		ONBOARDING_INTRO: OnboardingIntro,
		INTRO: Intro,
		NOTICE_OF_VALUE: NoticeOfValue,
		NOTICE_OF_VALUE_UPLOAD: NoticeOfValueUpload,
		PRIMARY_RESIDENCE: PrimaryResidence,
		CURRENT_HOMESTEAD_STATUS: CurrentHomesteadStatus,
		TAKEOVER: Takeover,
		HEARING_NOTICE: HearingNotice,
		HEARING_NOTICE_UPLOAD: HearingNoticeUpload,
		EVIDENCE_INTRO: EvidenceIntro,
		PURCHASED_RECENTLY: PurchaseDate,
		ARMS_LENGTH_TRANSACTION: ArmsLengthTransaction,
		ARMS_LENGTH_TRANSACTION_DESCRIPTION: ArmsLengthTransactionDescription,
		INCOME_PRODUCING: IncomeProducing,
		DAMAGE_AND_DEFERRED_MAINTENANCE_INTRO: DamageAndDeferredMaintenanceIntro,
		EASEMENT: Easement,
		FLOODING: Flooding,
		FOUNDATION_ISSUES: FoundationIssues,
		ROOF_DAMAGE: RoofDamage,
		MAJOR_DAMAGE: MajorDamage,
		CATCH_ALL: CatchAll,
		CONCIERGE_OPTIONS: ConciergeOptions,
		CONCIERGE_CONFIRMATION: ConciergeConfirmation,
		INTERNET_REDUCTION: InternetReduction,
		INSURANCE_MONITORING: InsuranceMonitoring,
		MORTGAGE_AND_EQUITY: MortgageAndEquity,
		//HOW_DID_YOU_HEAR_ABOUT_US: HowDidYouHearAboutUs,
		WILDCARD: Wildcard,
		CONFIRMATION: Confirmation,
		CONCIERGE_PAYMENT: ConciergePayment,
	};
	return PROPERTY_SURVEY_QUESTION_COMPONENT_MAP[state];
};

export interface PropertySurveyQuestionsAnswers
	extends Pick<
			PropertyDetailsAppeal,
			| "files"
			| "q_profile_seen_intro"
			| "q_notice_received"
			| "q_primary_residence"
			| "q_current_homestead_status"
			| "is_takeover"
			| "question_deferred"
			| "question_purchased"
			| "question_income"
			| "last_sale_date_prop"
			| "last_sale_price_prop"
			| "question_armslength"
			| "question_armslength_desc"
			//| "question_heardabout"
			| "q_negative_impact"
			| "q_negative_impact_list"
			| "q_negative_impact_other"
			| "q_negative_impact_cost"
			| "q_negative_impact_date"
			| "q_flooded"
			| "q_flooded_cause"
			| "q_flooded_desc"
			| "q_flooded_cost"
			| "q_flooded_date"
			| "q_structural_issues"
			| "q_structural_issues_list"
			| "q_structural_issues_desc"
			| "q_structural_issues_cost"
			| "q_structural_issues_date"
			| "q_major_damage"
			| "q_major_damage_list"
			| "q_major_damage_desc"
			| "q_major_damage_cost"
			| "q_major_damage_date"
			| "q_roof_damage"
			| "q_roof_damage_desc"
			| "q_roof_damage_date"
			| "q_roof_damage_cost"
			| "q_catchall"
			| "q_catchall_desc"
			| "q_catchall_cost"
			| "q_catchall_date"
			| "q_wildcard_type"
			| "q_wildcard_answer"
			| "q_yardsign"
			| "q_hearing_notice"
			| "q_hearing_notice_date"
			| "customer_completed_survey"
		>,
		Pick<PropertyDetails, "state" | "county" | "auth_company_type"> {
	// Pick<UserData, "has_answered_heardabout">
	enterprise: boolean;
	direction: "forwards" | "backwards";
}
export interface PropertySurveyQuestionFlowContext
	extends PropertySurveyQuestionsAnswers {
	progress: number | undefined;
	in_season: boolean;
	account_tier: string | null;
	active_subscription: ActiveSubscription[];
	current_account_tier: string | null;
	show_internet_reduction_question: boolean;
	show_insurance_monitoring_question: boolean;
	show_mortgage_and_equity_question: boolean;
}

export type PropertySurveyQuestionFlowEvent =
	| { type: "back" }
	| { type: "next" }
	| { type: "" }
	| { type: "init" }
	| { type: "restart" }
	| {
			type: "update_context";
			context: Partial<PropertySurveyQuestionFlowContext>;
	  };

type PropertySurveyQuestionFlowTypeState = {
	value: PropertySurveyQuestionState;
	context: PropertySurveyQuestionFlowContext;
};

export const generalPropertySurveyQuestionFlowMachine = setup({
	types: {
		context: {} as PropertySurveyQuestionFlowContext,
		events: {} as PropertySurveyQuestionFlowEvent,
		input: {} as Partial<PropertySurveyQuestionFlowContext>,
	},
	actions: {
		updateContext: assign(({ context, event }) => {
			if ("context" in event) {
				return {
					...context,
					...event.context,
				};
			}
			return context;
		}),
	},
	guards: {},
	delays: {},
}).createMachine({
	id: "PROPERTY_SURVEY",
	context: ({ input }) => ({ ...input }) as PropertySurveyQuestionFlowContext,
	initial: "IDLE",
	states: {
		IDLE: {},
		INIT: {
			always: [
				{
					target: "CONFIRMATION",
					guard: ({ context }) => context.customer_completed_survey === 1,
				},
				{
					target: "ONBOARDING_INTRO",
					guard: () => {
						const url = new URL(window.location.href);

						return !!url.searchParams.get("onboarding");
					},
				},
				{
					target: "INTRO",
					guard: ({ context }) => context.q_profile_seen_intro === 0,
				},
				{
					target: "QUALIFYING_QUESTIONS",
				},
			],
		},
		ONBOARDING_INTRO: {
			entry: [
				assign({
					progress: context => 0,
				}),
			],
			on: {
				next: [
					{
						target: "INTRO",
						actions: assign({
							direction: context => "forwards",
						}),
					},
				],
			},
		},
		INTRO: {
			entry: [
				assign({
					progress: context => 0,
				}),
			],
			on: {
				next: [
					{
						target: "QUALIFYING_QUESTIONS",
						actions: assign({
							direction: context => "forwards",
						}),
					},
				],
			},
		},
		QUALIFYING_QUESTIONS: {
			initial: "INIT",
			states: {
				INIT: {
					always: [
						{
							target: "IN_SEASON",
							guard: ({ context }) => !!context.in_season,
						},
						{
							target: "OUT_OF_SEASON",
						},
					],
				},
				IN_SEASON: {
					initial: "INIT",
					states: {
						INIT: {
							always: [
								{
									target: "LAST",
									guard: ({ context }) => context.direction === "backwards",
								},
								{
									target: "RESTART",
									guard: ({ context }) =>
										context.customer_completed_survey === 1,
								},
								{
									target: "RESUME",
								},
							],
						},
						RESUME: {
							always: [
								{
									target: "NOTICE_OF_VALUE",
									guard: ({ context }) =>
										context.state === "WA" &&
										context.q_notice_received === null,
								},
								{
									target: "NOTICE_OF_VALUE_UPLOAD",
									guard: ({ context }) =>
										context.q_notice_received === 1 &&
										!context.files.some(
											({ question }) =>
												context.state === "WA" && question === "noticeOfValue"
										),
								},
								{
									target: "PRIMARY_RESIDENCE",
									guard: ({ context }) =>
										!context.enterprise && context.q_primary_residence === null,
								},
								{
									target: "CURRENT_HOMESTEAD_STATUS",
									guard: ({ context }) =>
										!context.enterprise &&
										context.q_current_homestead_status === null &&
										!(
											context?.auth_company_type === "LLC" &&
											context?.state === "TX"
										),
								},
								{
									target: "TAKEOVER",
									guard: ({ context }) => context.is_takeover === null,
								},
								{
									target: "HEARING_NOTICE",
									guard: ({ context }) =>
										context.is_takeover === 1 &&
										context.q_hearing_notice === null,
								},
								{
									target: "HEARING_NOTICE_UPLOAD",
									guard: ({ context }) =>
										context.is_takeover === 1 &&
										context.q_hearing_notice === 1 &&
										context.q_hearing_notice_date === null,
								},
								{
									target: "EVIDENCE_INTRO",
									guard: ({ context }) =>
										[
											context.question_deferred,
											context.question_purchased,
											context.question_income,
										].every(v => v === null),
								},
								{
									target: "PURCHASED_RECENTLY",
									guard: ({ context }) =>
										context.question_purchased === 1 &&
										context.last_sale_price_prop === null &&
										context.last_sale_date_prop === null,
								},
								{
									target: "ARMS_LENGTH_TRANSACTION",
									guard: ({ context }) =>
										context.question_purchased === 1 &&
										context.question_armslength === null,
								},
								{
									target: "ARMS_LENGTH_TRANSACTION_DESCRIPTION",
									guard: ({ context }) =>
										context.question_purchased === 1 &&
										context.question_armslength === 1 &&
										context.question_armslength_desc === null,
								},
								{
									target: "INCOME_PRODUCING",
									guard: ({ context }) =>
										context.question_income === 1 &&
										!context.files.some(
											({ question }) => question === "incomeProducing"
										),
								},
								{
									target: "DAMAGE_AND_DEFERRED_MAINTENANCE_INTRO",
									guard: ({ context }) =>
										context.question_deferred === 1 &&
										[
											context.q_flooded,
											context.q_structural_issues_list,
											context.q_roof_damage,
											context.q_major_damage_list,
											context.q_negative_impact_list,
										].every(v => v === null),
								},
								{
									target: "EASEMENT",
									guard: ({ context }) =>
										context.question_deferred === 1 &&
										context.q_negative_impact_list !== null &&
										context.q_negative_impact_cost === null,
								},
								{
									target: "FLOODING",
									guard: ({ context }) =>
										context.question_deferred === 1 &&
										context.q_flooded === 1 &&
										context.q_flooded_cost === null,
								},
								{
									target: "FOUNDATION_ISSUES",
									guard: ({ context }) =>
										context.question_deferred === 1 &&
										context.q_structural_issues_list !== null &&
										context.q_structural_issues_cost === null,
								},
								{
									target: "ROOF_DAMAGE",
									guard: ({ context }) =>
										context.question_deferred === 1 &&
										context.q_roof_damage === 1 &&
										context.q_roof_damage_cost === null,
								},
								{
									target: "MAJOR_DAMAGE",
									guard: ({ context }) =>
										context.question_deferred === 1 &&
										context.q_major_damage_list !== null &&
										context.q_major_damage_cost === null,
								},
								{
									target: "#PROPERTY_SURVEY.QUALIFYING_QUESTIONS.DONE",
								},
							],
						},
						RESTART: {
							always: [
								{
									target: "NOTICE_OF_VALUE",
									guard: ({ context }) => context.state === "WA",
								},
								{
									target: "PRIMARY_RESIDENCE",
									guard: ({ context }) => !context.enterprise,
								},
								{
									target: "CURRENT_HOMESTEAD_STATUS",
									guard: ({ context }) =>
										!context.enterprise &&
										!(
											context?.auth_company_type === "LLC" &&
											context?.state === "TX"
										),
								},
								{
									target: "TAKEOVER",
								},
							],
						},
						LAST: {
							always: [
								{
									target: "CATCH_ALL",
								},
							],
						},
						NOTICE_OF_VALUE: {
							entry: [
								assign({
									progress: context => 10,
								}),
							],
							on: {
								back: [
									{
										target: "#PROPERTY_SURVEY.INTRO",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										guard: ({ context }) => context.q_notice_received === 1,
										target: "NOTICE_OF_VALUE_UPLOAD",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										guard: ({ context }) => !context.enterprise,
										target: "PRIMARY_RESIDENCE",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										target: "TAKEOVER",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						NOTICE_OF_VALUE_UPLOAD: {
							entry: [
								assign({
									progress: context => 15,
								}),
							],
							on: {
								back: [
									{
										target: "NOTICE_OF_VALUE",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										guard: ({ context }) => !context.enterprise,
										target: "PRIMARY_RESIDENCE",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										target: "TAKEOVER",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						PRIMARY_RESIDENCE: {
							entry: [
								assign({
									progress: context => 20,
								}),
							],
							on: {
								back: [
									{
										guard: ({ context }) =>
											context.state === "WA" && context.q_notice_received === 1,
										target: "NOTICE_OF_VALUE_UPLOAD",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) => context.state === "WA",
										target: "NOTICE_OF_VALUE",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										target: "#PROPERTY_SURVEY.INTRO",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										guard: ({ context }) =>
											context.q_primary_residence === 1 &&
											!(
												context?.auth_company_type === "LLC" &&
												context?.state === "TX"
											),
										target: "CURRENT_HOMESTEAD_STATUS",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										target: "TAKEOVER",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						CURRENT_HOMESTEAD_STATUS: {
							entry: [
								assign({
									progress: context => 25,
								}),
							],
							on: {
								back: [
									{
										target: "PRIMARY_RESIDENCE",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										target: "TAKEOVER",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						TAKEOVER: {
							entry: [
								assign({
									progress: context => 30,
								}),
							],
							on: {
								back: [
									{
										guard: ({ context }) =>
											context.q_primary_residence === 1 &&
											!(
												context?.auth_company_type === "LLC" &&
												context?.state === "TX"
											),
										target: "CURRENT_HOMESTEAD_STATUS",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) => !context.enterprise,
										target: "PRIMARY_RESIDENCE",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										target: "#PROPERTY_SURVEY.INTRO",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										target: "HEARING_NOTICE",
										guard: ({ context }) => context.is_takeover === 1,
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										target: "EVIDENCE_INTRO",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						HEARING_NOTICE: {
							entry: [
								assign({
									progress: context => 32,
								}),
							],
							on: {
								back: [
									{
										target: "TAKEOVER",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										guard: ({ context }) => context.q_hearing_notice === 1,
										target: "HEARING_NOTICE_UPLOAD",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										target: "EVIDENCE_INTRO",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						HEARING_NOTICE_UPLOAD: {
							entry: [
								assign({
									progress: context => 34,
								}),
							],
							on: {
								back: [
									{
										target: "HEARING_NOTICE",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										target: "EVIDENCE_INTRO",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						EVIDENCE_INTRO: {
							entry: [
								assign({
									progress: context => 35,
								}),
							],
							on: {
								back: [
									{
										target: "HEARING_NOTICE_UPLOAD",
										guard: ({ context }) =>
											context.is_takeover === 1 &&
											context.q_hearing_notice === 1,
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										target: "HEARING_NOTICE",
										guard: ({ context }) => context.is_takeover === 1,
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										target: "TAKEOVER",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										guard: ({ context }) => context.question_purchased === 1,
										target: "PURCHASED_RECENTLY",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										guard: ({ context }) => context.question_income === 1,
										target: "INCOME_PRODUCING",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										guard: ({ context }) => context.question_deferred === 1,
										target: "DAMAGE_AND_DEFERRED_MAINTENANCE_INTRO",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										target: "CATCH_ALL",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						PURCHASED_RECENTLY: {
							entry: [
								assign({
									progress: context => 40,
								}),
							],
							on: {
								back: [
									{
										target: "EVIDENCE_INTRO",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										target: "ARMS_LENGTH_TRANSACTION",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						ARMS_LENGTH_TRANSACTION: {
							entry: [
								assign({
									progress: context => 45,
								}),
							],
							on: {
								back: [
									{
										target: "PURCHASED_RECENTLY",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										guard: ({ context }) => context.question_armslength === 1,
										target: "ARMS_LENGTH_TRANSACTION_DESCRIPTION",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										guard: ({ context }) => context.question_income === 1,
										target: "INCOME_PRODUCING",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										guard: ({ context }) => context.question_deferred === 1,
										target: "DAMAGE_AND_DEFERRED_MAINTENANCE_INTRO",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										target: "CATCH_ALL",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						ARMS_LENGTH_TRANSACTION_DESCRIPTION: {
							entry: [
								assign({
									progress: context => 50,
								}),
							],
							on: {
								back: [
									{
										target: "ARMS_LENGTH_TRANSACTION",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										guard: ({ context }) => context.question_income === 1,
										target: "INCOME_PRODUCING",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										guard: ({ context }) => context.question_deferred === 1,
										target: "DAMAGE_AND_DEFERRED_MAINTENANCE_INTRO",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										target: "CATCH_ALL",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						INCOME_PRODUCING: {
							entry: [
								assign({
									progress: context => 55,
								}),
							],
							on: {
								back: [
									{
										guard: ({ context }) =>
											context.question_purchased === 1 &&
											context.question_armslength === 1,
										target: "ARMS_LENGTH_TRANSACTION_DESCRIPTION",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) => context.question_purchased === 1,
										target: "ARMS_LENGTH_TRANSACTION",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										target: "EVIDENCE_INTRO",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										guard: ({ context }) => context.question_deferred === 1,
										target: "DAMAGE_AND_DEFERRED_MAINTENANCE_INTRO",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										target: "CATCH_ALL",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						DAMAGE_AND_DEFERRED_MAINTENANCE_INTRO: {
							entry: [
								assign({
									progress: context => 60,
								}),
							],
							on: {
								back: [
									{
										guard: ({ context }) => context.question_income === 1,
										target: "INCOME_PRODUCING",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) =>
											context.question_purchased === 1 &&
											context.question_armslength === 1,
										target: "ARMS_LENGTH_TRANSACTION_DESCRIPTION",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) => context.question_purchased === 1,
										target: "ARMS_LENGTH_TRANSACTION",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										target: "EVIDENCE_INTRO",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										guard: ({ context }) =>
											context.q_negative_impact_list !== null,
										target: "EASEMENT",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										guard: ({ context }) => context.q_flooded === 1,
										target: "FLOODING",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										guard: ({ context }) =>
											context.q_structural_issues_list !== null,
										target: "FOUNDATION_ISSUES",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										guard: ({ context }) => context.q_roof_damage === 1,
										target: "ROOF_DAMAGE",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										guard: ({ context }) =>
											context.q_major_damage_list !== null,
										target: "MAJOR_DAMAGE",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										target: "CATCH_ALL",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						EASEMENT: {
							entry: [
								assign({
									progress: context => 65,
								}),
							],
							on: {
								back: [
									{
										target: "DAMAGE_AND_DEFERRED_MAINTENANCE_INTRO",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										guard: ({ context }) => context.q_flooded === 1,
										target: "FLOODING",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										guard: ({ context }) =>
											context.q_structural_issues_list !== null,
										target: "FOUNDATION_ISSUES",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										guard: ({ context }) => context.q_roof_damage === 1,
										target: "ROOF_DAMAGE",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										guard: ({ context }) =>
											context.q_major_damage_list !== null,
										target: "MAJOR_DAMAGE",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										target: "CATCH_ALL",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						FLOODING: {
							entry: [
								assign({
									progress: context => 70,
								}),
							],
							on: {
								back: [
									{
										guard: ({ context }) =>
											context.q_negative_impact_list !== null,
										target: "EASEMENT",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										target: "DAMAGE_AND_DEFERRED_MAINTENANCE_INTRO",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										guard: ({ context }) =>
											context.q_structural_issues_list !== null,
										target: "FOUNDATION_ISSUES",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										guard: ({ context }) => context.q_roof_damage === 1,
										target: "ROOF_DAMAGE",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										guard: ({ context }) =>
											context.q_major_damage_list !== null,
										target: "MAJOR_DAMAGE",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										target: "CATCH_ALL",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						FOUNDATION_ISSUES: {
							entry: [
								assign({
									progress: context => 75,
								}),
							],
							on: {
								back: [
									{
										guard: ({ context }) => context.q_flooded === 1,
										target: "FLOODING",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) =>
											context.q_negative_impact_list !== null,
										target: "EASEMENT",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										target: "DAMAGE_AND_DEFERRED_MAINTENANCE_INTRO",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										guard: ({ context }) => context.q_roof_damage === 1,
										target: "ROOF_DAMAGE",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										guard: ({ context }) =>
											context.q_major_damage_list !== null,
										target: "MAJOR_DAMAGE",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										target: "CATCH_ALL",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						ROOF_DAMAGE: {
							entry: [
								assign({
									progress: context => 80,
								}),
							],
							on: {
								back: [
									{
										guard: ({ context }) =>
											context.q_structural_issues_list !== null,
										target: "FOUNDATION_ISSUES",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) => context.q_flooded === 1,
										target: "FLOODING",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) =>
											context.q_negative_impact_list !== null,
										target: "EASEMENT",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										target: "DAMAGE_AND_DEFERRED_MAINTENANCE_INTRO",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										guard: ({ context }) =>
											context.q_major_damage_list !== null,
										target: "MAJOR_DAMAGE",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										target: "CATCH_ALL",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						MAJOR_DAMAGE: {
							entry: [
								assign({
									progress: context => 85,
								}),
							],
							on: {
								back: [
									{
										guard: ({ context }) => context.q_roof_damage === 1,
										target: "ROOF_DAMAGE",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) =>
											context.q_structural_issues_list !== null,
										target: "FOUNDATION_ISSUES",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) => context.q_flooded === 1,
										target: "FLOODING",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) =>
											context.q_negative_impact_list !== null,
										target: "EASEMENT",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										target: "DAMAGE_AND_DEFERRED_MAINTENANCE_INTRO",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										target: "CATCH_ALL",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						CATCH_ALL: {
							entry: [
								assign({
									progress: context => 90,
								}),
							],
							on: {
								back: [
									{
										guard: ({ context }) =>
											context.question_deferred === 1 &&
											context.q_major_damage_list !== null,
										target: "MAJOR_DAMAGE",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) =>
											context.question_deferred === 1 &&
											context.q_roof_damage === 1,
										target: "ROOF_DAMAGE",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) =>
											context.question_deferred === 1 &&
											context.q_structural_issues_list !== null,
										target: "FOUNDATION_ISSUES",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) =>
											context.question_deferred === 1 &&
											context.q_flooded === 1,
										target: "FLOODING",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) =>
											context.question_deferred === 1 &&
											context.q_negative_impact_list !== null,
										target: "EASEMENT",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) => context.question_deferred === 1,
										target: "DAMAGE_AND_DEFERRED_MAINTENANCE_INTRO",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) => context.question_income === 1,
										target: "INCOME_PRODUCING",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) =>
											context.question_purchased === 1 &&
											context.question_armslength === 1,
										target: "ARMS_LENGTH_TRANSACTION_DESCRIPTION",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										guard: ({ context }) => context.question_purchased === 1,
										target: "ARMS_LENGTH_TRANSACTION",
										actions: assign({
											direction: context => "backwards",
										}),
									},
									{
										target: "EVIDENCE_INTRO",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: {
									target: "#PROPERTY_SURVEY.QUALIFYING_QUESTIONS.DONE",
									actions: assign({
										direction: context => "forwards",
									}),
								},
							},
						},
					},
				},
				OUT_OF_SEASON: {
					initial: "INIT",
					states: {
						INIT: {
							always: [
								{
									target: "LAST",
									guard: ({ context }) => context.direction === "backwards",
								},
								{
									target: "RESTART",
									guard: ({ context }) =>
										context.customer_completed_survey === 1,
								},
								{
									target: "RESUME",
								},
							],
						},
						RESUME: {
							always: [
								{
									target: "PRIMARY_RESIDENCE",
									guard: ({ context }) =>
										!context.enterprise && context.q_primary_residence === null,
								},
								{
									target: "CURRENT_HOMESTEAD_STATUS",
									guard: ({ context }) =>
										!context.enterprise &&
										context.q_current_homestead_status === null &&
										!(
											context?.auth_company_type === "LLC" &&
											context?.state === "TX"
										),
								},
								{
									target: "#PROPERTY_SURVEY.QUALIFYING_QUESTIONS.DONE",
								},
							],
						},
						RESTART: {
							always: [
								{
									target: "PRIMARY_RESIDENCE",
									guard: ({ context }) => !context.enterprise,
								},
								{
									target: "CURRENT_HOMESTEAD_STATUS",
									guard: ({ context }) =>
										!context.enterprise &&
										!(
											context?.auth_company_type === "LLC" &&
											context?.state === "TX"
										),
								},
								{
									target: "#PROPERTY_SURVEY.QUALIFYING_QUESTIONS.DONE",
								},
							],
						},
						LAST: {
							always: [
								{
									target: "CURRENT_HOMESTEAD_STATUS",
									guard: ({ context }) =>
										!context.enterprise &&
										!(
											context?.auth_company_type === "LLC" &&
											context?.state === "TX"
										),
								},
								{
									target: "PRIMARY_RESIDENCE",
									guard: ({ context }) => !context.enterprise,
								},
								{
									target: "#PROPERTY_SURVEY.INTRO",
								},
							],
						},
						PRIMARY_RESIDENCE: {
							entry: [
								assign({
									progress: context => 20,
								}),
							],
							on: {
								back: [
									{
										target: "#PROPERTY_SURVEY.INTRO",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										guard: ({ context }) =>
											context.q_primary_residence === 1 &&
											!(
												context?.auth_company_type === "LLC" &&
												context?.state === "TX"
											),
										target: "CURRENT_HOMESTEAD_STATUS",
										actions: assign({
											direction: context => "forwards",
										}),
									},
									{
										target: "#PROPERTY_SURVEY.QUALIFYING_QUESTIONS.DONE",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
						CURRENT_HOMESTEAD_STATUS: {
							entry: [
								assign({
									progress: context => 25,
								}),
							],
							on: {
								back: [
									{
										target: "PRIMARY_RESIDENCE",
										actions: assign({
											direction: context => "backwards",
										}),
									},
								],
								next: [
									{
										target: "#PROPERTY_SURVEY.QUALIFYING_QUESTIONS.DONE",
										actions: assign({
											direction: context => "forwards",
										}),
									},
								],
							},
						},
					},
				},
				DONE: {
					type: "final",
				},
			},
			onDone: {
				target: "ADDITIONAL_SERVICES_QUESTIONS",
			},
		},
		ADDITIONAL_SERVICES_QUESTIONS: {
			initial: "INIT",
			states: {
				INIT: {
					always: [
						{
							target: "LAST",
							guard: ({ context }) => context.direction === "backwards",
						},
						{
							target: "CONCIERGE_OPTIONS",
							guard: ({ context }) =>
								context.account_tier == null ||
								context.account_tier == "essential",
						},
						{
							target: "CONCIERGE_CONFIRMATION",
							guard: ({ context }) =>
								context.account_tier != null &&
								context.account_tier.includes("concierge") &&
								context.current_account_tier != context.account_tier,
						},
						{
							target: "INTERNET_REDUCTION",
							guard: ({ context }) =>
								!!context.show_internet_reduction_question,
						},
						{
							target: "INSURANCE_MONITORING",
							guard: ({ context }) =>
								!!context.show_insurance_monitoring_question,
						},
						{
							target: "MORTGAGE_AND_EQUITY",
							guard: ({ context }) =>
								!!context.show_mortgage_and_equity_question,
						},
						{
							target: "DONE",
						},
					],
				},
				LAST: {
					always: [
						{
							target: "MORTGAGE_AND_EQUITY",
							guard: ({ context }) =>
								!!context.show_mortgage_and_equity_question,
						},
						{
							target: "INSURANCE_MONITORING",
							guard: ({ context }) =>
								!!context.show_insurance_monitoring_question,
						},
						{
							target: "INTERNET_REDUCTION",
							guard: ({ context }) =>
								!!context.show_internet_reduction_question,
						},
						{
							target: "CONCIERGE_CONFIRMATION",
							guard: ({ context }) =>
								context.account_tier != null &&
								context.account_tier.includes("concierge") &&
								context.current_account_tier != context.account_tier,
						},
						{
							target: "CONCIERGE_OPTIONS",
							guard: ({ context }) =>
								context.account_tier == null ||
								context.account_tier == "essential",
						},
						{
							target: "#PROPERTY_SURVEY.QUALIFYING_QUESTIONS",
						},
					],
				},
				CONCIERGE_OPTIONS: {
					entry: [
						assign({
							progress: context => 91,
						}),
					],
					on: {
						back: [
							{
								target: "#PROPERTY_SURVEY.QUALIFYING_QUESTIONS",
								actions: assign({
									direction: context => "backwards",
								}),
							},
						],
						next: [
							{
								target: "CONCIERGE_CONFIRMATION",
								guard: ({ context }) =>
									context.account_tier != null &&
									context.account_tier.includes("concierge") &&
									context.current_account_tier != context.account_tier,
								actions: assign({
									direction: context => "forwards",
								}),
							},
							{
								target: "INTERNET_REDUCTION",
								guard: ({ context }) =>
									!!context.show_internet_reduction_question,
								actions: assign({
									direction: context => "forwards",
								}),
							},
							{
								target: "INSURANCE_MONITORING",
								guard: ({ context }) =>
									!!context.show_insurance_monitoring_question,
								actions: assign({
									direction: context => "forwards",
								}),
							},
							{
								target: "MORTGAGE_AND_EQUITY",
								guard: ({ context }) =>
									!!context.show_mortgage_and_equity_question,
								actions: assign({
									direction: context => "forwards",
								}),
							},
							{
								target: "#PROPERTY_SURVEY.WILDCARD",
								actions: assign({
									direction: context => "forwards",
								}),
							},
						],
					},
				},
				CONCIERGE_CONFIRMATION: {
					entry: [
						assign({
							progress: context => 91,
						}),
					],
					on: {
						back: [
							{
								target: "#PROPERTY_SURVEY.QUALIFYING_QUESTIONS",
								actions: assign({
									direction: context => "backwards",
								}),
							},
						],
						next: [
							{
								target: "INTERNET_REDUCTION",
								guard: ({ context }) =>
									!!context.show_internet_reduction_question,
								actions: assign({
									direction: context => "forwards",
								}),
							},
							{
								target: "INSURANCE_MONITORING",
								guard: ({ context }) =>
									!!context.show_insurance_monitoring_question,
								actions: assign({
									direction: context => "forwards",
								}),
							},
							{
								target: "MORTGAGE_AND_EQUITY",
								guard: ({ context }) =>
									!!context.show_mortgage_and_equity_question,
								actions: assign({
									direction: context => "forwards",
								}),
							},
							{
								target: "#PROPERTY_SURVEY.WILDCARD",
								actions: assign({
									direction: context => "forwards",
								}),
							},
						],
					},
				},
				INTERNET_REDUCTION: {
					entry: [
						assign({
							progress: context => 91,
						}),
					],
					on: {
						back: [
							{
								target: "CONCIERGE_CONFIRMATION",
								guard: ({ context }) =>
									context.account_tier != null &&
									context.account_tier.includes("concierge") &&
									context.current_account_tier != context.account_tier,
								actions: assign({
									direction: context => "backwards",
								}),
							},
							{
								target: "CONCIERGE_OPTIONS",
								guard: ({ context }) =>
									context.account_tier == null ||
									context.account_tier == "essential",
								actions: assign({
									direction: context => "backwards",
								}),
							},
						],
						next: [
							{
								target: "INSURANCE_MONITORING",
								guard: ({ context }) =>
									!!context.show_insurance_monitoring_question,
								actions: assign({
									direction: context => "forwards",
								}),
							},
							{
								target: "MORTGAGE_AND_EQUITY",
								guard: ({ context }) =>
									!!context.show_mortgage_and_equity_question,
								actions: assign({
									direction: context => "forwards",
								}),
							},
							// {
							// 	target: "#PROPERTY_SURVEY.HOW_DID_YOU_HEAR_ABOUT_US",
							// 	actions: assign({
							// 		direction: context => "forwards",
							// 	}),
							// },
							{
								target: "#PROPERTY_SURVEY.WILDCARD",
								actions: assign({
									direction: context => "forwards",
								}),
							},
						],
					},
				},
				INSURANCE_MONITORING: {
					entry: [
						assign({
							progress: context => 92,
						}),
					],
					on: {
						back: [
							{
								target: "INTERNET_REDUCTION",
								guard: ({ context }) =>
									!!context.show_internet_reduction_question,
								actions: assign({
									direction: context => "backwards",
								}),
							},
							{
								target: "#PROPERTY_SURVEY.QUALIFYING_QUESTIONS",
								actions: assign({
									direction: context => "backwards",
								}),
							},
						],
						next: [
							{
								target: "MORTGAGE_AND_EQUITY",
								guard: ({ context }) =>
									!!context.show_mortgage_and_equity_question,
								actions: assign({
									direction: context => "forwards",
								}),
							},
							// {
							// 	target: "#PROPERTY_SURVEY.HOW_DID_YOU_HEAR_ABOUT_US",
							// 	actions: assign({
							// 		direction: context => "forwards",
							// 	}),
							// },
							{
								target: "#PROPERTY_SURVEY.WILDCARD",
								actions: assign({
									direction: context => "forwards",
								}),
							},
						],
					},
				},
				MORTGAGE_AND_EQUITY: {
					entry: [
						assign({
							progress: context => 93,
						}),
					],
					on: {
						back: [
							{
								target: "INSURANCE_MONITORING",
								guard: ({ context }) =>
									!!context.show_insurance_monitoring_question,
								actions: assign({
									direction: context => "backwards",
								}),
							},
							{
								target: "INTERNET_REDUCTION",
								guard: ({ context }) =>
									!!context.show_internet_reduction_question,
								actions: assign({
									direction: context => "backwards",
								}),
							},
							{
								target: "#PROPERTY_SURVEY.QUALIFYING_QUESTIONS",
								actions: assign({
									direction: context => "backwards",
								}),
							},
						],
						next: [
							{
								target: "DONE",
								actions: assign({
									direction: context => "forwards",
								}),
							},
						],
					},
				},
				DONE: {
					type: "final",
				},
			},
			// onDone: {
			// 	target: "#PROPERTY_SURVEY.HOW_DID_YOU_HEAR_ABOUT_US",
			// },
			onDone: {
				target: "#PROPERTY_SURVEY.WILDCARD",
			},
		},
		// HOW_DID_YOU_HEAR_ABOUT_US: {
		// 	entry: [
		// 		assign({
		// 			progress: context => 95,
		// 		}),
		// 	],
		// 	on: {
		// 		back: [
		// 			{
		// 				target: "ADDITIONAL_SERVICES_QUESTIONS",
		// 				actions: assign({
		// 					direction: context => "backwards",
		// 				}),
		// 			},
		// 		],
		// 		next: [
		// 			{
		// 				target: "WILDCARD",
		// 				actions: assign({
		// 					direction: context => "forwards",
		// 				}),
		// 			},
		// 		],
		// 	},
		// },
		WILDCARD: {
			entry: [
				assign({
					progress: context => 98,
				}),
			],
			on: {
				back: [
					// {
					// 	target: "HOW_DID_YOU_HEAR_ABOUT_US",
					// 	cond: context => context.has_answered_heardabout === 0,
					// 	actions: assign({
					// 		direction: context => "backwards",
					// 	}),
					// },
					{
						target: "ADDITIONAL_SERVICES_QUESTIONS",
						actions: assign({
							direction: context => "backwards",
						}),
					},
					{
						target: "QUALIFYING_QUESTIONS",
						actions: assign({
							direction: context => "backwards",
						}),
					},
				],
				next: [
					{
						target: "CONCIERGE_PAYMENT",
						guard: ({ context }) =>
							context.account_tier != null &&
							context.account_tier.includes("concierge") &&
							!context.active_subscription.some(subscription =>
								subscription.product.includes("concierge")
							),
						actions: assign({
							direction: context => "forwards",
						}),
					},
					{
						target: "CONFIRMATION",
						actions: assign({
							direction: context => "forwards",
						}),
					},
				],
			},
		},
		CONCIERGE_PAYMENT: {
			entry: [
				assign({
					progress: context => 100,
				}),
			],
			on: {
				back: [
					{
						target: "WILDCARD",
						actions: assign({
							direction: context => "backwards",
						}),
					},
				],
				next: [
					{
						target: "CONFIRMATION",
						actions: assign({
							direction: context => "forwards",
						}),
					},
				],
			},
		},
		CONFIRMATION: {
			entry: [
				assign({
					progress: context => 100,
				}),
			],
			on: {
				back: [
					{
						target: "WILDCARD",
						actions: assign({
							direction: context => "backwards",
						}),
					},
				],
				next: [
					{
						target: "INTRO",
						actions: assign({
							direction: context => "forwards",
						}),
					},
				],
			},
		},
	},
	on: {
		update_context: {
			actions: "updateContext",
		},
		init: {
			target: ".INIT",
		},
		restart: {
			target: ".INTRO",
		},
	},
});
