import { TextField } from "components";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import client from "utils/client";
import {
	PropertySurveyPhotoUploadPrompt,
	usePropertyContext,
} from "../../context";
import QuestionCard from "components/elements/QuestionCard";

import formatDollar, { formatDollarStr } from "utils/formatDollar";
import formatNumberWithCommas from "utils/formatNumberWithCommas";
import TextArea from "elements/Input/TextArea";
import formatDate from "utils/formatDate";
import { isValidDate } from "utils/validDate";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import { FileData } from "utils/types";
import {
	splitFileUploads,
	type FileUploadType,
} from "elements/Input/FileUpload";
import ButtonV2 from "elements/ButtonV2";

const Catchall = () => {
	const {
		details,
		activeQuestionId,
		send,
		next,
		propertySurveyContext,
		allUploadedPropertySurveyFiles,
	} = usePropertyContext();

	const [filesToUpload, setFilesToUpload] = useState<FileUploadType[]>([]);
	const [catchallCost, setCatchallCost] = useState(
		propertySurveyContext?.q_catchall_cost
			? formatDollar(propertySurveyContext?.q_catchall_cost)
			: ""
	);
	const [catchallDescription, setCatchallDescription] = useState(
		propertySurveyContext?.q_catchall_desc || ""
	);
	const [catchallDate, setCatchallDate] = useState(
		propertySurveyContext?.q_catchall_date || ""
	);
	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const questionFiles = useMemo(() => {
		return allUploadedPropertySurveyFiles.filter(fileName =>
			fileName.startsWith("catchAll")
		);
	}, []); // We only want to derive this value on mount

	const filesUploaded = useMemo(() => {
		return questionFiles.length > 0 || filesToUpload.length > 0;
	}, [questionFiles, filesToUpload]);

	const handleSubmit = async () => {
		send({
			type: "update_context",
			context: {
				q_catchall: 1,
				q_catchall_cost: Number(catchallCost.replace(/\D/g, "").trim()),
				q_catchall_desc: catchallDescription,
				q_catchall_date: catchallDate,
			},
		});

		try {
			setSubmitting(true);
			setError(false);

			await client.submitQuestionAnswer({
				questionId: activeQuestionId,
				appealId: details!.appeal_id,
				payload: {
					value: true,
					desc: catchallDescription || undefined,
					date: catchallDate || undefined,
					cost: catchallCost.replace(/\D/g, "").trim() || undefined,
				},
			});

			if (filesToUpload.length) {
				const { errors, success } = await splitFileUploads({
					filesToUpload,
					updateFilesToUpload: setFilesToUpload,
					buildRequest: async fileToUpload => {
						const fd = new FormData();
						fd.append("catchAll", fileToUpload.file);
						await client.uploadFile({
							form: fd,
							appealId: details!.appeal_id.toString(),
							onProgress: pe => {
								setFilesToUpload(prev =>
									prev.map(fu => {
										if (fu.file !== fileToUpload.file) return fu;
										return {
											...fu,
											progress: pe,
										};
									})
								);
							},
						});
					},
				});

				send({
					type: "update_context",
					context: {
						files: [
							...propertySurveyContext.files,
							...success.map(fileToUpload => {
								const fileData: FileData = {
									file_name: "catchAll-" + fileToUpload.file.name,
									question: "catchAll",
									is_ownwell_document: 0,
								};
								return fileData;
							}),
						],
					},
				});

				if (errors.length > 0) {
					setError(true);
					return;
				}
			}

			next();
		} catch (e) {
			setError(true);
			console.error(e);
		} finally {
			setSubmitting(false);
		}
	};

	const btnDisabled = useMemo(() => {
		const dateIsValid = catchallDate.length === 0 || catchallDate.length === 10;
		return submitting || !catchallDescription || !catchallCost || !dateIsValid;
	}, [submitting, catchallDescription, catchallCost, catchallDate]);

	const handleSkip = () => {
		next(true);
	};

	const lastYear = useMemo(
		() => (details?.tax_year ?? new Date().getFullYear()) - 1,
		[details]
	);

	const costUnder5000 = useMemo(() => {
		return (
			catchallCost !== "" && Number(catchallCost.replace(/\D/g, "")) < 5000
		);
	}, [catchallCost]);

	const showDocumentsRecommendedWarning = useMemo(() => {
		if (
			(catchallDescription !== "" ||
				catchallCost !== "" ||
				catchallDate !== "") &&
			!filesUploaded
		) {
			return true;
		}
		return false;
	}, [catchallDescription, catchallDate, catchallCost, filesUploaded]);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				e.stopPropagation();
				handleSubmit();
			}}>
			<QuestionCard
				id="roof-replacement"
				tooltipText=""
				withFiles
				filesLabel="Quotes, Invoices, and/or Photos showing proof of issues"
				filesToUpload={filesToUpload}
				setFilesToUpload={setFilesToUpload}
				alreadyUploaded={questionFiles}
				question={
					<p className="lg bold">
						Is there anything else about your property you’d like to share?
					</p>
				}>
				<div className="property-survey-question-catchall-warning-box denim-medium">
					<ul>
						{Number(details?.sqft_portal_resi_imprv) > 0 && (
							<li className="sm denim-medium">
								If the square footage of your structure is less than{" "}
								<span className="no-translate">
									{formatNumberWithCommas(
										Math.floor(Number(details?.sqft_portal_resi_imprv) * 0.95)
									)}
								</span>{" "}
								sqft, please upload supporting documentation including builders
								sketch, appraisal, or property survey.
							</li>
						)}
						<li className="sm denim-medium">
							Damage and deferred maintenance that would cost less than $5,000
							to fix rarely assist in a reduction to the assessed value of a
							property.
						</li>
						<li className="sm denim-medium">
							Documentation showing the dollar value of repairs is needed to
							support any description provided.
						</li>
					</ul>
				</div>
				<div className="property-survey-question-section-seperator" />
				<p className="bold">Describe the issues.*</p>
				<p className="sm denim-medium mb-1">
					Describe the issues including details on location and impact it has
					had on your property.
				</p>
				<TextArea
					className="mb-2 w-100"
					value={catchallDescription}
					onChange={e => setCatchallDescription(e.target.value)}
					rows={4}
					required
					placeholder="Description of issues"
				/>
				<p className="bold">What is the estimated impact to property value?*</p>
				<p className="sm denim-medium mb-1">
					Upload repair estimates or insurance claims as support for this value.
					Values under $5,000 are unlikely to have an impact on the assessment
					value.
				</p>
				<TextField
					className={costUnder5000 ? "input-warning" : ""}
					numeric
					required
					placeholder="$"
					value={catchallCost}
					onChange={e => setCatchallCost(formatDollarStr(e.target.value) ?? "")}
				/>
				{costUnder5000 && (
					<p className="sm terracotta">
						Values less than {formatDollarStr(5000)}, are rarely helpful in
						getting an assessment reduction.
					</p>
				)}
				<p className="bold mt-2">Date of photos</p>
				<p className="sm denim-medium mb-1">
					{PropertySurveyPhotoUploadPrompt}
				</p>
				<TextField
					maxLength={10}
					minLength={10}
					placeholder="MM/DD/YYYY"
					value={catchallDate || ""}
					onChange={e => {
						const formatted = formatDate(e, catchallDate || "");
						const valid = isValidDate(formatted);

						if (valid) {
							setCatchallDate(formatted);
						}
					}}
				/>
				<div className="property-survey-question-section-seperator" />
			</QuestionCard>
			{showDocumentsRecommendedWarning && (
				<div className="property-profile-question-note-rust">
					<p className="sm">
						We recommend uploading supporting documentation before continuing.
						Evidence submitted without documentation rarely results in a
						reduction.
					</p>
				</div>
			)}
			<ButtonV2
				size="large"
				disabled={btnDisabled}
				type="submit"
				className="mx-auto">
				{submitting ? "Submitting..." : "Continue"}
			</ButtonV2>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
			<p className="profile-question-skip" onClick={() => handleSkip()}>
				Skip <RightArrow />
			</p>
		</form>
	);
};

export default Catchall;
