import ButtonV2 from "elements/ButtonV2";
import CircleExclaim from "public/account/survey/concierge/circle-exclaim.svg";

const NoConciergeOptionCard = ({
	contingencyPercentage,
	onOptionSelect,
}: {
	contingencyPercentage: number;
	onOptionSelect: () => void;
}) => {
	return (
		<div className="concierge-options-card basic-option">
			<div className="card-header">
				<p className="card-heading">Basic</p>
			</div>
			<div className="pricing">
				<p className="strikeout-price"> </p>
				<p className="price h5">Included</p>
				<div className="percent-contingency">
					<p className="tiny">{contingencyPercentage}% contingency</p>
					<CircleExclaim />
				</div>
				<p className="billing-cycle"></p>
			</div>
			<div className="cta">
				<ButtonV2
					variant="secondary"
					size="extra-small"
					className="w-100"
					onClick={onOptionSelect}>
					Stay With Basic Plan
				</ButtonV2>
			</div>
			<div className="info">
				<p className="description xs">
					For individuals who only want tax appeal representation and track
					their taxes online.
				</p>
			</div>
			<div className="features">
				<p className="feature-heading">What's Included?</p>
				<div className="feature-list">
					<p className="list-item-label">
						<span className="green-dot" />
						<span>Property Tax Dashboard</span>
					</p>
					<p className="list-item-label">
						<span className="green-dot" />
						<span>Tax Appeal Support</span>
					</p>
					<p className="list-item-label">
						<span className="green-dot" />
						<span>Exemption filling</span>
					</p>
				</div>
			</div>
		</div>
	);
};

export default NoConciergeOptionCard;
