import toTitleCase from "./toTitleCase";

const replaceDescriptors = (address: string): string => {
	address = address.replace(/[.,]/g, "");

	let replaceWords: Record<string, string> = {
		apartment: "#",
		apt: "#",
		expressway: "Expy",
		"po box": "#",
		suite: "#",
		ste: "#",
		avenue: "Ave",
		boulevard: "Blvd",
		circle: "Cir",
		court: "Ct",
		crt: "Ct",
		drive: "Dr",
		lane: "Ln",
		mount: "Mt",
		highway: "Hwy",
		parkway: "Pkwy",
		place: "Pl",
		street: "St",
		east: "E",
		west: "W",
		south: "S",
		north: "N",
		road: "Rd",
		Nd: "nd",
		Ne: "NE",
		Nw: "NW",
		Se: "SE",
		Sw: "SW",
	};

	address = address
		.split(" ")
		.map(word => {
			if (replaceWords[word]) {
				return replaceWords[word];
			}
			return word;
		})
		.join(" ")
		.replace(/\# /g, "#");

	address = address.replace(".", "").replace(",", "");

	return address;
};

const cleanAddress = (address: string) => {
	address = toTitleCase(address);

	address = replaceDescriptors(address);

	return address;
};

export default cleanAddress;
