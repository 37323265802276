import React, { Dispatch, SetStateAction, useState } from "react";
import { useAuthContext } from "context/AuthContext";
import { PropertyEquity } from "utils/types";
import { usePropertyContext } from "./context";
import { usePropertyValueContext } from "./PropertyValueChart";
import { useEventTracker } from "utils/useEventTracker";
import client from "utils/client";
import constants from "utils/constants";
import moment from "moment";

const defaultPropertyEquityData: PropertyEquity.Response = {
	mortgage_last_synced_at: null,
	mortgage_last_updated_at: null,
	last_sale_date_prop: null,
	last_sale_price_prop: null,
	principal_paid: null,
	principal_remaining: null,
	interest_paid: null,
	interest_remaining: null,
	mortgage_loan_type: null,
	mortgage_financing_type: null,
	mortgage_amount: null,
	mortgage_down_payment: null,
	mortgage_interest_rate: null,
	mortgage_term: null,
	debt: null,
	equity: null,
	mortgage_paid_off: null,
	mortgage_refinance_date: null,
	mortgage_extra_monthly_payment: null,
	next_payment: null,
	amortization_schedule: [],
};

const mockResponse = () => {
	const response: PropertyEquity.Response = defaultPropertyEquityData;
	return response;
};

const mockRequest = ({ noData }: { noData?: boolean }) =>
	new Promise<{ data: PropertyEquity.Response }>(resolve =>
		resolve(
			noData ? { data: defaultPropertyEquityData } : { data: mockResponse() }
		)
	);

export interface IPropertyEquityContext extends PropertyEquity.Response {
	refreshPropertyEquityDetails: () => Promise<void>;
	shouldShowStartTrackingIntro: boolean;
	dataIncomplete: boolean;
	loading: boolean;
	error: boolean;
	userCameFrom: "ways-to-save" | "equity-card" | undefined;
	setUserCameFrom: Dispatch<
		SetStateAction<"ways-to-save" | "equity-card" | undefined>
	>;
}

const PropertyEquityContext = React.createContext<IPropertyEquityContext>({
	...defaultPropertyEquityData,
	refreshPropertyEquityDetails: async () => {},
	shouldShowStartTrackingIntro: false,
	dataIncomplete: true,
	loading: false,
	error: false,
	userCameFrom: undefined,
	setUserCameFrom: () => {},
});

export const PropertyEquityContextProvider: React.FC = ({ children }) => {
	const { userData } = useAuthContext();
	const { details } = usePropertyContext();
	const { fetchPropertyValue } = usePropertyValueContext();
	const trackEvent = useEventTracker();

	const propertyId = details?.id;

	const [userCameFrom, setUserCameFrom] = useState<
		"ways-to-save" | "equity-card"
	>();

	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState(false);

	const [propertyEquity, setPropertyEquity] =
		React.useState<PropertyEquity.Response>(defaultPropertyEquityData);

	const fetchPropertyEquityDetails = async () => {
		if (!propertyId) return;
		try {
			setLoading(true);
			const { data } = await Promise.allSettled([
				// mockRequest({ noData: false }),
				client.getPropertyEquity({ propertyId }),
				new Promise(resolve => setTimeout(resolve, 800)),
			]).then(([result]) => {
				if (result.status === "rejected") throw result.reason;
				return result.value;
			});

			if (data.equity && data.equity < 0) {
				trackEvent({
					eventName: constants.EVENTS.Equity_Negative_Equity,
					data: {
						"Customer ID": userData?.id,
						"Sign Up Date": userData?.created_at,
						"Number of Properties": userData?.num_properties,
						"Property ID": details?.id,
						...data,
					},
				});
			}
			setPropertyEquity(data);
		} catch (err: any) {
			setError(true);
		} finally {
			setLoading(false);
		}
	};

	const refreshPropertyEquityDetails = React.useCallback(async () => {
		if (propertyId) {
			await Promise.allSettled([
				fetchPropertyValue(),
				fetchPropertyEquityDetails(),
			]);
		}
	}, [propertyId]);

	React.useEffect(() => {
		refreshPropertyEquityDetails();
	}, [propertyId]);

	const dataIncomplete = React.useMemo(() => {
		if (!!propertyEquity.mortgage_paid_off) return false;
		if (!!propertyEquity.mortgage_refinance_date) {
			return (
				!propertyEquity.last_sale_price_prop ||
				!propertyEquity.mortgage_amount ||
				!propertyEquity.mortgage_interest_rate ||
				!propertyEquity.mortgage_term
			);
		}
		return (
			!propertyEquity.last_sale_date_prop ||
			!moment(
				propertyEquity.last_sale_date_prop,
				"MM/DD/YYYY",
				true
			).isValid() ||
			!propertyEquity.last_sale_price_prop ||
			!propertyEquity.mortgage_amount ||
			!propertyEquity.mortgage_interest_rate ||
			!propertyEquity.mortgage_term
		);
	}, [propertyEquity]);

	const someEquityValuesCalculated = React.useMemo(() => {
		return [
			propertyEquity.debt,
			propertyEquity.equity,
			propertyEquity.principal_paid,
			propertyEquity.principal_remaining,
			propertyEquity.interest_paid,
			propertyEquity.interest_remaining,
		].some(value => value);
	}, [propertyEquity]);

	const shouldShowStartTrackingIntro = React.useMemo(() => {
		return !someEquityValuesCalculated;
	}, [someEquityValuesCalculated]);

	return (
		<PropertyEquityContext.Provider
			value={{
				loading,
				error,
				dataIncomplete,
				shouldShowStartTrackingIntro,
				refreshPropertyEquityDetails,
				userCameFrom,
				setUserCameFrom,
				...propertyEquity,
			}}>
			{children}
		</PropertyEquityContext.Provider>
	);
};

export const usePropertyEquityContext = () =>
	React.useContext(PropertyEquityContext);
