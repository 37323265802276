import { DetailedHTMLProps, FC, HTMLAttributes } from "react";

const PropertyGrid: FC<
	DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ children, ...divProps }) => {
	return (
		<div
			{...divProps}
			className={
				divProps.className
					? "property-grid " + divProps.className
					: "property-grid"
			}>
			{children}
		</div>
	);
};

export default PropertyGrid;
