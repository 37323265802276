import { createUrl } from "./client";

export const createSatelliteUrl = (args: {
	address: string;
	city: string;
	state: string;
	zip_code: string | null;
	streetView?: boolean;
}): URL => {
	const params = new URLSearchParams();

	params.append("address", args.address);
	params.append("city", args.city);
	params.append("state", args.state);
	params.append("zip_code", args.zip_code ?? "");
	if (args.streetView) {
		params.append("street_view", "true");
	}

	const url = createUrl(`/satellite?` + params.toString());

	return url;
};

export const createSatelliteUrlCss = (args: {
	address: string;
	city: string;
	state: string;
	zip_code: string | null;
	streetView?: boolean;
}): string => {
	const url = createSatelliteUrl(args);

	const imgPosSize = `no-repeat center/cover`;

	const css = `${imgPosSize} url(${url.href}), ${imgPosSize} url('/savings-map.jpg')`;

	return css;
};

export default createSatelliteUrlCss;
