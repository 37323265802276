import { usePropertyContext } from "components/pages/Account/Property/context";
import React, { useEffect, useState } from "react";
import client from "utils/client";
import { PropertyValue } from "utils/types";

import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	TooltipProps,
	ReferenceLine,
} from "recharts";
import { formatNumberForAxis } from "utils/chart/formatNumberForAxis";
import { computeYAxisWidth } from "utils/chart/computeYAxisWidth";
import { formatTicks } from "utils/chart/formatTicks";
import { findDomainMax } from "utils/chart/findDomainRange";
import formatDollar from "utils/formatDollar";
import moment from "moment-timezone";
import Router from "next/router";

import ArrowIncreaseIcon from "public/arrow-increase.svg";
import ArrowDecreaseIcon from "public/arrow-decrease.svg";
import { NoDataAvailable } from "../shared/NoDataAvailable";
import { Toggle } from "elements/Toggle";
import QuickLink from "elements/QuickLink";
import formatNumberWithCommas from "utils/formatNumberWithCommas";
import { useOnboardingStyles } from "utils/account/useOnboardingStyles";
import { fitDomainToCeiling } from "utils/chart/fitDomainToCeiling";

// add timestamp
// add dollar change
// sort by date
const mockResponse = () => {
	const response: PropertyValue.Response = {
		value_change_since_purchase: 500000,
		market_value_history: [
			{
				date: "2023-09-01 00:00:00",
				is_purchase_date: false,
				market_value: "720000.00",
			},
			{
				date: "2022-06-01 00:00:00",
				is_purchase_date: false,
				market_value: "740000.00",
			},
			{
				date: "2021-08-01 00:00:00",
				is_purchase_date: false,
				market_value: "700000.00",
			},
			{
				date: "2020-03-01 00:00:00",
				is_purchase_date: false,
				market_value: "660000.00",
			},
			{
				date: "2019-09-01 00:00:00",
				is_purchase_date: true,
				market_value: "660000.00",
			},
			{
				date: "2019-01-01 00:00:00",
				is_purchase_date: false,
				market_value: "670000.00",
			},
		]
			.sort((a: any, b: any) =>
				moment.utc(a.date).isAfter(moment.utc(b.date)) ? 1 : -1
			)
			.reduce((acc, curr, idx) => {
				let valueChange = null;
				const purchaseValue = "660000.00";
				const purchaseDate = "2019-09-01 00:00:00";
				if (moment.utc(curr.date).isAfter(purchaseDate)) {
					valueChange =
						parseFloat(curr.market_value) - parseFloat(purchaseValue);
				}
				acc.push({
					...curr,
					timestamp: moment.utc(curr.date, "YYYY-MM-DD HH:mm:ss").valueOf(),
					value_change: valueChange,
				});

				return acc;
			}, [] as PropertyValue.MarketValuePrice[]),
	};

	return response;
};

const mockRequest = ({ noData }: { noData?: boolean }) =>
	new Promise<{ data: PropertyValue.Response }>(resolve =>
		resolve(
			noData
				? {
						data: {
							market_value_history: [],
							value_change_since_purchase: null,
						},
					}
				: { data: mockResponse() }
		)
	);

const toggleOptions = [
	{ label: "Since Purchase", value: "since-purchase" },
	{ label: "Year-To-Date", value: "ytd" },
	{ label: "All Time", value: "all-time" },
];

interface IPropertyValueContext {
	fetchPropertyValue: () => void;
	marketValueHistory: PropertyValue.MarketValuePrice[] | null;
	valueChangeSincePurchase: number | null;
	currentMarketValue: number | null;
	purchaseInfo: PropertyValue.MarketValuePrice | undefined;
	marketValueSincePurchase: PropertyValue.MarketValuePrice[] | null;
	notEnoughSincePurchase: boolean;
	roiSincePurchase: number | null;
	mostRecentMarketValuePrice: PropertyValue.MarketValuePrice | null;
	noData: boolean;
	chartConfig: () => React.ReactNode;
	chartData: ChartData;
	view: string;
	setView: (view: string) => void;
	timeDomainEnd: number;
	timeDomainStart: number;
	purchaseTimeStamp: number | undefined;
	priceDomainEnd: number;
	priceDomainStart: number;
	hidden: boolean;
	loading: boolean;
	initialized: boolean;
	error: boolean;
}

const PropertyValueContext = React.createContext<IPropertyValueContext>({
	fetchPropertyValue: () => {},
	marketValueHistory: null,
	valueChangeSincePurchase: null,
	currentMarketValue: null,
	purchaseInfo: undefined,
	marketValueSincePurchase: null,
	notEnoughSincePurchase: false,
	roiSincePurchase: null,
	mostRecentMarketValuePrice: null,
	noData: false,
	chartConfig: () => null,
	chartData: [],
	view: "since-purchase",
	setView: () => {},
	timeDomainEnd: 0,
	timeDomainStart: 0,
	purchaseTimeStamp: undefined,
	priceDomainEnd: 0,
	priceDomainStart: 0,
	hidden: true,
	loading: false,
	initialized: false,
	error: false,
});

export const PropertyValueContextProvider: React.FC = ({ children }) => {
	const { details } = usePropertyContext();

	const propertyId = details?.id;

	const [loading, setLoading] = React.useState(true);
	const [initialized, setInitialized] = React.useState(false);
	const [error, setError] = React.useState(false);

	const [marketValueHistory, setMarketValueHistory] = React.useState<
		PropertyValue.MarketValuePrice[] | null
	>(null);

	const [valueChangeSincePurchase, setValueChangeSincePurchase] =
		React.useState<number | null>(null);

	const fetchPropertyValue = async () => {
		if (!propertyId) return;
		try {
			setLoading(true);
			const {
				data: { market_value_history, value_change_since_purchase },
			} = await Promise.allSettled([
				// mockRequest({ noData: true }),
				client.getPropertyMarketValueHistory({ propertyId }),
				new Promise(resolve => setTimeout(resolve, 800)),
			]).then(([result]) => {
				if (result.status === "rejected") throw result.reason;
				return result.value;
			});

			setMarketValueHistory(market_value_history);
			setValueChangeSincePurchase(value_change_since_purchase);
		} catch (err: any) {
			setError(true);
		} finally {
			setLoading(false);
			setInitialized(true);
		}
	};

	React.useEffect(() => {
		fetchPropertyValue();
	}, [propertyId]);

	const currentMarketValue = React.useMemo(() => {
		if (!marketValueHistory || marketValueHistory.length === 0) return null;

		const latest = [...marketValueHistory].pop();
		if (Number(latest?.market_value) > 0) return Number(latest?.market_value);

		return null;
	}, [marketValueHistory]);

	const purchaseInfo = React.useMemo(() => {
		return marketValueHistory?.find(v => v.is_purchase_date && v.market_value);
	}, [marketValueHistory]);

	const marketValueSincePurchase = React.useMemo(() => {
		if (!marketValueHistory || !purchaseInfo?.date) return null;
		return marketValueHistory.filter(v =>
			moment(v.date).isSameOrAfter(purchaseInfo?.date)
		);
	}, [marketValueHistory]);

	const marketValueYearToDate = React.useMemo(() => {
		if (!marketValueHistory) return null;
		const startOfYear = moment().startOf("year");
		return marketValueHistory.filter(v =>
			moment(v.date).isSameOrAfter(startOfYear)
		);
	}, [marketValueHistory]);

	const notEnoughSincePurchase = React.useMemo(() => {
		if (!marketValueSincePurchase) return false;
		return marketValueSincePurchase.length < 3;
	}, [marketValueSincePurchase]);

	const roiSincePurchase = React.useMemo(() => {
		if (!valueChangeSincePurchase || !details?.last_sale_price_prop) {
			return null;
		}

		if (
			isNaN(Number(details?.last_sale_price_prop)) ||
			Number(details?.last_sale_price_prop) === 0
		) {
			return null;
		}

		return (
			(valueChangeSincePurchase / Number(details?.last_sale_price_prop)) * 100
		);
	}, [valueChangeSincePurchase, details?.last_sale_price_prop]);

	const [view, setView] = React.useState<string>("since-purchase");

	React.useEffect(() => {
		if (!loading && !purchaseInfo?.date) {
			setView("all-time");
		}
	}, [loading, purchaseInfo?.date]);

	const {
		noData,
		chartConfig,
		chartData,
		mostRecentMarketValuePrice,
		timeDomainEnd,
		timeDomainStart,
		purchaseTimeStamp,
		priceDomainEnd,
		priceDomainStart,
	} = React.useMemo(() => {
		const noData = !marketValueHistory || marketValueHistory.length < 4;

		let timeDomainStart: number;
		let timeDomainEnd: number;

		let priceDomainStart: number;
		let priceDomainEnd: number;

		let priceTicks: number[];

		let chartData: ChartData;
		let mostRecentMarketValuePrice: PropertyValue.MarketValuePrice | null =
			null;

		let purchaseTimeStamp: number | undefined;

		if (noData) {
			priceDomainStart = 1;
			priceDomainEnd = 4;

			const currentYear = moment.utc().year();
			chartData = Array(5)
				.fill({
					market_value: "0",
					is_purchase_date: false,
					value_change: null,
				})
				.map((data, i) => ({
					...data,
					date: moment.utc(currentYear - i),
					timestamp: moment
						.utc(currentYear - i, "YYYY-MM-DD HH:mm:ss")
						.valueOf(),
				}))
				.reverse();
		} else {
			if (
				!notEnoughSincePurchase &&
				view === "since-purchase" &&
				marketValueSincePurchase
			) {
				chartData = marketValueSincePurchase;
				priceDomainStart = 0;
				priceDomainEnd = findDomainMax(marketValueSincePurchase, [
					({ market_value }) => parseInt(market_value || "0"),
				]);
			} else if (
				!notEnoughSincePurchase &&
				view === "ytd" &&
				marketValueYearToDate
			) {
				chartData = marketValueYearToDate;
				priceDomainStart = 0;
				priceDomainEnd = findDomainMax(marketValueYearToDate, [
					({ market_value }) => parseInt(market_value || "0"),
				]);
			} else {
				chartData = marketValueHistory;
				priceDomainStart = 0;
				priceDomainEnd = findDomainMax(marketValueHistory, [
					({ market_value }) => parseInt(market_value || "0"),
				]);
			}

			priceDomainEnd = fitDomainToCeiling(priceDomainEnd);

			priceTicks = formatTicks({
				tickCount: 5,
				domainEnd: priceDomainEnd,
			});

			mostRecentMarketValuePrice =
				marketValueHistory[marketValueHistory.length - 1];

			purchaseTimeStamp = marketValueHistory.find(
				v => v.is_purchase_date
			)?.timestamp;
		}

		timeDomainStart = chartData[0].timestamp;
		timeDomainEnd = chartData[chartData.length - 1].timestamp;

		// It's important to return an array of elements and not a ReactNode
		// ... the components are imperative and will only function properly
		// ... once they are mounted under a valid recharts parent component
		const chartConfig = () => {
			if (noData) {
				return (
					<YAxis
						key="y-axis"
						tickFormatter={value => "$$$$"}
						tickLine={false}
						axisLine={false}
						tickCount={4}
						domain={[priceDomainStart, priceDomainEnd]}
						allowDataOverflow={true}
						scale="linear"
						type="number"
						width={64}
					/>
				);
			} else {
				return (
					<>
						<Tooltip key="tooltip" content={<AreaTooltip />} shared={false} />
						<YAxis
							key="y-axis"
							tickFormatter={value => formatNumberForAxis(value)}
							tickLine={false}
							ticks={priceTicks}
							axisLine={false}
							domain={[priceDomainStart, priceDomainEnd]}
							allowDataOverflow={true}
							scale="linear"
							// width={computeYAxisWidth(
							// 	priceTicks.map(t => formatNumberForAxis(t))
							// )}
							padding={{ top: 18, bottom: 12 }}
						/>
						{/* <ReferenceLine
								key="start-line"
								stroke="#1c7ce5"
								strokeWidth="2"
								strokeDasharray="5 5"
								x={timeDomainStart}
							/> */}
						<ReferenceLine
							key="purchase-line"
							stroke="#1c7ce5"
							strokeWidth="2"
							strokeDasharray="5 5"
							x={purchaseTimeStamp}
						/>
						{/* <ReferenceLine
								key="end-line"
								stroke="#1c7ce5"
								strokeWidth="2"
								strokeDasharray="5 5"
								x={timeDomainEnd}
							/> */}
					</>
				);
			}
		};

		return {
			noData,
			chartData,
			timeDomainStart,
			timeDomainEnd,
			purchaseTimeStamp,
			priceDomainStart,
			priceDomainEnd,
			chartConfig,
			mostRecentMarketValuePrice,
		};
	}, [
		marketValueHistory,
		marketValueSincePurchase,
		view,
		notEnoughSincePurchase,
	]);

	const hidden = React.useMemo(() => {
		return !initialized;
	}, [initialized]);

	return (
		<PropertyValueContext.Provider
			value={{
				initialized,
				loading,
				error,
				purchaseInfo,
				marketValueSincePurchase,
				notEnoughSincePurchase,
				roiSincePurchase,
				currentMarketValue,
				valueChangeSincePurchase,
				marketValueHistory,
				mostRecentMarketValuePrice,
				noData,
				chartConfig,
				chartData,
				view,
				setView,
				hidden,
				timeDomainEnd,
				timeDomainStart,
				purchaseTimeStamp,
				priceDomainEnd,
				priceDomainStart,
				fetchPropertyValue,
			}}>
			{children}
		</PropertyValueContext.Provider>
	);
};

export const usePropertyValueContext = () =>
	React.useContext(PropertyValueContext);

type AreaDataPoint = PropertyValue.MarketValuePrice & {
	timestamp: number;
};

type ChartData = Array<AreaDataPoint>;

export const AreaTooltip: React.FC<TooltipProps<any, any>> = ({
	active,
	payload,
}) => {
	if (active && payload && payload.length) {
		return (
			<div className="chart-tooltip property-value-chart-tooltip">
				<div>
					<label>
						{payload[0].payload.is_purchase_date ? "purchased " : ""}
						<span className="no-translate">
							{moment.utc(payload[0].payload.date).format("MMMM YYYY")}{" "}
						</span>
					</label>
					<p className="lg bold no-translate">
						{formatDollar(payload[0].payload.market_value)}
					</p>
				</div>
				{!payload[0].payload.value_change ? null : payload[0].payload
						.value_change > 0 ? (
					<p className="value-change sm bold azure no-translate">
						<ArrowIncreaseIcon />
						{formatDollar(payload[0].payload.value_change)}
					</p>
				) : (
					<p className="value-change sm bold rust no-translate">
						<ArrowDecreaseIcon />
						{formatDollar(Math.abs(payload[0].payload.value_change))}
					</p>
				)}
			</div>
		);
	}
	return null;
};

const ValueChangeDisplay = ({
	value,
	percentChange,
}: {
	value: number | null;
	percentChange: number | null;
}) => {
	if (!value) return <span>-</span>;

	const isPositive = value > 0;
	const Icon = isPositive ? ArrowIncreaseIcon : ArrowDecreaseIcon;
	const className = `box-value ${isPositive ? "kelly-dark" : "rust-dark"}`;

	return (
		<div className="value-change-display">
			<p className={className}>{formatDollar(value)}</p>
			{percentChange && (
				<div className={"percent-change-wrapper " + (isPositive ? "" : "rust")}>
					<p className="xs semibold nowrap no-translate">
						{percentChange.toFixed(2)}%
					</p>
					<Icon />
				</div>
			)}
		</div>
	);
};

const PropertyValueChart: React.FC<
	React.HTMLAttributes<HTMLDivElement> & { variant?: "full" | "widget" }
> = React.memo(({ variant = "full", ...props }) => {
	const [propertyValueChange, setPropertyValueChange] = useState<number | null>(
		null
	);
	const [propertyValuePercentChange, setPropertyValuePercentChange] = useState<
		number | null
	>(null);

	const { details } = usePropertyContext();
	const {
		marketValueHistory,
		valueChangeSincePurchase,
		loading,
		purchaseInfo,
		notEnoughSincePurchase,
		marketValueSincePurchase,
		roiSincePurchase,
		noData,
		mostRecentMarketValuePrice,
		view,
		setView,
		chartData,
		timeDomainEnd,
		timeDomainStart,
		purchaseTimeStamp,
		priceDomainEnd,
		priceDomainStart,
		chartConfig,
		hidden,
		currentMarketValue,
	} = usePropertyValueContext();

	const showViewOptions = React.useMemo(() => {
		return (
			variant === "full" &&
			!loading &&
			!noData &&
			!notEnoughSincePurchase &&
			!!purchaseInfo?.date
		);
	}, [
		variant,
		loading,
		noData,
		notEnoughSincePurchase,
		purchaseInfo?.date,
		marketValueHistory,
	]);

	useEffect(() => {
		if (view === "since-purchase") {
			setPropertyValueChange(valueChangeSincePurchase);
			if (valueChangeSincePurchase && currentMarketValue) {
				const percentChange =
					(valueChangeSincePurchase / currentMarketValue) * 100;
				setPropertyValuePercentChange(percentChange);
			}
		} else if (view === "ytd") {
			const firstYtdValue = getMarketValueForJanuaryOrFirstEntry();
			if (firstYtdValue) {
				const valueChange = currentMarketValue
					? currentMarketValue - parseFloat(firstYtdValue)
					: null;
				setPropertyValueChange(valueChange);
				if (valueChange && currentMarketValue) {
					const percentChange = (valueChange / currentMarketValue) * 100;
					setPropertyValuePercentChange(percentChange);
				}
			}
		} else if (view === "all-time") {
			const firstValue =
				marketValueHistory && marketValueHistory.length > 0
					? marketValueHistory[0].market_value
					: null;
			const valueChange =
				currentMarketValue && firstValue
					? currentMarketValue - parseFloat(firstValue)
					: null;
			setPropertyValueChange(valueChange);
			if (valueChange && currentMarketValue) {
				const percentChange = (valueChange / currentMarketValue) * 100;
				setPropertyValuePercentChange(percentChange);
			}
		} else {
			setPropertyValueChange(null);
		}
	}, [view]);

	const getMarketValueForJanuaryOrFirstEntry = () => {
		const thisYear = new Date().getFullYear();

		if (marketValueHistory && marketValueHistory.length > 0) {
			// Find the January entry for the current year
			const januaryEntry = marketValueHistory.find(entry => {
				const entryDate = new Date(entry.date);
				return (
					entryDate.getFullYear() === thisYear && entryDate.getMonth() === 0 // January is month 0
				);
			});

			// Return the market value from the January entry or the first available entry
			return januaryEntry
				? januaryEntry.market_value
				: marketValueHistory[0].market_value || null;
		} else {
			return null;
		}
	};

	const onboardingStyles = useOnboardingStyles();

	if (hidden) return null;

	return (
		<div
			{...props}
			style={onboardingStyles}
			className={`chart-card property-value-chart ${props.className || ""}`}>
			<div className="chart-card-top">
				<div className="chart-card-top-description">
					<div className="flex justify-between">
						{variant === "full" && (
							<p className="lg superbold">Property Value</p>
						)}
						{variant === "widget" && <p className="bold">Property Value</p>}
						{variant === "widget" &&
							!loading &&
							mostRecentMarketValuePrice?.market_value && (
								<p className="bold azure flex items-center no-translate">
									{formatDollar(
										parseFloat(mostRecentMarketValuePrice.market_value)
									)}
									{roiSincePurchase && Math.abs(roiSincePurchase) > 0 && (
										<span
											className={
												roiSincePurchase > 0
													? " roi roi-increase no-translate"
													: " roi roi-decrease no-translate"
											}>
											{roiSincePurchase > 0
												? formatNumberWithCommas(
														Math.abs(Math.floor(roiSincePurchase))
													)
												: formatNumberWithCommas(
														Math.abs(Math.ceil(roiSincePurchase))
													)}
											%{roiSincePurchase > 0 && <ArrowIncreaseIcon />}
											{roiSincePurchase < 0 && <ArrowDecreaseIcon />}
										</span>
									)}
								</p>
							)}
					</div>
					{variant === "full" && (
						<p className="body-tiny denim-medium">
							Track your property’s investment performance over time.
						</p>
					)}
					{/* {!loading && (
							<p className="body-tiny mt-1 denim-medium">
								Last updated:{" "}
								{moment(mostRecentMarketValuePrice?.date).format(
									"MMM. D, YYYY"
								)}
							</p>
						)} */}
				</div>
				{variant === "full" && (
					<div className="chart-card-top-badges">
						{loading && (
							<React.Fragment>
								<div className="chart-card-badge-skeleton">
									<p className="sm denim-medium">
										{moment.utc().year()} Market Value
									</p>
									<p className="lg bold">XXXXXX</p>
								</div>
								<div className="chart-card-badge-skeleton">
									<p className="sm denim-medium">Value Change</p>
									<p className="lg bold">$100,000</p>
								</div>
							</React.Fragment>
						)}
						{!loading && (
							<React.Fragment>
								<div className="box">
									<p className="box-label">
										{`${
											!!mostRecentMarketValuePrice
												? moment.utc(mostRecentMarketValuePrice.date).year()
												: moment.utc().year()
										} Market Value`}
									</p>
									<p className="box-value">
										{mostRecentMarketValuePrice?.market_value
											? formatDollar(
													parseFloat(mostRecentMarketValuePrice.market_value)
												)
											: "-"}
									</p>
								</div>
								<div className="box">
									<p className="box-label">Value Change</p>
									<ValueChangeDisplay
										value={propertyValueChange}
										percentChange={propertyValuePercentChange}
									/>
								</div>
							</React.Fragment>
						)}
					</div>
				)}
			</div>
			{showViewOptions && (
				<Toggle
					options={toggleOptions}
					selectedOption={view}
					onOptionChange={(option: string) => setView(option)}
				/>
			)}
			<div
				className={`chart property-value-chart ${props.className || ""}`}
				style={{ width: "100%", height: 360 }}>
				{!loading && noData && <NoDataAvailable />}
				<p className="y-axis-label denim-medium">Market Value</p>
				<p className="x-axis-label denim-medium">Year</p>
				<div
					style={{
						position: "absolute",
						left: 0,
						right: 0,
						bottom: 0,
						top: 0,
						paddingLeft: 20,
						paddingBottom: 16,
					}}>
					<ResponsiveContainer>
						<AreaChart
							key={view + purchaseInfo?.date + purchaseInfo?.market_value}
							data={chartData}
							className={noData ? "" : "has-data"}>
							<defs key="gradient-def">
								<linearGradient id="azure-gradient" x1="0" y1="0" x2="0" y2="1">
									<stop offset="5%" stopColor="#1c7ce5" stopOpacity={0.8} />
									<stop offset="95%" stopColor="#1c7ce5" stopOpacity={0} />
								</linearGradient>
							</defs>
							<CartesianGrid key="cartesian-grid" horizontal vertical={false} />
							<XAxis
								key="x-axis"
								dataKey="timestamp"
								scale="time"
								type="number"
								tickFormatter={value => {
									const date = moment.utc(value);
									if (value === purchaseTimeStamp) {
										return view === "since-purchase"
											? "'" + date.format("YY")
											: "Purchased";
									}
									if (view === "ytd") {
										return date.format("MMM");
									}
									if (date.month() === 0) {
										return "'" + date.format("YY");
									}
									return "";
								}}
								minTickGap={0}
								domain={[timeDomainStart, timeDomainEnd]}
								axisLine={false}
								tickLine={false}
								className="no-translate"
							/>
							<Area
								key="area"
								type="monotone"
								dataKey="market_value"
								stroke="#1c7ce5"
								strokeWidth={2}
								fillOpacity={1}
								fill="url(#azure-gradient)"
							/>

							{chartConfig()}
						</AreaChart>
					</ResponsiveContainer>
				</div>
			</div>
			{variant === "full" && (
				<p className="body-tiny denim_5 mt-1">
					Disclaimer: We use use a combination of multiple 3rd party sources to
					determine a more realistic market value of the property but it should
					not be used as a sell value. Seek an appraisal to get a more accurate
					sell value.
				</p>
			)}
			{variant === "widget" && (
				<QuickLink
					size="small"
					asButton
					className="ml-auto mt-1"
					onClick={() =>
						Router.push(`/account/properties/${details?.id}/mortgage`)
					}>
					View Details
				</QuickLink>
			)}
		</div>
	);
});

PropertyValueChart.displayName = "PropertyValueChart";

export default PropertyValueChart;
