import { DetailedHTMLProps, FC, TextareaHTMLAttributes } from "react";
import Label from "./Label";

export interface TextFieldProps
	extends DetailedHTMLProps<
		TextareaHTMLAttributes<HTMLTextAreaElement>,
		HTMLTextAreaElement
	> {
	label?: string;
	labelEl?: FC;
	labelBeside?: boolean;
	containerClassName?: string;
}

const TextArea: FC<TextFieldProps> = ({
	label,
	labelBeside = false,
	labelEl: UserLabelEl,
	containerClassName,
	required,
	...inputProps
}) => {
	if (label) {
		const LabelEl = UserLabelEl || Label;

		return (
			<div
				className={containerClassName}
				style={{
					display: "flex",
					flexDirection: labelBeside ? "row" : "column",
				}}>
				<LabelEl className="textarea-label" required={required}>
					{label}
				</LabelEl>
				<textarea
					{...inputProps}
					className={"with-label " + inputProps.className}
					required={required}
				/>
			</div>
		);
	} else {
		return <textarea {...inputProps} />;
	}
};

export default TextArea;
