import { FC, useCallback, useEffect, useMemo, useState } from "react";

export interface Question<T = {}> {
	id: string;
	component: FC<T>;
}

interface UseQuestionStateArgs<T> {
	questions: Question<T>[];
	answeredQuestionIds?: string[];
	forceInitialProgress?: number;
	finalQuestionWeight?: 1 | -1;
	showAnsweredQuestions?: boolean;
}

export function useQuestionState<T>(args: UseQuestionStateArgs<T>) {
	const {
		questions: allQuestions,
		answeredQuestionIds,
		forceInitialProgress,
		finalQuestionWeight,
		showAnsweredQuestions,
	} = args;

	const [activeQuestionId, setActiveQuestionId] = useState(
		allQuestions.find(({ id }) => !answeredQuestionIds?.includes(id))?.id ??
			allQuestions[0]?.id ??
			""
	);

	const [progress, setProgress] = useState(forceInitialProgress ?? 0);
	const [shouldReset, setShouldReset] = useState(false);
	const [didCalculateInitialQuestionID, setDidCalculateInitialQuestionID] =
		useState(false);

	const questionsToUse = useMemo(() => {
		if (
			answeredQuestionIds?.length &&
			answeredQuestionIds.length < allQuestions.length &&
			!showAnsweredQuestions
		) {
			return allQuestions.filter(({ id }) => !answeredQuestionIds.includes(id));
		} else {
			return allQuestions;
		}
	}, [answeredQuestionIds, allQuestions, showAnsweredQuestions]);

	useEffect(() => {
		const questionsToUseIds = questionsToUse.map(({ id }) => id);
		if (
			questionsToUse.length &&
			!questionsToUseIds.includes(activeQuestionId)
		) {
			setActiveQuestionId(questionsToUse[0].id);
		}
	}, [activeQuestionId, questionsToUse]);

	useEffect(() => {
		if (questionsToUse.length && !activeQuestionId) {
			setActiveQuestionId(questionsToUse[0].id);
		}
	}, [questionsToUse, activeQuestionId]);

	const progressIncrementAmount = useMemo(() => {
		const denominator = allQuestions.length - 1 - (finalQuestionWeight ?? 0);

		return allQuestions.length ? (1 / denominator) * 100 : 0;
	}, [allQuestions, finalQuestionWeight]);

	const setInitialProgress = useCallback(() => {
		if (!answeredQuestionIds?.length) return;
		const initialProgress =
			typeof forceInitialProgress === "number"
				? forceInitialProgress
				: answeredQuestionIds.length * progressIncrementAmount;

		setProgress(initialProgress);
	}, [
		forceInitialProgress,
		answeredQuestionIds?.length,
		progressIncrementAmount,
	]);

	useEffect(() => {
		setInitialProgress();
	}, [setInitialProgress]);

	const next = useCallback(() => {
		if (!questionsToUse.length) return;

		setProgress(p => {
			const newAmount = p + progressIncrementAmount;

			if (Math.round(newAmount) > 100) return p;

			return newAmount;
		});

		const activeQuestionIdIndex = questionsToUse.findIndex(
			({ id }) => id === activeQuestionId
		);

		const nextQuestionId = questionsToUse[activeQuestionIdIndex + 1]?.id;

		if (nextQuestionId) {
			setActiveQuestionId(nextQuestionId);
		}
	}, [progressIncrementAmount, activeQuestionId, questionsToUse]);

	const prev = useCallback(() => {
		if (!questionsToUse.length) return;

		setActiveQuestionId(questionId => {
			const questionIdIndex = questionsToUse.findIndex(
				({ id }) => id === questionId
			);

			const prevQuestionId = questionsToUse[questionIdIndex - 1]?.id;

			if (!prevQuestionId) {
				return questionId;
			}

			return prevQuestionId;
		});

		setProgress(p => {
			const newAmount = p - progressIncrementAmount;

			if (newAmount < 0) return p;

			return newAmount;
		});
	}, [questionsToUse, progressIncrementAmount]);

	// Don't do resetting logic in this fn so that activeQuestionId does not get set before answeredQuestionIds is recalculated
	const reset = () => {
		setShouldReset(true);
	};

	// Reset progress + activeQuestionId
	useEffect(() => {
		if (!shouldReset || !questionsToUse.length) return;
		setInitialProgress();
		setActiveQuestionId(questionsToUse[0].id);
		setShouldReset(false);
		setDidCalculateInitialQuestionID(false);
	}, [shouldReset, questionsToUse, answeredQuestionIds, setInitialProgress]);

	const Question = useMemo(
		() => questionsToUse.find(({ id }) => id === activeQuestionId)?.component,
		[activeQuestionId, questionsToUse]
	);

	const resetProgress = () => {
		setProgress(0);
	};

	return {
		next,
		prev,
		reset,
		progress,
		resetProgress,
		Question,
		activeQuestionId,
		progressIncrementAmount,
	};
}
