import React, { useEffect, useMemo } from "react";
import Image from "next/image";
import { Label, QuickLink } from "components";
import { usePropertyContext } from "components/pages/Account/Property/context";
import { useAuthContext } from "context/AuthContext";
import Reflink from "components/pages/shared/ShareReferral/Reflink";
import Socials from "components/pages/shared/ShareReferral/Socials";
import constants from "utils/constants";
import HouseWithCoinsAround from "public/housewcoinsaround.png";
import { useRouter } from "next/router";
import formatDollar from "utils/formatDollar";
import ExemptionsIcon from "public/account/survey/serviceCards/exemptions.svg";
import InsuranceIcon from "public/account/survey/serviceCards/insurance.svg";
import MortgageEquityIcon from "public/account/survey/serviceCards/mortgage-equity.svg";
import BillReductionIcon from "public/account/survey/serviceCards/bill-reduction.svg";
import { useEventTracker } from "utils/useEventTracker";
import ButtonV2 from "elements/ButtonV2";
import { getExemptionAverageSavings } from "utils/account";
import Router from "next/router";
import client from "utils/client";
import { sendErrorToWebhook } from "utils/sendWebhook";

const ServiceCard = ({
	averageSavings,
	title,
	body,
	icon,
	cta,
	onClick,
}: {
	averageSavings: number | string;
	title: string;
	body: JSX.Element;
	icon: () => React.ReactSVGElement;
	cta: string;
	onClick: () => void;
}) => {
	const heading =
		typeof averageSavings === "string"
			? averageSavings
			: `${formatDollar(averageSavings)} AVERAGE SAVINGS`;

	return (
		<div id="confirmation-service-card">
			<div id="confirmation-service-card-desktop">
				<div id="confirmation-service-card-heading">
					<p className="sm bold">{heading}</p>
				</div>
				<div id="confirmation-service-card-body">
					<div className="flex justify-between items-center">
						<div>
							<p className="lg bold">{title}</p>
							{body}
						</div>
						<div className="ml-2" id="confirmation-service-card-icon">
							{icon()}
						</div>
					</div>
					<ButtonV2 size="mobile-extra-small" onClick={onClick}>
						{cta}
					</ButtonV2>
				</div>
			</div>
			<div id="confirmation-service-card-mobile">
				<div id="confirmation-service-card-heading">
					<p className="sm bold">{heading}</p>
				</div>
				<div id="confirmation-service-card-body">
					<div className="flex justify-between items-center">
						<div className="flex items-center">
							<div id="confirmation-service-card-icon">{icon()}</div>
							<p className="lg bold">{title}</p>
						</div>
					</div>
					<div className="mt-2 mb-2">{body}</div>
					<ButtonV2 size="mobile-extra-small" onClick={onClick}>
						{cta}
					</ButtonV2>
				</div>
			</div>
		</div>
	);
};

const ConfirmationReferralCard = () => {
	const { userData } = useAuthContext();

	const referralLink = React.useMemo(
		() => `https://www.ownwell.com/referral?owl=${userData?.referral_code}`,
		[userData]
	);
	return (
		<div className="confirmation-referral-card">
			<div className="image-container">
				<Image
					src={HouseWithCoinsAround}
					alt="Small house on top of stacks of coins."
					priority
				/>
			</div>
			<div className="content">
				<p className="lg bold">
					Give ${constants.REFERRAL_CREDIT}, Get ${constants.REFERRAL_CREDIT}
				</p>
				<p className="denim-medium sm bold">
					Tell your friends and neighbors about Ownwell. You get a $
					{constants.REFERRAL_CREDIT} credit for every sign up and so does your
					friend 🎉
				</p>
				<Reflink
					referralLink={referralLink}
					useLabel
					label="Your Referral Link"
				/>
				<div className="mt-2">
					<Label>Share to Social</Label>
					<Socials referralLink={referralLink} />
				</div>
				<p className="body-tiny denim_5">
					*Offer has no cash value. Referrals must be new customers. Offer only
					applies when a customer signs up using your unique link; offer cannot
					be retroactively applied if the link was not used to sign up. Credit
					will be applied against your invoiced amount for services. Void where
					prohibited, taxed or otherwise restricted. Ownwell has the right to
					end or modify any promotion and/or offer at any time. Offers only
					apply to the United States. For more details, please visit
					https://www.ownwell.com/terms
				</p>
			</div>
		</div>
	);
};

const Confirmation = () => {
	const {
		setModalToShow,
		resetProfile,
		details,
		propertySurveyContext,
		next,
		expensesData,
	} = usePropertyContext();

	const serviceContentToShow = useMemo(() => {
		const isQualifiedForExemptions =
			!!details?.accepting_exemptions &&
			propertySurveyContext.q_primary_residence !== 0 &&
			!(details?.auth_company_type === "LLC" && details?.state === "TX") &&
			!details?.exemption?.signed_at &&
			!["yes", "yesAnotherProperty"].includes(
				propertySurveyContext.q_current_homestead_status ?? ""
			);

		if (isQualifiedForExemptions) return "exemptions";

		if (!details?.insurance.current_premium) {
			return "insurance";
		}

		if (!details?.mortgage_monitoring_enabled) {
			return "mortgage-equity";
		}

		if (!details?.bill_reduction.length) {
			return "bill-reduction";
		}

		return null;
	}, [
		details,
		propertySurveyContext.q_current_homestead_status,
		propertySurveyContext.q_primary_residence,
	]);

	const ServiceCardContent = useMemo(() => {
		switch (serviceContentToShow) {
			case "exemptions": {
				return (
					<ServiceCard
						averageSavings={`Exemptions can save homeowners ${getExemptionAverageSavings(
							details?.state
						)} in just the first year!`}
						title="Congrats! You qualify for additional savings."
						body={
							<p className="sm">
								Exemptions reduce the taxable value of your property. This means
								you're taxed on a lower amount, leading to a smaller tax bill.
								Answer a few more questions and we will handle the rest.
							</p>
						}
						icon={ExemptionsIcon}
						cta="Complete Survey"
						onClick={() => {
							if (!!router.query.onboarding) {
								trackEvent({
									eventName:
										constants.EVENTS
											.Onboarding_property_survey_complete_exemptions,
									data: {
										"Customer ID": userData?.id,
										"Property ID": details?.id,
									},
								});
							}
							setModalToShow("exemptions");
							client
								.getUserData()
								.then(data => {
									setUserData(data);
								})
								.catch(error => {
									console.error("Error fetching user data", error);
									sendErrorToWebhook(`Error fetching user data: ${error}`);
								});
							Router.push(
								`/account/properties/${details?.id}/exemptions` +
									(router.query.onboarding ? "?onboarding=true" : "")
							);
						}}
					/>
				);
			}
			case "insurance": {
				return (
					<ServiceCard
						averageSavings={290}
						title="Let’s help you save on insurance!"
						body={
							<p className="sm">
								Do you know that 45% of Homeowners overpay annually? Activate
								Insurance Monitoring and we'll notify you if we can find you
								some savings!
							</p>
						}
						icon={InsuranceIcon}
						cta="Activate Insurance Monitoring"
						onClick={() => {
							if (!!router.query.onboarding) {
								trackEvent({
									eventName:
										constants.EVENTS
											.Onboarding_property_survey_complete_insurance,
									data: {
										"Customer ID": userData?.id,
										"Property ID": details?.id,
									},
								});
							}
							setModalToShow("insurance-survey");
						}}
					/>
				);
			}
			case "mortgage-equity": {
				return (
					<ServiceCard
						averageSavings={3687}
						title="Reduce monthly expenses and tap into your equity!"
						body={
							<div>
								<p className="sm">
									We'll monitor the market and and alert you when there are
									smart ways you can:
								</p>
								<ul style={{ listStyle: "inside", paddingLeft: "16px" }}>
									<li className="sm">Lower Mortgage & Escrow Payments</li>
									<li className="sm"> Consolidate Costly Debts</li>
									<li className="sm"> Increase Your Property's Value</li>
								</ul>
							</div>
						}
						icon={MortgageEquityIcon}
						cta="Activate Mortgage & Equity Services"
						onClick={() => {
							if (!!router.query.onboarding) {
								trackEvent({
									eventName:
										constants.EVENTS
											.Onboarding_property_survey_complete_mortgage_equity,
									data: {
										"Customer ID": userData?.id,
										"Property ID": details?.id,
									},
								});
							}
							setModalToShow("edit-equity-details");
						}}
					/>
				);
			}
			case "bill-reduction": {
				return (
					<ServiceCard
						averageSavings={239}
						title="Reduce your monthly bills with Ownwell!"
						body={
							<p className="sm">
								We can help reduce your monthly recurring bills! Get started by
								clicking the button below!
							</p>
						}
						icon={BillReductionIcon}
						cta="Activate Bill Reduction"
						onClick={() => {
							if (!!router.query.onboarding) {
								trackEvent({
									eventName:
										constants.EVENTS
											.Onboarding_property_survey_complete_bill_reduction,
									data: {
										"Customer ID": userData?.id,
										"Property ID": details?.id,
									},
								});
							}
							setModalToShow("bill-reduction");
						}}
					/>
				);
			}
		}

		return null;
	}, [serviceContentToShow, setModalToShow]);

	const { userData, setUserData } = useAuthContext();
	const trackEvent = useEventTracker();
	const router = useRouter();

	// Keep this in sync with onAttemptExit in components/pages/Account/Property/Survey/PropertySurvey.tsx
	const onDoneClick = () => {
		setModalToShow(undefined);
		resetProfile();
		if (!!router.query.onboarding) {
			trackEvent({
				eventName: constants.EVENTS.Onboarding_property_survey_complete_done,
				data: {
					"Customer ID": userData?.id,
					"Property ID": details?.id,
				},
			});
		}
		client
			.getUserData()
			.then(data => {
				setUserData(data);
			})
			.catch(error => {
				console.error("Error fetching user data", error);
				sendErrorToWebhook(`Error fetching user data: ${error}`);
			});
		router.replace({
			pathname: `/account/properties/${details?.id}`,
			query: {
				...(router.query.onboarding ? { onboarding: true } : {}),
			},
		});
	};

	useEffect(() => {
		if (!!router.query.onboarding) {
			trackEvent({
				eventName: constants.EVENTS.Onboarding_property_survey_complete_view,
				data: {
					"Customer ID": userData?.id,
					"Property ID": details?.id,
				},
			});
		}
	}, [router.query.onboarding]);

	return (
		<div id="property-survey-confirmation-card">
			{" "}
			<p className="lg bold">Thanks for completing your property survey!</p>
			<p className="sm denim-medium">
				Our team will evaluate your case and may contact you for more details.
				You can add more information or evidence in your documents tab or update
				your survey below.
			</p>
			<ButtonV2
				size="large"
				className="mx-auto"
				id="property-survey-confirmation-primary-cta"
				onClick={onDoneClick}>
				Done
			</ButtonV2>
			<QuickLink
				asButton
				onClick={() => next()}
				className="mt-1 mx-auto"
				icon="cycle">
				Update Survey
			</QuickLink>
			{ServiceCardContent}
			<ConfirmationReferralCard />
		</div>
	);
};

export default Confirmation;
