import React from "react";
import { usePropertyContext } from "../../context";
import ButtonV2 from "elements/ButtonV2";
import ConciergeConfirmationContent from "../../Concierge/ConciergeConfirmation";

const ConciergeConfirmation: React.FC<{
	showSkip?: boolean;
	overrideNext?: () => void;
}> = ({ showSkip = false, overrideNext }) => {
	const { next } = usePropertyContext();

	return (
		<div className="concierge-confirmation-wrapper">
			<ConciergeConfirmationContent />
			<ButtonV2 variant="primary" onClick={() => next()}>
				Continue
			</ButtonV2>
		</div>
	);
};

export default ConciergeConfirmation;
