import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { usePropertyContext } from "../../context";
import { useEventTracker } from "utils/useEventTracker";
import constants from "utils/constants";
import { useAuthContext } from "context/AuthContext";
import ButtonV2 from "elements/ButtonV2";

const FIRST_ANIMATION_DURATION = 2;

const TopLeftCloud = () => {
	return (
		<motion.svg
			id="top-left-cloud"
			initial={{
				x: "-200%",
			}}
			animate={{
				x: 0,
			}}
			transition={{
				duration: FIRST_ANIMATION_DURATION,
				type: "spring",
				stiffness: 30,
			}}
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 944 213"
			fill="none">
			<path
				opacity="0.5"
				d="M39.7235 -287.249L-86.4771 183.738C170.152 291.869 274.02 63.6626 344.819 59.9962C412.605 56.4859 612.769 76.7757 742.595 4.23747C810.599 -33.7591 924.699 -46.2623 1012.11 -26.6988L1036.57 -20.1462C1028.71 -22.632 1020.53 -24.8142 1012.11 -26.6988L39.7235 -287.249Z"
				fill="#D5EAFF"
			/>
		</motion.svg>
	);
};

const RightCloud = () => {
	const controls = useAnimation();

	useEffect(() => {
		controls
			.start({
				x: 0,
				transition: {
					type: "spring",
					stiffness: 30,
					duration: FIRST_ANIMATION_DURATION,
				},
			})
			.then(() => {
				// After the initial animation, start the looping animation
				controls.start({
					x: [0, 30, 0, -30, 0], // Animate x position in a loop
					y: [2, -5, 0, -5, 2],
					transition: {
						loop: Infinity,
						ease: "linear",
						duration: 7,
						repeatDelay: 0,
					},
				});
			});
	}, [controls]);

	return (
		<motion.svg
			initial={{
				x: "100vw",
			}}
			animate={controls}
			transition={{
				duration: FIRST_ANIMATION_DURATION,
				repeat: Infinity,
			}}
			id="right-cloud"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 200 31"
			fill="none">
			<path
				d="M199.75 30.9903H0C10.64 20.0703 29.1 10.6103 40.39 12.6603C47.45 13.9503 53.69 18.3603 60.79 19.2603C70.42 20.4703 79.48 15.1603 88.22 10.9503C103.52 3.58027 120.53 -0.939734 137.48 0.160266C154.45 1.28027 196.03 13.2203 199.75 30.9803V30.9903Z"
				fill="#EDF6FF"
			/>
		</motion.svg>
	);
};

const LeftCloud = () => {
	const controls = useAnimation();

	useEffect(() => {
		controls
			.start({
				x: 0,
				transition: {
					type: "spring",
					stiffness: 30,
					duration: FIRST_ANIMATION_DURATION,
				},
			})
			.then(() => {
				// After the initial animation, start the looping animation
				controls.start({
					x: [0, 30, 0, -30, 0], // Animate x position in a loop
					y: [2, -5, 0, -5, 2],
					transition: {
						loop: Infinity,
						ease: "linear",
						duration: 5.0,
						repeatDelay: 0,
					},
				});
			});
	}, [controls]);
	return (
		<motion.svg
			initial={{
				x: "-100vw",
			}}
			animate={controls}
			transition={{
				duration: FIRST_ANIMATION_DURATION,
				repeat: Infinity,
			}}
			xmlns="http://www.w3.org/2000/svg"
			id="left-cloud"
			viewBox="0 0 129 25"
			fill="none">
			<path
				d="M-0.000198364 24.3999H128.3C125.28 22.4099 122.04 20.8199 118.52 19.9699C112.69 18.5699 106.5 19.3199 100.76 21.0499C99.3998 21.4599 98.0398 21.9099 96.6398 21.7599C95.1098 21.5899 93.7898 20.6999 92.4998 19.8599C82.6398 13.2999 72.2998 7.22993 61.0298 3.53993C49.7798 -0.170066 37.4398 -1.36007 26.0398 1.87993C14.6398 5.13993 4.3298 13.2299 0.0298004 24.2899L0.0098114 24.3999H-0.000198364Z"
				fill="#EDF6FF"
			/>
		</motion.svg>
	);
};

const BottomRightCloud = () => {
	return (
		<motion.svg
			initial={{
				x: "200%",
			}}
			animate={{
				x: "0%",
			}}
			transition={{
				duration: FIRST_ANIMATION_DURATION,
				type: "spring",
				stiffness: 30,
			}}
			id="bottom-right-cloud"
			xmlns="http://www.w3.org/2000/svg"
			width="880"
			height="211"
			viewBox="0 0 880 211"
			fill="none">
			<path
				opacity="0.5"
				d="M924.494 319.585L947.152 2.39371C714.109 -14.2535 683.15 79.2517 539.915 124.977C396.681 170.703 106.796 80.0486 0.847623 253.606L924.494 319.585Z"
				fill="#D5EAFF"
			/>
		</motion.svg>
	);
};

const House = ({ next }: { next: () => void }) => {
	const trackEvent = useEventTracker();
	const { userData } = useAuthContext();

	useEffect(() => {
		trackEvent({
			eventName: constants.EVENTS.Onboarding_welcome_page_viewed,
			data: {
				customerID: userData?.id,
			},
		});
	}, []);

	const handleOnClick = () => {
		trackEvent({
			eventName: constants.EVENTS.Onboarding_welcome_page_begin_clicked,
			data: {
				customerID: userData?.id,
			},
		});
		next();
	};

	return (
		<motion.div
			id="house"
			initial={{
				y: "200%",
				translateX: "-50%",
			}}
			animate={{
				y: "-50%",
				translateY: "-50%",
				translateX: "-50%",
			}}
			style={{
				width: "100%",
				height: "100%",
				left: "50%",
				transform: "translateX(-50%)",
			}}
			transition={{
				type: "spring",
				// stiffness: 80,
				// damping: 50,
				duration: 2,
			}}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="100%"
				viewBox="0 0 779 253"
				fill="none">
				<g clip-path="url(#clip0_669_11282)">
					<rect x="-0.5" y="225" width="779" height="29" fill="#57C975" />
					<path
						d="M955 246H-160C-125.129 189.51 34.6615 204.916 126.916 208.768C219.171 212.62 379.844 206.2 480.926 189.51C582.009 172.82 883.933 145.217 955 246Z"
						fill="#57C975"
					/>
				</g>
				<g clip-path="url(#clip1_669_11282)">
					<path
						d="M166.833 169.067C155.337 169.528 145.652 160.313 145.677 148.784V68.3001C145.652 56.7707 155.337 47.5554 166.833 48.0166C178.033 48.0166 187.117 57.1001 187.117 68.3001V148.784C187.117 159.992 178.033 169.067 166.833 169.067Z"
						fill="#99EDBA"
					/>
					<path
						d="M166.891 272.338C166.537 272.338 166.249 272.049 166.249 271.695V53.2376C166.241 52.4058 167.542 52.4058 167.526 53.2376V271.695C167.526 272.049 167.237 272.338 166.883 272.338H166.891Z"
						fill="#566C77"
					/>
					<path
						d="M166.892 95.6411C166.694 95.6411 166.496 95.5505 166.373 95.3775L150.709 73.8422C150.503 73.554 150.569 73.1587 150.849 72.9528C151.137 72.747 151.533 72.8128 151.739 73.0928L167.402 94.6281C167.608 94.9164 167.55 95.3117 167.262 95.5175C167.147 95.5999 167.015 95.6411 166.883 95.6411H166.892Z"
						fill="#566C77"
					/>
					<path
						d="M158.943 85.3059H148.287C147.455 85.3142 147.455 84.013 148.287 84.0295H158.943C159.775 84.0212 159.775 85.3224 158.943 85.3059Z"
						fill="#566C77"
					/>
					<path
						d="M166.997 113.092C166.89 113.092 166.775 113.059 166.676 113.001C166.396 112.828 166.314 112.458 166.495 112.178L182.372 87.1835C182.784 86.5246 183.805 87.1835 183.385 87.8258L167.508 112.82C167.392 113.001 167.203 113.1 167.005 113.1L166.997 113.092Z"
						fill="#566C77"
					/>
					<path
						d="M174.829 103.143C174.582 103.143 174.343 102.995 174.236 102.748C174.105 102.419 174.253 102.048 174.582 101.916L183.501 98.2188C183.822 98.087 184.201 98.2353 184.333 98.5647C184.465 98.8941 184.316 99.2647 183.987 99.3964L175.068 103.094C174.986 103.127 174.903 103.143 174.821 103.143H174.829Z"
						fill="#566C77"
					/>
					<path
						d="M166.998 144.262C166.816 144.262 166.635 144.18 166.503 144.032L147.579 121.41C147.348 121.138 147.389 120.734 147.661 120.512C147.933 120.29 148.336 120.322 148.559 120.594L167.483 143.217C167.714 143.488 167.673 143.892 167.401 144.114C167.278 144.213 167.138 144.262 166.989 144.262H166.998Z"
						fill="#566C77"
					/>
					<path
						d="M167.213 156.442C167.073 156.442 166.924 156.393 166.809 156.294C166.537 156.072 166.496 155.668 166.718 155.396L180.858 138.209C181.377 137.559 182.382 138.39 181.847 139.016L167.707 156.203C167.583 156.36 167.394 156.434 167.213 156.434V156.442Z"
						fill="#566C77"
					/>
				</g>
				<g clip-path="url(#clip2_669_11282)">
					<path
						d="M687.609 105.721C687.609 134.84 664.006 158.443 634.887 158.443C565.03 155.858 565.045 55.5693 634.887 53C664.006 53 687.609 76.6029 687.609 105.721Z"
						fill="#99EDBA"
					/>
					<path
						d="M634.495 229.118C634.07 229.118 633.725 228.772 633.725 228.348V93.5272C633.709 92.5293 635.28 92.5293 635.265 93.5272V228.356C635.265 228.78 634.919 229.126 634.495 229.126V229.118Z"
						fill="#566C77"
					/>
					<path
						d="M634.228 122.622C634.008 122.622 633.788 122.528 633.631 122.339L622.615 108.958C622.348 108.628 622.395 108.141 622.725 107.874C623.055 107.599 623.534 107.646 623.809 107.976L634.825 121.357C635.092 121.687 635.045 122.174 634.715 122.441C634.574 122.559 634.401 122.622 634.228 122.622Z"
						fill="#566C77"
					/>
					<path
						d="M634.487 141.77C634.329 141.77 634.164 141.723 634.031 141.621C633.685 141.369 633.614 140.89 633.866 140.544L648.032 121.397C648.284 121.059 648.771 120.98 649.109 121.239C649.454 121.491 649.525 121.97 649.274 122.316L635.107 141.464C634.958 141.668 634.722 141.778 634.487 141.778V141.77Z"
						fill="#566C77"
					/>
				</g>

				<g clip-path="url(#clip3_669_11282)">
					<g clip-path="url(#clip4_669_11282)">
						<path
							d="M482.522 7.16309H482.623L550.062 114.893H527.212L476.364 18.1179L482.522 7.16309Z"
							fill="#8CB3DC"
						/>
						<path
							d="M539.33 111.373V228.6H264.824V115.884C265.521 115.884 380.682 114.602 405.251 114.337C407.659 114.303 408.749 114.29 409.825 114.29L412.125 110.905L482.521 7.16309L539.08 110.912L539.33 111.38V111.373Z"
							fill="#D5EAFF"
						/>
						<path
							d="M539.331 111.373V114.697H405.251V110.905H539.08L539.331 111.373Z"
							fill="#EDF6FF"
						/>
						<path
							d="M409.419 114.29V228.599H264.824V115.884C265.589 115.884 401.022 114.385 409.419 114.29Z"
							fill="#8CB3DC"
						/>
						<path d="M352.353 0H326.397V24.1616H352.353V0Z" fill="#566C77" />
						<path
							d="M352.346 10.6226V17.7991L326.397 18.9523V15.771L352.346 10.6226Z"
							fill="#053249"
						/>
						<path
							d="M482.521 7.16309L476.513 16.1101L475.971 16.9241L470.477 25.1317L469.949 25.9253L464.442 34.1262L463.907 34.9266L458.42 43.1274L457.872 43.9279L452.378 52.1151L451.85 52.9291L446.356 61.1164L445.828 61.91L440.34 70.0973L439.806 70.8977L434.318 79.085L433.791 79.8786L428.296 88.0524L427.769 88.846L422.288 97.04L421.747 97.8337L416.266 106.007L415.738 106.801L410.089 115.226L253.559 118.142L257.639 111.495L258.119 110.701L262.97 102.792L263.471 101.999L268.323 94.0758L268.823 93.2822L273.681 85.3527L274.162 84.559L279.033 76.6363L279.514 75.8427L284.386 67.9131L284.866 67.1195L289.738 59.1968L290.232 58.3828L295.09 50.46L295.584 49.6528L300.455 41.7233L300.956 40.9161L305.821 32.9934L306.308 32.1794L311.18 24.2431L311.674 23.4427L315.801 16.7206L482.521 7.16309Z"
							fill="#053249"
						/>
						<path
							d="M476.513 16.1099L475.971 16.9238L311.18 24.2429L311.674 23.4425L476.513 16.1099Z"
							fill="#566C77"
						/>
						<path
							d="M470.478 25.1318L469.95 25.9255L305.821 32.9935L306.308 32.1795L470.478 25.1318Z"
							fill="#566C77"
						/>
						<path
							d="M464.441 34.126L463.907 34.9264L300.455 41.7231L300.956 40.9159L464.441 34.126Z"
							fill="#566C77"
						/>
						<path
							d="M458.42 43.1274L457.872 43.9279L295.09 50.46L295.584 49.6528L458.42 43.1274Z"
							fill="#566C77"
						/>
						<path
							d="M452.377 52.1152L451.849 52.9292L289.737 59.1969L290.231 58.3829L452.377 52.1152Z"
							fill="#566C77"
						/>
						<path
							d="M446.356 61.1162L445.828 61.9098L284.386 67.9129L284.866 67.1193L446.356 61.1162Z"
							fill="#566C77"
						/>
						<path
							d="M440.34 70.0972L439.806 70.8976L279.033 76.6361L279.514 75.8425L440.34 70.0972Z"
							fill="#566C77"
						/>
						<path
							d="M434.319 79.085L433.791 79.8786L273.682 85.3526L274.162 84.559L434.319 79.085Z"
							fill="#566C77"
						/>
						<path
							d="M428.296 88.0522L427.768 88.8459L268.322 94.0757L268.823 93.2821L428.296 88.0522Z"
							fill="#566C77"
						/>
						<path
							d="M422.288 97.04L421.747 97.8337L262.971 102.792L263.471 101.999L422.288 97.04Z"
							fill="#566C77"
						/>
						<path
							d="M416.266 106.007L415.738 106.801L257.639 111.495L258.119 110.701L416.266 106.007Z"
							fill="#566C77"
						/>
						<g opacity="0.46">
							<path
								d="M409.132 183.493H264.981V228.562H409.132V183.493Z"
								fill="#7B9FC5"
							/>
							<path
								d="M539.592 183.493H409.132V228.562H539.592V183.493Z"
								fill="#C2DEFC"
							/>
							<path
								d="M270.507 183.593H268.951V228.593H270.507V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M278.438 183.593H276.882V228.593H278.438V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M286.388 183.593H284.832V228.593H286.388V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M294.338 183.593H292.782V228.593H294.338V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M302.275 183.593H300.719V228.593H302.275V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M310.225 183.593H308.669V228.593H310.225V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M318.163 183.593H316.606V228.593H318.163V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M326.113 183.593H324.557V228.593H326.113V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M334.063 183.593H332.507V228.593H334.063V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M342 183.593H340.443V228.593H342V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M349.95 183.593H348.394V228.593H349.95V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M357.9 183.593H356.344V228.593H357.9V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M365.837 183.593H364.281V228.593H365.837V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M373.788 183.593H372.231V228.593H373.788V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M381.738 183.593H380.182V228.593H381.738V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M389.674 183.593H388.118V228.593H389.674V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M397.625 183.593H396.068V228.593H397.625V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M405.562 183.593H404.006V228.593H405.562V183.593Z"
								fill="#8CB3DC"
							/>
							<path
								d="M416.685 183.593H415.129V228.593H416.685V183.593Z"
								fill="#D5EAFF"
							/>
							<path
								d="M424.635 183.593H423.079V228.593H424.635V183.593Z"
								fill="#D5EAFF"
							/>
							<path
								d="M432.586 183.593H431.03V228.593H432.586V183.593Z"
								fill="#D5EAFF"
							/>
							<path
								d="M440.523 183.593H438.967V228.593H440.523V183.593Z"
								fill="#D5EAFF"
							/>
							<path
								d="M448.473 183.593H446.917V228.593H448.473V183.593Z"
								fill="#D5EAFF"
							/>
							<path
								d="M456.423 183.593H454.867V228.593H456.423V183.593Z"
								fill="#D5EAFF"
							/>
							<path
								d="M464.36 183.593H462.804V228.593H464.36V183.593Z"
								fill="#D5EAFF"
							/>
							<path
								d="M472.31 183.593H470.754V228.593H472.31V183.593Z"
								fill="#D5EAFF"
							/>
							<path
								d="M480.248 183.593H478.691V228.593H480.248V183.593Z"
								fill="#D5EAFF"
							/>
							<path
								d="M488.198 183.593H486.642V228.593H488.198V183.593Z"
								fill="#D5EAFF"
							/>
							<path
								d="M496.148 183.593H494.592V228.593H496.148V183.593Z"
								fill="#D5EAFF"
							/>
							<path
								d="M504.085 183.593H502.528V228.593H504.085V183.593Z"
								fill="#D5EAFF"
							/>
							<path
								d="M512.035 183.593H510.479V228.593H512.035V183.593Z"
								fill="#D5EAFF"
							/>
							<path
								d="M519.986 183.593H518.43V228.593H519.986V183.593Z"
								fill="#D5EAFF"
							/>
							<path
								d="M527.922 183.593H526.366V228.593H527.922V183.593Z"
								fill="#D5EAFF"
							/>
							<path
								d="M535.873 183.593H534.316V228.593H535.873V183.593Z"
								fill="#D5EAFF"
							/>
						</g>
						<path
							d="M455.97 130.495H428.229V174.836H455.97V130.495Z"
							fill="#F7F9FC"
						/>
						<path
							d="M430.016 132.747V134.517L440.233 160.924L452.54 172.442H454.178V132.747H430.016Z"
							fill="#053249"
						/>
						<path
							d="M452.452 134.49H430.016V172.442H452.452V134.49Z"
							fill="#1C7CE5"
						/>
						<path
							d="M455.646 149.942H429.027V151.733H455.646V149.942Z"
							fill="#F7F9FC"
						/>
						<path
							d="M513.544 130.495H485.803V174.836H513.544V130.495Z"
							fill="#F7F9FC"
						/>
						<path
							d="M487.589 132.747V134.517L497.799 160.924L510.114 172.442H511.751V132.747H487.589Z"
							fill="#053249"
						/>
						<path
							d="M510.026 134.49H487.589V172.442H510.026V134.49Z"
							fill="#1C7CE5"
						/>
						<path
							d="M513.213 149.942H486.595V151.733H513.213V149.942Z"
							fill="#F7F9FC"
						/>
						<path
							d="M496.026 75.4017C496.026 85.0813 488.529 92.9226 479.273 92.9226C457.08 92.0748 457.087 58.7219 479.273 57.874C488.523 57.874 496.026 65.7221 496.026 75.3949V75.4017Z"
							fill="#F7F9FC"
						/>
						<path
							d="M494.003 75.402C494.003 80.0349 492.048 84.1998 488.949 87.0216C474.456 99.272 456.708 79.5669 468.163 65.2748C477 54.5438 494.111 61.3609 494.003 75.3952V75.402Z"
							fill="#053249"
						/>
						<path
							d="M492.663 76.7919C492.663 80.7058 491.249 84.2941 488.949 87.0277C474.456 99.2781 456.708 79.573 468.163 65.2809C477.494 56.4356 492.846 63.7818 492.663 76.7919Z"
							fill="#1C7CE5"
						/>
						<path
							d="M481.182 58.5391H479.402V91.858H481.182V58.5391Z"
							fill="#F7F9FC"
						/>
						<path
							d="M495.337 74.4043H463.481V76.2629H495.337V74.4043Z"
							fill="#F7F9FC"
						/>
						<path
							d="M307.127 130.488H279.386V174.829H307.127V130.488Z"
							fill="#F7F9FC"
						/>
						<path
							d="M305.341 132.747V134.497L292.357 159.479L282.816 172.442H281.179V132.747H305.341Z"
							fill="#053249"
						/>
						<path
							d="M305.341 134.49H282.904V172.442H305.341V134.49Z"
							fill="#1C7CE5"
						/>
						<path
							d="M306.335 149.942H279.717V151.733H306.335V149.942Z"
							fill="#F7F9FC"
						/>
						<path
							d="M395.243 130.488H367.502V174.829H395.243V130.488Z"
							fill="#F7F9FC"
						/>
						<path
							d="M393.457 132.747V134.497L380.473 159.479L370.926 172.442H369.295V132.747H393.457Z"
							fill="#053249"
						/>
						<path
							d="M393.457 134.49H371.021V172.442H393.457V134.49Z"
							fill="#1C7CE5"
						/>
						<path
							d="M394.452 149.942H367.834V151.733H394.452V149.942Z"
							fill="#F7F9FC"
						/>
						<path
							d="M357.651 138.587H315.01V228.593H357.651V138.587Z"
							fill="#053249"
						/>
						<path
							d="M357.786 141.022H317.263V226.558H357.786V141.022Z"
							fill="#1C7CE5"
						/>
						<path
							d="M355.831 224.211H319.212C318.995 224.211 318.826 224.034 318.826 223.817V143.369C318.826 143.152 319.002 142.976 319.212 142.976H355.831C356.047 142.976 356.216 143.152 356.216 143.369V223.817C356.216 224.034 356.04 224.211 355.831 224.211ZM319.598 223.431H355.438V143.762H319.598V223.431Z"
							fill="#053249"
						/>
						<path
							d="M333.881 178.411H324.219C324.002 178.411 323.833 178.235 323.833 178.018V146.293C323.833 146.076 324.009 145.899 324.219 145.899H333.888C334.104 145.899 334.273 146.076 334.273 146.293V178.018C334.273 178.235 334.097 178.411 333.888 178.411H333.881ZM324.598 177.624H333.488V146.679H324.598V177.624Z"
							fill="#053249"
						/>
						<path
							d="M351.094 178.411H341.425C341.208 178.411 341.039 178.234 341.039 178.017V146.292C341.039 146.075 341.215 145.899 341.425 145.899H351.094C351.31 145.899 351.479 146.075 351.479 146.292V178.017C351.479 178.234 351.303 178.411 351.094 178.411ZM341.817 177.624H350.708V146.679H341.817V177.624Z"
							fill="#053249"
						/>
						<path
							d="M333.881 218.364H324.219C324.002 218.364 323.833 218.187 323.833 217.97V186.239C323.833 186.022 324.009 185.845 324.219 185.845H333.888C334.104 185.845 334.273 186.022 334.273 186.239V217.97C334.273 218.187 334.097 218.364 333.888 218.364H333.881ZM324.598 217.584H333.488V186.632H324.598V217.584Z"
							fill="#053249"
						/>
						<path
							d="M351.094 218.364H341.425C341.208 218.364 341.039 218.187 341.039 217.97V186.239C341.039 186.022 341.215 185.845 341.425 185.845H351.094C351.31 185.845 351.479 186.022 351.479 186.239V217.97C351.479 218.187 351.303 218.364 351.094 218.364ZM341.817 217.584H350.708V186.632H341.817V217.584Z"
							fill="#053249"
						/>
						<path
							d="M546.083 226.205H252.902V231.781H546.083V226.205Z"
							fill="#566C77"
						/>
					</g>
					<g clip-path="url(#clip5_669_11282)">
						<path
							d="M571 235H478.39C479.275 229.893 481.72 225.078 485.853 222.17C491.508 218.168 500.511 219.14 504.017 225.22C506.729 213.542 516.074 203.733 527.393 200.665C538.74 197.569 551.591 201.345 559.598 210.106C565.797 216.865 568.915 225.871 570.991 235H571Z"
							fill="#249241"
						/>
					</g>
					<g clip-path="url(#clip6_669_11282)">
						<path
							d="M299.611 235H207C207.886 229.893 210.33 225.078 214.463 222.17C220.118 218.168 229.122 219.14 232.627 225.22C235.339 213.542 244.684 203.733 256.004 200.665C267.35 197.569 280.201 201.345 288.208 210.106C294.408 216.865 297.526 225.871 299.601 235H299.611Z"
							fill="#249241"
						/>
					</g>
				</g>
				<defs>
					<clipPath id="clip0_669_11282">
						<rect
							width="779"
							height="128"
							fill="white"
							transform="translate(0 125)"
						/>
					</clipPath>
					<clipPath id="clip1_669_11282">
						<rect
							width="54.3529"
							height="185.644"
							fill="white"
							transform="translate(132.5 48)"
						/>
					</clipPath>
					<clipPath id="clip2_669_11282">
						<rect
							width="116.286"
							height="145.863"
							fill="white"
							transform="translate(582.5 49.918)"
						/>
					</clipPath>
					<clipPath id="clip3_669_11282">
						<rect
							width="364"
							height="235"
							fill="white"
							transform="translate(207)"
						/>
					</clipPath>
					<clipPath id="clip4_669_11282">
						<rect
							width="297.159"
							height="231.781"
							fill="white"
							transform="translate(252.902)"
						/>
					</clipPath>
					<clipPath id="clip5_669_11282">
						<rect
							width="92.6106"
							height="35.411"
							fill="white"
							transform="translate(478.39 199.589)"
						/>
					</clipPath>
					<clipPath id="clip6_669_11282">
						<rect
							width="92.6106"
							height="35.411"
							fill="white"
							transform="translate(207 199.589)"
						/>
					</clipPath>
				</defs>
			</svg>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{
					duration: 1,
					delay: 1,
				}}>
				<h3 className="mt-2">Welcome to Ownwell! 🎉</h3>
				<p>
					Let's get you set up so you can maximize your savings on property
					taxes, home insurance and other bills!
				</p>
				<ButtonV2 size="large" className="mx-auto mt-2" onClick={handleOnClick}>
					Begin
				</ButtonV2>
			</motion.div>
		</motion.div>
	);
};

const OnboardingIntro = () => {
	const { next } = usePropertyContext();

	return (
		<div id="property-survey-onboarding-intro">
			<AnimatePresence>
				<TopLeftCloud />
				<RightCloud />
				<LeftCloud />
				<BottomRightCloud />
				<House next={next} />
			</AnimatePresence>
		</div>
	);
};

export default OnboardingIntro;
