import { Dispatch, FC, RefObject, SetStateAction } from "react";
import { InsuranceAnswerReducerState } from "utils/types";
import { DispatchAnswerState } from "./InsuranceSurvey";
import { InsuranceQuestionID } from "utils/types";

export type InsuranceSurveyComponent = FC<{
	formRef: RefObject<HTMLFormElement>;
	setNextBtnDisabled: Dispatch<SetStateAction<boolean>>;
	answerState: InsuranceAnswerReducerState[InsuranceQuestionID];
	dispatchAnswerState: DispatchAnswerState;
}>;

export const withInsuranceSurvey = (component: InsuranceSurveyComponent) => {
	return component;
};
