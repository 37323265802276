import Grid from "elements/Grid";
import Link from "next/link";

import Error404Img from "public/error-404-img.png";
import Image from "next/image";
import React from "react";
import ButtonV2 from "elements/ButtonV2";

export default function ErrorPage({
	errorType,
	title,
	body,
}: {
	errorType?: string;
	title?: string;
	body?: string | JSX.Element;
}) {
	return (
		<Grid id="error">
			<div className="error-img">
				<Image
					src={Error404Img}
					alt="Lost"
					style={{
						maxWidth: "100%",
						height: "auto",
					}}
					priority
				/>
			</div>
			<div className="error-body">
				{errorType && <h4 className="error-description">{errorType} Error</h4>}
				<h2 className="error-title">
					{title || "Oops! Looks like you took a wrong turn"}
				</h2>
				{body ? (
					React.isValidElement(body) ? (
						body
					) : (
						<p className="error-text">{body}</p>
					)
				) : (
					<p className="error-text">
						We couldn't find the page you are looking for. The page might be
						removed or unavailable.
					</p>
				)}
				{errorType === "Client-Side" ? (
					<ButtonV2 size="large" onClick={() => window.location.reload()}>
						Refresh Page
					</ButtonV2>
				) : (
					<Link passHref href="/">
						<ButtonV2 className="large">Head Back Home</ButtonV2>
					</Link>
				)}
			</div>
		</Grid>
	);
}
