import Concierge from "public/account/survey/concierge/concierge.svg";
import { PARTICLE_CONFIG } from "../../PremiumPricingModal";
import { useCallback } from "react";

import dynamic from "next/dynamic";
import type { ParticlesProps } from "react-particles";

const LazyParticles = dynamic<ParticlesProps>(
	() => import("react-particles").then(module => module.default),
	{
		loading: () => null,
		ssr: false,
	}
);

const ConciergeConfirmationContent = () => {
	const particlesInit = useCallback(async engine => {
		const loadSlim = (await import("tsparticles-slim")).loadSlim;
		const loadConfettiPreset = (await import("tsparticles-preset-confetti"))
			.loadConfettiPreset;
		await loadConfettiPreset(engine);
		await loadSlim(engine);
	}, []);

	return (
		<div className="concierge-confirmation-content">
			<div className="img-wrapper">
				<Concierge />
			</div>
			<p className="h4">Welcome to Concierge</p>
			<p className="small">
				A Concierge teammate will reach out with next steps, in the meantime
				continue activating all of your Ways to Save!
			</p>
			<LazyParticles
				id="tsparticles"
				options={PARTICLE_CONFIG}
				init={particlesInit}
			/>
		</div>
	);
};

export default ConciergeConfirmationContent;
