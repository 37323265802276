import { PropertyDetails } from "./types";

export const getTotalSavings = (properties: PropertyDetails[]): number => {
	return properties.reduce((acc, property) => {
		return (
			acc +
			(property.final_tax_savings ? parseFloat(property.final_tax_savings) : 0)
		);
	}, 0);
};
