import { FC } from "react";
import Label from "./Input/Label";

const ProgressBar: FC<{
	complete: number;
	color?: string;
	labelBelow?: boolean;
	id?: string;
	className?: string;
	textBeforeProgress?: string;
	noLabel?: boolean;
}> = ({
	complete,
	color,
	className,
	id,
	labelBelow = false,
	textBeforeProgress = "",
	noLabel = false,
}) => {
	if (textBeforeProgress && !textBeforeProgress.endsWith(" ")) {
		textBeforeProgress = textBeforeProgress + " ";
	}

	return (
		<div
			id={id}
			className={
				"progress-bar" +
				(color ? ` progress-bar-${color}` : "") +
				(className ? " " + className : "")
			}>
			{!labelBelow && !noLabel && (
				<Label>
					{textBeforeProgress && textBeforeProgress}
					<span className="no-translate">{Math.round(complete)}%</span> COMPLETE
				</Label>
			)}
			<div className="progress-bar-container">
				<div className="progress-bar-bar" style={{ width: `${complete}%` }} />
			</div>
			{labelBelow && !noLabel && (
				<Label>
					{textBeforeProgress}
					<span className="no-translate">{Math.round(complete)}%</span> COMPLETE
				</Label>
			)}
		</div>
	);
};

export default ProgressBar;
