import InfoCircle from "public/info-circle.svg";
import { FC, MouseEventHandler, ReactNode, useState } from "react";
import Label from "./Input/Label";

export const TooltipNoIcon = ({
	text,
	disabled = false,
	children,
}: {
	text: string;
	disabled?: boolean;
	children: ReactNode;
}) => {
	return (
		<div
			className={!disabled ? "tooltip-css tooltip-css-left" : ""}
			data-tip={text}>
			{children}
		</div>
	);
};

const Tooltip: FC<{
	text: string;
	small?: boolean;
	isButton?: boolean;
	onClick?: MouseEventHandler<Element>;
	children?: string;
	noLabel?: boolean;
}> = ({
	text,
	isButton = false,
	onClick,
	children,
	small = false,
	noLabel = false,
}) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);

	return (
		<div
			className={"appeal-info-question-card-tooltip" + (small ? "-small" : "")}
			onClick={onClick}>
			<div
				className="appeal-info-question-card-tooltip-text"
				onMouseEnter={() => !isButton && setTooltipOpen(true)}
				onMouseLeave={() => !isButton && setTooltipOpen(false)}>
				<InfoCircle />
				{!noLabel && (
					<p className="sm">{children ? children : "Why provide this info?"}</p>
				)}
			</div>
			{tooltipOpen && (
				<div
					className={"appeal-info-question-card-tooltip-body"}
					onClick={e => {
						e.stopPropagation();
						e.preventDefault();
					}}>
					<div className="triangle-top" />
					{!noLabel && (
						<Label>{children ? children : "Why provide this info?"}</Label>
					)}

					<p className="sm">{text}</p>
				</div>
			)}
		</div>
	);
};

export const Tooltip2: FC<
	React.HTMLAttributes<HTMLDivElement> & {
		text?: string;
		disabled?: boolean;
		label?: string;
		content?: React.ReactNode;
		direction?: "left" | "right";
	}
> = ({
	text,
	label,
	disabled = false,
	content,
	direction = "right",
	...props
}) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);

	return (
		<div
			{...props}
			className={[
				"tooltip2",
				disabled ? "disabled" : "",
				tooltipOpen ? "reveal" : "",
				`tooltip2-direction-${direction}`,
				props.className,
			].join(" ")}
			onMouseEnter={() => !disabled && setTooltipOpen(true)}
			onMouseLeave={() => !disabled && setTooltipOpen(false)}>
			<div className="tooltip2-trigger">
				{props.children}
				<div
					className={["triangle-top", tooltipOpen ? "reveal" : ""].join(" ")}
				/>
			</div>
			<div
				className={`tooltip2-transparent-cursor-bridge ${
					tooltipOpen ? "reveal" : ""
				}`}>
				<div className={`tooltip2-body ${tooltipOpen ? "reveal" : ""}`}>
					{content ? (
						content
					) : (
						<>
							{label && <p className="tooltip2-body-label">{label}</p>}
							<p className="tooltip2-body-text">{text}</p>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default Tooltip;
