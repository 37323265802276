import { FC, InputHTMLAttributes, useMemo, useRef } from "react";
import Label from "./Label";

interface CheckboxProps {
	label?: string;
	labelEl?: "p" | "p.sm" | "label";
	containerClassName?: string;
}

const Checkbox: FC<CheckboxProps & InputHTMLAttributes<HTMLInputElement>> = ({
	label,
	labelEl = "label",
	containerClassName,
	...rest
}) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const containerClassNames = useMemo(() => {
		let val = "checkbox";

		if (containerClassName) {
			val += " ";
			val += containerClassName;
		}

		return val;
	}, [containerClassName]);

	const handleTextClick = () => {
		inputRef.current?.click();
	};

	return (
		<div className={containerClassNames}>
			<div className="pretty p-icon p-curve">
				<input type="checkbox" {...rest} ref={inputRef} />
				<div className="state p-success">
					<label></label>
					<i className="icon mdi mdi-check"></i>
				</div>
			</div>
			{labelEl === "p" || labelEl === "p.sm" ? (
				<p onClick={handleTextClick} className={labelEl === "p.sm" ? "sm" : ""}>
					{label}
				</p>
			) : (
				<Label onClick={handleTextClick}>{label}</Label>
			)}
		</div>
	);
};

export default Checkbox;
