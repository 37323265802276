import { FC, useEffect, useState } from "react";
import { useRouter } from "next/router";

export const LanguagePickerSwitch: FC<
	React.HTMLAttributes<HTMLDivElement>
> = props => {
	const [activeLocale, setActiveLocale] = useState("");

	const router = useRouter();

	useEffect(() => {
		if (router.locale) {
			setActiveLocale(router.locale);
			switchLanguage(router.locale);
		}
	}, [router.locale]);

	const localOptions = router.locales?.map(locale => {
		let label = locale.split("-")[0].toUpperCase();
		let icon = "";

		switch (label) {
			case "EN":
				icon = "🇺🇸 ";
				label = "English";
				break;
			case "ES":
				icon = "🇲🇽";
				label = "Español";
				break;
		}

		return {
			label,
			icon,
			value: locale,
		};
	});

	const switchLanguage = (locale: string) => {
		//@ts-ignore
		if (!!window.Localize) {
			//@ts-ignore
			Localize.setLanguage(locale);
		}
	};

	return (
		<div
			{...props}
			className={"language-picker-switch " + (props.className ?? "")}>
			{localOptions?.map(option => (
				<div
					key={option.value}
					className={
						"language-picker-switch-item" +
						(activeLocale === option.value ? "-active" : "")
					}
					onClick={() => switchLanguage(option.value)}>
					<p className="sm nowrap">
						{option.icon} {option.label}
					</p>
				</div>
			))}
		</div>
	);
};
