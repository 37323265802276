import { FC } from "react";
import Footer from "./Footer";
import Header from "./Header";

const Layout: FC<{ disabled?: boolean }> = ({ children, disabled = false }) => {
	if (disabled) {
		return <main>{children}</main>;
	}
	return (
		<div id="site-layout">
			<Header />
			<main id="site-body">{children}</main>
			<Footer />
		</div>
	);
};

export default Layout;
