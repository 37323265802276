import { NextRouter } from "next/router";
import { sendErrorToWebhook } from "./sendWebhook";

const checkAuthenticated = (router: NextRouter) => {
	if (!window.localStorage) return false;

	try {
		const token = window.localStorage.getItem("auth_token");
		if (token) return true;

		const oauthToken = router.query["oauth_token"];
		if (oauthToken) {
			window.localStorage.setItem("auth_token", oauthToken as string);
			return true;
		}
	} catch (err) {
		sendErrorToWebhook(
			"Error in checkAuthenticated, local storage not found: " + err
		);
	}
	return false;
};

export default checkAuthenticated;
