import React, { ComponentProps, FC, ReactElement } from "react";

export const combineComponents = (...components: FC<{}>[]): FC => {
	return components.reduce(
		(PrevComponents, CurrentComponent) => {
			return ({ children }: ComponentProps<FC>): ReactElement => {
				return (
					<PrevComponents>
						<CurrentComponent>{children}</CurrentComponent>
					</PrevComponents>
				);
			};
		},
		({ children }) => <>{children}</>
	);
};
