import { FC, SelectHTMLAttributes } from "react";
import Label from "./Label";
import QuestionMarkIcon from "public/question-mark.svg";
import { Tooltip2 } from "elements/Tooltip";

interface SelectProps {
	label?: string;
	labelEl?: FC;
	labelBeside?: boolean;
	containerClassName?: string;
	tooltipText?: string;
}

const Select: FC<SelectProps & SelectHTMLAttributes<HTMLSelectElement>> = ({
	label,
	labelBeside = false,
	labelEl: UserLabelEl,
	containerClassName,
	required,
	children,
	tooltipText,
	...inputProps
}) => {
	const LabelEl = UserLabelEl || Label;

	return (
		<div
			className={containerClassName}
			style={{
				display: "flex",
				flexDirection: labelBeside ? "row" : "column",
			}}>
			{label && (
				<div className="label-container">
					<LabelEl className="input-label" required={required}>
						{label}
					</LabelEl>
					{tooltipText && (
						<Tooltip2 label={label} text={tooltipText}>
							<QuestionMarkIcon />
						</Tooltip2>
					)}
				</div>
			)}
			<div className="select-field-input-wrapper">
				<select
					className={
						(label ? " with-label" : "") + (inputProps.value ? "" : " empty")
					}
					required={required}
					placeholder={undefined}
					{...inputProps}>
					{inputProps.placeholder ? (
						<option hidden disabled value="placeholder">
							{inputProps.placeholder}
						</option>
					) : null}
					{children}
				</select>
			</div>
		</div>
	);
};

export default Select;
