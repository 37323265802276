import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import PropertyModal from "./Property/Modal";
import { Label } from "components";
import {
	PremiumPricingComparisonTable,
	usePricing,
} from "elements/Premium/PremiumPricingComparisonTable";
import PremiumDiamond from "public/premium/premium-diamond.svg";
import { useAuthContext } from "context/AuthContext";
import client from "utils/client";
import { PropertyDetails } from "utils/types";
import InfoCircle from "public/info-circle.svg";
import concatAddressLine2 from "utils/concatAddressLine2";
import { sortProperties } from "utils/account";
import EditIcon from "public/edit.svg";
import { Tooltip2 } from "elements/Tooltip";
import ButtonV2 from "elements/ButtonV2";

const SuccessCard = ({ setOpen }: { setOpen: (open: boolean) => void }) => {
	return (
		<div id="tier-change-success-card" className="text-center">
			<h4 className="lg">Welcome to Essentials!</h4>
			<p className="sm denim-medium mt-1">
				You can unlock Premium benefits at anytime from your settings!
			</p>
			<ButtonV2
				size="large"
				className="mt-4 mx-auto"
				onClick={() => {
					setOpen(false);
				}}>
				Continue
			</ButtonV2>
		</div>
	);
};

const PropertyPreviewList: React.FC<{
	renderPropertyCard: (property: PropertyDetails) => React.ReactNode;
}> = ({ renderPropertyCard }) => {
	const { properties } = useAuthContext();

	const [numToShow, setNumToShow] = useState(4);

	return (
		<div className="property-preview-list">
			<ul className="property-preview-cards">
				{properties
					.slice(0, numToShow)
					.map((property, i) => renderPropertyCard(property))}
			</ul>
			{properties.length > numToShow && (
				<div className="property-preview-list-load-more-container">
					<p
						className="sm azure"
						onClick={() =>
							setNumToShow(prev => Math.min(properties.length, prev + 4))
						}>
						Load ({Math.max(0, properties.length - numToShow)}) More
					</p>
				</div>
			)}
		</div>
	);
};

export const ContingencyBreakdown: React.FC<{
	customerPricing: ReturnType<typeof usePricing>["customerPricing"];
	downgrade?: boolean;
	upgrade?: boolean;
}> = ({ customerPricing, upgrade, downgrade }) => {
	const renderPropertyContingency = (property: PropertyDetails) => {
		// Customer pricing is null when the pricing is loading.
		if (!customerPricing) {
			return (
				<div
					className="property-preview-card-contingency"
					data-skeleton-placeholder="XX% of savings"
					data-skeleton="true"
				/>
			);
		}

		const propertyPricing =
			customerPricing &&
			customerPricing.find(cp => cp.property_id === property.id);
		if (!propertyPricing) {
			return null;
		}

		if (downgrade) {
			let contingency = propertyPricing.current.appeal_contingency;
			let tooltip = false;
			const classNames = ["property-preview-card-contingency"];

			if (!!propertyPricing.current.contingency_locked) {
				classNames.push("premium");
			} else {
				tooltip = true;
				classNames.push("essentials");
				contingency = propertyPricing.pricing.appeal_contingency;
			}

			return (
				<div className={classNames.join(" ")}>
					<p className="sm bold">
						{contingency}% of Savings
						{tooltip && (
							<Tooltip2
								direction="left"
								text={`The current contingency fee for this property is ${propertyPricing.current.appeal_contingency}%. After switching to Essentials the contingency fee for this property will be ${contingency}%.`}>
								<InfoCircle className="info-icon" />
							</Tooltip2>
						)}
					</p>
				</div>
			);
		}

		if (upgrade) {
			let contingency = propertyPricing.current.appeal_contingency;
			let tooltip = false;
			const classNames = ["property-preview-card-contingency"];

			if (!!propertyPricing.current.contingency_locked) {
				classNames.push("essentials");
				tooltip = true;
			} else {
				classNames.push("premium");
				contingency = propertyPricing.pricing.premium_appeal_contingency;
			}

			return (
				<div className={classNames.join(" ")}>
					<p className="sm bold">
						{contingency}% of Savings{" "}
						{!tooltip && <PremiumDiamond className="premium-diamond" />}
						{tooltip && (
							<Tooltip2
								direction="left"
								text={`There is an active appeal with a scheduled or past hearing date. The contingency fee for that appeal will be ${propertyPricing.current.appeal_contingency}%. Future appeals will have a contingency fee of ${propertyPricing.pricing.premium_appeal_contingency}%.`}>
								<InfoCircle className="info-icon" />
							</Tooltip2>
						)}
					</p>
				</div>
			);
		}

		const classNames = ["property-preview-card-contingency", "premium"];
		return (
			<div className={classNames.join(" ")}>
				<p className="sm bold">
					{propertyPricing.current.appeal_contingency}% of Savings{" "}
					<PremiumDiamond className="premium-diamond" />
				</p>
			</div>
		);
	};

	return (
		<div id="contingency-breakdown">
			<Label>All properties</Label>
			<PropertyPreviewList
				renderPropertyCard={property => {
					return (
						<li key={property.id} className="property-preview-card">
							<div className="property-preview-card-address">
								<p className="sm no-translate">
									{concatAddressLine2(property.address, property.address2)},{" "}
									{property.city}, {property.state} {property.zip_code}
								</p>
								<p className="body-tiny denim_5">#{property.apn}</p>
							</div>
							{renderPropertyContingency(property)}
						</li>
					);
				}}
			/>
		</div>
	);
};

const DowngradeConfirmation: React.FC<{
	onConfirm: () => void;
	onCancel: () => void;
}> = ({ onConfirm, onCancel }) => {
	return (
		<div id="downgrade-confirmation">
			<p className="lg bold">Switching to Essentials?</p>
			<p className="sm denim-medium">
				Going back to Essentials is easy. We will keep your Premium Plan active
				on any appeals where we have already attended a hearing. All other
				appeals will switch back to the basics.
			</p>
			<ButtonV2 size="large" variant="primary" onClick={onConfirm}>
				Yes, Switch to Essentials Plan
			</ButtonV2>
			<ButtonV2
				variant="primary-outline-filled"
				size="small"
				onClick={onCancel}>
				No, Stay with Premium
			</ButtonV2>
		</div>
	);
};

const InfoModalContent = ({
	setOpen,
	setSuccess,
}: {
	setOpen: (open: boolean) => void;
	setSuccess: Dispatch<SetStateAction<boolean>>;
}) => {
	const { setUserData, setProperties } = useAuthContext();
	const { generalPricing, customerPricing, hasOwnershipInMultipleStates } =
		usePricing();

	const [showEditMembership, setShowEditMembership] = useState(false);
	const [showConfirmDowngrade, setShowConfirmDowngrade] = useState(false);

	useEffect(() => {
		document.getElementById("premium-info-modal")?.scrollIntoView({
			behavior: "auto",
			block: "start",
			inline: "nearest",
		});
	}, [showEditMembership, showConfirmDowngrade]);

	const handleDismiss = async () => {
		setShowEditMembership(false);
	};

	const handleDowngrade = async () => {
		setShowConfirmDowngrade(true);
	};

	const handleCancelDowngrade = async () => {
		setShowConfirmDowngrade(false);
	};

	const handleConfirmDowngradeToEssentials = async () => {
		try {
			await client.downgradeAccountToPremium();

			setUserData(p => {
				if (!p) return p;
				return {
					...p,
					account_tier: "essentials",
				};
			});

			try {
				window.navigator.vibrate(500);

				const { data } = await client.getProperties();

				setProperties(data.sort(sortProperties));
			} catch {}

			setSuccess(true);
		} catch (e) {
		} finally {
		}
	};

	if (!showEditMembership) {
		return (
			<>
				<div id="membership-benefits">
					<p id="membership-benefits-title" className="lg bold">
						<PremiumDiamond className="premium-diamond" /> Premium Membership
					</p>
					<table>
						<tbody>
							<tr>
								<td>
									<Label>PRIORITY SUPPORT LINE</Label>
								</td>
								<td>
									<p className="sm bold">844-696-9355</p>
								</td>
							</tr>
							<tr>
								<td>
									<Label>TAX SPECIALIST EMAIL</Label>
								</td>
								<td>
									<p className="sm bold">premium@ownwell.com</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div id="edit-membership">
					<p className="bold" onClick={() => setShowEditMembership(true)}>
						Edit Membership Status <EditIcon className="edit-icon" />
					</p>
				</div>
				<ContingencyBreakdown customerPricing={customerPricing} />
			</>
		);
	}

	if (!showConfirmDowngrade) {
		return (
			<>
				<p id="premium-info-modal-title" className="lg bold">
					Edit your Ownwell membership status.
				</p>
				<p id="premium-info-modal-subtitle" className="denim-medium">
					We've designed our Premium Membership based on the most popular
					requests from our customers, to deliver even greater savings and
					value.
				</p>
				<PremiumPricingComparisonTable
					generalPricing={generalPricing}
					premiumActive
					onPremiumAction={handleDismiss}
					onEssentialsAction={handleDowngrade}
					hasOwnershipInMultipleStates={hasOwnershipInMultipleStates}
				/>
				<p
					id="premium-info-modal-disclaimer"
					className="body-tiny denim-medium">
					*Escalation funding eligibility is determined on a case by case basis.
				</p>
				<ButtonV2
					size="large"
					variant="primary"
					className="mx-auto"
					onClick={handleDismiss}>
					Stay with Premium
				</ButtonV2>
				<ButtonV2
					variant="primary-outline-filled"
					size="small"
					className="mx-auto"
					onClick={handleDowngrade}>
					Go Back to Essentials
				</ButtonV2>
			</>
		);
	}

	return (
		<>
			<DowngradeConfirmation
				onConfirm={handleConfirmDowngradeToEssentials}
				onCancel={handleCancelDowngrade}
			/>
			<ContingencyBreakdown customerPricing={customerPricing} downgrade />
		</>
	);
};

export const PremiumInfoModal = ({
	setOpen,
}: {
	setOpen: (open: boolean) => void;
}) => {
	const [success, setSuccess] = useState(false);

	if (success) {
		return (
			<div id="forceful-modal">
				<div id="forceful-modal-content" style={{ zIndex: 100000 }}>
					<SuccessCard setOpen={setOpen} />
				</div>
			</div>
		);
	}

	return (
		<PropertyModal
			clickAway
			modalId="premium-info-modal"
			onAttemptExit={() => setOpen(false)}>
			<InfoModalContent setOpen={setOpen} setSuccess={setSuccess} />
		</PropertyModal>
	);
};
