const nearestPowerOfTen = (value: number) => {
	const log10Value = Math.log10(value);
	const nearestExponent = Math.floor(log10Value);
	return Math.pow(10, nearestExponent);
};

const nearestCeilingDivisibleBy = (value: number, divisor: number) => {
	const remainder = value % divisor;
	return remainder === 0 ? value : value + divisor - remainder;
};

export const fitDomainToCeiling = (maxValue: number) => {
	const powerOfTen = nearestPowerOfTen(maxValue / 10);
	const tickInterval = 2 * powerOfTen;
	const roundedValue = nearestCeilingDivisibleBy(maxValue, tickInterval);
	const upperBound = nearestCeilingDivisibleBy(roundedValue, tickInterval);
	return upperBound;
};
