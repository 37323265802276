import { IAppealContext } from "context/AppealContext";
import formatDollar from "./formatDollar";
import { isGeoLevelSavingsData } from "./typePredicates";
import { ExactSavingsData, SavingsCopyType, SavingsData } from "./types";
import constants from "./constants";

export const LOW_SAVINGS_MAX_VALUE = 150;

const getSavingsDollars = ({ amt }: { amt: number }) => {
	if (amt !== undefined && amt <= 0) {
		return "0";
	}
	if (!amt || !amt) return null;

	if (isNaN(amt)) return null;

	return Math.floor(amt).toString();
};

export const getSavingsCopyType = ({
	nonAutocompleteParcelNumber,
	savingsData,
	addressRaw,
}: {
	nonAutocompleteParcelNumber: boolean;
	addressRaw: string | undefined;
	savingsData: SavingsData | undefined;
}): SavingsCopyType => {
	if (!savingsData) return null;
	const savingsValue = getSavingsDollars({ amt: savingsData.amt });

	const isExempt = !!(savingsData as ExactSavingsData).exemption_capped;

	const unknownAssessment = savingsValue === null;
	const exemptionCap = !!isExempt;
	const isTarget = !!(savingsData as ExactSavingsData).score && !addressRaw;
	const lowSavings =
		savingsValue && parseInt(savingsValue) < LOW_SAVINGS_MAX_VALUE;
	const noChanceOfSavings =
		!isGeoLevelSavingsData(savingsData) &&
		!!savingsData.exemption_capped_no_chance_of_savings;

	if (
		!isGeoLevelSavingsData(savingsData) &&
		savingsData.show_savings_on_website === false
	) {
		return "skip";
	}

	if (isTarget) {
		return "targeted";
	}

	if (noChanceOfSavings) {
		return "no-chance-of-savings";
	}

	if (unknownAssessment) {
		return "unknown-assessment";
	}
	if (exemptionCap) {
		return "exemption-or-zero";
	}
	if (nonAutocompleteParcelNumber) {
		return "non-apn";
	}
	if (lowSavings) {
		return "low-savings";
	} else {
		return "non-targeted";
	}
};

export const getSavingsBoxSavingsAmt = (args: IAppealContext) => {
	const {
		savingsData,
		nonAutocompleteParcelNumber,
		addressRaw,
		address,
		isEndToEndExemptionsFlow,
		signUpType,
		signUpServiceType,
		alreadySavingOnExemption,
	} = args;

	if (signUpServiceType === "default") {
		if (signUpType === "property_only") {
			return formatDollar(529) + "/yr";
		}

		const state = address?.state;

		const savingsCopyType = getSavingsCopyType({
			nonAutocompleteParcelNumber,
			savingsData,
			addressRaw,
		});

		const savingsValue = getSavingsDollars({ amt: savingsData?.amt ?? 0 });

		if (
			address?.state === "CA" &&
			savingsData &&
			!isGeoLevelSavingsData(savingsData) &&
			savingsData.exemptionSavings &&
			(isEndToEndExemptionsFlow ||
				(!savingsData.inService && savingsData.exemptionsInService)) &&
			[0, null].includes(savingsData.amt)
		) {
			return formatDollar(savingsData.exemptionSavings.futureSavings);
		}

		if (
			savingsData &&
			!isGeoLevelSavingsData(savingsData) &&
			savingsData.exemptionSavings &&
			!savingsData.inService &&
			savingsData.exemptionsInService
		) {
			if (!savingsData.exemptionSavings.retroSavings) {
				return formatDollar(savingsData.exemptionSavings.futureSavings) + "/yr";
			} else {
				return formatDollar(savingsData.exemptionSavings.retroSavings);
			}
		}

		if (
			isEndToEndExemptionsFlow &&
			savingsData &&
			!isGeoLevelSavingsData(savingsData) &&
			savingsData.exemptionSavings &&
			savingsData.exemptionsInService
		) {
			const appealSavingsValueNumber = parseInt(savingsValue ?? "0");

			const exemptionsRetroSavings = savingsData.exemptionSavings.retroSavings;

			let appealSavings: number;

			if (
				appealSavingsValueNumber < LOW_SAVINGS_MAX_VALUE &&
				appealSavingsValueNumber > 0
			) {
				// Keep this logic in sync with the range logic in the switch statement below
				const lowerbound = Math.max(appealSavingsValueNumber, 100);
				const roundToNearestFive = Math.round(Math.round(lowerbound / 5) * 5);
				appealSavings = roundToNearestFive - 25;
			} else {
				appealSavings = appealSavingsValueNumber;
			}

			return formatDollar(appealSavings + exemptionsRetroSavings);
		}

		if (
			savingsData &&
			!isGeoLevelSavingsData(savingsData) &&
			savingsData.exemption_capped_no_chance_of_savings
		) {
			if (state === "CA") {
				return "$0";
			}

			return `$0-$50`;
		}

		switch (savingsCopyType) {
			case "unknown-assessment":
				return "???";
			case "targeted":
				return `${formatDollar(parseInt(savingsValue ?? "0"))}`;
			default:
				if (!savingsValue) return null;
				const savingsValueToNumber = parseInt(savingsValue);
				if (savingsValueToNumber > LOW_SAVINGS_MAX_VALUE) {
					return `${formatDollar(savingsValueToNumber ?? 0)}`;
				}
				const lowerbound = Math.max(savingsValueToNumber, 100);
				const roundToNearestFive = Math.round(Math.round(lowerbound / 5) * 5);
				return `${
					!!savingsValue ? `${formatDollar(roundToNearestFive - 25)}` : "$0"
				}-${formatDollar(Math.max(roundToNearestFive, 0) + 25)}`;
		}
	} else if (signUpServiceType) {
		let total =
			constants.SAVINGS_PAGE_ESTIMATES.BILL_REDUCTION +
			constants.SAVINGS_PAGE_ESTIMATES.INSURANCE;

		if (signUpType === "property_only") {
			return formatDollar(total) + "/yr";
		}

		const state = address?.state;

		const savingsCopyType = getSavingsCopyType({
			nonAutocompleteParcelNumber,
			savingsData,
			addressRaw,
		});

		if (
			savingsData &&
			!isGeoLevelSavingsData(savingsData) &&
			savingsData.exemptionSavings &&
			savingsData.exemptionsInService
		) {
			if (!alreadySavingOnExemption) {
				total += savingsData.exemptionSavings.futureSavings;
				if (savingsData.exemptionSavings.retroSavings) {
					total += savingsData.exemptionSavings.retroSavings;
				}
			}
		}

		if (savingsData?.inService) {
			let appealSavings: number = 0;

			switch (savingsCopyType) {
				case "unknown-assessment":
					return formatDollar(total);
				case "targeted":
					return formatDollar(total + (savingsData?.amt ?? 0));
				default:
					appealSavings =
						Math.max(+savingsData?.amt, constants.MIN_APPEAL_SAVINGS) ?? 0;
					total += appealSavings;
			}
		}
		return formatDollar(total);
	}
};
