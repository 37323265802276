import React from "react";

export interface TileConfig {
	unselectedTileContent: React.ReactNode;
	selectedTileContent: React.ReactNode;
	isSelected: boolean;
	onSelect?: () => void;
	className?: string;
}

export const TileSelect: React.FC<
	{
		multiSelect?: boolean;
		tiles: TileConfig[];
	} & React.HTMLAttributes<HTMLDivElement>
> = ({ multiSelect = false, tiles, ...props }) => {
	return (
		<div className={["tile-options", props.className ?? ""].join(" ")}>
			{tiles.map((tile, i) => {
				const { onSelect } = tile;
				return (
					<div
						key={i}
						onClick={onSelect}
						className={["tile-option", tile.isSelected ? "selected" : ""].join(
							" "
						)}>
						{tile.isSelected
							? tile.selectedTileContent
							: tile.unselectedTileContent}
					</div>
				);
			})}
		</div>
	);
};
