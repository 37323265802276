import { useEffect, useState, useMemo, useCallback } from "react";
import client from "utils/client";
import { usePropertyContext } from "../../context";
import QuestionCard from "components/elements/QuestionCard";
import { OneZeroOrNull } from "utils/types";
import { TileSelect } from "elements/TileSelect";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import ButtonV2 from "elements/ButtonV2";

const PrimaryResidence = () => {
	const { details, activeQuestionId, send, next, propertySurveyContext } =
		usePropertyContext();

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const handleSkip = () => {
		next(true);
	};

	const handleSubmit = async (payload?: { value: OneZeroOrNull }) => {
		if (payload) {
			send({
				type: "update_context",
				context: { q_primary_residence: payload.value },
			});
		}

		try {
			setSubmitting(true);
			setError(false);
			await client.submitQuestionAnswer({
				questionId: activeQuestionId,
				appealId: details!.appeal_id,
				payload: {
					primaryResidence: !!(payload
						? payload.value
						: propertySurveyContext.q_primary_residence),
				},
			});
			next();
		} catch (e) {
			console.error("ERROR", e);
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	const tiles = useMemo(
		() => [
			{
				unselectedTileContent: "Yes",
				selectedTileContent: "Yes",
				isSelected: propertySurveyContext.q_primary_residence === 1,
				onSelect: () => {
					handleSubmit({ value: 1 });
				},
			},
			{
				unselectedTileContent: "No",
				selectedTileContent: "No",
				isSelected: propertySurveyContext.q_primary_residence === 0,
				onSelect: () => {
					handleSubmit({ value: 0 });
				},
			},
		],
		[propertySurveyContext.q_primary_residence]
	);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}>
			<QuestionCard
				id="primary-residence"
				tooltipText="Property owners can receive discounts on their property taxes on their primary residence. Your property tax appeal will have a more favorable outcome in some jurisdictions if a property is owner-occupied."
				question={
					<p className="lg bold">Is this property your primary residence?</p>
				}>
				<TileSelect tiles={tiles} />
			</QuestionCard>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
			<ButtonV2
				size="large"
				disabled={propertySurveyContext.q_primary_residence === null}
				className="mx-auto"
				type="submit">
				Continue
			</ButtonV2>
			<p className="profile-question-skip" onClick={() => handleSkip()}>
				Skip <RightArrow />
			</p>
		</form>
	);
};

export default PrimaryResidence;
