import { TextField } from "components";
import { useEffect, useState } from "react";
import { formatDollarStr } from "utils/formatDollar";
import { withInsuranceSurvey } from "../withInsuranceSurvey";

export const InsuranceCurrentDeductibleQuestion = withInsuranceSurvey(
	({ dispatchAnswerState, setNextBtnDisabled, formRef, answerState }) => {
		const [value, setValue] = useState(
			answerState ? formatDollarStr(answerState) : ""
		);

		useEffect(() => {
			setNextBtnDisabled(!value);
		}, [value, setNextBtnDisabled]);

		useEffect(() => {
			if (!formRef.current || !value) return;

			const form = formRef.current;

			const handler = (e: SubmitEvent) => {
				e.preventDefault();
				dispatchAnswerState({
					id: "current_deductible",
					payload: value || "",
				});
			};

			form.addEventListener("submit", handler);

			return () => {
				form.removeEventListener("submit", handler);
			};
		}, [formRef, value, dispatchAnswerState]);

		return (
			<div>
				<p className="insurance-question-title">Current Deductible</p>
				<div>
					<TextField
						required
						placeholder="$"
						label="Deductible Amount"
						value={value}
						onChange={e => setValue(formatDollarStr(e.target.value) || "")}
					/>
				</div>
			</div>
		);
	}
);
