import { useEffect, useState } from "react";
import { withInsuranceSurvey } from "../withInsuranceSurvey";
import { Select } from "components";

export const INSURANCE_MARITAL_STATUS_OPTIONS = [
	{ label: "Single", value: "SINGLE" },
	{ label: "Married", value: "MARRIED" },
	{ label: "Widowed", value: "WIDOWED" },
];

export const InsuranceMaritalStatusQuestion = withInsuranceSurvey(
	({ setNextBtnDisabled, dispatchAnswerState, formRef, answerState }) => {
		const [maritalStatus, setMaritalStatus] = useState(answerState ?? "");

		useEffect(() => {
			setNextBtnDisabled(!maritalStatus);
		}, [maritalStatus, setNextBtnDisabled]);

		useEffect(() => {
			if (!formRef.current) return;

			const form = formRef.current;

			const handler = (e: SubmitEvent) => {
				e.preventDefault();
				dispatchAnswerState({
					id: "marital_status",
					payload: maritalStatus,
				});
			};

			form.addEventListener("submit", handler);

			return () => {
				form?.removeEventListener("submit", handler);
			};
		}, [formRef, maritalStatus, dispatchAnswerState]);

		return (
			<div>
				<p className="insurance-question-title">Marital Status</p>
				<Select
					required
					value={maritalStatus}
					onChange={e => setMaritalStatus(e.target.value)}>
					<option value="" hidden disabled>
						Select a status
					</option>
					{INSURANCE_MARITAL_STATUS_OPTIONS.map(({ label, value }) => (
						<option key={label} value={value}>
							{label}
						</option>
					))}
				</Select>
			</div>
		);
	}
);
