import { usePropertyContext } from "components/pages/Account/Property/context";
import QuestionCard from "elements/QuestionCard";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import ButtonV2 from "elements/ButtonV2";
import {
	EquityFieldsProvider,
	EquityFields,
	EquityFieldsContext,
} from "../../EditEquityDetails";

export const MortgageAndEquity = () => {
	const { next } = usePropertyContext();

	const handleSkip = () => {
		next(true);
	};

	return (
		<EquityFieldsProvider>
			<EquityFieldsContext.Consumer>
				{({
					submitEquityDetails,
					allFieldsFilled,
					patchSubmitting,
					patchError,
				}) => {
					return (
						<form
							onSubmit={async e => {
								e.preventDefault();
								await submitEquityDetails();
								next();
							}}>
							<QuestionCard
								id="mortgage-and-equity"
								tooltipText=""
								question={
									<p className="bold">
										Would you like to track changes in your property value,
										equity, ROI and other insights?
									</p>
								}>
								<div className="space-y-2">
									<EquityFields />
								</div>
							</QuestionCard>
							<ButtonV2
								size="large"
								className="mx-auto"
								disabled={!allFieldsFilled}>
								{patchSubmitting ? "Submitting..." : "Submit"}
							</ButtonV2>
							{patchError && (
								<p className="sm text-center terracotta">
									Whoops, something went wrong. Try again.
								</p>
							)}
							<p className="profile-question-skip" onClick={() => handleSkip()}>
								Skip <RightArrow />
							</p>
						</form>
					);
				}}
			</EquityFieldsContext.Consumer>
		</EquityFieldsProvider>
	);
};
