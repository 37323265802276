const dollarFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	maximumFractionDigits: 0,
	minimumFractionDigits: 0,
});

const dollarFormatterWithCentsAlways = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	maximumFractionDigits: 2,
	minimumFractionDigits: 2,
});

const dollarFormatterWithCentsWhenNecessary = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	maximumFractionDigits: 2,
	minimumFractionDigits: 0,
});

interface FormatDollarOptions {
	withCents?: "always" | "when-necessary";
}

const formatDollar = (value: number, options?: FormatDollarOptions): string => {
	if (options?.withCents) {
		if (options.withCents === "always") {
			return dollarFormatterWithCentsAlways.format(value);
		} else if (options.withCents === "when-necessary") {
			const val = dollarFormatterWithCentsWhenNecessary.format(value);

			if (val.endsWith(".00")) {
				return val.replace(".00", "");
			}

			return val;
		}
	}

	return dollarFormatter.format(value);
};

function formatDollarStr(
	val: string | number,
	options?: FormatDollarOptions
): string | undefined {
	let suffix = "";

	if (typeof val === "string") {
		const orig = val;
		if (val === "" || val === "$") return "";
		val = parseFloat(val);
		if (isNaN(val)) val = parseFloat(orig.replace(/[^0-9\.]/g, ""));
		if (isNaN(val)) return;

		if (orig.endsWith(".")) {
			suffix = ".";
		}
		if (orig.endsWith(".0")) {
			suffix = ".0";
		}
	}

	return formatDollar(val, options) + suffix;
}

export const formatDollarWithK = (value: string) => {
	if (!value) {
		value = "0";
	}

	const valueNum = Number(value);

	const formatted = formatDollar(valueNum);
	const split = formatted.split(",");

	if (split.length === 2) return split[0] + "k";

	const numsOnly = formatted.replace(/\D/g, "");

	let suffix = "",
		exp = 0;

	if (split.length === 3) (exp = 6), (suffix = "m");
	if (split.length === 4) (exp = 9), (suffix = "b");

	return "$" + (parseInt(numsOnly) / Math.pow(10, exp)).toFixed() + suffix;
};

export { formatDollarStr };

export default formatDollar;
