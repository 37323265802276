import React from "react";
import { useRouter } from "next/router";
import sendWebhook, { sendErrorToWebhook } from "utils/sendWebhook";
import {
	ExactSavingsData,
	IAddress,
	SavingsData,
	SavingsType,
} from "utils/types";
import client from "utils/client";
import { nanoid } from "nanoid";

export type OwnerInfo = {
	owner_name?: string;
	first_name?: string;
	last_name?: string;
	company_title?: string;
	company_type?: string;
	owned_by_entity?: boolean;
};

export type PortfolioPropertyPreview = {
	temp_id?: string;
	address: IAddress;
	savingsData: SavingsData;
	isMailer: boolean;
	savingsType: SavingsType;
	contingency: number;
	owner_info?: OwnerInfo;
	recent_purchase?: boolean;
};

type PortfolioSignupData = {
	email: string;
	phone: string;
	firstName: string;
	lastName: string;
	portfolioPropertyPreviews: PortfolioPropertyPreview[];
	landing_page_source?: string;
};

const PortfolioSignupContext = React.createContext<
	PortfolioSignupData & {
		backBtnFnOverride: (() => void) | null;
		setBackBtnFnOverride: React.Dispatch<
			React.SetStateAction<(() => void) | null>
		>;
		tryingToClose: boolean;
		setTryingToClose: React.Dispatch<React.SetStateAction<boolean>>;
		eligiblePortfolioProperties: PortfolioPropertyPreview[];
		ineligiblePortfolioProperties: PortfolioPropertyPreview[];
		setPortfolioSignupData: React.Dispatch<
			React.SetStateAction<PortfolioSignupData>
		>;
		setPortfolioSignupValues: (values: Partial<PortfolioSignupData>) => void;
		resetPortfolioSignupFlow: () => void;
		savingsLoading: boolean;
		setSavingsLoading: React.Dispatch<React.SetStateAction<boolean>>;
		savingsError: boolean;
		setSavingsError: React.Dispatch<React.SetStateAction<boolean>>;
		getSavings: (
			address: IAddress,
			options: { autoCompleteApn: boolean }
		) => void;
	}
>({
	email: "",
	phone: "",
	firstName: "",
	lastName: "",
	portfolioPropertyPreviews: [],
	backBtnFnOverride: null,
	setBackBtnFnOverride: () => {},
	tryingToClose: false,
	setTryingToClose: () => {},
	eligiblePortfolioProperties: [],
	ineligiblePortfolioProperties: [],
	setPortfolioSignupData: () => {},
	setPortfolioSignupValues: () => {},
	resetPortfolioSignupFlow: () => {},
	savingsLoading: false,
	setSavingsLoading: () => {},
	savingsError: false,
	setSavingsError: () => {},
	getSavings: () => {},
});

export const usePortfolioSignupContext = () => {
	return React.useContext(PortfolioSignupContext);
};

export const PortfolioSignupContextProvider: React.FC = ({ children }) => {
	const [hydrating, setHydrating] = React.useState(true);
	const [savingsError, setSavingsError] = React.useState(false);
	const [savingsLoading, setSavingsLoading] = React.useState(false);

	const [tryingToClose, setTryingToClose] = React.useState(false);
	const router = useRouter();

	const [portfolioSignupData, setPortfolioSignupData] =
		React.useState<PortfolioSignupData>({
			email: "",
			phone: "",
			firstName: "",
			lastName: "",
			portfolioPropertyPreviews: [],
		});

	const setPortfolioSignupValues = React.useCallback(
		(values: Partial<PortfolioSignupData>) => {
			setPortfolioSignupData(prev => ({
				...prev,
				...values,
			}));
		},
		[setPortfolioSignupData]
	);

	const [backBtnFnOverride, setBackBtnFnOverride] = React.useState<
		(() => void) | null
	>(null);

	const eligiblePortfolioProperties = React.useMemo(() => {
		return portfolioSignupData.portfolioPropertyPreviews.filter(
			ppp => ppp.savingsData.inService
		);
	}, [portfolioSignupData.portfolioPropertyPreviews]);

	const ineligiblePortfolioProperties = React.useMemo(() => {
		return portfolioSignupData.portfolioPropertyPreviews.filter(
			ppp => !ppp.savingsData.inService
		);
	}, [portfolioSignupData.portfolioPropertyPreviews]);

	const resetPortfolioSignupFlow = React.useCallback(() => {
		try {
			setPortfolioSignupData({
				email: "",
				phone: "",
				firstName: "",
				lastName: "",
				portfolioPropertyPreviews: [],
			});
			window.sessionStorage.removeItem("portfolioSessionSignupData");
		} catch {
			sendErrorToWebhook(
				"sessionStorage not available in resetPortfolioSignupFlow"
			);
		}
	}, []);

	React.useEffect(() => {
		if (!router.isReady || hydrating) return;
		if (
			router.pathname.startsWith("/appeal/portfolio") ||
			router.pathname.startsWith("/appeal/portfolio/contact") ||
			router.pathname.startsWith("/appeal/multi")
		) {
			return;
		}
		resetPortfolioSignupFlow();
	}, [router.isReady, hydrating, router.pathname, resetPortfolioSignupFlow]);

	React.useEffect(() => {
		const query_landing_page_source = router.query?.landing_page_source;
		if (
			typeof query_landing_page_source === "string" &&
			["investors", "commercial"].includes(query_landing_page_source)
		) {
			setPortfolioSignupData(prev => ({
				...prev,
				landing_page_source: query_landing_page_source,
			}));
		}
	}, [router.query]);

	React.useEffect(() => {
		if (typeof window === "undefined") {
			setHydrating(false);
			return;
		}

		if (!router.isReady) return;

		try {
			const portfolioSessionSignupDataStr = window.sessionStorage.getItem(
				"portfolioSessionSignupData"
			);

			if (!portfolioSessionSignupDataStr) {
				setHydrating(false);
				return;
			}

			const portfolioSessionSignupData = JSON.parse(
				portfolioSessionSignupDataStr
			);

			if (!portfolioSessionSignupData) {
				setHydrating(false);
				return;
			}

			setPortfolioSignupData(portfolioSessionSignupData);

			setHydrating(false);
		} catch {
			sendErrorToWebhook("sessionStorage not available in AppealContext");
		}
	}, [router.isReady]);

	React.useEffect(() => {
		if (hydrating) return;
		try {
			window.sessionStorage.setItem(
				"portfolioSessionSignupData",
				JSON.stringify(portfolioSignupData)
			);
		} catch {
			sendErrorToWebhook("sessionStorage not available in AppealContext");
		}
	}, [hydrating, portfolioSignupData]);

	const getSavings = async (
		address: IAddress,
		options: { autoCompleteApn: boolean }
	) => {
		try {
			setSavingsError(false);
			setSavingsLoading(true);

			let savingsData: SavingsData;

			let newPortfolioPropertyPreview: Partial<PortfolioPropertyPreview> = {
				temp_id: nanoid(),
				address,
			};

			if (options.autoCompleteApn) {
				const { data } = await Promise.allSettled([
					client.getSavingsFromGeocoding(address),
					new Promise(resolve => setTimeout(resolve, 1000)),
				]).then(([result]) => {
					if (result.status === "rejected") throw result.reason;
					return result.value;
				});

				savingsData = data;

				if (address && !address.county && savingsData.county) {
					newPortfolioPropertyPreview["address"] = {
						...address,
						county: savingsData.county ?? address.county,
					};
				}

				newPortfolioPropertyPreview["savingsType"] = "exact";
				newPortfolioPropertyPreview["isMailer"] = !!savingsData.score;
				newPortfolioPropertyPreview["savingsData"] = savingsData;

				if (savingsData.discount) {
					newPortfolioPropertyPreview["contingency"] = savingsData.discount;
				}
				newPortfolioPropertyPreview["owner_info"] = {
					owner_name: savingsData.owner_name,
				};
			} else {
				const response = await client.getCountyWideSavings({
					county: address.county as string,
					state: address.state,
				});

				savingsData = response.data;

				newPortfolioPropertyPreview["savingsType"] = savingsData.savingsType;
				newPortfolioPropertyPreview["isMailer"] = false;
				newPortfolioPropertyPreview["savingsData"] = savingsData;
				newPortfolioPropertyPreview["owner_info"] = {
					owner_name: undefined,
				};
			}

			setPortfolioSignupData(prev => {
				// prevent duplicates from being added
				if (
					prev.portfolioPropertyPreviews?.find(
						ppp => JSON.stringify(ppp.address) === JSON.stringify(address)
					)
				) {
					return prev;
				}

				return {
					...prev,
					portfolioPropertyPreviews: [
						...prev.portfolioPropertyPreviews,
						newPortfolioPropertyPreview as PortfolioPropertyPreview,
					],
				};
			});
		} catch (e: any) {
			setSavingsError(true);
			console.error(e);
			const err = e instanceof Error ? e : new Error(e);
			const addressJson = "```json\n" + JSON.stringify(address) + "\n```";
			sendWebhook(
				`**ERROR**: Error caught in PortfolioAddPropertiesPage getSavings\n${err.toString()}\nAddress: ${addressJson}`
			);
		} finally {
			setSavingsLoading(false);
		}
	};

	return (
		<PortfolioSignupContext.Provider
			value={{
				backBtnFnOverride,
				setBackBtnFnOverride,
				tryingToClose,
				setTryingToClose,
				setPortfolioSignupData,
				setPortfolioSignupValues,
				...portfolioSignupData,
				eligiblePortfolioProperties,
				ineligiblePortfolioProperties,
				resetPortfolioSignupFlow,
				savingsLoading,
				setSavingsLoading,
				savingsError,
				setSavingsError,
				getSavings,
			}}>
			{children}
		</PortfolioSignupContext.Provider>
	);
};
