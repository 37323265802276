import { v4 as uuid } from "uuid";

const SESSION_ID_KEY = "sessionId";

export const getSessionId = (): string => {
	if (typeof window === "undefined" || !window.sessionStorage) return "";

	let sessionId = window.sessionStorage.getItem(SESSION_ID_KEY);

	if (!sessionId) {
		sessionId = uuid();

		window.sessionStorage.setItem(SESSION_ID_KEY, sessionId);
	}

	return sessionId;
};
