import React, { useEffect, useState } from "react";
import { usePropertyContext } from "../../context";
import ConciergeGuy from "public/account/survey/concierge/concierge-guy.svg";
import { SavingsBox } from "../../Concierge/ConciergeOptionCard";
import { ConciergeFeatures } from "../../Concierge/ConciergeOptionCard";
import { ConciergeProFeatures } from "../../Concierge/ConciergeProOptionCard";
import PayInvoicePaymentForm from "components/pages/PayInvoice/PayInvoicePaymentForm";
import client from "utils/client";
import ButtonV2 from "elements/ButtonV2";
import Image from "next/image";
import PaymentSuccessfulIcon from "public/invoice-paid.gif";
import { useResponsiveContext } from "context/ResponsiveContext";
import { set } from "nprogress";

type PlanPricing = {
	[plan: string]: {
		[cycle: string]: number;
		strikeout: number;
	};
};

const ConciergePayment: React.FC<{
	showSkip?: boolean;
	overrideNext?: () => void;
	handleContinueClick?: () => void;
}> = ({ showSkip = false, overrideNext, handleContinueClick }) => {
	const { next, propertySurveyContext, details } = usePropertyContext();
	const { isMobile } = useResponsiveContext();

	const [billingCycle, setBillingCycle] = useState("");
	const [conciergePlan, setConciergePlan] = useState("");
	const [paymentSuccess, setPaymentSuccess] = useState(false);
	const [isExpanded, setIsExpanded] = useState(true);
	const [errored, setErrored] = useState(false);

	useEffect(() => {
		const accountTier = propertySurveyContext.account_tier;
		if (accountTier) {
			const lastHyphenIndex = accountTier.lastIndexOf("-");
			const before =
				lastHyphenIndex !== -1
					? accountTier.slice(0, lastHyphenIndex)
					: accountTier;
			const after =
				lastHyphenIndex !== -1 ? accountTier.slice(lastHyphenIndex + 1) : "";

			// Ensure values match allowed types
			if (before === "concierge" || before === "concierge-pro") {
				setConciergePlan(before);
			}
			if (after === "annually" || after === "monthly") {
				setBillingCycle(after);
			}
		}
	}, [propertySurveyContext.account_tier]);

	const planPricing: PlanPricing = {
		concierge: {
			annually: 99,
			monthly: 10,
			strikeout: 120,
		},
		"concierge-pro": {
			annually: 510,
			monthly: 50,
			strikeout: 600,
		},
	};

	const onFormSubmit = async (
		card_number: string,
		card_cvc: string,
		card_exp_month: string,
		card_exp_year: string
	) => {
		try {
			const response = await client.payConciergeInvoice({
				product: conciergePlan,
				cadence: billingCycle,
				card_number,
				card_cvc,
				card_exp_month,
				card_exp_year,
			});
			if (response.status !== 200) {
				setErrored(true);
				console.error("Failed to pay invoice");
				return;
			} else {
				setPaymentSuccess(true);
			}
		} catch (err) {
			setErrored(true);
			console.error(err);
		}
	};

	const showHideFeatures = () => {
		setIsExpanded(!isExpanded);
	};

	const trackFieldValidation = (fieldName: string) => {};

	const handleContinue = () => {
		if (handleContinueClick) {
			handleContinueClick();
		} else {
			next();
		}
	};

	return (
		<div className="concierge-payment-wrapper">
			{paymentSuccess ? (
				<div className="payment-success-card">
					<div id="pay-invoice-payment-successful-animation-container">
						<Image
							id="pay-invoice-payment-successful-animation"
							src={PaymentSuccessfulIcon}
							alt="Invoice Paid Animation"
						/>
					</div>
					<p>
						<b>Payment Received!</b>
					</p>
					<p className="sm mt-1">
						We will send a receipt to the account email.
					</p>
					<ButtonV2
						variant="primary"
						size="extra-small"
						className="w-100 next-btn"
						onClick={handleContinue}>
						Continue
					</ButtonV2>
				</div>
			) : (
				<>
					<div className="plan-details-card">
						{isMobile && (
							<p className="show-hide-toggle-btn xs" onClick={showHideFeatures}>
								Show me {isExpanded ? "less" : "more"}
							</p>
						)}
						<p className="card-title xl bold">
							Ownwell{" "}
							{conciergePlan == "concierge"
								? "Concierge"
								: conciergePlan == "concierge-pro"
									? "Concierge Pro"
									: ""}
						</p>
						<div className="car-body">
							{conciergePlan.includes("concierge") && billingCycle != "" && (
								<>
									<div className="plan-pricing">
										{billingCycle == "annually" && (
											<p className="strike-out-amount">
												<span className="strike-out">
													${planPricing[conciergePlan].strikeout}
												</span>
												<span className="xs">/year</span>
											</p>
										)}
										<p className="plan-amount font-40">
											${planPricing[conciergePlan][billingCycle]}
											<span className="xs">
												/{billingCycle == "annually" ? "year" : "month"}
											</span>
										</p>
										<p className="contingency">
											{details?.contingency}% Contingency
										</p>
									</div>
									<div className="concirege-img-container">
										<ConciergeGuy />
									</div>
								</>
							)}
						</div>
						<div className="card-footer">
							<SavingsBox />
							{isExpanded && (
								<>
									{conciergePlan === "concierge" && <ConciergeFeatures />}
									{conciergePlan === "concierge-pro" && (
										<ConciergeProFeatures />
									)}
								</>
							)}
						</div>
					</div>
					<div className="payemnt-card-wrapper">
						<div className="payment-card-container">
							{conciergePlan != "" && billingCycle != "" && (
								<PayInvoicePaymentForm
									errored={errored}
									setErrored={setErrored}
									trackFieldValidation={trackFieldValidation}
									onFormSubmit={onFormSubmit}
									amount={planPricing[conciergePlan][billingCycle].toString()}
								/>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default ConciergePayment;
