import { DetailedHTMLProps, FC, HTMLAttributes } from "react";

const Grid: FC<
	DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ children, ...divProps }) => {
	return (
		<div
			{...divProps}
			className={divProps.className ? "grid " + divProps.className : "grid"}>
			{children}
		</div>
	);
};

export default Grid;
