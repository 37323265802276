import React from "react";

export function useOutsideClickWithRef<T extends Node = HTMLDivElement>(
	ref: React.RefObject<T>,
	callback: () => void
): React.RefObject<T> {
	React.useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				callback();
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);

		// Cleanup: Remove event listener on component unmount
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, callback]);

	return ref;
}

export default function useOutsideClick<T extends Node = HTMLDivElement>(
	callback: () => void
): React.RefObject<T> {
	const ref = React.useRef<T>(null);

	return useOutsideClickWithRef(ref, callback);
}
