import { TextField } from "components";
import moment from "moment";
import { useEffect, useState } from "react";
import { withInsuranceSurvey } from "../withInsuranceSurvey";

type Limits = {
	max?: number;
	min?: number;
};

const CURRENT_YEAR_DIGITS = new Date()
	.getFullYear()
	.toString()
	.split("")
	.map(Number);

const createInput = (args: {
	index: number;
	value: string;
	onChange: (val: string, i: number, limits?: Limits) => void;
	placeholder: string;
	limits?: Limits;
}) => (
	<TextField
		required
		containerClassName="insurance-birthday-input"
		inputMode="numeric"
		placeholder={args.placeholder}
		pattern="[0-9]*"
		value={args.value}
		onKeyDown={e => e.key === "Backspace" && args.onChange(e.key, args.index)}
		onChange={e => {
			if (e.target.value) {
				args.onChange(e.target.value.slice(-1), args.index, args.limits);
			}
		}}
	/>
);

export const InsuranceBirthdayQuestion = withInsuranceSurvey(
	({ formRef, setNextBtnDisabled, dispatchAnswerState, answerState }) => {
		const [values, setValues] = useState<string[]>(
			answerState
				? moment(answerState)
						.format("MM/DD/YYYY")
						.split("")
						.filter(v => !isNaN(+v))
				: Array(8).fill("")
		);

		useEffect(() => {
			const valid = values.every(v => v);

			if (!formRef.current || !valid) return;

			const form = formRef.current;

			const handler = (e: SubmitEvent) => {
				e.preventDefault();
				dispatchAnswerState({
					id: "birthday",
					payload: [
						...values.slice(0, 2),
						"/",
						...values.slice(2, 4),
						"/",
						...values.slice(4),
					].join(""),
				});
			};

			form.addEventListener("submit", handler);

			return () => {
				form.removeEventListener("submit", handler);
			};
		}, [formRef, values, dispatchAnswerState]);

		useEffect(() => {
			const valid = values.every(v => v);
			setNextBtnDisabled(!valid);
		}, [values, setNextBtnDisabled]);

		const onChange = (value: string, i: number, limits?: Limits) => {
			const form = formRef.current;
			//@ts-ignore
			const formEls = [...form];

			if (!form) return;

			if (!Number.isNaN(Number(value))) {
				if (values && values[i].length) {
					formEls[i + 1]?.focus();
				}

				if (typeof limits?.max === "number" && Number(value) > limits.max)
					return;
				if (typeof limits?.min === "number" && Number(value) < limits.min)
					return;

				setValues(p => {
					const copy = [...p];

					copy[i] = value;

					return copy;
				});
				formEls[i + 1]?.focus();
			}

			if (value === "Backspace") {
				if (!values[i]) {
					formEls[i - 1]?.focus();
				}

				setValues(p => {
					const copy = [...p];

					const index = values[i] ? i : i - 1;

					copy[index] = "";

					return copy;
				});

				return;
			}
		};

		const baseArgs: {
			index: number;
			value: string;
			onChange: typeof onChange;
		}[] = Array(8)
			.fill([])
			.map((_, index) => ({ index, value: values[index], onChange }));

		return (
			<div>
				<p className="insurance-question-title">Date of Birth</p>
				<div className="insurance-birthday-input-container">
					{createInput({
						...baseArgs[0],
						placeholder: "M",
						limits: { max: 1 },
					})}
					{createInput({
						...baseArgs[1],
						placeholder: "M",
						limits: {
							min: values[0] === "0" ? 1 : undefined,
							max: values[0] === "1" ? 2 : undefined,
						},
					})}
					<div className="insurance-birthday-slash" />
					{createInput({
						...baseArgs[2],
						placeholder: "D",
						limits: { max: 3 },
					})}
					{createInput({
						...baseArgs[3],
						placeholder: "D",
						limits: {
							min: values[2] === "0" ? 1 : undefined,
							max: values[2] === "3" ? 1 : 9,
						},
					})}
					<div className="insurance-birthday-slash" />
					{createInput({
						...baseArgs[4],
						placeholder: "Y",
						limits: { min: 1, max: 2 },
					})}
					{createInput({
						...baseArgs[5],
						placeholder: "Y",
						limits: {
							min: values[4] === "1" ? 9 : 0,
							max: values[4] === "2" ? 0 : 9,
						},
					})}
					{createInput({
						...baseArgs[6],
						placeholder: "Y",
						limits: {
							max:
								values[4] === "2" &&
								values[5] ===
									CURRENT_YEAR_DIGITS[CURRENT_YEAR_DIGITS.length - 3].toString()
									? CURRENT_YEAR_DIGITS[CURRENT_YEAR_DIGITS.length - 2]
									: 9,
						},
					})}
					{createInput({
						...baseArgs[7],
						placeholder: "Y",
						limits: {
							max:
								values[4] === "2" &&
								values[6] ===
									CURRENT_YEAR_DIGITS[CURRENT_YEAR_DIGITS.length - 2].toString()
									? CURRENT_YEAR_DIGITS[CURRENT_YEAR_DIGITS.length - 1]
									: 9,
						},
					})}
				</div>
			</div>
		);
	}
);
