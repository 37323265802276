import { useState, useEffect, useCallback } from "react";
import client from "utils/client";
import { MortgageRates } from "utils/types";

const MORTGAGE_RATES_KEY = "currentMortgageRates";
const MORTGAGE_RATES_EXPIRY_KEY = "mortgageRatesExpiry";

const getMidnightTimestamp = () => {
	const now = new Date();
	const target = new Date(now);
	target.setUTCHours(13, 0, 0, 0);
	return target.getTime();
};

const useMortgageRates = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [currentMortgageRateData, setCurrentMortgageRateData] =
		useState<MortgageRates.GetCurrent.Response | null>(null);

	const fetchCurrentMortgageRates = useCallback(async () => {
		try {
			setLoading(true);
			const { data } = await Promise.allSettled([
				client.getCurrentMortgageRates(),
			]).then(([result]) => {
				if (result.status === "rejected") throw result.reason;
				return result.value;
			});

			setCurrentMortgageRateData(data);
			localStorage.setItem(MORTGAGE_RATES_KEY, JSON.stringify(data));
			localStorage.setItem(
				MORTGAGE_RATES_EXPIRY_KEY,
				JSON.stringify(getMidnightTimestamp())
			);
		} catch (err: any) {
			setError(true);
		} finally {
			setLoading(false);
		}
	}, []);

	const isDataExpired = useCallback(() => {
		const expiry = localStorage.getItem(MORTGAGE_RATES_EXPIRY_KEY);
		return !expiry || Date.now() > JSON.parse(expiry);
	}, []);

	useEffect(() => {
		const cachedData = localStorage.getItem(MORTGAGE_RATES_KEY);

		if (cachedData && !isDataExpired()) {
			setCurrentMortgageRateData(JSON.parse(cachedData));
		} else {
			fetchCurrentMortgageRates();
		}
	}, [fetchCurrentMortgageRates, isDataExpired]);

	return { currentMortgageRateData, loading, error };
};

export default useMortgageRates;
