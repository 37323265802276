import tlds from "public/tlds.json";
import IsEmail from "isemail";

const emailValidRegex =
	/^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

const isValidEmail = (email: string) => {
	if (!emailValidRegex.test(email)) {
		return false;
	}

	if (IsEmail.validate(email)) {
		const tld = email.split(".").slice(-1)[0];

		return tld ? tlds.includes(tld.toUpperCase()) : false;
	}

	return false;
};

export default isValidEmail;
