import Button from "elements/Button";
import FileUploadIcon from "public/upload-file.svg";
import FileUploadErrorIcon from "public/file-upload-error.svg";
import FileUploadActiveIcon from "public/upload-file-active.svg";
import {
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import Label from "./Label";
import DocumentFileIcon from "public/document-file-icon.svg";
import ImageFileIcon from "public/image-file-icon.svg";
import SpreadsheetFileIcon from "public/xls-file-icon.svg";
import InformationIcon from "public/information.svg";
import CrossIcon from "assets/imgs/cross.svg";
import { AxiosError, AxiosProgressEvent } from "axios";
import { motion } from "framer-motion";
import { useEventTracker } from "utils/useEventTracker";
import constants from "utils/constants";
import ButtonV2 from "elements/ButtonV2";

const MAX_NUM_FILES_UPLOAD = 10;

const DRIVERS_LICENSE_ACCEPTED_FILE_TYPES = [
	//image types
	"image/jpeg",
	"image/png",
	"image/heic",
	//.pdf
	"application/pdf",
]; //Don't forget to change the user prompt to match what files we accept

const ACCEPTED_FILE_TYPES = [
	//image types
	"image/jpeg",
	"image/png",
	"image/heic",
	//.doc
	"application/msword",
	//.docx
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	//.csv
	"text/csv",
	//.pdf
	"application/pdf",
	//.txt
	"text/plain",
	//.xls
	"application/vnd.ms-excel",
	//.xlsx
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const getIcon = (fileName: string) => {
	const fileNameSplit = fileName.split(".");
	const extenstion: string | undefined =
		fileNameSplit[fileNameSplit.length - 1];

	if (
		["gif", "jpg", "jpeg", "png", "heic"].includes(
			extenstion?.toLowerCase() || ""
		)
	) {
		return ImageFileIcon;
	}
	if (["xls", "csv", "xlsx"].includes(extenstion?.toLowerCase() || "")) {
		return SpreadsheetFileIcon;
	}
	return DocumentFileIcon;
};

export type FileUploadType = {
	status: "staged" | "pending" | "success" | "error" | "already-uploaded";
	file: File;
	progress?: AxiosProgressEvent;
	tryAgain?: () => Promise<void>;
};

export const splitFileUploads = async <T,>({
	filesToUpload,
	updateFilesToUpload,
	buildRequest,
}: {
	filesToUpload: FileUploadType[];
	updateFilesToUpload: (
		fn: (prev: FileUploadType[]) => FileUploadType[]
	) => void;
	buildRequest: (fileUpload: FileUploadType) => Promise<T>;
}) => {
	const uploadRequests: Array<() => Promise<void>> = [];
	const uploadErrors: Error[] = [];
	const uploadSuccess: Array<FileUploadType & { result: T }> = [];
	for (const fileUpload of filesToUpload) {
		if (fileUpload.status === "success") continue;
		const tryUpload = async () => {
			try {
				updateFilesToUpload(prev =>
					prev.map(fu => {
						if (fu.file !== fileUpload.file) return fu;
						return {
							...fu,
							status: "pending",
						};
					})
				);

				// Uncomment to test error handling
				// if (
				// 	[
				// 		"upload-docs-test-51435125-1251-1235123-1235.jpg",
				// 		"upload-docs-test-1.jpg",
				// 	].includes(fileUpload.file.name)
				// ) {
				// 	await new Promise((resolve, reject) =>
				// 		setTimeout(() => reject(new AxiosError("")), 3000)
				// 	);
				// }

				const result = await buildRequest(fileUpload);
				uploadSuccess.push({ ...fileUpload, status: "success", result });
				updateFilesToUpload(prev =>
					prev.map(fu => {
						if (fu.file !== fileUpload.file) return fu;
						return {
							...fu,
							status: "success",
						};
					})
				);
			} catch (e: any) {
				uploadErrors.push(e);
				updateFilesToUpload(prev =>
					prev.map(fu => {
						if (fu.file !== fileUpload.file) return fu;
						return {
							...fu,
							status: "error",
						};
					})
				);
			}
		};
		updateFilesToUpload(prev =>
			prev.map(fu => {
				if (fu.file !== fileUpload.file) return fu;
				return {
					...fu,
					tryAgain: tryUpload,
				};
			})
		);
		uploadRequests.push(tryUpload);
	}

	await Promise.allSettled([...uploadRequests.map(fn => fn())]);

	return {
		success: uploadSuccess,
		errors: uploadErrors,
	};
};

export const getFileUploadButtonCopy = (filesToUpload: FileUploadType[]) => {
	if (filesToUpload.every(fileToUpload => fileToUpload.status === "success")) {
		return "Complete";
	}

	if (filesToUpload.some(fileToUpload => fileToUpload.status === "pending")) {
		return "Uploading...";
	}

	if (filesToUpload.some(fileToUpload => fileToUpload.status === "error")) {
		return "Continue";
	}

	return "Upload";
};

const FileUploadStatus: React.FC<{
	fileUpload: FileUploadType;
	updateFilesToUpload: (
		fn: (prev: FileUploadType[]) => FileUploadType[]
	) => void;
}> = ({ fileUpload, updateFilesToUpload }) => {
	const Icon = getIcon(fileUpload.file.name || "");

	const trackEvent = useEventTracker();

	useEffect(() => {
		if (fileUpload.status === "error") {
			trackEvent({
				eventName: constants.EVENTS.File_Upload_Failed,
				data: {
					"File Size": fileUpload.file.size,
				},
			});
		}
	}, []);

	return (
		<div className={`file-upload-results-file file-${fileUpload.status}`}>
			<div className="file-upload-results-file-left-side">
				{fileUpload?.progress?.progress && fileUpload.status === "pending" && (
					<motion.div
						className="file-upload-results-status-progress-bar"
						initial={{ width: 0 }}
						animate={{
							width: `${100 * fileUpload.progress.progress}%`,
						}}
						transition={{ duration: 0.4 }}
					/>
				)}

				<div className="file-icon-name-status">
					{["staged", "error", "success"].includes(fileUpload.status) && (
						<Icon className={"file-upload-results-file-icon"} />
					)}
					{fileUpload.status === "pending" && (
						<div className="file-upload-results-status-spinner" />
					)}
					<p className="no-translate">{fileUpload.file.name}</p>
				</div>

				{fileUpload.status === "error" && fileUpload.tryAgain && (
					<ButtonV2
						type="button"
						variant="primary-outline"
						size="mobile-extra-small"
						className="file-upload-results-try-again"
						onClick={async () => {
							if (fileUpload.tryAgain) {
								await fileUpload.tryAgain();
							}
						}}>
						Try Again
					</ButtonV2>
				)}
			</div>

			{["error", "staged"].includes(fileUpload.status) && (
				<div
					className="file-upload-results-remove"
					onClick={() => {
						updateFilesToUpload(oldFileUploads => {
							return oldFileUploads.filter(f => f.file !== fileUpload.file);
						});
					}}>
					<CrossIcon />
				</div>
			)}
		</div>
	);
};

interface IFileUploadProps {
	filesToUpload: FileUploadType[];
	updateFilesToUpload: (
		fn: (prev: FileUploadType[]) => FileUploadType[]
	) => void;
	alreadyUploaded?: string[];
	label?: string;
	numOfFilesAccepted?: number;
	isDriversLicense?: boolean;
	required?: boolean;
}

const FileResults: FC<{
	fileUploads: FileUploadType[];
	updateFilesToUpload: (
		fn: (prev: FileUploadType[]) => FileUploadType[]
	) => void;
	alreadyUploaded?: string[];
}> = ({ fileUploads, updateFilesToUpload, alreadyUploaded }) => {
	return (
		<div className="file-upload-results">
			{(fileUploads.some(fileUpload => fileUpload.status === "success") ||
				(alreadyUploaded && alreadyUploaded?.length > 0)) && (
				<div>
					<Label>Uploaded</Label>
					{alreadyUploaded?.map((fileName = "", i) => {
						const Icon = getIcon(fileName || "");
						const cleanFileName =
							fileName.split("-").slice(1).join("") || fileName;

						return (
							<div
								key={fileName + i}
								className="file-upload-results-file file-success">
								<div className="file-icon-name-status">
									<Icon className="file-upload-results-file-icon" />
									<p className="no-translate">{cleanFileName}</p>
								</div>
							</div>
						);
					})}
					{fileUploads.some(fileUpload => fileUpload.status === "success") && (
						<>
							{fileUploads
								.filter(fu => fu.status === "success")
								.map((fileUpload, i) => (
									<FileUploadStatus
										key={i}
										fileUpload={fileUpload}
										updateFilesToUpload={updateFilesToUpload}
									/>
								))}
						</>
					)}
				</div>
			)}

			{fileUploads.some(fileUpload =>
				["staged"].includes(fileUpload.status)
			) && (
				<div>
					<Label>Upload</Label>
					{fileUploads
						.filter(fu => ["staged"].includes(fu.status))
						.map((fileUpload, i) => (
							<FileUploadStatus
								key={i}
								fileUpload={fileUpload}
								updateFilesToUpload={updateFilesToUpload}
							/>
						))}
				</div>
			)}

			{fileUploads.some(fileUpload =>
				["pending"].includes(fileUpload.status)
			) && (
				<div>
					<Label>Uploading</Label>
					{fileUploads
						.filter(fu => ["pending"].includes(fu.status))
						.map((fileUpload, i) => (
							<FileUploadStatus
								key={i}
								fileUpload={fileUpload}
								updateFilesToUpload={updateFilesToUpload}
							/>
						))}
				</div>
			)}

			{fileUploads.some(fileUpload => fileUpload.status === "error") && (
				<div>
					<Label>Upload Errors</Label>
					<div id="property-documents-upload-failure-box">
						<FileUploadErrorIcon />
						<p className="sm">
							Oops! There was an error uploading your files. Please check your
							internet connection and the upload limits before trying again.
						</p>
					</div>

					{fileUploads
						.filter(fu => fu.status === "error")
						.map((fileUpload, i) => (
							<FileUploadStatus
								key={i}
								fileUpload={fileUpload}
								updateFilesToUpload={updateFilesToUpload}
							/>
						))}
				</div>
			)}
		</div>
	);
};

const FileUpload: FC<IFileUploadProps> = ({
	required,
	filesToUpload,
	updateFilesToUpload,
	label,
	alreadyUploaded,
	numOfFilesAccepted = MAX_NUM_FILES_UPLOAD,
	isDriversLicense = false,
}) => {
	const [err, setErr] = useState<FileRejection>();

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			updateFilesToUpload(p => [
				...p,
				...acceptedFiles.map(
					file => ({ status: "staged", file } as FileUploadType)
				),
			]);
		},
		[updateFilesToUpload]
	);

	const getErrorMessage = useCallback((code: string) => {
		switch (code) {
			case "file-invalid-type":
				return "Invalid file type";
			case "file-too-large":
				return "File exceeds max upload size of 20mb";
			case "file-0b":
				return "The file you uploaded has no content. If you are trying to upload a file from a file-sharing website (e.g., Google Drive, Dropbox), please make sure to download the file to your computer before uploading here.";
			case "file-exists":
				return "File has already been uploaded";
			case "too-many-files":
				return `You can only upload ${numOfFilesAccepted} file${
					numOfFilesAccepted > 1 ? "s" : ""
				} at a time`;
			default:
				return "Could not accept this file";
		}
	}, []);

	const fileValidator = (file: File) => {
		if (filesToUpload.length > numOfFilesAccepted) {
			return {
				code: "too-many-files",
				message: "",
			};
		}
		for (let i = 0; i < filesToUpload.length; i++) {
			const fileToCheck = filesToUpload[i].file;
			if (
				fileToCheck.name === file.name &&
				fileToCheck.size === file.size &&
				fileToCheck.lastModified === file.lastModified
			) {
				return {
					code: "file-exists",
					message: ``,
				};
			}
		}

		if (file.size === 0) {
			return {
				code: "file-0b",
				message: "",
			};
		}

		if (file.size > 20000000) {
			return {
				code: "file-too-large",
				message: "",
			};
		}
		return null;
	};

	const { getRootProps, isDragActive, getInputProps, open } = useDropzone({
		onDrop,
		noClick: true,
		noKeyboard: true,
		maxSize: 2 * Math.pow(10, 7),
		maxFiles: numOfFilesAccepted,
		accept: isDriversLicense
			? DRIVERS_LICENSE_ACCEPTED_FILE_TYPES
			: ACCEPTED_FILE_TYPES,
		validator: fileValidator,
		onDropRejected: e => {
			setErr(e[0]);
		},
		onDropAccepted: () => {
			setErr(undefined);
		},
	});

	return (
		<div className="file-upload-container">
			{label && <Label className="file-upload-label">{label}</Label>}
			<div
				className={
					"file-upload" + (isDragActive ? "-active" : err ? "-error" : "")
				}
				{...getRootProps()}>
				<input
					{...getInputProps()}
					required={required ? filesToUpload.length === 0 : false}
					style={{
						opacity: 0,
						width: 0,
						height: 0,
						display: "block",
						padding: 0,
					}}
				/>
				{isDragActive ? (
					<>
						<div className="file-upload-row">
							<FileUploadActiveIcon
								height="96px"
								width="96px"
								className="file-upload-icon-active"
							/>
						</div>
						<div className="file-upload-row">
							<p className="file-upload-active-text">
								Drag & drop to upload file
							</p>
						</div>
					</>
				) : (
					<>
						<div className="file-upload-title-row">
							<div className="mobile-hidden tablet-block">
								<p className="sm bold">Drag & drop</p>
							</div>
							<div className="mobile-hidden tablet-block">
								<p className="file-upload-or sm">or</p>
							</div>
							<div>
								<ButtonV2
									size="mobile-extra-small"
									onClick={e => {
										e.preventDefault();
										e.stopPropagation();
										open();
									}}>
									Browse Files
								</ButtonV2>
							</div>
						</div>
						<div className="file-upload-row">
							<p className="body-tiny file-upload-filetypes">
								{isDriversLicense
									? "Accepted file types: .jpg, .png, .heic, .pdf. "
									: "Accepted file types: .pdf, .doc, .txt, .csv, .xls, .jpeg, .png. "}
								Max size: 20mb.
								{numOfFilesAccepted > 1 &&
									` ${numOfFilesAccepted} files max per upload.`}
							</p>
						</div>
					</>
				)}
			</div>
			{err && (
				<div className="file-upload-error-row">
					<InformationIcon className="file-upload-error-icon" />
					<p className="sm file-upload-error-text">
						Error: {getErrorMessage(err.errors[0].code)}
					</p>
				</div>
			)}
			{Boolean(filesToUpload?.length || alreadyUploaded?.length) && (
				<FileResults
					fileUploads={filesToUpload}
					updateFilesToUpload={updateFilesToUpload}
					alreadyUploaded={alreadyUploaded}
				/>
			)}
		</div>
	);
};

export default FileUpload;
