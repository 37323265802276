export function formatNumberForAxis(n: number): string {
	if (n >= 1_000_000) {
		// Round to two decimal places
		const valueInMillions = Math.round((n / 1_000_000) * 100) / 100;
		return `$${valueInMillions}M`;
	} else if (n >= 1_000) {
		const valueInThousands = Math.round(n / 1_000);
		return `$${valueInThousands}K`;
	} else {
		const value = Math.round(n);
		return "$" + value.toString();
	}
}
