import { useEffect, useState, useMemo, useCallback } from "react";
import client from "utils/client";
import { usePropertyContext } from "../../context";
import QuestionCard from "components/elements/QuestionCard";
import { OneZeroOrNull } from "utils/types";
import { TileSelect } from "elements/TileSelect";
import {
	prefixIndefiniteArticle,
	stateSpecificAppealWord,
} from "utils/account";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import ButtonV2 from "elements/ButtonV2";

const Takeover = () => {
	const { details, activeQuestionId, send, next, propertySurveyContext } =
		usePropertyContext();

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const handleSkip = () => {
		next(true);
	};

	const handleSubmit = async (payload?: { value: OneZeroOrNull }) => {
		if (payload) {
			send({
				type: "update_context",
				context: { is_takeover: payload.value },
			});
		}

		try {
			setSubmitting(true);
			setError(false);
			await client.submitQuestionAnswer({
				questionId: activeQuestionId,
				appealId: details!.appeal_id,
				payload: {
					isTakeover: !!(payload
						? payload.value
						: propertySurveyContext.is_takeover),
				},
			});
		} catch (e) {
			console.error("ERROR", e);
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	const tiles = useMemo(
		() => [
			{
				unselectedTileContent: "Yes",
				selectedTileContent: "Yes",
				isSelected: propertySurveyContext.is_takeover === 1,
				onSelect: () => {
					handleSubmit({ value: 1 });
				},
			},
			{
				unselectedTileContent: "No",
				selectedTileContent: "No",
				isSelected: propertySurveyContext.is_takeover === 0,
				onSelect: () => {
					handleSubmit({ value: 0 });
				},
			},
		],
		[propertySurveyContext.is_takeover]
	);

	const appealWord = useMemo(
		() => stateSpecificAppealWord(details?.state),
		[details?.state]
	);

	const takeoverWarning = useMemo(() => {
		if (details?.state === "NY") {
			if (propertySurveyContext.is_takeover === 0) {
				return (
					<div className="property-profile-question-note-azure">
						<p className="sm">
							<b>Important:</b> If multiple parties are filing a grievance for
							the same property, all cases are at risk of getting dismissed.
							Please ensure this information is accurate.
						</p>
					</div>
				);
			} else if (propertySurveyContext.is_takeover === 1) {
				return (
					<div className="property-profile-question-note-rust">
						<p className="sm">
							<b>Important:</b> While we will still proceed with your case,
							there are situations where a previously filed grievance causes
							challenges with getting an Ownwell appeal scheduled. Please upload
							any hearing notices you may receive to our team.
						</p>
					</div>
				);
			}
		} else {
			if (propertySurveyContext.is_takeover === 0) {
				return (
					<div className="property-profile-question-note-azure">
						<p className="sm">
							<b>Reminder:</b> Ownwell's service is end-to-end and hassle-free.
							We'll handle all of the paperwork for you. Do not file your own{" "}
							{appealWord} or we may not be able to represent you.
						</p>
					</div>
				);
			} else if (propertySurveyContext.is_takeover === 1) {
				return (
					<div className="property-profile-question-note-azure">
						<p className="sm">
							While we will still proceed with your case, there are situations
							where a previously filed {appealWord} causes challenges with
							getting an Ownwell appeal scheduled. Please upload any hearing
							notices you may receive to our team.
						</p>
					</div>
				);
			}
		}
		return null;
	}, [details?.state, propertySurveyContext.is_takeover]);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
				next();
			}}>
			<QuestionCard
				id="primary-residence"
				tooltipText="If you have already filed an appeal for this property, we will have to file to take over as the authorized agent."
				question={
					<p className="lg bold">
						Have you or another company recently filed{" "}
						{prefixIndefiniteArticle(appealWord)} for this property?
					</p>
				}>
				<TileSelect tiles={tiles} />
			</QuestionCard>
			{takeoverWarning}
			<ButtonV2
				size="large"
				disabled={propertySurveyContext.is_takeover === null}
				className="mx-auto"
				type="submit">
				Continue
			</ButtonV2>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
		</form>
	);
};

export default Takeover;
