import { useMemo } from "react";

export type RequirementsMap = Record<string, (password: string) => boolean>;

export type RequirementsMetMap = Record<string, boolean>;

export const PASSWORD_REQUIREMENTS: RequirementsMap = {
	"At least 8 characters": p => p.length >= 8,
	"At least 1 lowercase letter": p => /[a-z]/.test(p),
	"At least 1 uppercase letter": p => /[A-Z]/.test(p),
	"At least 1 number": p => /[0-9]/.test(p),
	"At least 1 special character": p =>
		/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(p),
};

export const usePasswordRequirements = (password: string | undefined) => {
	const allRequirementsMet = useMemo(
		() =>
			Object.values(PASSWORD_REQUIREMENTS).every(isValid =>
				isValid(password ?? "")
			),
		[password]
	);

	const requirements: RequirementsMetMap = Object.entries(
		PASSWORD_REQUIREMENTS
	).reduce((acc, [requirement, isValid]) => {
		acc[requirement] = isValid(password ?? "");
		return acc;
	}, {} as RequirementsMetMap);

	return {
		allRequirementsMet,
		requirements,
	};
};
