import { useResponsiveContext } from "context/ResponsiveContext";
import {
	Dispatch,
	RefObject,
	SetStateAction,
	useEffect,
	useMemo,
	useRef,
} from "react";
import { DispatchAnswerState, InsuranceSurveyStage } from "./InsuranceSurvey";
import { InsuranceAnswerReducerState, InsuranceQuestionID } from "utils/types";
import { InsuranceSurveyQuestionCard } from "./InsuranceSurveyQuestionCard";
import { insuranceQuestions } from "./questions";
// import { InsuranceDiscountsQuestion } from "./questions/Discounts";
import { isInsuranceStageDone } from "./utils";

const DESKTOP_CONTAINER_SIZE_PX = 500;

interface Props {
	stage: InsuranceSurveyStage;
	setStage: Dispatch<SetStateAction<InsuranceSurveyStage>>;
	currentQuestionIndex: number;
	questionContainerRef: RefObject<HTMLDivElement>;
	initialQuestionsRef: RefObject<HTMLDivElement>;
	activeQuestionId: string;
	// finalQuestionRef: RefObject<HTMLDivElement>;
	answerState: InsuranceAnswerReducerState;
	dispatchAnswerState: DispatchAnswerState;
	nextBtnDisabled: boolean;
	prevBtnDisabled: boolean;
	showPrevArrow: boolean;
	next: () => void;
	prev: () => void;
}

export const InsruanceSurveyQuestions = (props: Props) => {
	const {
		currentQuestionIndex,
		questionContainerRef,
		initialQuestionsRef,
		// finalQuestionRef,
		answerState,
		dispatchAnswerState,
		nextBtnDisabled,
		prevBtnDisabled,
		showPrevArrow,
		activeQuestionId,
		setStage,
		stage,
		next,
		prev,
	} = props;

	const formRef = useRef<HTMLFormElement>(null);

	const { isDesktop } = useResponsiveContext();

	const title = useMemo(() => {
		switch (activeQuestionId as InsuranceQuestionID) {
			case "birthday":
			case "marital_status":
				// case "credit_range":
				return "We just need a few personal details to get started.";
			case "current_deductible":
			case "current_premium":
			case "current_provider":
				return "Let’s get some information about your current policy.";
		}
	}, [activeQuestionId]);

	return (
		<div
			ref={initialQuestionsRef}
			id={
				"insurance-initial-questions" /*stage === "intro"
					? "-initializing"
					: */ +
				(isInsuranceStageDone("initial-questions", stage)
					? "-done"
					: stage === "initial-questions"
					? "-active"
					: "")
			}>
			<div id="insurance-survey-title">
				<h4>{title}</h4>
			</div>
			<div id="insurance-question-wrapper">
				<div id="insurance-question-top-overlay">
					<svg height="100%" width="100%">
						<defs>
							<filter id="f1" x="0" y="0">
								<feGaussianBlur in="SourceGraphic" stdDeviation="15" />
							</filter>
						</defs>
					</svg>
				</div>
				<div
					id="insurance-question-container"
					ref={questionContainerRef}
					style={{
						height: formRef.current
							? isDesktop
								? DESKTOP_CONTAINER_SIZE_PX
								: formRef.current?.offsetHeight
							: undefined,
					}}>
					{insuranceQuestions.map((props, i) => (
						<InsuranceSurveyQuestionCard
							{...props}
							index={i}
							formRef={formRef}
							key={props.id}
							setStage={setStage}
							currentQuestionIndex={currentQuestionIndex}
							answerState={answerState[props.id]}
							dispatchAnswerState={dispatchAnswerState}
							next={next}
							nextBtnDisabled={nextBtnDisabled}
							showPrevArrow={showPrevArrow}
							prevBtnDisabled={prevBtnDisabled}
							prev={prev}
						/>
					))}
				</div>
				<div id="insurance-question-bottom-overlay">
					<svg height="100%" width="100%">
						<defs>
							<filter id="f1" x="0" y="0">
								<feGaussianBlur in="SourceGraphic" stdDeviation="15" />
							</filter>
						</defs>
					</svg>
				</div>
			</div>
		</div>
	);
	{
		/* 
			<div
				ref={finalQuestionRef}
				id={
					"insurance-final-question" +
					(isInsuranceStageDone("final-question", stage)
						? "-done"
						: stage === "final-question"
						? "-active"
						: "")
				}>
				<InsuranceDiscountsQuestion
					prev={prev}
					onSubmit={() => {
						submitAnswers();
					}}
					dispatchAnswerState={dispatchAnswerState}
				/>
			</div>
            */
	}
};
