import { useEffect, useState, useMemo, useCallback } from "react";
import client from "utils/client";
import { usePropertyContext } from "components/pages/Account/Property/context";
import { useEventTracker_DEPRECATED } from "utils/useEventTracker";
import constants from "utils/constants";
import QuestionCard from "elements/QuestionCard";
import { FileData, OneZeroOrNull } from "utils/types";
import { TileSelect, TileConfig } from "components/elements/TileSelect";
import { FileUploadType, splitFileUploads } from "elements/Input/FileUpload";
import ButtonV2 from "elements/ButtonV2";

const NoticeOfValue = () => {
	const { details, activeQuestionId, send, next, propertySurveyContext } =
		usePropertyContext();

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const handleSubmit = async (payload?: { value: OneZeroOrNull }) => {
		if (payload) {
			send({
				type: "update_context",
				context: { q_notice_received: payload.value },
			});
		}

		try {
			setSubmitting(true);
			setError(false);

			await client.submitQuestionAnswer({
				appealId: details!.appeal_id,
				questionId: activeQuestionId,
				payload: {
					value: !!(payload
						? payload.value
						: propertySurveyContext.q_notice_received),
				},
			});
			next();
		} catch (e) {
			console.error("ERROR", e);
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	const tiles = useMemo(
		() => [
			{
				unselectedTileContent: "Yes",
				selectedTileContent: "Yes",
				isSelected: propertySurveyContext.q_notice_received === 1,
				onSelect: () => {
					handleSubmit({ value: 1 });
				},
			},
			{
				unselectedTileContent: "No",
				selectedTileContent: "No",
				isSelected: propertySurveyContext.q_notice_received === 0,
				onSelect: () => {
					handleSubmit({ value: 0 });
				},
			},
		],
		[propertySurveyContext.q_notice_received]
	);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}>
			<QuestionCard
				id="notice-of-value"
				tooltipText="If your local assessor changes your property valuation for tax purposes they are required to send you a notice. This notice is typically sent by mail but can also be received electronically in some places. Please upload a copy of your value notice. In some geographies, we may be unable to protest without it."
				question={
					<p className="bold">
						Did you receive a notice of value from{" "}
						<span className="no-translate">{details?.county}</span> County?
					</p>
				}>
				<TileSelect tiles={tiles} />
			</QuestionCard>
			{details?.state.toUpperCase() === "WA" &&
				details?.county?.toUpperCase() === "KING" && (
					<div className="property-profile-question-note-rust">
						<p className="sm">
							If you answer "Yes" to this question and do not upload your
							original value notice, <span className="no-translate">King</span>{" "}
							County will not allow us to appeal on your behalf.
						</p>
					</div>
				)}
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
		</form>
	);
};

export const NoticeOfValueUpload = () => {
	const {
		details,
		next,
		send,
		propertySurveyContext,
		allUploadedPropertySurveyFiles,
	} = usePropertyContext();

	const [filesToUpload, setFilesToUpload] = useState<FileUploadType[]>([]);
	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const questionFiles = useMemo(() => {
		return allUploadedPropertySurveyFiles.filter(fileName =>
			fileName.startsWith("noticeOfValue")
		);
	}, []); // We only want to derive this value on mount

	const filesUploaded = useMemo(() => {
		return questionFiles.length > 0 || filesToUpload.length > 0;
	}, [questionFiles, filesToUpload]);

	const handleSubmit = async () => {
		try {
			setSubmitting(true);
			setError(false);

			if (filesToUpload.length) {
				const { errors, success } = await splitFileUploads({
					filesToUpload,
					updateFilesToUpload: setFilesToUpload,
					buildRequest: async fileToUpload => {
						const fd = new FormData();
						fd.append("noticeOfValue", fileToUpload.file);
						await client.uploadFile({
							form: fd,
							appealId: details!.appeal_id.toString(),
							onProgress: pe => {
								setFilesToUpload(prev =>
									prev.map(fu => {
										if (fu.file !== fileToUpload.file) return fu;
										return {
											...fu,
											progress: pe,
										};
									})
								);
							},
						});
					},
				});

				send({
					type: "update_context",
					context: {
						files: [
							...propertySurveyContext.files,
							...success.map(fileToUpload => {
								const fileData: FileData = {
									file_name: "noticeOfValue-" + fileToUpload.file.name,
									question: "noticeOfValue",
									is_ownwell_document: 0,
								};
								return fileData;
							}),
						],
					},
				});

				if (errors.length > 0) {
					setError(true);
					return;
				}
			}

			next();
		} catch (e) {
			console.error("ERROR", e);
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}>
			<QuestionCard
				id="notice-of-value-upload"
				tooltipText="If your local assessor changes your property valuation for tax purposes they are required to send you a notice. This notice is typically sent by mail but can also be received electronically in some places. Please upload a copy of your value notice. In some geographies, we may be unable to protest without it."
				question={
					<p className="bold">Pleae upload a copy of your notice of value.</p>
				}
				value={true}
				alreadyUploaded={questionFiles}
				filesLabel="Notice of Value"
				withFiles
				filesToUpload={filesToUpload}
				setFilesToUpload={setFilesToUpload}
			/>
			{!filesUploaded && (
				<div className="property-profile-question-note-rust">
					<p className="sm">
						We recommend uploading supporting documentation before continuing.
						Evidence submitted without documentation rarely results in a
						reduction.
					</p>
				</div>
			)}
			<ButtonV2
				size="large"
				disabled={submitting}
				type="submit"
				className="mx-auto">
				{submitting ? "Submitting..." : "Continue"}
			</ButtonV2>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
		</form>
	);
};

export default NoticeOfValue;
