import React, {
	createContext,
	useContext,
	useState,
	FC,
	ReactNode,
} from "react";
import { useEventTracker } from "utils/useEventTracker";

type TrackEventArgs = {
	stepName: string;
	data?: any;
	customData?: any;
	skipStep?: boolean;
};

const TrackEventsContext = createContext<
	| {
			trackAnswerEvent: (args: TrackEventArgs) => void;
			trackViewPageEvent: (args: TrackEventArgs) => void;
			initFlowName: (flowName: string) => void;
			trackBackEvent: (args: TrackEventArgs) => void;
	  }
	| undefined
>(undefined);

export const TrackEventsProvider: FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [flowName, setFlowName] = useState<string>("");

	const trackEvent = useEventTracker();

	const trackAnswerEvent = (args: TrackEventArgs) => {
		const { stepName, data, customData, skipStep = false } = args;
		if (stepName == "IDLE" || stepName == "INIT" || skipStep) return;
		const eventName = (
			flowName && flowName != ""
				? flowName + "_" + stepName + "_ANSWER"
				: stepName + "_ANSWER"
		).toUpperCase();
		const eventData = {
			...data,
			...customData,
		};
		trackEvent({
			eventName: eventName,
			data: eventData,
		});
		//console.log('ANSWER EVENT - ', eventName, eventData);
	};

	const trackViewPageEvent = (args: TrackEventArgs) => {
		const { stepName, customData, skipStep = false } = args;
		if (stepName == "IDLE" || stepName == "INIT" || skipStep) return;
		const eventName = (
			flowName && flowName != ""
				? flowName + "_" + stepName + "_VIEW"
				: stepName + "_VIEW"
		).toUpperCase();
		trackEvent({
			eventName: eventName,
			data: customData,
		});
		//console.log('VIEW PAGE EVENT - ', eventName, customData);
	};

	const trackBackEvent = (args: TrackEventArgs) => {
		const { stepName, customData, skipStep = false } = args;
		if (stepName == "IDLE" || stepName == "INIT" || skipStep) return;
		const eventName = (
			flowName && flowName != ""
				? flowName + "_" + stepName + "_BACK"
				: stepName + "_BACK"
		).toUpperCase();
		trackEvent({
			eventName: eventName,
			data: customData,
		});
	};

	const initFlowName = (flowName: string) => {
		setFlowName(flowName);
	};

	return (
		<TrackEventsContext.Provider
			value={{
				trackAnswerEvent,
				trackViewPageEvent,
				initFlowName,
				trackBackEvent,
			}}>
			{children}
		</TrackEventsContext.Provider>
	);
};

export const useGenericFlowEventTracker = () => {
	const context = useContext(TrackEventsContext);
	if (!context) {
		throw new Error(
			"useGenericFlowEventTracker must be used within a TrackEventsProvider"
		);
	}
	return context;
};
