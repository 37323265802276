import { InsuranceMaritalStatusQuestion } from "./MaritalStatus";
import { InsuranceBirthdayQuestion } from "./Birthday";
import { InsuranceCreditScoreQuestion } from "./CreditScore";
import { InsuranceCurrentProviderQuestion } from "./CurrentProvider";
import { InsuranceCurrentPremiumQuestion } from "./CurrentPremium";
import { InsuranceCurrentDeductibleQuestion } from "./CurrentDeductible";
import { InsuranceDiscountsQuestion } from "./Discounts";
import { InsuranceSurveyComponent } from "../withInsuranceSurvey";
import { InsuranceQuestionID } from "utils/types";

export const insuranceQuestions: {
	id: InsuranceQuestionID;
	component: InsuranceSurveyComponent;
}[] = [
	{
		id: "marital_status",
		component: InsuranceMaritalStatusQuestion,
	},
	{
		id: "birthday",
		component: InsuranceBirthdayQuestion,
	},
	// {
	// 	id: "credit_range",
	// 	component: InsuranceCreditScoreQuestion,
	// },
	{
		id: "current_provider",
		component: InsuranceCurrentProviderQuestion,
	},
	{
		id: "current_premium",
		component: InsuranceCurrentPremiumQuestion,
	},
	{
		id: "current_deductible",
		component: InsuranceCurrentDeductibleQuestion,
	},
	// {
	// 	id: "discounts",
	// 	component:
	// 		InsuranceDiscountsQuestion as unknown as InsuranceSurveyComponent,
	// },
];
