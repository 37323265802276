import { CSSProperties, FC, ReactElement } from "react";
import Warning from "public/warning.svg";
import { Label } from "components";
import BackArrow from "public/appeal-back-arrow.svg";
import Close from "public/close-bold.svg";

interface IExitAreYouSureProps {
	onExit: () => void;
	onCancel: () => void;
	label?: string;
	body?: string | ReactElement;
	cancelBtnContent?: string | ReactElement;
	exitBtnContent?: string | ReactElement;
	top?: number | string;
}

const ExitAreYouSure: FC<IExitAreYouSureProps> = ({
	onExit,
	onCancel,
	label,
	body,
	exitBtnContent,
	cancelBtnContent,
	top,
}) => {
	return (
		<div id="appeal-modal-overlay">
			<div id="appeal-exit-are-you-sure" style={{ top }}>
				<div id="appeal-exit-are-you-sure-text">
					<div id="appeal-exit-are-you-sure-warning">
						<Warning />
						<Label>{label ?? "Notice"}</Label>
					</div>

					<p>
						{body ?? (
							<>
								By exiting, your appeal information will be lost. <br /> Are you
								sure you want to close?
							</>
						)}
					</p>
				</div>
				<div id="appeal-exit-are-you-sure-btn-container">
					<div className="appeal-exit-are-you-sure-btn" onClick={onCancel}>
						{cancelBtnContent ? (
							typeof cancelBtnContent === "string" ? (
								<>
									<BackArrow />
									<p>{cancelBtnContent}</p>
								</>
							) : (
								cancelBtnContent
							)
						) : (
							<>
								<BackArrow />
								<p>Back</p>
							</>
						)}
					</div>
					<div className="appeal-exit-are-you-sure-btn" onClick={onExit}>
						{exitBtnContent ? (
							typeof exitBtnContent === "string" ? (
								<>
									<Close id="appeal-close-icon" />
									<p>{exitBtnContent}</p>
								</>
							) : (
								exitBtnContent
							)
						) : (
							<>
								<Close id="appeal-close-icon" />
								<p>Yes, close</p>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExitAreYouSure;
