import moment from "moment";
import { Nullable } from "./types";
import { generateAmortizationSchedule } from "./generateAmortizationSchedule";

type GenerateRefinanceSavingsParams = {
	original_loan_start_date: string;
	original_loan_interest_rate: string | number;
	original_loan_amount: number;
	original_loan_term: number;
	new_loan_term: number;
	new_loan_amount: number;
	new_loan_interest_rate: number;
};

const generateRefinanceSavingsParamsAreValid = (
	params: Partial<Nullable<GenerateRefinanceSavingsParams>>
): params is GenerateRefinanceSavingsParams => {
	const {
		original_loan_start_date,
		original_loan_interest_rate,
		original_loan_amount,
		original_loan_term,
		new_loan_term,
		new_loan_amount,
		new_loan_interest_rate,
	} = params;
	return (
		typeof original_loan_start_date === "string" &&
		typeof original_loan_interest_rate === "string" &&
		typeof original_loan_amount === "number" &&
		typeof original_loan_term === "number" &&
		typeof new_loan_term === "number" &&
		typeof new_loan_amount === "number" &&
		typeof new_loan_interest_rate === "number"
	);
};

export const generateRefinanceSavings = ({
	original_loan_start_date,
	original_loan_interest_rate,
	original_loan_amount,
	original_loan_term,
	new_loan_term,
	new_loan_amount,
	new_loan_interest_rate,
}: GenerateRefinanceSavingsParams) => {
	const original_loan_amortization_schedue = generateAmortizationSchedule({
		start_date: moment(original_loan_start_date),
		loan_term_months: original_loan_term,
		principal: original_loan_amount,
		interest_rate:
			typeof original_loan_interest_rate === "number"
				? original_loan_interest_rate
				: parseFloat(original_loan_interest_rate),
	});

	const current_monthly_payment =
		original_loan_amortization_schedue[0].principal_payment +
		original_loan_amortization_schedue[0].interest_payment;
	const current_remaining_interest = original_loan_amortization_schedue
		.filter(row => row.date >= moment().format("YYYY-MM-DD"))
		.reduce((total, row) => total + row.interest_payment, 0);

	const refinanced_loan_amortization_schedule = generateAmortizationSchedule({
		start_date: moment(),
		loan_term_months: new_loan_term,
		principal: new_loan_amount,
		interest_rate: new_loan_interest_rate,
	});

	const monthly_payment_after_refinance =
		refinanced_loan_amortization_schedule[0].principal_payment +
		refinanced_loan_amortization_schedule[0].interest_payment;
	const total_interest_after_refinance =
		refinanced_loan_amortization_schedule.reduce(
			(total, row) => total + row.interest_payment,
			0
		);

	const monthly_savings_after_refinance =
		current_monthly_payment - monthly_payment_after_refinance;
	const total_interest_saved_after_refinance =
		current_remaining_interest - total_interest_after_refinance;

	const higher_payment =
		monthly_payment_after_refinance > current_monthly_payment;
	const lower_payment =
		monthly_payment_after_refinance < current_monthly_payment;

	return {
		higher_payment,
		lower_payment,
		monthly_payment_after_refinance,
		total_interest_after_refinance,
		monthly_savings_after_refinance,
		total_interest_saved_after_refinance,
	};
};

export const generateRefinanceSavingsResults = (
	params: Partial<Nullable<GenerateRefinanceSavingsParams>>
): Nullable<ReturnType<typeof generateRefinanceSavings>> => {
	if (generateRefinanceSavingsParamsAreValid(params)) {
		return generateRefinanceSavings(params);
	} else {
		return {
			higher_payment: null,
			lower_payment: null,
			monthly_payment_after_refinance: null,
			total_interest_after_refinance: null,
			monthly_savings_after_refinance: null,
			total_interest_saved_after_refinance: null,
		};
	}
};
