import NextImage, { ImageProps, StaticImageData } from "next/image";

interface Props extends ImageProps {
	openInNewTab?: boolean;
}

const getActualSrcFromOptimizedSrc = (src: string) => {
	src = src.replace("/_next/static/image/public/", "/");
	const srcSplit = src.split("/");
	const [fileName] = srcSplit.splice(-1, 1);
	const fileNameSplit = fileName.split(".");
	fileNameSplit.splice(1, 1);
	const newFileName = fileNameSplit.join(".");
	return [...srcSplit, newFileName].join("/");
};

export const Image = (props: Props) => {
	if (props.openInNewTab) {
		if (typeof props.src === "string") {
			return (
				<a
					href={props.src}
					onClick={() =>
						window.open(props.src as string, "_blank", "noreferrer noopener")
					}>
					<NextImage
						{...props}
						style={{
							maxWidth: "100%",
							height: "auto",
						}}
					/>
				</a>
			);
		} else {
			const actualSrc = getActualSrcFromOptimizedSrc(
				(props.src as StaticImageData).src
			);
			return (
				<a
					href={actualSrc}
					onClick={() => {
						window.open(actualSrc, "_blank", "noreferrer noopener");
					}}>
					<NextImage
						{...props}
						style={{
							maxWidth: "100%",
							height: "auto",
						}}
					/>
				</a>
			);
		}
	}

	return (
		<NextImage
			{...props}
			style={{
				maxWidth: "100%",
				height: "auto",
			}}
		/>
	);
};
