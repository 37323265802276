import { useOfferContext } from "context/OfferContext";
import { createPortal } from "react-dom";
import NextImage from "next/image";
import Close from "public/close.svg";
import MarketingOfferImg from "public/landing/marketing-offer.png";
import QuickLink from "./QuickLink";

const OfferModalEl = () => {
	const { showOfferModal, setShowOfferModal, offerContents } =
		useOfferContext();

	if (showOfferModal && offerContents) {
		return (
			<div
				id="offer-modal-container"
				onClick={() => {
					setShowOfferModal(false);
				}}>
				<style jsx global>
					{`
						#modal-portal {
							display: initial;
						}
					`}
				</style>
				<div
					id="offer-modal"
					onClick={e => {
						e.stopPropagation();
						e.preventDefault();
					}}>
					<Close
						id="offer-modal-close"
						height="10px"
						width="10px"
						onClick={() => {
							setShowOfferModal(false);
						}}
					/>
					<NextImage
						src={MarketingOfferImg}
						alt="Marketing offer - Woman sitting outside of her lovely home"
						style={{
							maxWidth: "100%",
							height: "auto",
						}}
					/>
					<div id="offer-modal-body">
						<h3 className="offer-modal-title">{offerContents.title}</h3>
						<p className="offer-modal-subtitle">{offerContents.subtitle}</p>
						<p className="sm offer-modal-terms">
							All offers are valid only when setting up a new and valid Ownwell
							account. New customers signing up for an account must be the
							property owner on record with the local County Assessor’s Office.
							The new account must be started by either using the specific URL
							indicated or by scanning the QR code printed on the advertisement.
						</p>
						<div>
							<QuickLink
								size="small"
								href="/terms"
								onClick={() => {
									setShowOfferModal(false);
								}}>
								View Terms
							</QuickLink>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return null;
};

const OfferModal = () => {
	if (typeof window === "undefined") return null;
	const portal = createPortal(
		<OfferModalEl />,
		document.getElementById("modal-portal")!
	);
	return portal;
};

export default OfferModal;
